import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'styleguide/components/Button';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import { ORDER_URL } from 'helpers/routes';
import { ReactComponent as Icon } from 'components/icons/IconNoMatters.svg';

import { EmptyStateWrapper, EmptyStateIcon } from './styled';
import { useDeviceState } from 'state/deviceState';

const EmptyState: FC = () => {
  const history = useHistory();
  const [deviceState] = useDeviceState();

  const handleRedirectToOrderForm = useCallback(() => {
    history.push(ORDER_URL);
  }, [history]);

  return (
    <EmptyStateWrapper>
      <Spacing
        direction="top"
        value={deviceState.isMobileOrTablet ? 80 : 136}
      />
      <EmptyStateIcon>
        <Icon />
      </EmptyStateIcon>
      <Spacing direction="top" value={deviceState.isMobileOrTablet ? 10 : 42} />
      <BasicHeading
        tag={deviceState.isMobileOrTablet ? 'div' : 'h2'}
        size={deviceState.isMobileOrTablet ? 'sm' : 'xxl'}
      >
        You have no saved matters
      </BasicHeading>
      <Spacing direction="top" value={deviceState.isMobileOrTablet ? 10 : 62} />
      {deviceState?.isDesktop && (
        <Button onClick={handleRedirectToOrderForm} resetOrder medium primary>
          Place an order
        </Button>
      )}
      <Spacing
        direction="top"
        value={deviceState.isMobileOrTablet ? 100 : 242}
      />
    </EmptyStateWrapper>
  );
};

export default EmptyState;
