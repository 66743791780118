import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import SimpleLayout from 'layout/simpleLayout';
import Spacing from 'styleguide/components/Spacing';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';

import { Wrapper } from './styled';

const NotFound: FC = () => (
  <>
    <Helmet>
      <title>New Order</title>
    </Helmet>
    <SimpleLayout>
      <Wrapper>
        <Spacing direction="top" value={80} />

        <Spacing direction="top" value={40} />
       
        <Spacing direction="top" value={18} />
        <BasicHeading tag="h1" size="xxl" isCentered>
          <FormattedMessage id="newOrder.title" />
        </BasicHeading>
        <Spacing direction="top" value={18} />
        <BasicHeading tag="h1" size="sm" style={{textAlign:'center'}}>
        
          <FormattedMessage id="newOrder.discription"/>
        </BasicHeading>
        <Spacing direction="top" value={34} />
       <Link to="/dashboard">
          <Button primary>
            <FormattedMessage id="newOrder.button" />
          </Button>
          </Link>
      
      </Wrapper>
    </SimpleLayout>
  </>
);

export default NotFound;
