import React, { FC, useState } from 'react';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Persist } from 'formik-persist';
import FullLayout from 'styleguide/components/FullLayout';
import Button, { RightButtonIcon } from 'styleguide/components/Button';
import Error from 'styleguide/components/Error';
import { ChevronRight, ChevronLeft } from 'styleguide/components/Icon';
import Tooltip from 'styleguide/components/Tooltip';
import { OrderFormStateSubscriber } from './state/state';
import Navigation from './components/Navigation';

import CreateGridAside from './components/searches/CreateGridAside';
import SelectSearchTypesAside from './components/searches/SelectSearchTypesAside';
import TableForm from './components/TableForm';
import CellSelectForm from './components/searches/CellSelectForm';
import SearchesCellProductForm from './components/searches/SearchesCellProductForm';
import GridHeaderSelect from './components/searches/GridHeaderSelect';
import JurisdictionSelectField from './components/JurisdictionSelectField';
import ProductSelectColumnForm from './components/ProductSelectColumnForm';
import DeleteModal from './components/DeleteModal';
import OrderFormObserver from './components/OrderFormObserver';
import { useScrollTop } from 'helpers/hooks';

import { filterEmptyValues } from './utils';
import InstructionModal from './components/InstructionModal';
import styled from 'styled-components';

const LayoutAside = styled(FullLayout.Aside)`
  padding: 0px;
`;

export const SearchesSchema = Yup.object().shape({
  names: Yup.array()
    .of(
      Yup.string()
        .min(3, 'Names must be 3 or more characters')
        .required('Please add a name')
        .test({
          name: 'unique',
          message: 'Remove duplicate name',
          test: function (val: any) {
            if (val) {
              const valueIndex = this.parent.findIndex(
                (name: string) =>
                  name && name.toLowerCase() === val.toLowerCase()
              );
              return `names[${valueIndex}]` === this.path;
            }
            return true;
          }
        })
    )
    .required('Please add a name')
    .min(1, 'Please add a name'),
  jurisdictions: Yup.array()
    .of(
      Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required()
        })
        .required('Please add a jurisdiction').nullable()
    )
    .required('Please add a jurisdiction'),
  selection: Yup.array()
    .nullable()
    .required('Please select at least one cell')
    .test({
      name: 'hasNames',
      message: 'Match a name for each jurisdiction',
      test: function (val: any) {
        const errors = [];

        for (let i in this.parent.jurisdictions) {
          const hasError = Object.values(get(val, `[${i}]`, {})).filter(Boolean).length === 0;
          if (hasError) errors.push(`jurisdictions.${i}`);
          if (hasError) {
            return this.createError({
              path: `jurisdictions.${i}`,
              message: 'Match a name for each jurisdiction'
            });
          }
        }
        return errors.length === 0;
      }
    })
    .test({
      name: 'hasJurisdictions',
      message: 'Match a jurisdiction for each name',
      test: function (val: any) {
        const errors = [];
        for (let i in this.parent.names) {
          const hasNoValues = !this.parent.jurisdictions
            .map((_: any, j: number) => get(val, `[${j}][${i}]`, null))
            .filter(Boolean).length;
          if (hasNoValues) errors.push(`names.${i}`);
          if (hasNoValues) {
            return this.createError({
              path: `names.${i}`,
              message: 'Match a jurisdiction for each name'
            });
          }
        }
        return errors.length === 0;
      }
    })
    .test({
      name: 'hasSelection',
      message: 'Select at least one search type',
      test: function (val: any) {
        if (this.parent.state === 0) return true;
        const hasAnySearchTypesSelected = val.some((col: any) =>
          Object.values(col).some((row: any) =>
            (row || []).some((value: any) => value && value.length)
          )
        );
        return hasAnySearchTypesSelected;
      }
    })
});

export enum SearchFormStates {
  Grid,
  Types
}

export type JurisdictionValue = {
  value: string;
  label: string;
};

export interface SearchesFormValues {
  names?: string[];
  jurisdictions?: JurisdictionValue[];
  selection?: string[][][];
  state: SearchFormStates;
}

const SearchesError = ({
  message,
  submitCount
}: {
  message: string;
  submitCount: number;
}) =>
  message &&
    message.length &&
    (message !== 'Please add a name' || submitCount > 0) ? (
      <Error>{message}</Error>
    ) : null;

const JurisdictionsError = ({ errors }: { errors: any }) =>
  errors.filter((m: any) => !!m)
    .map((m: any, i: number) => <Error key={i}>{typeof m === 'string' ? m : 'Please add a jurisdiction'}</Error>);

const Searches: FC = () => {
  const { ref, scrollTop } = useScrollTop();
  const [isOpen, setIsOpen] = useState(localStorage.getItem("instruction") === "true" ? false: true);
  const [isFirst, setIsFirst] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <OrderFormStateSubscriber>
      {(state, actions) => (
        <Formik<SearchesFormValues>
          initialValues={
            {
              names: [''],
              jurisdictions: [''],
              state: SearchFormStates.Grid,
              ...(state.searches || {})
            } as SearchesFormValues
          }
          onSubmit={(values, helpers) => {
            const filteredValues = filterEmptyValues(values);
            helpers.setValues(filteredValues);
            if (values.state === SearchFormStates.Grid) {
              setIsFirst(false);
              helpers.setFieldValue('state', SearchFormStates.Types);
            } else {
              actions.submitStep(filteredValues);
            }
            scrollTop();
          }}
          validateOnBlur={false}
          validateOnChange={isFirst}
          validationSchema={SearchesSchema}
          validate={async(values) => {
            setIsFirst(true);
            try {
              await SearchesSchema.validate(values);
              return {};
            } catch (error:any) {
              return {
                [error?.path]: error?.message
              };
            }
          }}
        >
          {({ values, errors, touched, submitCount }) => {
            return (
            <Form>
              <OrderFormObserver />
              <FullLayout.Wrapper>
                <Navigation />
                <FullLayout.Container>
                  <LayoutAside>
                    {values.state === SearchFormStates.Grid ? (
                      <CreateGridAside />
                    ) : (
                        <SelectSearchTypesAside />
                      )}
                  </LayoutAside>
                  <FullLayout.Content autoScroll ref={ref}>
                    <TableForm
                      selectComponent={JurisdictionSelectField}
                      component={
                        values.state === SearchFormStates.Grid
                          ? CellSelectForm
                          : SearchesCellProductForm
                      }
                      headerSelectComponent={
                        values.state === SearchFormStates.Grid
                          ? GridHeaderSelect
                          : ProductSelectColumnForm
                      }
                    />
                    <DeleteModal />
                  </FullLayout.Content>
                </FullLayout.Container>
                <FullLayout.Footer>
                  <FullLayout.Left>
                    <Tooltip/>
                    <Button data-tip="Delete everything in this section" small type="button" onClick={actions.showDeleteModal}>
                      Delete
                  </Button>
                  </FullLayout.Left>
                  <FullLayout.Center>
                    {touched.names && errors.names && errors.names.length && (
                      <SearchesError
                        submitCount={submitCount}
                        message={
                          typeof errors.names === 'string'
                            ? errors.names
                            : errors.names.find(
                              (el, i) =>
                                Boolean(el) &&
                                ((touched.names as unknown) as boolean[])[i]
                            )
                        }
                      />
                    )}
                    {
                      touched.jurisdictions &&
                      errors.jurisdictions &&
                      errors.jurisdictions.length &&
                      <JurisdictionsError errors={errors.jurisdictions} />
                    }

                    {Boolean(
                      !errors.jurisdictions &&
                      !errors.names &&
                      submitCount > 1 && // switching steps is treated like submit, hence 1 not 0
                      errors.selection
                    ) && <Error>{errors.selection}</Error>}
                  </FullLayout.Center>
                  <FullLayout.Right>
                    <Button type="button" onClick={actions.previousStep}>
                      <ChevronLeft />
                    </Button>
                    <Button type="submit" primary>
                      {values.state === SearchFormStates.Grid
                        ? 'Save grid & continue'
                        : 'Next'}
                      <RightButtonIcon>
                        <ChevronRight />
                      </RightButtonIcon>
                    </Button>
                  </FullLayout.Right>
                </FullLayout.Footer>
              </FullLayout.Wrapper>
              <Persist name="searches-form" />
              <InstructionModal isOpen={isOpen} onClose={onClose} />
            </Form>
          )}}
        </Formik>
      )}
    </OrderFormStateSubscriber>
  );
};

export default Searches;
