import React, { FC, useState, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { LOGIN_URL } from 'helpers/routes';
import { useLocation } from 'react-router-dom';

import Spacing from 'styleguide/components/Spacing';
import { LeftButtonIcon } from 'styleguide/components/Button';
import { ChevronLeft } from 'styleguide/components/Icon';
// import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
import RegistrationFirstStep from './RegistrationFirstStep';
import RegistrationSecondStep from './RegistrationSecondStep';
// import { STATES } from '../contants';
import {
  RightColumnContent,
  Header,
  HeaderLink,
  HeaderButton,
  // FooterText
} from './styled';
import logo from 'components/icons/dealbinder.png';
import { useDeviceState } from 'state/deviceState';
export interface RegistrationFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  firmName: string;
  // firmAddress: string;
  // city: string;
  // state: typeof STATES[number];
  // postalCode: string;
  phone: string;
  hasAcceptedTerms: boolean;
}

const RegistrationRightColumn: FC = () => {
  const location = useLocation();
  const [state] = useDeviceState();
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    firmName: '',
    // firmAddress: '',
    // city: '',
    // state: '',
    // postalCode: '',
    phone: '',
    hasAcceptedTerms: true
  });

  const onNextStep = useCallback((values: RegistrationFormValues): void => {
    setFormValues(values);
    setCurrentStep(prevState => prevState + 1);
  }, []);

  const onPreviousStep = useCallback((values: RegistrationFormValues): void => {
    setFormValues(values);
    setCurrentStep(prevState => prevState - 1);
  }, []);

  const currentStepComponent = useMemo(
    () =>
      [
        <RegistrationFirstStep
          initialValues={formValues}
          onNextStep={onNextStep}
        />,
        <RegistrationSecondStep initialValues={formValues} />
      ][currentStep],
    [onNextStep, currentStep, formValues]
  );

  return (
    <RightColumnContent className="container-fluid">
      {currentStep === 0 && state.isDesktop && (
        <Header>
          <a href="https://www.dealbinder.com">
            <img src={logo} style={{ width: '133px' }} alt="Dealbinder logo" />
          </a>
          <HeaderLink to={`${LOGIN_URL}${location.search}`}>
            <FormattedMessage id="shared.signIn" />
          </HeaderLink>
        </Header>
      )}
       {currentStep === 1 && state.isMobileOrTablet && <Spacing direction="top" value={-30} />}

      {currentStep === 1 && <Header>

        {currentStep === 1 && (
          <HeaderButton onClick={() => onPreviousStep(formValues)}>
            <LeftButtonIcon>
              <ChevronLeft />
            </LeftButtonIcon>
            <FormattedMessage id="shared.back" />
          </HeaderButton>
        )}


        {state.isDesktop ? (
          <HeaderLink to={`${LOGIN_URL}${location.search}`}>
            <FormattedMessage id="shared.signIn" />
          </HeaderLink>
        ) : null}

      </Header>}


      {currentStep === 0 && state.isDesktop && <Spacing direction="top" value={25} />}
      {currentStep === 1 && state.isDesktop && <Spacing direction="top" value={25} />}

      {/* <BasicSubHeading size="xxs" isUppercase>
        <FormattedMessage
          id="registration.step"
          values={{ currentStep: currentStep + 1 }}
        />
      </BasicSubHeading>
      <Spacing direction="top" value={4} /> */}
      {currentStepComponent}

      {/* <FooterText className="text-lg-left text-center">
        <LegalPagesModal
          title={<FormattedMessage id="links.privacyPolicy" />}
          type="privacyPolicy"
        />{' '}
        and{' '}
        <LegalPagesModal
          title={<FormattedMessage id="links.termsOfService" />}
          type="termsOfService"
        />
      </FooterText> */}
    </RightColumnContent>

  );
};

export default RegistrationRightColumn;
