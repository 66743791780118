import styled from 'styled-components';
import TextInput from '../TextInput';
import SearchIcon from 'components/icons/IconSearch.svg';

const SearchInput = styled(TextInput)`
  background: #fff url(${SearchIcon}) left 16px center no-repeat;
  padding-left: 40px;
  margin: 0;
  @media (max-width: 768px) {
    background: #fff url(${SearchIcon}) left 16px center no-repeat;
    padding-left: 40px;
    margin: 0;
  } 
`;

export default SearchInput;
