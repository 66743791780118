import React, { FC } from 'react';
import Tag from 'styleguide/components/Tag';
import MaterialTable, { cellStyles } from 'styleguide/components/MaterialTable';
import { Order } from 'api/orders';
import { ReactComponent as DeleteIcon } from 'components/icons/IconDelete2.svg';
import collaborationIcon from 'components/icons/collaboration-icon.svg';
import { getMatterUrl } from 'helpers/routes';
import Tooltip from 'styleguide/components/Tooltip';
import { useDeviceState } from 'state/deviceState';
import { format, parseISO } from 'date-fns';

import {
  TableButtonWrapper,
  TableButton,
  MatterLink,
  MiddleIcon
} from './styled';
import { DeleteMatterModalData } from './';

interface TableProps {
  orders: Order[];
  isLoading: boolean;
  totalCount: number;
  onChangePage: (page: number) => Promise<void>;
  currentPage: number;
  setDeleteMatterData: (data: DeleteMatterModalData) => void;
  onDelete: () => void;
}

const getWithoutBorderStyle = (isFirstItem: boolean) =>
  isFirstItem === false ? cellStyles.withoutBorder : {};

const Table: FC<TableProps> = ({
  orders,
  isLoading,
  totalCount,
  onChangePage,
  currentPage,
  setDeleteMatterData,
  onDelete
}) => {
  const [deviceState] = useDeviceState();
  const columns = [
    {
      title: 'Matter',
      field: 'matterName',
      cellStyle: (_: any, { isFirstItem }: any) => {
        return deviceState.isMobile
          ? {
            ...getWithoutBorderStyle(isFirstItem),
            width: '30%'
          }
          : {
            ...cellStyles.bold,
            ...getWithoutBorderStyle(isFirstItem),
            width: '30%'
          }
      },
      render: ({ matterId, matterName, isFirstItem, isOwner }: any) =>
        isFirstItem && (
          <>
            <MatterLink to={getMatterUrl(matterId)}>{matterName}</MatterLink>{' '}
            {!isOwner && (
              <>
                <MiddleIcon
                  src={collaborationIcon}
                  alt="Collaboration"
                  data-tip
                  data-for={`collaboration.${matterId}`}
                />
                <Tooltip id={`collaboration.${matterId}`}>
                  Shared with me
                </Tooltip>
              </>
            )}
          </>
        )
    },
    {
      title: 'Order status',
      field: 'status',
      cellStyle: (_: any, { isFirstItem }: any) =>
        getWithoutBorderStyle(isFirstItem),
      render: ({ status }: any) => {
        if (status === 'In Progress') {
          return (
            <Tag color="orange">
              {' '}
              {deviceState.isMobile ? 'In Prog' : status}
            </Tag>
          );
        }
        if (status === 'Complete') {
          return (
            <Tag color="cyan" isFilled>
              {status}
            </Tag>
          );
        }
        return null;
      }
    },
    ...(deviceState.isMobile
      ? []
      : [
        {
          title: 'Assigned to',
          field: 'assignedTo',
          cellStyle: (_: any, { isFirstItem, assignedTo }: any) => ({
            ...getWithoutBorderStyle(isFirstItem),
            ...(assignedTo ? {} : cellStyles.gray)
          }),
          render: ({ assignedTo, isFirstItem }: any) =>
            isFirstItem && (assignedTo || '-')
        }
      ]),
    {
      title: 'Order date',
      field: 'orderDate',
      cellStyle: (_: any, { isFirstItem }: any) =>
        getWithoutBorderStyle(isFirstItem),
      render: ({ orderDate }: any) =>
        orderDate ? format(parseISO(orderDate), 'M/dd/yy') : null
    },
    {
      title: 'Invoice status',
      field: 'invoiceStatus',
      cellStyle: (_: any, { isFirstItem }: any) =>
        getWithoutBorderStyle(isFirstItem),
      render: ({ invoiceStatus, invoiceLink }: any) => {
        switch (invoiceStatus) {
          case 'Paid': {
            return (
              <a href={invoiceLink}
                target="_blank"
                style={{ whiteSpace: 'nowrap', ...cellStyles.green }}
                rel="noopener noreferrer">
                {invoiceStatus}
              </a>
            );
          }
          case 'Outstanding': {
            return (
              <a href={invoiceLink}
                target="_blank"
                style={{ whiteSpace: 'nowrap', ...cellStyles.blue }}
                rel="noopener noreferrer">
                {invoiceStatus}
              </a>
            );
          }
          default: {
            return invoiceLink ? (
              <a href={invoiceLink} target="_blank" rel="noopener noreferrer">
                View
              </a>
            ) : (
              <span style={{ whiteSpace: 'nowrap', ...cellStyles.gray }}>
                {invoiceStatus}
              </span>
            );
          }
        }
      }
    },
    ...(deviceState.isMobile
      ? []
      : [
        {
          title: '',
          field: 'actions',
          cellStyle: (_: any, { id, isFirstItem }: any) =>
            getWithoutBorderStyle(isFirstItem),
          render: ({
            id,
            matterName,
            matterId,
            isFirstItem,
            isOwner
          }: any) => {
            if (!isFirstItem) {
              return null;
            }

            return (
              <TableButtonWrapper>
                {isOwner && (
                  <>
                    <Tooltip id={`${id}-matter-delete-tooltip`}>
                      Delete
                      </Tooltip>
                    <TableButton
                      data-tip
                      data-for={`${id}-matter-delete-tooltip`}
                      onClick={() => { onDelete(); setDeleteMatterData({ matterId, matterName }); }}
                    >
                      <DeleteIcon />
                    </TableButton>
                  </>
                )}
              </TableButtonWrapper>
            );
          }
        }
      ])
  ];

  return (
    <MaterialTable
      paginationItemLabel="orders"
      totalCount={totalCount}
      currentPage={currentPage - 1}
      onChangePage={(event, page) => {
        onChangePage(page + 1);
      }}
      materialTableProps={{
        columns: columns,
        data: orders,
        isLoading
      }}
      paginationPosition="top"
      pageSize={20}
    />
  );
};

export default Table;
