import React from 'react';
import { useFormikContext } from 'formik';
import Button from 'styleguide/components/Button';
import { HeaderSelectComponent } from '../TableForm';
import { SearchesFormValues } from '../../searches';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  font-size: 13px;
`;

const GridHeaderSelect: HeaderSelectComponent = ({ col }) => {
  const { values, setFieldValue } = useFormikContext<SearchesFormValues>();
  const selected =
    values.selection &&
    values.selection[col]?.filter(Boolean).length === values.names.length;
  return (
    <StyledButton
      type="button"
      variant="inline"
      onClick={() => {
        if (selected) {
          setFieldValue(`selection.${col}`, []);
        } else {
          setFieldValue(
            `selection.${col}`,
            values.names.map((): string[] => [])
          );
        }
      }}
    >
      {selected ? 'Unselect All' : 'Select All'}
    </StyledButton>
  );
};

export default GridHeaderSelect;
