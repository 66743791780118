import { useRef, useEffect, useCallback } from 'react';

export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}

export function useScrollTop() {
  const ref = useRef();

  const scrollTop = useCallback(() => {
    if (ref.current) {
      const el = ref.current as any;
      el.scrollTop = 0;
      el.scrollLeft = 0;
    }
  }, [ref]);

  return {ref, scrollTop} as any;
};