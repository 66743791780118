import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import StyleguideSidebarButton from 'styleguide/components/SidebarButton';
import StyleguideButton from 'styleguide/components/Button';
import StyleguideHorizontalLine from 'styleguide/components/HorizontalLine';
import StyleguideSidebarLink from 'styleguide/components/SidebarLink';
import StyleguideBasicText from 'styleguide/components/BasicText';
import sidebarPattern from 'components/icons/SidebarPattern.svg';

export const Wrapper = styled.div<{collapsed?:boolean}>`
  width: ${({collapsed})=> !collapsed ? '220px': 'auto'};
  flex-shrink: 0;
  background: ${({ theme }) => theme.colorsNewDesign.blueDark};
  display: flex;
  flex-direction: column;
  svg{
    margin: ${({collapsed})=> collapsed ? 'auto': 0}
  }
`;

export const ContentContainer = styled.div`
  padding: 15px 24px;
`;

export const LogoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled(StyleguideButton)<{collapsed?:boolean}>`
  order: 0;
  padding: ${({collapsed})=> collapsed ?  '3px 0px': 'inherit'};
`;

export const SidebarButton = styled(StyleguideSidebarButton)`
  margin-left: auto;
`;

export const HorizontalLine = styled(StyleguideHorizontalLine)`
  background: ${({ theme }) => theme.colorsNewDesign.gray100};
  opacity: 0.1;
`;

export const LinksContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: url(${sidebarPattern}) no-repeat bottom left;
  & #dashboard-sidebar-order{
    z-index:9999;
  }
`;

export const SidebarLink = styled(StyleguideSidebarLink)<{collapsed?:boolean}>`
  margin: ${({collapsed})=> collapsed ? '0 auto': 0};
  margin-top: 17px;
  & > div {
    margin: ${({collapsed})=> collapsed ? '0 auto': '0px 10px 0px 0px'};
  }
`;

export const BottomLinksWrapper = styled.div<{collapsed?:boolean}>`
  margin-top: auto;
  align-self: ${({collapsed})=> collapsed ? 'center':'auto'};
`;

export const FooterWrapper = styled.div`
  display: flex;
`;

export const FooterGrayText = styled(StyleguideBasicText)`
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colorsNewDesign.gray500};
`;

export const FooterText = styled(StyleguideBasicText)`
  color: white;
`;

export const Link = styled(RouterLink)`
  :hover {
    text-decoration: none;
  }
`;

export const SupportModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 24px;

  @media (max-width: 500px) {
    width: auto;
  }  `;

export const SupportModalConfirmationWrapper = styled.div`
  width: 600px;
  padding: 31px 56px 52px 65px;

  @media (max-width: 500px) {
    width: auto;
    padding: 10px 20px 20px 10px;
  }
`;

export const SupportModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 16px;
  align-items: center;
`;

export const SupportModalReferToMatterContainer = styled.div`
  padding-left: 24px;
  width: 256px;
`;

export const SupportModalRadioList = styled.div`
  display: flex;

  > * + * {
    margin-left: 45px;
  }
`;

export const Logo = styled.div`
  cursor: pointer;
`;
export const CollapseBtn = styled.div<{collapsed?:boolean}>`
  cursor: pointer;
  color: #fff;
  padding: 1px;
  border-radius: 4px;
  position: relative;
  svg {
    display: flex;
    height: 30px;
    width: 28px;
  }
  & #dashboard-sidebar-collapse {
    z-index:9999;
  }
`;
export const RegistrationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  text-align: center;
`;

export const RegistrationModalButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
  },
  a {
    img {
      width: 133px;
    }
  }
`;

export const ImageLogo = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
`;
