import React, { FC, useCallback, useState, useMemo } from 'react';
import { ValueType } from 'react-select';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled from 'styled-components';

import { ReviewHistory, DownloadedFileData } from 'api/matter';
import NavButton from 'styleguide/components/NavButton';
import BasicHeading from 'styleguide/components/BasicHeading';
import Select, {
  OptionType
} from 'styleguide/components/Select/reactSelectMobilePdf';
import { ReactComponent as ChevronLeft } from 'components/icons/IconChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as EmailIcon } from 'components/icons/IconEmail2.svg';
import { ReactComponent as CloseIcon } from 'components/icons/IconClose.svg';

import ReviewSelectForm from '../../ReviewSelectForm';
import {
  NavWrapper,
  NavRightSection,
  NavDescription,
  Dot,
  MobileNavWrapper,
  NavSubHeadingContainer,
  NavSubAddressContainer
} from '../styled';
import DealbinderMessage from '../../DealbinderMessage';
import MoreIcon from '@material-ui/icons/MoreVert';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useDeviceState } from 'state/deviceState';

const NavButtonPdf = styled(NavButton)`
  border: none;
  padding: 0px;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreButton: {
      width: '30px',
      minWidth: '0px!important'
    },
    menu: {
      zIndex: 99
    }
  })
);

interface NavProps {
  orderLineItemId: string;
  reviewHistory: ReviewHistory[];
  onClose: () => void;
  name: string;
  documentType: string;
  jurisdiction: string;
  showPrevArrow: boolean;
  showNextArrow: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  openDownloadModal: () => void;
  downloadFiles: (filesIds: string[]) => Promise<DownloadedFileData>;
  openEmailModal: () => void;
  dealbinderMessage?: string;
}

const Nav: FC<NavProps> = ({
  orderLineItemId,
  reviewHistory,
  onClose,
  name,
  documentType,
  jurisdiction,
  showPrevArrow,
  showNextArrow,
  onPrevClick,
  onNextClick,
  openDownloadModal,
  downloadFiles,
  openEmailModal,
  dealbinderMessage
}) => {
  const link = React.useRef(null);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
  const classes = useStyles();
  const [deviceState] = useDeviceState();

  const downloadSelectRef = useOnclickOutside(() => {
    if (isDownloadDropdownOpen) {
      setIsDownloadDropdownOpen(false);
    }
  });

  const handleChange = useCallback(
    (option: ValueType<OptionType>) => {
      const opt = option as OptionType;
      if (opt.value === 'single') {
        downloadFiles([orderLineItemId]).then(fileData => {
          const href = window.URL.createObjectURL(
            new Blob([fileData.data], { type: fileData.type })
          );
          const a = link.current;
          a.download = fileData.filename;
          a.href = href;
          a.click();
          a.href = '';
        });
      }
      if (opt.value === 'multiple') {
        openDownloadModal();
      }

      if (opt.value === 'email') {
        openEmailModal();
      }

      setIsDownloadDropdownOpen(false);
    },
    [openDownloadModal, orderLineItemId, downloadFiles, openEmailModal]
  );

  const DownloadButton: FC = useMemo(
    () => () => (
      <NavButtonPdf onClick={() => setIsDownloadDropdownOpen(true)}>
        <MoreIcon />
      </NavButtonPdf>
    ),
    [setIsDownloadDropdownOpen]
  );

  const displayHeading = (title: any) => {
    if (title && title.length > 25 && deviceState.isMobile) {
      return title.slice(0, 25) + '...';
    } else {
      return title;
    }
  };

  return (
    <NavWrapper>
      {/* eslint-disable-next-line */}
      <a ref={link} />
      <MobileNavWrapper>
        <NavSubHeadingContainer>
          <NavButton onClick={onClose}>
            <CloseIcon />
          </NavButton>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <BasicHeading tag="div" size="sm">
              {name}
            </BasicHeading>
            <NavRightSection>
              {/* <ReviewSelectForm id={orderLineItemId} reviewHistory={reviewHistory} /> */}

              {showPrevArrow && (
                <>
                  <NavButtonPdf
                    data-tip
                    data-for="pdf-viewer-arrow-left"
                    onClick={onPrevClick}
                  >
                    <ChevronLeft />
                  </NavButtonPdf>
                </>
              )}

              {showNextArrow && (
                <>
                  <NavButtonPdf
                    data-tip
                    data-for="pdf-viewer-arrow-right"
                    onClick={onNextClick}
                  >
                    <ChevronRight />
                  </NavButtonPdf>
                </>
              )}

              {
                <div ref={downloadSelectRef}>
                  <Select
                    options={[
                      { label: 'Download this file', value: 'single' },
                      { label: 'Download multiple files', value: 'multiple' },
                      { label: 'Email', value: 'email' }
                    ]}
                    components={{ Control: DownloadButton }}
                    onChange={handleChange}
                    value={null}
                    menuIsOpen={isDownloadDropdownOpen}
                    className={classes.moreButton}
                  />
                </div>
              }

              {false && (
                <>
                  {' '}
                  <NavButtonPdf
                    onClick={openEmailModal}
                    data-tip
                    data-for={`pdfviewer.nav.${orderLineItemId}`}
                  >
                    <EmailIcon />
                    <span>Email</span>
                  </NavButtonPdf>
                </>
              )}
            </NavRightSection>
          </div>
        </NavSubHeadingContainer>

        <NavSubAddressContainer>
          <NavDescription size="sm" isUppercase>
            {displayHeading(documentType)}
          </NavDescription>
          <Dot />
          <NavDescription size="sm" isUppercase>
            {displayHeading(jurisdiction)}
          </NavDescription>
          <div className="d-flex align-items-center" style={{ marginLeft: 'auto', marginRight: '50px' }}>
            <ReviewSelectForm
              id={orderLineItemId}
              reviewHistory={reviewHistory}
            />
            <DealbinderMessage
              id={`${orderLineItemId}.pdf`}
              message={dealbinderMessage}
            />
          </div>
          
        </NavSubAddressContainer>
      </MobileNavWrapper>
    </NavWrapper>
  );
};

export default Nav;
