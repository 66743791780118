import Start from './start';
import Diligence from './diligence';
import Searches from './searches';
import Ofac from './ofac';
import Documents from './documents';
import Review from './review';
import Confirm from './confirm';
import { ORDER_URL } from 'helpers/routes';

export const OFAC_PRODUCT_ID = '01t1U000000hVbdQAE';
export const OFAC_JURISDICTION_ID = 'a0L1U000000clxpUAA';
export const CHARTER_DOCS_PRODUCT_ID = '01t1U000000hVbcQAE';

export const UCC_STATE_PRODUCT_ID = '01t1U000000hVaAQAU';
export const WASHINGTON_DC_PRODUCT_ID = 'a0L1U000000clx8UAA';

export enum STEP_NAMES {
  Start = 'start',
  Diligence = 'diligence',
  Searches = 'searches',
  Ofac = 'ofac',
  Documents = 'documents',
  Review = 'review',
  Confirm = 'confirm'
}

export const STEP_PATHS = {
  [STEP_NAMES.Start]: ORDER_URL,
  [STEP_NAMES.Diligence]: `${ORDER_URL}/${STEP_NAMES.Diligence}`,
  [STEP_NAMES.Searches]: `${ORDER_URL}/${STEP_NAMES.Searches}`,
  [STEP_NAMES.Ofac]: `${ORDER_URL}/${STEP_NAMES.Ofac}`,
  [STEP_NAMES.Documents]: `${ORDER_URL}/${STEP_NAMES.Documents}`,
  [STEP_NAMES.Review]: `${ORDER_URL}/${STEP_NAMES.Review}`,
  [STEP_NAMES.Confirm]: `${ORDER_URL}/${STEP_NAMES.Confirm}`
};

export const DILIGENCE_STEPS = [
  STEP_NAMES.Searches,
  STEP_NAMES.Ofac,
  STEP_NAMES.Documents
];

export const STEPS = [
  { name: STEP_NAMES.Start, path: STEP_PATHS.start, component: Start },
  {
    name: STEP_NAMES.Diligence,
    path: STEP_PATHS.diligence,
    component: Diligence
  },
  { name: STEP_NAMES.Searches, path: STEP_PATHS.searches, component: Searches },
  { name: STEP_NAMES.Ofac, path: STEP_PATHS.ofac, component: Ofac },
  {
    name: STEP_NAMES.Documents,
    path: STEP_PATHS.documents,
    component: Documents
  },
  { name: STEP_NAMES.Review, path: STEP_PATHS.review, component: Review },
  { name: STEP_NAMES.Confirm, path: STEP_PATHS.confirm, component: Confirm }
];
