import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const HEADER_HEIGHT = '63px';
const FOOTER_HEIGHT = '65px';

interface ContentProps {
  autoScroll?: boolean;
  noFooter?: boolean;
  ref?: any;
}

const StyledWrapper = styled.div`
  display: flex;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorsNewDesign.background};
  flex-direction: column;
`;

const StyledRight = styled.div`
  display: flex;
`;

const StyledHeader = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT};
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${StyledRight} {
    border-left: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const StyledAside = styled.div`
  width: 275px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 15px 10px 0 10px;
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  border-right: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  max-height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  overflow: auto;
`;

const StyledContent = styled.main<ContentProps>`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 12px 0 0 12px;
  max-height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  ${({ noFooter }) => noFooter && css`
    max-height: calc(100vh - ${HEADER_HEIGHT});
    padding-bottom: 0;
  `}
`;

const ContentWrapper = styled.div<ContentProps>`
  overflow: scroll;
  height: 100%;
  ${({ autoScroll }) => autoScroll && css`
    overflow: auto;
  `}
`;

const StyledFooter = styled.footer`
  margin-top: auto;
  width: 100%;
  padding: 8px 20px;
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${FOOTER_HEIGHT};

  ${StyledRight} {
    & > button {
      margin: 0 0 0 16px !important;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    & > button:last-child {
      margin-right: 100px !important;
    }
  }
`;

const StyledLeft = styled.div``;

const StyledCenter = styled.div`
  display: flex;

  & > * {
    margin: 0 9px;
  }
`;

const Content: FC<ContentProps> = React.forwardRef(({ children, autoScroll, noFooter = false }, ref) => (
  <StyledContent noFooter={noFooter}>
    <ContentWrapper autoScroll={autoScroll} ref={ref}>{children}</ContentWrapper>
  </StyledContent>
));

const FullLayout = {
  Wrapper: StyledWrapper,
  Header: StyledHeader,
  Container: StyledContainer,
  Aside: StyledAside,
  Content,
  Footer: StyledFooter,
  Left: StyledLeft,
  Center: StyledCenter,
  Right: StyledRight,
};

export default FullLayout;
