import React, { FC, useContext, useCallback, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';
import { auth } from 'firebaseConfig';
import Select from 'styleguide/components/Select/reactSelectMobilePdf';
import { ReactComponent as AppsIcon } from 'components/icons/IconApps.svg';
import { ReactComponent as FolderIcon } from 'components/icons/IconFolder.svg';
import { ReactComponent as TagIcon } from 'components/icons/IconTag.svg';
import { ReactComponent as InfoIcon } from 'components/icons/IconInfo.svg';
import { ReactComponent as SettingsIcon } from 'components/icons/IconSettings.svg';
import { ReactComponent as OnIcon } from 'components/icons/IconOn.svg';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import {
  DASHBOARD_URL,
  MATTERS_URL,
  ESTIMATES_URL,
  SETTINGS_URL,
  ORDER_URL,
  ORDER_URL_MOBILE,
  LOGIN_URL,
  LOGOUT_URL
} from 'helpers/routes';
import { AuthContext } from 'components/app/Auth';
import { isMagicLink } from 'helpers/helpers';
import SupportModal from './SupportModal';
import RegistrationModal from './RegistrationModal';
import {
  Wrapper,
  LogoRow,
  SidebarButton,
  HorizontalLine,
  ContentContainer,
  SidebarLink,
  BottomLinksWrapper,
  LinksContainer,
  Button,
  FooterWrapper,
  FooterGrayText,
  FooterText,
  Logo,
  CollapseBtn
} from './styled';
import { useDeviceState } from 'state/deviceState';
import logo from "components/icons/LogoSimple.svg";

const Sidebar: FC = () => {
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isRegistrationModalOpen, setIsRegistrationModalOpen] = useState(false);
  const [isLogoutDropdownOpen, setIsLogoutDropdownOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [state] = useDeviceState();
  const [collapsed, setCollapsed] = useState(false);
  const currentUser = useContext(AuthContext);

  const logoutSelectRef = useOnclickOutside(() => {
    if (isLogoutDropdownOpen) {
      setIsLogoutDropdownOpen(false);
    }
  });

  const isActiveLink = (pathToCheck: string): boolean =>
    location.pathname === pathToCheck;

  const logOut = useCallback((redirectTo?: string) => {
    localStorage.removeItem('instruction');
    if (redirectTo) {

      auth.signOut().then(() => {
        window.location.href = redirectTo || LOGIN_URL;
      });

    } else {
      history.push(LOGOUT_URL)
    }
  }, [history]);

  const isMagicLinkUser = useMemo(() => isMagicLink(currentUser.email), [
    currentUser
  ]);

  const handleRedirect = useCallback(
    url => {
      if (isMagicLinkUser) {
        setIsRegistrationModalOpen(true);
      } else {
        if (url === ORDER_URL) {
          if (state.isDesktop) {
            history.push(url);
          } else {
            history.push(ORDER_URL_MOBILE);
          }
        } else {
          history.push(url);
        }
      }
    },
    [isMagicLinkUser, history, state.isDesktop]
  );

  const handleSupportClick = useCallback(() => {
    if (isMagicLinkUser) {
      setIsRegistrationModalOpen(true);
    } else {
      setIsSupportModalOpen(true);
    }
  }, [isMagicLinkUser]);

  const LogoutButton: FC = useMemo(
    () => () => (
      <SidebarButton onClick={() => setIsLogoutDropdownOpen(true)}>
        <OnIcon />
      </SidebarButton>
    ),
    [setIsLogoutDropdownOpen]
  );

  return (
    <Wrapper collapsed={collapsed}>
      {state.isDesktop && <ContentContainer>
        <LogoRow>
          {!collapsed && <Logo onClick={() => handleRedirect(DASHBOARD_URL)}>
            <img src={logo} alt="logo" />
          </Logo>}
          <CollapseBtn collapsed={collapsed} data-tip onClick={() => setCollapsed(!collapsed)} data-for='dashboard-sidebar-collapse'>
            {collapsed ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
            <Tooltip place="right" id="dashboard-sidebar-collapse">
              {collapsed ? 'Open sidebar' : 'Collapse sidebar'}
            </Tooltip>
          </CollapseBtn>
        </LogoRow>
      </ContentContainer>}
      <HorizontalLine width="100%" />
      <LinksContainer>
        {state.isDesktop && <Button
          collapsed={collapsed}
          data-tip
          data-for='dashboard-sidebar-order'
          primary
          medium
          width="100%"
          onClick={() => handleRedirect(ORDER_URL)}
        >
          {collapsed ?
            <>
              <Tooltip place="right" id="dashboard-sidebar-order">
                New Order/Estimate
              </Tooltip>
              <AddIcon />
            </> :
            'New Order'}
        </Button>}
        <Spacing direction="top" value={5} />
        <SidebarLink
          data-tip
          data-for='dashboard-sidebar-home'
          collapsed={collapsed}
          icon={<AppsIcon />}
          active={isActiveLink(DASHBOARD_URL)}
          onClick={() => handleRedirect(DASHBOARD_URL)}
        >
          {!collapsed ? 'Home' :
            <Tooltip place="right" id="dashboard-sidebar-home">
              Home
            </Tooltip>
          }
        </SidebarLink>
        <SidebarLink
          collapsed={collapsed}
          icon={<FolderIcon />}
          active={isActiveLink(MATTERS_URL)}
          onClick={() => handleRedirect(MATTERS_URL)}
          data-tip
          data-for='dashboard-sidebar-Matters'
        >
          {!collapsed ? 'Matters' :
            <Tooltip place="right" id="dashboard-sidebar-Matters">
              Matters
            </Tooltip>
          }
        </SidebarLink>
        <SidebarLink
          collapsed={collapsed}
          icon={<TagIcon />}
          active={isActiveLink(ESTIMATES_URL)}
          onClick={() => handleRedirect(ESTIMATES_URL)}
          data-tip
          data-for='dashboard-sidebar-Estimates'
        >
          {!collapsed ? 'Estimates' :
            <Tooltip place="right" id="dashboard-sidebar-Estimates">
              Estimates
            </Tooltip>
          }
        </SidebarLink>
        <BottomLinksWrapper collapsed={collapsed}>
          <SidebarLink
            collapsed={collapsed}
            onClick={handleSupportClick}
            icon={<InfoIcon />}
            active={false}
            data-tip
            data-for='dashboard-sidebar-Support'
          >
            {!collapsed ? 'Contact us' :
              <Tooltip place="right" id="dashboard-sidebar-Support">
                Contact us
              </Tooltip>
            }
          </SidebarLink>
          <SidebarLink
            collapsed={collapsed}
            icon={<SettingsIcon />}
            active={isActiveLink(SETTINGS_URL)}
            onClick={() => handleRedirect(SETTINGS_URL)}
            data-tip
            data-for='dashboard-sidebar-Settings'
          >
            {!collapsed ? 'Settings' :
              <Tooltip place="right" id="dashboard-sidebar-Settings">
                Settings
              </Tooltip>}
          </SidebarLink>
        </BottomLinksWrapper>
      </LinksContainer>
      <HorizontalLine width="100%" />
      <ContentContainer>
        <FooterWrapper>
          {!isMagicLinkUser && !collapsed && (
            <div>
              <FooterGrayText size="xxs">HELLO</FooterGrayText>
              <FooterText size="regular">{currentUser.displayName}</FooterText>
            </div>
          )}
          <Spacing direction="left" value="auto" />
          <div ref={logoutSelectRef} data-tip data-for='dashboard-sidebar-Logout'>
            <Tooltip place="right" id="dashboard-sidebar-Logout">
              Logout
            </Tooltip>
            <Select
              options={[{ label: 'Log out', value: 'logout' }]}
              styles={{
                menu: styles => ({
                  ...styles,
                  width: '100px'
                }),
                option: styles => ({ ...styles, cursor: 'pointer' })
              }}
              components={{ Control: LogoutButton }}
              onChange={() => logOut()}
              value={null}
              menuIsOpen={isLogoutDropdownOpen}
              menuPlacement="top"
            />
          </div>
        </FooterWrapper>
      </ContentContainer>
      <SupportModal
        isOpen={isSupportModalOpen}
        onClose={() => setIsSupportModalOpen(false)}
      />
      <RegistrationModal
        isOpen={isRegistrationModalOpen}
        onClose={() => setIsRegistrationModalOpen(false)}
        logOut={logOut}
      />
    </Wrapper>
  );
};

export default Sidebar;
