import React, { FC, useState, useEffect, useRef } from 'react';
import { isEmpty, some } from 'lodash';
import { Helmet } from 'react-helmet';

import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
import { useLocation,useHistory } from 'react-router-dom';
import { useMyEstimatesState } from '../../state/myEstimates';
import Filters from './Filters';
import { Wrapper, Header, FiltersButton,EstimateButton } from './styled';
import DeleteModal from './DeleteModal';
import Table from './Table';
import EmptyState from './EmptyState';
import { Loader } from 'pages/matter/components/common/styled';
import { theme } from 'theme/theme';
import { useDeviceState } from 'state/deviceState';

export interface DeleteEstimateModalData {
  id: string;
  name: string;
  matterId: string;
}

const MyEstimates: FC = () => {
  const [state, actions] = useMyEstimatesState();
  // const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const scrollingContainerRef = useRef(null);
  const [deviceState] = useDeviceState();
  const { pathname } = useLocation();
  const history = useHistory();
  const [deleteEstimateData, setDeleteEstimateData] = useState<
    DeleteEstimateModalData
  >(null);

  useEffect(() => {
    actions.resetFilters();
    actions.fetchData();
  }, [actions]);
  useEffect(() => {
    if (scrollingContainerRef?.current) {
      scrollingContainerRef.current.scrollTop = 0;
    }
  }, [pathname,scrollingContainerRef]);
  const areFiltersApplied = some(state.filters, filter => !isEmpty(filter));

  const shouldDisplayEmptyState =
    !state.isLoading && state.totalCount === 0 && !areFiltersApplied;

  if (state.isLoading) return <Loader marginLess background={theme.colorsNewDesign.lightBlue} />;

  return (
    <>
      <Helmet>
        <title>My Estimates - DealBinder</title>
      </Helmet>
      <Wrapper>
      { deviceState.isMobileOrTablet?"":<> <Header>
        <Spacing direction="top" value={deviceState.isMobileOrTablet?0:0} />
          <BasicHeading tag="h1" size={deviceState.isMobile? "regular":"xxl"}>
            My Estimates
          </BasicHeading>
          <Spacing direction="left" value="auto" />
          {deviceState.isDesktop && <EstimateButton onClick={()=>history.push({pathname:'/order',search:'initialOrderType=estimate'})}>
              Get estimate
          </EstimateButton>}
          {!shouldDisplayEmptyState && (
            <FiltersButton
              active={areFiltersApplied}
              onClick={() => actions.toggleTableFilterSidebar('myEstimates')}
            >
              <FiltersIcon />
              Filters
            </FiltersButton>
          )}
        </Header>
        <Spacing direction="top" value={20} /></>}
        {shouldDisplayEmptyState ? (
          <EmptyState />
        ) : (

          <Table
            isLoading={state.isLoading}
            estimates={state.estimates}
            currentPage={state.currentPage}
            totalCount={state.totalCount}
            onChangePage={actions.changePage}
            setDeleteEstimateData={setDeleteEstimateData}
            onDelete={actions.triggerDeleteModal}
          />

        )}
        <Filters
          isOpen={state.tableFilterSidebar.myEstimates}
          onClose={() => actions.toggleTableFilterSidebar('myEstimates')}
          onSubmit={actions.updateFilters}
          fetchMatterFilterOptions={actions.fetchMatterFilterOptions}
          matterFilterOptions={state.matterFilterOptions}
          dateFilterOptions={state.dateFilterOptions}
          filters={state.filters}
        />
        <DeleteModal
          isOpen={state.modalShow}
          onClose={actions.triggerDeleteModal}
          deleteEstimateData={deleteEstimateData}
          onDelete={actions.deleteEstimate}
        />
      </Wrapper>
    </>
  );
};

export default MyEstimates;
