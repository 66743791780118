import { auth } from 'firebaseConfig';
import { AxiosRequestConfig } from 'axios';
import { getAuthorizedApi } from '../apiInstance';

import api from '../apiInstance';
import { url } from '../url';

const authorizedPostRequest = async (
  endpoint: string,
  data: any,
  config: AxiosRequestConfig = {}
) => {
  const token = await auth.currentUser.getIdToken();
  return api.post(`${url}${endpoint}`, data, {
    ...config,
    headers: { ...(config.headers || {}), Authorization: `Bearer ${token}` }
  });
};

export const postEstimate = (data: any) =>
    authorizedPostRequest('/pricings/estimate', data, {});

export const postMatter = (data:any) =>
  authorizedPostRequest('/matters', data, {});

export const postMatterOrder = (matterId: string, data:any) =>
  authorizedPostRequest(`/matters/${matterId}/orders`, data, {});

export const deleteOrder = async (matterId: string, orderId: string): Promise<void> => {
  const client = await getAuthorizedApi();
  return client.delete(`${url}/matters/${matterId}/orders/${orderId}`);
};

export interface OrderLineItem {
  diligenceName: string;
  product: {
    id: string;
    name: string;
    family: string;
  };
  jurisdiction: {
    id: string;
    name: string;
  };
}

export interface OrderData {
  id: string;
  stage: string;
  charterHistoryType: string;
  charterFormat: string;
  orderLineItems: OrderLineItem[];
  adminDiscount?: number;
  adminDiscountMessage?: string;
}

export const fetchOrderData = async (
  matterId: string,
  orderId: string
): Promise<OrderData> => {
  const client = await getAuthorizedApi();
  const response = await client.get<OrderData>(`${url}/matters/${matterId}/orders/${orderId}`);
  return response.data;
};
