import React, { FC, useState } from 'react';
import { ReactComponent as IconAlert } from 'components/icons/IconAlert.svg';
import Tooltip from 'styleguide/components/Tooltip';
import { colors } from 'helpers/colors';

interface DealbinderMessageProps {
  id?: string;
  message?: string;
  isMobileOrTablet?: boolean;
  clickable?: boolean;
}

const DealbinderMessage: FC<DealbinderMessageProps> = ({ id, message, isMobileOrTablet,clickable }) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  if (!message) return null;

  return (
    <>
      <IconAlert style={{ fill: isHover ? colors.active : colors.inactive, cursor: "pointer" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-tip data-event="click" data-for={`dm-message.${id}`} width={isMobileOrTablet ? '14px' : '21px'} />
      <Tooltip id={`dm-message.${id}`} clickable={clickable}>
        <div style={{ maxWidth: '400px' }}>{message}</div>
      </Tooltip>
    </>
  );
};

export default DealbinderMessage;
