import api from 'api/apiInstance';

interface RegisterPostParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gRecaptchaResponse: string;
  firmName: string;
  // firmAddress: string;
  // city: string;
  // state: string;
  // postalCode: string;
  phone: string;
  hasAcceptedTerms: boolean;
  redirectUrl?: string;
}

export const registerApi = (path: string) => ({
  path,
  POST: (config: RegisterPostParams) => api.post(path, config)
});
