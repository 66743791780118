import React, {
  useState,
  useCallback,
  ChangeEvent,
  FC,
  useContext
} from 'react';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import BasicModal from 'styleguide/components/BasicModal/BootstarpModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import { ReactComponent as EmailIcon } from 'components/icons/IconEmail2.svg';
import TextInput from 'styleguide/components/TextInput';
import TagsInput from 'styleguide/components/TagsInput';
import Loader from 'styleguide/components/Loader';
import Snackbar from 'styleguide/components/Snackbar';
import Button from 'styleguide/components/Button';
import { EMAIL_VALIDATION } from 'helpers/validation';
import { emailFile } from 'api/matter';
import { AuthContext } from 'components/app/Auth';
import { makeStyles } from '@material-ui/core';
import { Wrapper, Footer, FieldDescription, ContentWrapper } from './styled';
import { useMatterState } from 'pages/matter/state/matter';
import { useDeviceState } from 'state/deviceState';

const useStyles = makeStyles({
  BackDropCss: {
    zIndex: 9999
  }
})

const StyledButton = styled(Button)`
    margin: 0;
    padding: 0 5px;
  `;

const StyledSnackbar = styled(Snackbar)`
  @media (max-width: 576px) {
    order: 1;
  }
`;

const EmailModal: FC = () => {
  const classes = useStyles();
  const currentUser = useContext(AuthContext);
  const [deviceState] = useDeviceState();
  const [{ emailModal }, { closeEmailModal: onClose }] = useMatterState();
  const isOpen = emailModal && emailModal.open;
  const { matterId, orderId, orderLineItemId } = emailModal || {};
  const [isError, setIsError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [subjectValue, setSubjectValue] = useState(
    `${currentUser.claims?.name} has shared a file with you`
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleTagsChange = useCallback(
    (emails: string[]) => {
      if (emails.every(email => EMAIL_VALIDATION.test(email))) {
        setEmails(uniq(emails));
        setEmailInputValue('');
      } else {
        setIsError(true);
      }
    },
    [setEmails, setEmailInputValue]
  );

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isError) {
        setIsError(false);
      }
      if (isSubmitted) {
        setIsSubmitted(false);
      }
      if (
        event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Tab' ||
        event.key === ','
      ) {
        event.preventDefault();
        handleTagsChange([...emails, emailInputValue]);
      }
    },
    [isError, isSubmitted, handleTagsChange, emails, emailInputValue]
  );
  const handleInputBlur = useCallback(() => {
    if (isError) {
      setIsError(false);
    }
    if (emailInputValue) {
      if (EMAIL_VALIDATION.test(emailInputValue)) {
        setEmails(uniq([...emails, emailInputValue]));
      }
      setEmailInputValue('');
    }
  }, [isError, emailInputValue, emails]);

  const resetValues = useCallback(() => {
    setIsLoading(false);
    setEmails([]);
    setEmailInputValue('');
    setSubjectValue(subjectValue);
    setMessageValue('');
  }, [subjectValue]);

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    emailFile(
      matterId,
      orderId,
      orderLineItemId,
      emails,
      subjectValue.replace(/\n/g, "<br/>"),
      messageValue
    )
      .then(() => {
        setIsSubmitted(true);
        setTimeout(()=> {
          setIsSubmitted(false);
        },5000);
      })
      .finally(() => {
        resetValues();
      });
  }, [
    emails,
    matterId,
    messageValue,
    orderId,
    orderLineItemId,
    subjectValue,
    resetValues,
  ]);

  return (
    <BasicModal
    className={classes.BackDropCss}
      title={
        <>
          <EmailIcon />
          <Spacing direction="left" value={16} />
          <BasicHeading tag="h3" size={deviceState.isDesktop?"xl":"md"}>
            Email: Who would you like to email?
          </BasicHeading>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Wrapper>
        <ContentWrapper>
          <BasicHeading tag={deviceState.isDesktop?"h4":"h3"} size="md">
            Recipients
          </BasicHeading>
          <Spacing direction="top" value="10px" />
          <TagsInput
            value={emails}
            onChange={handleTagsChange}
            inputProps={{
              placeholder: 'Enter email address(es)',
              autoFocus: true,
              onKeyDown: handleInputKeyDown,
              onBlur: handleInputBlur
            }}
            inputValue={emailInputValue}
            onChangeInput={setEmailInputValue}
            addOnPaste
            pasteSplit={(data): string[] =>
              data.split(/,|\s/g).map(s => s.trim())
            }
          />
          {!isError && emailInputValue && (
            <>
              <Spacing direction="top" value={8} />
              <FieldDescription size="xs">
                Type Enter, space or comma to add multiple emails
              </FieldDescription>
            </>
          )}

          <Spacing direction="top" value="10px" />
          <BasicHeading tag={deviceState.isDesktop?"h4":"h3"} size="md">
            Subject line
          </BasicHeading>
          <TextInput 
            fontSize="14px"
            placeholder="Subject"
            value={subjectValue}
            onChange={(e: ChangeEvent<HTMLInputElement>): void =>
              setSubjectValue(e.target.value)
            }
          />
          <BasicHeading tag={deviceState.isDesktop?"h4":"h3"} size="md">
            Message
          </BasicHeading>

          <Spacing direction="top" value="10px" />
          <TextInput
            fontSize="14px"
            id="email"
            as="textarea"
            hasNoMargin
            placeholder="Add a message (Optional)"
            rows={5}
            value={messageValue}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>): void =>
              setMessageValue(e.target.value)
            }
          />
        </ContentWrapper>
        <Footer>
          {isSubmitted && (
            <>
              <StyledSnackbar type="success" width="100%">
                Your file has been sent!{' '}
                <StyledButton variant="inline" onClick={onClose}>
                  Return to matter
                </StyledButton>
              </StyledSnackbar>
              <Spacing direction="right" value={8} />
            </>
          )}
          {isError && (
            <>
              <StyledSnackbar type="error" width="100%">
                Please enter a valid email address
              </StyledSnackbar>
              <Spacing direction="right" value={8} />
            </>
          )}
          {isLoading && <Loader />}
          <Button
            resetOrder
            medium
            onClick={() => {
              resetValues();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Spacing direction="left" value={8} />
          <Button
            resetOrder
            medium
            primary
            onClick={handleSubmit}
            disabled={isLoading || (emails.length === 0 && emailInputValue === "")}
          >
            Send
          </Button>
        </Footer>
      </Wrapper>
    </BasicModal>
  );
};

export default EmailModal;
