import React, { FC } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Error from 'styleguide/components/Error';
import { ReactComponent as IconMore } from 'components/icons/IconMore.svg';
import Button from '../Button';
import { theme } from 'theme/theme';
import Loader from 'styleguide/components/Loader';
import Spacing from '../Spacing';

interface NoteProps {
  id?: string;
  name: string;
  created: string;
  text: string;
  color?: number;
  onEdit?: (text: string) => void;
  onDelete?: () => void;
  isUpdated?:boolean;
}

const Wrapper = styled.div`
  border-bottom: 1px solid #ebecef;
  padding: 16px 0;

  &:last-child {
    border-bottom: none;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Avatar = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
`;

const Name = styled.div`
  margin-right: 8px;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
const Date = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
const Options = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

const Content = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};  
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  line-height: 130%;
  padding: 4px 17px;
  white-space: pre-line;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff !important;
  z-index: 10;
  border: 1px solid #ebecef;
  border-radius: 4px;
  padding: 7px 20px;
`;

const DropdownOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
`;

const DeleteConfirm = styled.div`
  border: 1px solid #ebecef;
  padding: 0 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 13px;
  ${Loader} {
    font-size: 2px;
  }
  & > div {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
  button {
    padding: 5px 10px;
    font-size: 13px;
    margin-left: 5px;
  }
`;

const NoteInput = styled.textarea`
  resize: none;
  width: 100%;
  height: 100px;
  border: 1px solid #ebecef;
  margin-top: 10px;
  padding: 12px 18px 12px 18px;
  display: block;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  &::placeholder {
    color: #a8a8b4;
  }
`;
const NotesFormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  border: 1px solid #ebecef;
  border-top: none;
  padding: 0 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-items: center;

  button {
    padding: 7px 24px !important;
    margin-left: 10px;
    font-size: 13px;
  }
  ${Loader} {
    font-size: 3.2px;
  }
`;

interface NoteFormValues {
  text: string;
}
const NoteFormSchema = Yup.object().shape<NoteFormValues>({
  text: Yup.string()
    .required('Please include a message')
    .min(1, 'Please include a message')
});

const Note: FC<NoteProps> = ({
  name,
  created,
  text,
  onEdit,
  onDelete,
  color = 0,
  isUpdated
}) => {
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);

  return (
    <Wrapper>
      {open && <DropdownOverlay onClick={() => setOpen(false)} />}
      <Header>
        <Avatar
          style={{
            backgroundColor: color < 0 ? '#9192A2' : Object.values(theme.userCircleColors)[color % 10]
          }}
        />
        <Name>{name}</Name>
        <Date>{created}{isUpdated ? " (edited)": ""}</Date>
        {onEdit ? (
          <Options>
            <IconMore onClick={() => setOpen(true)} />
            {open && (
              <Dropdown onClick={() => setOpen(false)}>
                <div>
                  <Button variant="inline" onClick={() => setEditOpen(true)}>
                    Edit note
                  </Button>
                </div>
                <div>
                  <Button variant="inline" onClick={() => setDeleteOpen(true)}>
                    Delete note
                  </Button>
                </div>
              </Dropdown>
            )}
          </Options>
        ) : null}
      </Header>
      {deleteOpen && (
        <DeleteConfirm>
          Delete note?
          <div>
            
            <Button onClick={() => setDeleteOpen(false)} disabled={isDeleting}>Keep</Button>
            <Button danger onClick={() => { setIsDeleting(true); onDelete(); }} disabled={isDeleting}>
          
            {(isDeleting && (
                <>
                  <Spacing direction="left" value={16} />
                  <Loader marginLess size={1} background="#E81414" />
                  <Spacing direction="right" value={16} />
                
                </>
              )) || "Delete"}
          </Button>
          </div>
        </DeleteConfirm>
      )}
      {editOpen ? (
        <Formik<NoteFormValues>
          initialValues={{ text }}
          validationSchema={NoteFormSchema}
          onSubmit={async values => {
            await onEdit(values.text);
            setEditOpen(false);
          }}
        >
          {({ submitCount, isSubmitting }) => (
            <Form>
              <Field name="text">
                {({ field, meta }: any) => (
                  <>
                    <NoteInput placeholder="Add a note" autoFocus {...field} />
                    {submitCount > 0 && meta.error && (
                      <Error>{meta.error}</Error>
                    )}
                  </>
                )}
              </Field>
              <NotesFormActions>
                <Button
                  type="button"
                  onClick={() => {
                    setEditOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button primary type="submit" disabled={isSubmitting}>
                {(isSubmitting && (
                <>
                  <Spacing direction="left" value={16} />
                  <Loader  size={1} background="#dddce7" />
                  <Spacing direction="right" value={16} />
                
                </>
              )) || "Post"}
                </Button>
              </NotesFormActions>
            </Form>
          )}
        </Formik>
      ) : (
        <Content>{text}</Content>
      )}
    </Wrapper>
  );
};

export default Note;
