import React, { FC } from 'react';
import styled from 'styled-components';

import BasicSubHeading from 'styleguide/components/BasicSubHeading';

export type Color = 'red' | 'orange' | 'cyan' | 'grey' | 'blue' | 'orange2';

interface TagProps {
  isFilled?: boolean;
  color: Color;
  cursorPointer?: boolean;
}

const Wrapper = styled.div<TagProps>`
  display: inline-flex;
  padding: 2px 12px;
  border-radius: 999px;
  background: ${({ theme, isFilled, color }) =>
    isFilled ? theme.tagColors.filled[color] : theme.tagColors.regular[color]};
  white-space: nowrap;
  cursor: ${({ cursorPointer }) => cursorPointer ? 'pointer' : 'default'};
`;

const Text = styled(BasicSubHeading)<TagProps>`
  color: ${({ theme, isFilled, color }) =>
    isFilled ? 'white' : theme.tagColors.regularFontColor[color]};

  padding: 4px 0;
  line-height: 1;
  letter-spacing: 1;
`;

const Tag: FC<TagProps> = ({ isFilled = false, color, children, ...props }) => (
  <Wrapper isFilled={isFilled} color={color} {...props}>
    <Text as="div" isFilled={isFilled} color={color} size="xs" isUppercase>
      {children}
    </Text>
  </Wrapper>
);

export default Tag;
