import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef
} from 'react';
import {
  RouteComponentProps,
  Route,
  Switch,
  Redirect,
  useHistory
} from 'react-router';
import { flatten, size } from 'lodash';
import { Helmet } from 'react-helmet';

import { ERROR_URL } from 'helpers/routes';
import { theme } from 'theme/theme';
import {
  getOverviewUrl,
  getSearchesUrl,
  getOfacUrl,
  getDocumentsUrl,
  getHitDetailsUrl,
  getFilePreviewUrl
} from './constants';
import {
  DilligenceContentWrapper,
  Loader,
  StickyContainer
} from './components/common/styled';
import { useMatterState } from './state/matter';
import Searches from './searches';
import Ofac from './ofac';
import Documents from './documents';
import HitDetails from './hitDetails';
import Nav from './components/Nav';
import SubNav from './components/SubNav';
import Overview from './components/Overview';
import DownloadFilesModal from './components/DownloadFilesModal';
import PDFViewer from './components/PDFViewer';
import PDFViewerMobile from './components/PDFViewer/PdfFIleViewMobile';

import ShareModal, { tabs as shareModalTabs } from './components/ShareModal';
import ViewAccessModal from './components/ViewAccessModal';
import PermissionsModal from './components/PermissionsModal';
import EmailModal from './components/EmailModal';
import { useDeviceState } from 'state/deviceState';
import DownloadReportModal from './components/Nav/DownloadReportModal';
import ShareSuggestionPopup from './components/ShareSuggestionPopup';

interface MatterMatchParams {
  id: string;
}

const Matter: FC<RouteComponentProps<MatterMatchParams>> = props => {
  const [state, actions] = useMatterState();
  const [shareModal, setShareModal] = useState(false);
  // const [isViewAccessModalOpen, setIsViewAccessModalOpen] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [multipleFiles, setMultipleFiles] = useState(true);
  const [deviceState] = useDeviceState();
  const link = useRef(null);
  const [docType, setDocType] = useState(null);
  const history = useHistory();
  const { search, pathname } = history.location;
  useEffect(() => {
    if (
      search.includes("shareSuggestion") &&
      state.view
    ) {
      setTimeout(() => setShareModal(true), 3000);
    } else {
      setShareModal(false);
    }
  }, [search, state.view]);
  const removeQuery = () => {
    history.push(pathname);
    setShareModal(false);
  };
  useEffect(() => {
    actions.fetchData(props.match.params.id);
    actions.fetchInvitesList();
    actions.fetchFilesList();
    actions.getAssign();
  }, [actions, props.match.params.id]);

  const hasSearches = useMemo(
    () => size(state?.view?.searches?.orderLineItems) > 0,
    [state]
  );
  const hasOfac = useMemo(() => size(state?.view?.ofac?.orderLineItems) > 0, [
    state
  ]);
  const hasOrgDocs = useMemo(
    () => size(state?.view?.documents?.orderLineItems) > 0,
    [state]
  );
  const onInviteCirclesClick = useCallback(() => {
    if (state.data?.role === 'owner') {
      actions.toggleShareModal('manage-access');
    } else {
      actions.toggleViewAccessModal(true);
    }
  }, [state.data, actions]);

  const handleDownloadReport = useCallback(
    async (includeAllFiles: boolean) => {
      const allFilesIds = flatten(Object.values(state.filesList)).map(
        file => file.id
      );

      await actions
        .downloadReport(docType, includeAllFiles ? allFilesIds : undefined)
        .then(fileData => {
          const href = window.URL.createObjectURL(
            new Blob([fileData.data], { type: fileData.type })
          );
          const a = link.current;
          a.download = fileData.filename;
          a.href = href;
          a.click();
          a.href = '';
          setDocType('');
        });
    },
    [actions, docType, state.filesList]
  );

  const onGetFilesClick = useCallback(() => {
    const files = flatten(Object.values(state.filesList));
    if (files && files.length > 0) {
      actions.toggleGetReports(true);
    } else {
      actions.toggleGetReports(true);
      setMultipleFiles(false);
    }
  }, [state.filesList, actions]);

  if (state.isLoading) {
    return <Loader marginLess background={theme.colorsNewDesign.lightBlue} />;
  }

  if (state.error) {
    return <Redirect to={ERROR_URL} />;
  }

  return (
    <>
      <Helmet>
        <title>Matter {state.data?.name} - DealBinder</title>
      </Helmet>
      <div>
        <StickyContainer>
          {deviceState.isMobileOrTablet ? null : (
            <Nav
              id={state.id}
              name={state.data?.name}
              role={state.data?.role}
              onNameChange={actions.editMatterName}
              onDownloadClick={() => actions.toggleGetDownloads(true)}
              onShareClick={() =>
                actions.toggleShareModal(shareModalTabs[0].id)
              }
              onInviteCirclesClick={onInviteCirclesClick}
              invites={state.invites}
              owner={state.data?.owner}
              onGetFilesClick={onGetFilesClick}
            />
          )}
          <SubNav
            id={state.id}
            hasSearches={hasSearches}
            hasOfac={hasOfac}
            hasOrgDocs={hasOrgDocs}
          />
        </StickyContainer>
        <DilligenceContentWrapper>
          <Switch>
            <Route exact path={getSearchesUrl(':id')} component={Searches} />
            <Route path={getHitDetailsUrl(':id')} component={HitDetails} />
            <Route path={getOfacUrl(':id')} component={Ofac} />
            <Route path={getDocumentsUrl(':id')} component={Documents} />
            <Route
              path={getOverviewUrl(':id')}
              render={() => (
                <Overview
                  hasSearches={hasSearches}
                  hasOfac={hasOfac}
                  hasOrgDocs={hasOrgDocs}
                  openPermissions={() => setIsPermissionsModalOpen(true)}
                />
              )}
            />
          </Switch>
        </DilligenceContentWrapper>
      </div>
      <EmailModal />
      <DownloadFilesModal
        isOpen={state.getDownloads}
        onClose={() => actions.toggleGetDownloads(false)}
        filesList={state.filesList}
        downloadFiles={actions.downloadFiles}
      />
      {/* eslint-disable-next-line */}
      <a ref={link} />
      <DownloadReportModal
        isOpen={state.getReports}
        onClose={() => actions.toggleGetReports(false)}
        onAccept={async () => await handleDownloadReport(true)}
        onDecline={async () => await handleDownloadReport(false)}
        setDocumentType={setDocType}
        documentType={docType}
        multipleFiles={multipleFiles}
      />
      <ShareSuggestionPopup
        onShare={() => actions.toggleShareModal(shareModalTabs[0].id)}
        onClose={removeQuery}
        isOpen={shareModal}
      />
      <ShareModal
        name={state.data?.name}
        activeTab={state.shareModal}
        setActiveTab={actions.toggleShareModal}
        onClose={() => actions.toggleShareModal(null)}
        fetchCoworkersEmail={actions.fetchCoworkersEmail}
        coworkersEmail={state.coworkersEmail}
        inviteToMatter={actions.inviteToMatter}
        invites={state.invites}
        updateInvite={actions.updateInvite}
        deleteInvite={actions.deleteInvite}
        transferMatter={actions.transferMatter}
        owner={state.data?.owner}
        openPermissions={() => setIsPermissionsModalOpen(true)}
        sendMagicLink={actions.sendMagicLink}
        isOwner={state.data?.role === 'owner'}
      />
      <ViewAccessModal
        isOpen={state.viewAccessModal}
        onClose={() => actions.toggleViewAccessModal(false)}
        name={state.data?.name}
        invites={state.invites}
        owner={state.data?.owner}
        openPermissions={() => setIsPermissionsModalOpen(true)}
      />
      <PermissionsModal
        isOpen={isPermissionsModalOpen}
        onClose={() => setIsPermissionsModalOpen(false)}
      />
      {deviceState.isMobileOrTablet ? (
        <Route
          path={getFilePreviewUrl(
            ':matterId',
            ':matterTab',
            ':orderLineItemId'
          )}
          render={props => (
            <PDFViewerMobile
              {...props}
              openDownloadModal={() => actions.toggleGetDownloads(true)}
            />
          )}
        />
      ) : (
        <Route
          path={getFilePreviewUrl(
            ':matterId',
            ':matterTab',
            ':orderLineItemId'
          )}
          render={props => (
            <PDFViewer
              {...props}
              openDownloadModal={() => actions.toggleGetDownloads(true)}
            />
          )}
        />
      )}
    </>
  );
};

export default Matter;
