import styled, { css } from 'styled-components';
import Button from 'styleguide/components/Button';
import StyleguideLoader from 'styleguide/components/Loader';

export const DilligenceContentWrapper = styled.div`
  padding: 25px 40px 40px 40px;
  @media (max-width: 768px) {
    padding: 10px 0px 0px 0px;
  } 
`;

export const TableAccessory = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  & > * {
    display: flex;
    justify-content: center;

    &:first-child {
      justify-content: flex-start;
      min-width: 188px;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
`;

export const Loader = styled(StyleguideLoader)`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
`;

export const StickyContainer = styled.div`
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0px;
  z-index: 1000;
`;

export const FiltersButton = styled(Button)`
  padding: 11px 15px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-left: 4px;
  margin-right: 4px;

  svg {
    margin-right: 8px;

    ${({ active, theme }) =>
      !active &&
      css`
        path {
          fill: ${theme.colorsNewDesign.gray300};
        }
      `}
  }

  @media (max-width: 768px) {
    padding: 5px 5px;
    margin-left: 4px;
    margin-right: 4px;
    border: 0px solid ${({ theme }) => theme.colorsNewDesign.gray100};

    svg {
      margin-right: 0px;
  
      ${({ active, theme }) =>
        !active &&
        css`
          path {
            fill: ${theme.colorsNewDesign.gray300};
          }
        `}
    }


  }
`;

export const ExpandButton = styled(Button)`
  padding: 11px 15px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  svg {
    margin-right: 5px;
    ${({ active, theme }) =>
      !active &&
      css`
        path {
          fill: ${theme.colorsNewDesign.gray300};
        }
      `}
  }

  @media (max-width: 768px) {
    border: 0px solid ${({ theme }) => theme.colorsNewDesign.gray100};
    padding: 5px 5px;

  }
`;

export const UserCircle = styled.div<{ background: string }>`
  width: 36px;
  height: 36px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  border: 2px solid white;
  background: ${({ background }) => background};
  margin-right: -12px;
  text-transform: uppercase;
`;


export const CountBadge = styled.span`
  height: 16px;
  width: 16px;
  margin-left: 5px;
  background-color: #0a2aab;
  color: #fff;
  border-radius: 16px;
  line-height: 16px;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xxs};
`;