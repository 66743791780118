import React, { FC } from 'react';
import DocumentsReviewCellProductValue from './DocumentsReviewCellProductValue';
import CellProductForm from '../TableForm/CellProductForm';
import {
  useProductState,
  ORGANIZATIONAL_DOCUMENTS,
  getOrgDocs
} from '../../state/productsState';

interface DocumentsReviewCellProductFormProps {
  row: number;
  col: number;
}

const DocumentsReviewCellProductForm: FC<DocumentsReviewCellProductFormProps> = props => {
  const [{ products }] = useProductState();
  return (
    <CellProductForm
      {...props}
      products={getOrgDocs(products).filter((product)=> !['Annual Report','Foreign Qualification'].includes(product.name))}
      valueComponent={DocumentsReviewCellProductValue}
      productFamily={ORGANIZATIONAL_DOCUMENTS}
    />
  );
};

export default DocumentsReviewCellProductForm;
