import React, { FC } from 'react';

import styled from 'styled-components';

export interface LogoProps {
  width?: number;
  isColored?: boolean;
}

const StyledImage = styled.img<Pick<LogoProps, 'width'>>`
  ${({ width }) => width && { width: width }};
`;

const Logo: FC<LogoProps> = ({ width, isColored = false }) => (
  <StyledImage
    src={isColored ? '/logo-color.png' : '/logo.png'}
    width={width}
  />
);

export default Logo;
