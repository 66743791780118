import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Col, Row } from 'react-bootstrap';

interface BasicSectionProps {
  title: string | ReactNode;
  condensed?: boolean;
}

const BasicSectionWrapper = styled.div<{ condensed?: boolean }>`
  padding-top: 24px;
  padding-bottom: 24px;

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  }

  ${({ condensed }) =>
    condensed &&
    css`
      padding: 10px 0;
    `}
`;

const BasicSectionTitle = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  letter-spacing: 1px;
  line-height: 1.5;
  text-transform: uppercase;
`;

const BasicSection: FC<BasicSectionProps> = ({
  title,
  condensed,
  children
}) => (
  <BasicSectionWrapper condensed={condensed}>
    <Row>
      <Col xs={4}>
        <BasicSectionTitle>{title}</BasicSectionTitle>
      </Col>
      <Col xs={8}>{children}</Col>
    </Row>
  </BasicSectionWrapper>
);

export default BasicSection;
