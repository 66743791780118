import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';

interface CenteredLayoutProps {
  anchor?: ReactChild;
}

export const CenteredLayoutWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colorsNewDesign.background};
  padding-top: 6rem;
  position: relative;
`;

const CenteredLayoutAnchor = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const CenteredLayout: FC<CenteredLayoutProps> = ({ children, anchor, ...props }) => (
  <CenteredLayoutWrapper {...props}>
    {anchor && <CenteredLayoutAnchor>{anchor}</CenteredLayoutAnchor>}
    {children}
  </CenteredLayoutWrapper>
);

export default CenteredLayout;
