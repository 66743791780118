import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Spacing from 'styleguide/components/Spacing';
import RoundIcon from 'styleguide/components/RoundIcon';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import { ReactComponent as IconCheck } from 'components/icons/IconCheck.svg';
import SimpleLayout from 'layout/simpleLayout';

import { VerificationWrapper, GrayText } from './styled';

interface VerificationPreapproved {
  continueUrl: string;
}

const VerificationPreapproved: FC<VerificationPreapproved> = () => {
  useEffect(() => {
    setTimeout(() => {
      // using react-router history push is not refeeshing firebase currentUser data
      window.location.href = '/dashboard';
    }, 3000);
  }, []); //eslint-disable-line

  return (
    <SimpleLayout>
      <VerificationWrapper>
        <Spacing direction="top" value={80} />
        <RoundIcon>
          <IconCheck />
        </RoundIcon>
        <Spacing direction="top" value={55} />
        <BasicHeading tag="h1" size="xxxl" isCentered>
          <FormattedMessage id="shared.welcome" />
        </BasicHeading>
        <Spacing direction="top" value={16} />
        <GrayText>
          <FormattedMessage id="verify.redirected" />
        </GrayText>
        <Spacing direction="top" value={40} />
        <Link to="/dashboard">
          <Button primary>
            <FormattedMessage id="shared.backToHome" />
          </Button>
        </Link>
      </VerificationWrapper>
    </SimpleLayout>
  );
};

export default VerificationPreapproved;
