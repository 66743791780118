import React from 'react';
import styled, { css } from 'styled-components';

interface CellProps {
  isCentered?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  isFullSize?: boolean;
  hasHoverState?: boolean;
  isTransparent?: boolean;
  isSticky?: boolean;
  row2?: boolean
}

export const Cell = styled.td<CellProps>`
    border: 1px solid ${({ theme }) => theme.colorsNewDesign.lightBlue};
    min-height: 35px;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colorsNewDesign.lightest};
    border-collapse: collapse;

    ${({ isCentered }) =>
      isCentered &&
      `
        text-align: center;
        & > * {
          margin: 0 auto;
        }
    `}

    ${({ isHighlighted, theme }) =>
      isHighlighted &&
      `
        background: ${theme.tableCellColors.highlighted};
    `}

    ${({ isSelected, theme }) =>
      isSelected &&
      `
        background: ${theme.tableCellColors.selected};
    `}

    ${({ isFullSize }) =>
      isFullSize &&
      `
        vertical-align: top;
        padding: 0;
    `}

    ${({ hasHoverState, theme }) =>
      hasHoverState &&
      css`
        &:hover {
          position: relative; 
          &:after {
            background: ${theme.colorsNewDesign.background};
            text-align: center;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            content: attr(data-hover);
            color: ${theme.colorsNewDesign.primary};
            font-size: ${theme.fontSizesNewDesign.sm};
            font-weight: ${theme.fontWeights.semiBold};
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
    `}

    ${({ isTransparent }) => isTransparent && css`
        background: transparent;
        border: none;
    `}

    ${({ isSticky }) => isSticky && css`
        position: sticky;
        top: 0;
        z-index: 2;
    `}

    ${({ isSticky, row2 }) => isSticky && row2 && css`
        top: 35px;
    `}
`;

export const Row = styled.tr`
  border-collapse: collapse;
  height: auto;
  &:nth-child(1) {
    position: sticky;
    top:-1px;
    z-index:2
  }
  &:nth-child(2) {
    position: sticky;
    top:36px;
    z-index:2
  }

  ${Cell} {
    min-width: 148px;
    &:nth-child(1) {
      position: sticky;
      left:0;
      min-width: 35px;
      z-index:1
    }
    &:nth-child(2) {
      position: sticky;
      left:35px;
      min-width: 270px;
      z-index:1
    }
    &:last-child {
      min-width: 35px;
    }
  }

  &:first-child {
    ${Cell}:first-child {
      border-top-left-radius: 8px;
    }
    ${Cell}:last-child {
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    ${Cell}:first-child {
      border-bottom-left-radius: 8px;
    }
    ${Cell}:last-child {
      border-bottom-right-radius: 8px;
    }
  }
`;

export const Table: React.FC = ({ children }) => (
  <table>
    <tbody>{children}</tbody>
  </table>
);

export default Table;
