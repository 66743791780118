import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export interface BaseLinkProps {
  content?: ReactNode;
  to?: string;
  className?: string;
  onClick?: () => void;
  href?: string;
}

const LinkWrapper = styled.span`
  color: ${({ theme }) => theme.colors.link};
  font-size: inherit;

  &:hover {
    color: ${({ theme }) => theme.colors.linkHover};
  }
`;

const StyledRouterLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const StyledLink = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const BaseLink: FC<BaseLinkProps> = ({
  content,
  children,
  to,
  className,
  onClick,
  href
}) => (
  <LinkWrapper className={className} onClick={onClick}>
    {to ? (
      <StyledRouterLink to={to}>{content || children}</StyledRouterLink>
    ) : (
      <StyledLink href={href}>{content || children}</StyledLink>
    )}
  </LinkWrapper>
);

export default BaseLink;
