import styled from 'styled-components';
import Button from '../Button';

const Wrapper = styled.div``;
const Bottom = styled.div`
  ${Button} {
    width: 35px;
    height: 35px;
    padding: 11px;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
const Right = styled.div`
  display: flex;
  & > ${Button} {
    width: 40px;
    height: 40px;
    padding: 11px;
    margin: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    align-self: flex-start;
  }
`;

export default {
  Wrapper,
  Bottom,
  Right
};
