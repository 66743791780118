import React from 'react';

import BasicAside from 'styleguide/components/BasicAside';
import BasicOrderedList from 'styleguide/components/BasicOrderedList';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicDescription from 'styleguide/components/BasicDescription';
import styled from 'styled-components';

const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;
const AsideInstruct = styled(BasicAside.Wrapper)`
  padding: 10px;
`;

const GoodStandingAside = () => (
  <BasicAside.Wrapper>
    <AsideHead>
      <BasicAside.Heading>Good Standing Certificates</BasicAside.Heading>
    </AsideHead>
    <AsideInstruct>
      <BasicAside.Content>
        <BasicOrderedList.Wrapper>
          <BasicOrderedList.Item
            description="Add all entities for which you need good standings/certificates of status"
            hasSpacing
            tip={
              <>
                <BasicHeading tag="h5" size="xs" style={{ color: '#84848D' }}>
                  Tip:
                </BasicHeading>

                <BasicDescription style={{ color: '#84848D' }}>
                  If you have a list of names, you can copy and paste the whole
                  list into a cell
                </BasicDescription>
              </>
            }
          >
            <BasicHeading tag="h5" size="md">
              Add names
            </BasicHeading>
          </BasicOrderedList.Item>
          <BasicOrderedList.Item
            description="Type or select all states from which you need good standings"
            hasSpacing
          >
            <BasicHeading tag="h5" size="md">
              Add jurisdictions
            </BasicHeading>
          </BasicOrderedList.Item>
          <BasicOrderedList.Item
            description="Click or drag across cells to indicate where we should pull good standings for each name"
            hasSpacing
          >
            <BasicHeading tag="h5" size="md">
              Match names to jurisdictions
            </BasicHeading>
          </BasicOrderedList.Item>
        </BasicOrderedList.Wrapper>
      </BasicAside.Content>
    </AsideInstruct>
  </BasicAside.Wrapper>
);

export default GoodStandingAside;
