import { auth } from 'firebaseConfig';

import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const fetchDashboardDataUrl = `${url}/dashboard/matters/orders`;
const getDeleteMatterUrl = (id: string): string => `${url}/matters/${id}`;

export interface FetchOrdersFilters {
  page?: number;
  pageSize?: number;
  matterNames?: string[];
  orderStatuses?: string[];
  dates?: string[];
  invoiceStatuses?: string[];
}

export interface Order {
  id: string;
  matterName: string;
  matterId: string;
  status: string;
  assignedTo: string;
  orderDate: string;
  invoiceStatus: string;
  invoiceLink: string;
  isFirstItem: boolean;
  isOwner: boolean;
}

export interface FetchOrdersData {
  orders: Order[];
  totalCount: number;
}

interface Role {
  contact: string;
  role: string;
}

interface ResponseMatter {
  id: string;
  name: string;
  status: string;
  roles: Role[];
  assignedTo: string;
}

interface ResponseOrder {
  id: string;
  invoiceLink: string;
  invoiceStatus: string;
  orderDate: string;
  fulfillmentStatus: string;
  matter: ResponseMatter;
}

const createOrder = (
  order: ResponseOrder,
  isFirstItem: boolean,
  currentUserEmail: string
): Order => ({
  id: order.id,
  matterName: order.matter.name,
  matterId: order.matter.id,
  status: order.fulfillmentStatus,
  assignedTo: order.matter.assignedTo,
  orderDate: order.orderDate,
  invoiceStatus: order.invoiceStatus,
  invoiceLink: order.invoiceLink,
  isFirstItem,
  isOwner:
    order.matter.roles.find(role => role.contact === currentUserEmail)?.role ===
    'owner'
});

export const fetchOrders = async ({
  page = 1,
  pageSize = 20,
  matterNames,
  orderStatuses,
  dates,
  invoiceStatuses
}: FetchOrdersFilters): Promise<FetchOrdersData> => {
  const client = await getAuthorizedApi();
  const currentUserEmail = await auth.currentUser.email;
  const skip = (page - 1) * pageSize;
  const { data } = await client.get(fetchDashboardDataUrl, {
    params: {
      take: pageSize,
      skip,
      names: matterNames,
      fulfillmentStatuses: orderStatuses,
      dates,
      invoiceStatuses
    }
  });

  const responseOrders: ResponseOrder[] = data.orders;

  const orders = responseOrders.map((order, index, arr) =>
    createOrder(
      order,
      arr[index - 1]?.matter?.id !== order.matter.id,
      currentUserEmail
    )
  );

  return { orders, totalCount: data.count };
};

export const deleteMatter = async (id: string): Promise<void> => {
  const client = await getAuthorizedApi();
  const deleteUrl = getDeleteMatterUrl(id);

  await client.delete(deleteUrl);
};
