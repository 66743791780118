import React, { FC } from 'react';
import BasicCheckboxList from 'styleguide/components/BasicCheckboxList';
import { Product } from '../state/productsState';

interface ProductSelectProps {
  name: string;
  products: Product[];
  selected: string[];
  disabled: string[];
  select: (id: string) => void;
  deselect: (id: string) => void;
  selectAll?: () => void;
  deselectAll?: () => void;
  allSelected?: boolean;
  selectAllLabel?: string;
}

const ProductSelect: FC<ProductSelectProps> = ({
  disabled,
  name,
  products,
  selected,
  select,
  deselect,
  selectAll,
  deselectAll,
  allSelected,
  selectAllLabel
}) => (
  <BasicCheckboxList.Wrapper isRow={false}>
    {selectAllLabel && (
      <BasicCheckboxList.Item
        name={name}
        value="all"
        disabled={disabled.includes('selectAll')}
        onChange={(e: any) => {
          if (e.target.checked) {
            selectAll();
          } else {
            deselectAll();
          }
        }}
        checked={allSelected}
      >
        {selectAllLabel}
      </BasicCheckboxList.Item>
    )}
    {products.map(p => (
      <BasicCheckboxList.Item
        disabled={disabled.includes(p.id)}
        name={name}
        key={p.id}
        value={p.id}
        checked={selected.includes(p.id)}
        onChange={(e: any) => {
          if (e.target.checked) {
            select(p.id);
          } else {
            deselect(p.id);
          }
        }}
      >
        {p.name}
      </BasicCheckboxList.Item>
    ))}
  </BasicCheckboxList.Wrapper>
);

export default ProductSelect;
