import React, { FC, useEffect } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { Helmet } from 'react-helmet';

import RegistrationLeftColumn from './components/RegistrationLeftColumn';
import RegistrationRightColumn from './components/RegistrationRightColumn';
import { getRecaptchaKey } from './contants';
import AuthenticationLayout from 'layout/authenticationLayout/AuthenticationLayout';

const Registration: FC = () => {
  useEffect(() => {
    loadReCaptcha(getRecaptchaKey());

    return () => {
        const nodeBadge = document.querySelector('.grecaptcha-badge');
        if (nodeBadge) {
          document.body.removeChild(nodeBadge.parentNode);
        }
        
        const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' +
        getRecaptchaKey() + '\']';
        const script = document.querySelector(scriptSelector);
        if (script) {
          script.remove();
        }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign up - DealBinder</title>
      </Helmet>
      <AuthenticationLayout
        leftColumn={<RegistrationLeftColumn />}
        rightColumn={<RegistrationRightColumn />}
      />
    </>
  );
};

export default Registration;
