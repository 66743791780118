import styled from 'styled-components';

import StyleguideHorizontalLine from 'styleguide/components/HorizontalLine';
import BasicText from 'styleguide/components/BasicText';

export const BlueBox = styled.div`
  background: ${({ theme }) => theme.colorsNewDesign.lightBlue};
  text-align: center;
  padding: 16px 0;
`;

export const Wrapper = styled.div`
  width: 600px;
  max-width: 100vw;
  padding: 32px;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
`;

export const HorizontalLine = styled(StyleguideHorizontalLine)`
  background: ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const Link = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
