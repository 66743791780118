import React, { FC, forwardRef, useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import SvgIcon from '@material-ui/core/SvgIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  RoleSelectLabel,
  RoleSelectedLabel,
  RoleSelectDescription
} from './styled';

const BootstrapInput = withStyles(() =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      border: 0,
      marginTop: 1,
      maxHeight: 25,
      lineHeight: '26px',
      padding: '10px 20px 10px 17px',
      '&:hover, &:focus': {
        cursor: 'default',
        backgroundColor: '#fff'
      }
    }
  })
)(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      borderRadius: 4,
      minWidth: 150,
      height: 50,
      border: '1px solid #EBECEF',
      '&:hover, &:focus': {
        borderColor: '#0930CC',
        cursor: 'default'
      }
    },
    icon: {
      transition: 'color 0.15s',
      lineHeight: '26px',
      '&:hover': {
        cursor: 'default',
        color: 'rgb(153, 153, 153)',
        transition: 'color 0.15s'
      }
    },
    select: {
      '&:hover': {
        cursor: 'default'
      }
    }
  })
);

export type OptionType = { value: string; label: string; description?: string };

export const accessOptions: OptionType[] = [
  {
    value: 'limitedViewer',
    label: 'Limited viewer',
    description: 'Can view matter, cannot see notes'
  },
  {
    value: 'viewer',
    label: 'Viewer',
    description: 'Can view matter, see notes'
  },
  {
    value: 'collaborator',
    label: 'Collaborator',
    description: 'Can view matter, see notes and add notes'
  },
  {
    value: 'reviewer',
    label: 'Reviewer',
    description:
      'Can view matter, see and add notes, and change review status of files'
  },
  { value: 'owner', label: 'Owner' }
];

const TypeSelectMenuItem: FC<any> = forwardRef((props, ref) => (
  <MenuItem
    ref={ref}
    {...props}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }}
  >
    <ListItemText>
      <RoleSelectLabel>{props.data.label}</RoleSelectLabel>
      {props.data.description && (
        <RoleSelectDescription>{props.data.description}</RoleSelectDescription>
      )}
    </ListItemText>
  </MenuItem>
));

const RoleDropdown: FC<any> = ({ isAdmin, ...props }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        className={classes.select}
        onClick={() => setOpen(true)}
        onClose={() => setTimeout(() => setOpen(false))}
        open={open}
        {...props}
        input={<BootstrapInput />}
        IconComponent={() => (
          <SvgIcon className={classes.icon}>
            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
          </SvgIcon>
        )}
      >
        {accessOptions
          .filter(option => option.value !== 'owner' || isAdmin)
          .map((option, index) => (
            <TypeSelectMenuItem value={option.value} key={index} data={option}>
              <RoleSelectedLabel>{option.label}</RoleSelectedLabel>
            </TypeSelectMenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default RoleDropdown;
