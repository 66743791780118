import React, { FC, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { authApi } from 'api';
// import { auth } from 'firebaseConfig';
import { CONTACT_MAIL, LOGIN_URL } from 'helpers/routes';
import Spacing from 'styleguide/components/Spacing';
import RoundIcon from 'styleguide/components/RoundIcon';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import { ReactComponent as IconInfo } from 'components/icons/IconInfo.svg';
import SimpleLayout from 'layout/simpleLayout';

import VerificationPreapproved from './VerificationPreapproved';
import { VerificationWrapper, StyledBaseLink, GrayText } from './styled';

export interface VerificationProps {
  oobCode: string;
  continueUrl: string;
}

const Verification: FC<VerificationProps> = ({ oobCode, continueUrl }) => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPreapproved, setIsPreapproved] = useState(false);
  
  const verifyEmail = useCallback<() => void>(async () => {
    try {
    
      const { data } = await authApi()
        .email()
        .verify()
        .POST({ oobCode });

    //   await auth.currentUser.getIdToken(true);

      if (data.redirectToDashboard) {
        setIsPreapproved(true);
      }

      setIsLoaded(true);
      setIsError(false);
    } catch {
      setIsLoaded(true);
      setIsError(true);
    }
  }, [oobCode]);

  useEffect(() => {
    setTimeout(() => {
      // using react-router history push is not refeeshing firebase currentUser data
      verifyEmail();
    }, 5000);
    // verifyEmail(); // previous code
  }, [verifyEmail]);

  if (!isLoaded) {
    return null;
  }

  if (isPreapproved) {
    return <VerificationPreapproved continueUrl={continueUrl} />;
  }

  return (
    <SimpleLayout>
      <VerificationWrapper>
        <Spacing direction="top" value={80} />
        <RoundIcon>
          <IconInfo />
        </RoundIcon>
        {!isError && (
          <>
            <Spacing direction="top" value={40} />
            <BasicSubHeading isUppercase isSlim size="xxs">
              <FormattedMessage id="verify.helper" />
            </BasicSubHeading>
            <Spacing direction="top" value={7} />
            <BasicHeading tag="h1" size="xxxl" isCentered>
              <FormattedMessage id="verify.title" />
            </BasicHeading>
            <Spacing direction="top" value={19} />
          </>
        )}
        {isError && (
          <>
            <Spacing direction="top" value={55} />
            <BasicHeading tag="h1" size="xxxl" isCentered>
              <FormattedMessage id="shared.error" />
            </BasicHeading>
            <Spacing direction="top" value={16} />
          </>
        )}
        <GrayText>
          {isError && (
            <>
              <FormattedMessage id="verify.errorContent1" />
              <br />
              <FormattedMessage
                id="verify.errorContent2"
                values={{
                  loginLink: (
                    <StyledBaseLink to={LOGIN_URL}>
                      <FormattedMessage id="verify.logIn" />
                    </StyledBaseLink>
                  )
                }}
              />
            </>
          )}
          {!isError && (
            <FormattedMessage
              id="verify.contact"
              values={{
                contactLink: (
                  <StyledBaseLink href={CONTACT_MAIL}>
                    <FormattedMessage id="verify.contactUsLink" />
                  </StyledBaseLink>
                )
              }}
            />
          )}
        </GrayText>
        <Spacing direction="top" value={30} />
        {isError && (
          <Link to={LOGIN_URL}>
            <Button primary>
              <FormattedMessage id="verify.verifyButton" />
            </Button>
          </Link>
        )}
        {!isError && (
          <Link to="/">
            <Button primary>
              <FormattedMessage id="shared.backToHome" />
            </Button>
          </Link>
        )}
      </VerificationWrapper>
    </SimpleLayout>
  );
};

export default Verification;
