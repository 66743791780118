import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import Button from 'styleguide/components/Button';
import { HeaderSelectComponent } from '../TableForm';
import { DocumentsFormStates } from '../../documents';
import {
  useProductState,
  getCharterDocs,
  getGoodStanding
} from '../../state/productsState';

const StyledButton = styled(Button)`
  font-size: 13px;
`;

const GridHeaderSelect: HeaderSelectComponent = ({ col }) => {
  const [state] = useProductState();
  const { values, setFieldValue } = useFormikContext<{
    names: string[];
    selection: string[][][];
    state: DocumentsFormStates;
  }>();
  const value =
    values.state === DocumentsFormStates.CharterDocuments
      ? getCharterDocs(state).id
      : getGoodStanding(state).id;
  const selected =
    values.selection &&
    values.selection[col]?.filter(Boolean).filter(n => n.includes(value))
      .length === values.names.length;
  return (
    <StyledButton
      type="button"
      variant="inline"
      onClick={() => {
        if (selected) {
          setFieldValue(
            `selection.${col}`,
            values.selection[col].map(row =>
              row ? row.filter(el => el !== value) : row
            )
          );
        } else {
          setFieldValue(
            `selection.${col}`,
            values.names.map((val, i): string[] => {
              if (
                !values.selection ||
                !values.selection[col] ||
                !values.selection[col][i]
              )
                return [value];
              return [
                ...values.selection[col][i],
                ...(values.selection[col][i].includes(value) ? [] : [value])
              ];
            })
          );
        }
      }}
    >
      {selected ? 'Unselect All' : 'Select All'}
    </StyledButton>
  );
};

export default GridHeaderSelect;
