export default {
  signIn: `Sign in`,
  signUp: `Sign up`,
  resetYourPassword: `reset your password`,
  or: `or`,
  show: `show`,
  hide: `hide`,
  next: 'Next',
  back: `Back`,
  backToHome: `Back to homepage`,
  sendAgain: `Send again`,
  sent: 'Sent',
  failed: 'Failed',
  clickHere: 'Click here',
  error: `Oops!`,
  password: `Password`,
  confirmPassword: `Confirm password`,
  email: `Email`,
  resetPassword: `Reset password`,
  save: `Save`,
  close: `Close`,
  welcome: `Welcome!`
};
