import styled from 'styled-components';
import { Link } from 'react-router-dom';

import BasicSubheading from 'styleguide/components/BasicSubHeading';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import StyleguideHorizontalLine from 'styleguide/components/HorizontalLine';
import dashboardSummaryPattern from 'components/icons/DashboardSummaryPattern.svg';

export const Wrapper = styled.div`

  padding: 40px;
  @media (max-width: 768px) {
    padding: 20px;



`;

export const Grid = styled.div`
  display: flex;
  grid-gap: 22px;
`;
// display: grid;

// grid-template-columns: repeat(3, 1fr);

export const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
`;


export const SummaryWrapper = styled.div<{ isLight: boolean }>`
  border-radius: 4px;
  color: white;
  padding: 24px;
  background: ${({ theme, isLight }) =>
      isLight
        ? theme.colorsNewDesign.dodgerBlue
        : theme.colorsNewDesign.primaryHover}
    url(${dashboardSummaryPattern}) no-repeat right bottom;
`;

export const SummaryIconWrapper = styled.div<{ isLight?: boolean }>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, isLight }) =>
    isLight
      ? theme.colorsNewDesign.dodgerBlueLight
      : theme.colorsNewDesign.persianBlue};
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
`;

export const SummarySubHeading = styled(BasicSubheading)`
  color: white;
  opacity: 0.6;
`;

export const SummaryHeading = styled(BasicHeading)`
  color: white;
`;

export const SummaryCount = styled(BasicHeading)`
  color: white;
  margin-left: auto;
  line-height: 1;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  padding: 24px 24px 22px;
  box-shadow: ${({ theme }) => theme.boxShadow.strong};
  flex-grow: 1;
`;

export const ListHeader = styled(BasicSubheading)`
  line-height: 1;
`;

export const ListItemName = styled(BasicHeading)`
  display: flex;
  align-items: center;
`;

export const ListPrice = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
`;

export const ListItem = styled.div`
  display: flex;
  padding: 4px 8px;
  cursor: pointer;
  min-height: 50px;
  align-items: center;

  &:hover {
    ${ListItemName}, ${ListPrice} {
      color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }

    svg path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
  }
`;

export const HorizontalLine = styled(StyleguideHorizontalLine)`
  background: ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const ListItemButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const ListItemLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const EmptyListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 383px;

  @media (max-width: 768px) {
    min-height: 210px;
  }  
`;
// min-height: 383px;


export const LoaderWrapper = styled(EmptyListWrapper)`
  justify-content: center;
`;

export const EmptyListIconWrapper = styled(SummaryIconWrapper)`
  background: ${({ theme }) => theme.colorsNewDesign.background};
  width: 64px;
  height: 64px;
`;

export const EmptyListButton = styled(Button)`
  margin-top: auto;
`;

export const InvoicesModalContent = styled.div`
  width: 526px;
  max-width: 100vw;
`;
