const STAGING_RECAPTCHA_KEY = '6LfKHPwUAAAAACwNNksz-xvW-EkGnbbKwDRrK5wv';
const PRODUCTION_RECAPTCHA_KEY = '6Lc7TtAZAAAAAPUZpjJcQAFAIa7bbIR8vXye6CRE';
const DEVELOPMENT_RECAPTCHA_KEY = '6LdwNZYcAAAAAEXgjvDf8Bf5Jitc1pqW43-nHfu7';

export const getRecaptchaKey = () => {
  switch (process.env.REACT_APP_ENVIROMENT) {
    case 'STAGING':
      return STAGING_RECAPTCHA_KEY;
    case 'PRODUCTION':
      return PRODUCTION_RECAPTCHA_KEY;
    default:
      return DEVELOPMENT_RECAPTCHA_KEY;
  }
};

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY'
];
