import styled, { css } from 'styled-components';

import BasicText from 'styleguide/components/BasicText';
import { ReactComponent as CheckIcon } from 'components/icons/IconCheck.svg';
import { ReactComponent as DotIcon } from 'components/icons/IconDot.svg';

export const GrayText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export const List = styled.div`
  margin-left: 11px;
`;

export const ListItem = styled(GrayText)<{ isComplete: boolean }>`
  display: flex;
  align-items: center;

  ${({ isComplete, theme }) =>
    isComplete &&
    css`
      color: ${theme.colorsNewDesign.cyan110};
    `}

  & + & {
    margin-top: 2px;
  }
`;

export const Check = styled(CheckIcon)`
  width: 18px;
  height: 18px;

  path {
    fill: ${({ theme }) => theme.colorsNewDesign.cyan110};
  }
`;

export const Dot = styled(DotIcon)`
  width: 18px;
  margin: 7px 0 6px;
`;
