import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from 'react-sweet-state';
import uniq from 'lodash/uniq';
import { STEP_NAMES, DILIGENCE_STEPS } from '../consts';
import { SearchesFormValues } from '../searches';
import { DiligenceFormValues } from '../diligence';
import { StartFormValues } from '../start';
import { DocumentsFormValues, DocumentsFormStates } from '../documents';
import { OfacFormValues } from '../ofac';
import { ReviewFormValues } from '../components/review/ReviewForm';

import {
  formDataToOrderLineItems,
  EstimateData,
  formDataToMatter,
  formDataToOrder
} from '../utils/formData';
import { postEstimate, postMatter, postMatterOrder, deleteOrder } from 'api/order';
import { postAdminMatter, postAdminMatterOrder, deleteAdminOrder } from 'api/order/admin';

export type Turnarounds = {
  charterDocs?: {
    [jurisdictionId: string]: string;
  };
  goodStanding?: {
    [jurisdictionId: string]: string;
  };
};

export type State = {
  currentStep: STEP_NAMES;
  deleteModal: STEP_NAMES | null;
  validSteps: STEP_NAMES[];
  start?: StartFormValues;
  diligence?: DiligenceFormValues;
  steps?: STEP_NAMES[];
  searches?: SearchesFormValues;
  documents?: DocumentsFormValues;
  ofac?: OfacFormValues;
  review?: ReviewFormValues;
  estimateResponse?: EstimateData;
  turnarounds?: Turnarounds;
  matterId?: string;
  orderId?: string;
  // used for loading estimates which should be deleted when actual order is placed:
  // setting to true will result in DELETE request for order specified in orderId
  shouldDeleteEstimate?: boolean;
  // used for admin to create an order for given contact:
  contactId?: string;
  // admin discount related fields:
  adminDiscount?: number;
  adminDiscountMessage?: string;
  order?: any;
};

type StoreApi = StoreActionApi<State>;
type Actions = typeof actions;

const initialState: State = {
  currentStep: STEP_NAMES.Start,
  steps: [STEP_NAMES.Start, STEP_NAMES.Diligence],
  estimateResponse: {},
  deleteModal: null,
  validSteps: [],
};

export const actions = {
  setStep: (step: STEP_NAMES) => ({ setState, getState }: StoreApi) => {
    setState({ ...getState(), currentStep: step });
  },
  setStart: (values: StartFormValues) => ({ setState, getState }: StoreApi) => {
    setState({
      ...getState(),
      start: values
    });
  },
  setDiligence: (values: DiligenceFormValues) => ({
    setState,
    getState
  }: StoreApi) => {
    const state = getState();
    const steps = DILIGENCE_STEPS.filter(step => values.diligence[step as keyof {}]);
    setState({
      ...state,
      currentStep: steps[0],
      diligence: values,
      validSteps: uniq([...state.validSteps, STEP_NAMES.Diligence]),
      steps: [
        STEP_NAMES.Start,
        STEP_NAMES.Diligence,
        ...steps,
        STEP_NAMES.Review
      ]
    });
  },
  submitStep: (values: any) => ({ setState, getState }: StoreApi) => {
    // TODO: replace any with proper type
    const state = getState();
    const nextStepName =
      state.steps[state.steps.indexOf(state.currentStep) + 1];

    setState({
      ...state,
      currentStep: nextStepName,
      [state.currentStep]: values,
      validSteps: uniq([...state.validSteps, state.currentStep]),
    });
  },
  previousStep: () => ({ setState, getState }: StoreApi) => {
    const state = getState();
    const prevStepName =
      state.steps[state.steps.indexOf(state.currentStep) - 1];
    setState({ ...state, currentStep: prevStepName });
  },
  setDeleteModal: (deleteModal: STEP_NAMES | null) => ({ setState, getState }: StoreApi) => {
    setState({...getState(), deleteModal });
  },
  showDeleteModal: () => ({ setState, getState }: StoreApi) => {
    const state = getState();
    setState({...state, deleteModal: state.currentStep });
  },
  deleteStep: (step: STEP_NAMES) => ({ setState, getState }: StoreApi) => {
    const state = getState();
    setState({
      ...state,
      steps: state.steps.filter(s => s !== step),
      validSteps: state.validSteps.filter(s => s!==step),
      [step]: undefined,
      deleteModal: null,
      diligence: { diligence: { ...state.diligence.diligence, [step]: false }},
      currentStep: (state.currentStep === step) ? state.steps[state.steps.indexOf(step) - 1] : state.currentStep,
    });
  },
  submit: ({adminDiscount, adminDiscountMessage }: {adminDiscount: number; adminDiscountMessage: string}) => async ({ setState, getState }: StoreApi) => {
    const state = getState();
    try {
      let response;
      if (state.review.existing) {
        response = await postMatterOrder(
          state.review.matterId.value,
          {
            ...formDataToOrder(state),
            adminDiscount, 
            adminDiscountMessage
          }
        );
      } else {
        response = await postMatter(formDataToMatter(state));
      }
      if (response.status === 201) {
        // Delete old estimate:
        if (state.matterId && state.orderId && state.shouldDeleteEstimate) {
          deleteOrder(state.matterId, state.orderId);
        }
        const matterId = state.review.existing ? state.review.matterId.value : response.data.id;
        setState({ ...getState(), currentStep: STEP_NAMES.Confirm, matterId });
      }
    } catch (e) {
      console.error(e);
    }
  },
  submitAdmin: ({ email, doNotify, adminDiscount, adminDiscountMessage }: { email: string; doNotify: boolean; adminDiscount: number; adminDiscountMessage: string}) => async ({ setState, getState }: StoreApi) => {
    const state = getState();
    try {
      let response;
      if (state.review.existing) {
        response = await postAdminMatterOrder(
          {
            ...formDataToOrder(state),
            email,
            doNotify,
            adminDiscount,
            adminDiscountMessage,
            matterId: state.review.matterId.value
          }
        )
      } else {
        response = await postAdminMatter(
          {
            order: formDataToOrder(state),
            name: state.review.matterName,
            status: 'In progress',
            email,
            doNotify,
            adminDiscount,
            adminDiscountMessage,
            isDeleted: false,
          }
        )
      }
      if (response.status === 201) {
        // Delete old estimate:
        if (state.matterId && state.orderId && state.shouldDeleteEstimate) {
          deleteAdminOrder(state.orderId);
        }
        // const matterId = state.review.existing ? state.review.matterId.value : response.data.id;
        setState({ ...getState(), currentStep: STEP_NAMES.Confirm });
      }
    } catch (e) {
      console.error(e);
    }
  }, 
  exit: () => ({ setState, getState }: StoreApi) => {
    const state = getState();
    setState({
      // Apparently setState does not replace state, but calculates a diff
      // so in order to clean state to initialState i need to set all existing keys to null
      ...Object.keys(state).reduce((acc: any, key:string) => {acc[key] = null; return acc; }, {}),
      ...initialState
    });
  },
  // handles URL change:
  urlChanged: (currentUrl: string) => ({ setState, getState }: StoreApi) => {
    const currentStep: STEP_NAMES = currentUrl as STEP_NAMES;
    const state = getState();
    if (
      !state.steps.includes(currentStep) &&
      currentStep !== STEP_NAMES.Confirm
    ) {
      setState({ ...state, currentStep: STEP_NAMES.Start });
    } else if (state.currentStep !== currentStep) {
      setState({ ...state, currentStep });
    }
  },
  requestEstimate: () => async ({ setState, getState }: StoreApi) => {
    const state = getState();
    setState({ ...state, estimateResponse: {} });
    const formData = formDataToOrderLineItems(state);
    const response = await postEstimate(formData);

    if (response.status === 200 && response.data.estimate) {
      setState({ ...getState(), estimateResponse: response.data });
    }
  },
  setTurnaround: (type: keyof Turnarounds, stateName: string, turnaround: string) => ({ setState, getState }: StoreApi) => {
    const state = getState();
    const turnarounds = state.turnarounds || {};
    setState({ 
      turnarounds: {
        ...turnarounds,
        [type]: {
          ...(turnarounds[type] || {}),
          [stateName]: turnaround,
        },
      } 
    });
  },
  addStep: (step: STEP_NAMES) => ({ setState, getState }: StoreApi) => {
    const state = getState();
    const diligence = { diligence: { ...state.diligence.diligence, [step]: true }}
    const steps = DILIGENCE_STEPS.filter(step => diligence.diligence[step as keyof {}]);
    setState({
      ...state,
      currentStep: step,
      diligence,
      steps: [
        STEP_NAMES.Start,
        STEP_NAMES.Diligence,
        ...steps,
        STEP_NAMES.Review
      ]
    });
  },
  addDocs: (type: 'goodStanding' | 'charterDocuments') => ({ getState, setState }: StoreApi) => {
    const state = getState();
    // Resets Documents form state to config and adds stated document type
    setState({
      ...state,
      documents: {
        ...state.documents,
        state: DocumentsFormStates.Config,
        [type]: true
      },
      currentStep: STEP_NAMES.Documents,
      validSteps: state.validSteps.filter(step => step !== STEP_NAMES.Documents)
    })
  },
  stepFormChanged: () => ({ getState, setState }: StoreApi) => {
    const { currentStep, validSteps } = getState();
    if (validSteps.includes(currentStep)) {
      setState({ validSteps: validSteps.filter(step => step !== currentStep)});
    }
  },
  stepFormUnmounted: (values: any) => () => {},
  loadState: (state: any) => ({ setState }: StoreApi) => {
    setState({...state });
  },
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'orderForm'
});

export const useOrderFormState = createHook(Store);
export const OrderFormStateSubscriber = createSubscriber(Store);
