import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 4px;

    path {
      fill: ${({ theme }) => theme.colorsNewDesign.primary};
    }
  }

  .react-tagsinput {
    display: block;
    border-radius: ${({ theme }) => theme.borderRadius.regular};
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};

    &:focus-within {
      border: 1px solid ${({ theme }) => theme.colorsNewDesign.primary};
      outline: none;
    }
  }

  .react-tagsinput-tag {
    color: ${({ theme }) => theme.colorsNewDesign.primary};
    background: ${({ theme }) => theme.colorsNewDesign.grayBlue100};
    border-radius: 999px;
    font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
    padding: 4px 12px;
    line-height: 1.7;
    display: inline-flex;
    align-items: center;
    margin: 2px 4px 2px 0;
  }

  input {
    border: none;
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
    line-height: 2.1;

    &::placeholder {
      color: ${({ theme }) => theme.colorsNewDesign.gray400};
    }
  }
`;
