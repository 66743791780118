import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconDone } from 'components/icons/IconDone.svg';

import { ThemeType } from 'theme/theme';

type CellColor = keyof ThemeType['statusColors'];
export const colors: CellColor[] = [
  'lightBlue',
  'orange',
  'blue',
  'mint',
  'yellow',
  'pink',
  'teal',
  'purple',
  'green'
];

interface StatusBoxProps {
  color: CellColor;
  hasBackgroundColor?: boolean;
  hasIcon?: boolean;
  isCondensed?: boolean;
}

export const MultiStatusBoxWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const StatusBoxWrapper = styled.span<StatusBoxProps>`
  pointer-events: none;
  position: relative;
  display: block;
  height: 100%;
  min-height: 35px;
  width: 100%;
  padding: 7px;
  color: ${({ theme, color }) => theme.statusColors[color].font};
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  border-bottom: 1px solid
    ${({ theme, color, hasBackgroundColor }) =>
      hasBackgroundColor
        ? theme.statusColors[color].border
        : theme.colorsNewDesign.lightest};
  background-color: ${({ theme, color, hasBackgroundColor }) =>
    hasBackgroundColor
      ? theme.statusColors[color].background
      : 'transparent'};
  ${({hasIcon}) => hasIcon && `
    padding-right: 36px;
  `}
  ${({isCondensed}) => isCondensed && `
    padding: 0px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  `}
`;

const StyledIconDone = styled(IconDone)`
  color: ${({ theme, color }) => theme.statusColors[color].font};
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const StatusBox: FC<StatusBoxProps> = ({
  color,
  hasBackgroundColor,
  hasIcon = true,
  isCondensed = false,
  children
}) => (
  <StatusBoxWrapper color={color} hasBackgroundColor={hasBackgroundColor} hasIcon={hasIcon} isCondensed={isCondensed}>
    {children && (
      <>
        {children}
        {hasIcon && <StyledIconDone color={color} />}
      </>
    )}
  </StatusBoxWrapper>
);

export default StatusBox;
