import React, { FC, useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Spacing from 'styleguide/components/Spacing';
import TextInput from 'styleguide/components/TextInput';
import Error from 'styleguide/components/Error';
import Loader from 'styleguide/components/Loader';
import Button from 'styleguide/components/Button';
import Snackbar from 'styleguide/components/Snackbar';
import { EMAIL_VALIDATION } from 'helpers/validation';
import {useMediaQuery} from "@material-ui/core";

import {
  AssignToContentWrapper,
  Link,
  Row,
  HorizontalLine,
  Label,
  Footer
} from './styled';

interface AssignToModalProps {
  isOpen: boolean;
  onClose: () => void;
  openPermissions: () => void;
  assign: (assignedTo: string, email: string) => Promise<void>;
}

type AssignToFormValues = {
  email: string;
  name: string;
};

const emailError = 'Please enter a valid email';
const nameError = 'Name must be 3 or more characters';

const validationSchema = yup.object().shape<AssignToFormValues>({
  email: yup
    .string()
    .email(emailError)
    .required(emailError)
    .matches(EMAIL_VALIDATION, {
      message: emailError,
      excludeEmptyString: true
    }),
  name: yup
    .string()
    .required(nameError)
    .min(3, nameError)
});

const AssignToModal: FC<AssignToModalProps> = ({
  isOpen,
  onClose,
  openPermissions,
  assign
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const matches = useMediaQuery('(min-width:768px)');
  useEffect(() => {
    if (isOpen) {
      setIsSubmitted(false);
      setIsError(false);
    }
  }, [isOpen]);

  const handleAssign = (values: AssignToFormValues) =>
    assign(values.name, values.email)
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {
        setIsSubmitted(true);
        setIsError(true);
      });

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <Spacing direction="left" value={8} />
          <BasicHeading tag="h2" size="xl">
            Assign a Reviewer
          </BasicHeading>
        </>
      }
    >
      {isSubmitted && (
        <AssignToContentWrapper>
          <Spacing direction="top" value={16} />
          <Row>
            <Spacing direction="left" value={32} />
            {isError ? (
              <Snackbar width="100%" type="error">
                Failed to assign a Reviewer
              </Snackbar>
            ) : (
              <Snackbar width="100%" type="success">
                Successfully assigned!
              </Snackbar>
            )}
            <Spacing direction="left" value={32} />
          </Row>
          <Spacing direction="top" value={16} />
        </AssignToContentWrapper>
      )}
      {!isSubmitted && (
        <Formik
          initialValues={{
            email: '',
            name: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleAssign}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <AssignToContentWrapper>
                <BasicText size="regular">
                  The assignee will be given a Reviewer role and will receive an
                  email request to review this matter.
                </BasicText>
                <Spacing direction="top" value={16} />
                <Row>
                {matches && <Spacing direction="left" value="auto" />}
                  <Link onClick={openPermissions} size="regular">
                    Learn more about roles
                  </Link>
                  <Spacing direction="left" value={32} />
                </Row>
                <Spacing direction="top" value={24} />
                <HorizontalLine width="100%" />
                <Spacing direction="top" value={24} />
                <Label htmlFor="email">Email</Label>
                <Spacing direction="top" value={8} />
                <TextInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="email"
                  name="email"
                  value={values.email}
                  placeholder="Add assignee's email"
                  invalid={Boolean(touched.email && errors.email)}
                  hasNoMargin
                />
                {touched.email && errors.email && (
                  <>
                    <Spacing direction="top" value={8} />
                    <Error>{errors.email}</Error>
                  </>
                )}
                <Spacing direction="top" value={16} />
                <Label htmlFor="name">Name</Label>
                <Spacing direction="top" value={8} />
                <TextInput
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="name"
                  name="name"
                  value={values.name}
                  placeholder="Add assignee's name"
                  invalid={Boolean(touched.name && errors.name)}
                  hasNoMargin
                />
                {touched.name && errors.name && (
                  <>
                    <Spacing direction="top" value={8} />
                    <Error>{errors.name}</Error>
                  </>
                )}
                <Spacing direction="top" value={24} />
              </AssignToContentWrapper>
              <Footer>
                {isSubmitting && <Loader />}
                <Button type="button" resetOrder medium onClick={onClose}>
                  Cancel
                </Button>
                <Spacing direction="left" value={8} />
                <Button
                  type="submit"
                  resetOrder
                  medium
                  primary
                  disabled={isSubmitting}
                >
                  Send
                </Button>
              </Footer>
            </form>
          )}
        </Formik>
      )}
    </BasicModal>
  );
};

export default AssignToModal;
