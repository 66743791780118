import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { authApi } from 'api';
import { INFO_EMAIL_ADDRESS } from 'helpers/constants';
import { ERROR_URL } from 'helpers/routes';
import Spacing from 'styleguide/components/Spacing';
import RoundIcon from 'styleguide/components/RoundIcon';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import HorizontalLine from 'styleguide/components/HorizontalLine';
import { ReactComponent as IconEmail } from 'components/icons/IconEmail.svg';
import { AuthContext } from 'components/app/Auth';
import Loader from 'components/shared/loader/Loader';
import Status from 'components/shared/status/Status';
import SimpleLayout from 'layout/simpleLayout';

const CheckYourEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 415px;
`;

const StyledLoader = styled(Loader)`
  margin-left: 8px;
`;

const StyledStatus = styled(Status)`
  margin-left: 8px;
`;

const StyledError = styled.p`
  margin-top: 3px;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  color: ${({ theme }) => theme.colors.error};
`;

const Email = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
`;

const GrayText = styled.div`
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizes.md};
  max-width: 320px;
`;

const GrayTextSmall = styled(GrayText)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const TextButton = styled.span`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const PendingStatus: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const history = useHistory();
  const currentUser = useContext(AuthContext);

  const resendLink = async () => {
    try {
      setIsLoading(true);
      setIsSent(false);
      await authApi()
        .email()
        .resend()
        .POST({ email: currentUser.email });
      setIsLoading(false);
      setIsSuccess(true);
      setIsSent(true);
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsSent(true);
      console.error(error);
    }
  };

  useEffect(() => {
    if (!currentUser || currentUser.emailVerified) {
      history.push(ERROR_URL);
    }
  }, [currentUser, history]);

  if (!currentUser || currentUser.emailVerified) {
    return null;
  }

  return (
    <SimpleLayout>
      <CheckYourEmailWrapper>
        <Spacing direction="top" value={80} />
        <RoundIcon>
          <IconEmail />
        </RoundIcon>
        <Spacing direction="top" value={40} />
        <BasicHeading tag="div" size="xxl">
          <FormattedMessage id="checkYourEmail.helper" />
        </BasicHeading>
        <Spacing direction="top" value={33} />
        <BasicHeading tag="h1" size="xl" isCentered>
          <FormattedMessage id="checkYourEmail.title" />
        </BasicHeading>
        <Spacing direction="top" value={19} />
        <Email weight="semiBold" size="md">
          {currentUser.email}
        </Email>
        <Spacing direction="top" value={15} />
        <BasicHeading tag="div" size="xl">
          <FormattedMessage id="checkYourEmail.paragraph" />
        </BasicHeading>
        <Spacing direction="top" value={16} />
        <HorizontalLine width="320px" />
        <Spacing direction="top" value={24} />
        <GrayText>
          <FormattedMessage id="checkYourEmail.paragraph3" />{' '}
          {INFO_EMAIL_ADDRESS}
        </GrayText>
        <Spacing direction="top" value={16} />
        <GrayTextSmall>
          <FormattedMessage
            id="checkYourEmail.didntReceive"
            values={{
              link: (
                <TextButton onClick={resendLink}>
                  <FormattedMessage id="shared.sendAgain" />
                </TextButton>
              )
            }}
          />
        </GrayTextSmall>
        <Spacing direction="top" value={16} />
        {isLoading && <StyledLoader />}
        {isSent && (
          <>
            <StyledStatus
              successText={<FormattedMessage id="shared.sent" />}
              failText={<FormattedMessage id="shared.failed" />}
              isSuccess={isSuccess}
            />

            {!isSuccess && (
              <StyledError>
                <FormattedMessage id="checkYourEmail.sendingMailCondition" />
              </StyledError>
            )}
          </>
        )}
      </CheckYourEmailWrapper>
    </SimpleLayout>
  );
};

export default PendingStatus;
