import styled, { css } from 'styled-components';

import IconCircle from 'components/icons/IconCheckNewDesign.svg';

interface ItemProps {
  isDone?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
}

const List = styled.div`
  display: flex;
  height: 100%;
  align-items: stretch;
  justify-content: center;
  height: 64px;
`;

const Item = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  line-height: 1.3;
  color: ${({ theme }) => theme.colorsNewDesign.gray400};
  margin-right: 50px;
  border-bottom: 2px solid transparent;

  a {
    color: ${({ theme }) => theme.colorsNewDesign.gray400};

    &:hover {
      text-decoration: none;
    }
  }

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.colorsNewDesign.primary};
    border: 8px solid ${({ theme }) => theme.colorsNewDesign.lightBlue};
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    border-radius: 50%;
    box-sizing: content-box;
  }

  ${({ isDone, theme }) =>
    isDone &&
    css`
      color: ${theme.colorsNewDesign.darkest};
      a {
        color: ${theme.colorsNewDesign.darkest};
      }
      &::before {
        width: 20px;
        height: 20px;
        background: url(${IconCircle}) center no-repeat;
        background-size: 20px 20px;
        border: none;
      }
    `}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-bottom: 2px solid ${theme.colorsNewDesign.primary};
      color: ${theme.colorsNewDesign.darkest};
      a {
        color: ${theme.colorsNewDesign.darkest};
      }
    `}
`;

export default {
  List,
  Item
};
