import styled from 'styled-components';
import BasicModal from 'styleguide/components/BasicModal/PdfModalForMobile';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Button from 'styleguide/components/Button';
import { ReactComponent as Details } from 'components/icons/IconDetails.svg';
import { ReactComponent as Notes } from 'components/icons/IconNotes.svg';

export const StickyContainer = styled.div`
  position: fixed;
  top: 0;
  width:100%;
  z-index: 99;
  background:white;
`;

export const StickyContainerSidebar = styled.div`
  position: fixed;
  top: 56px;
  z-index: 99;
  background:white;
  width:100%
`;

export const BlankContainer = styled.div`

  padding:57px
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};

  .viewer-toolbar-right {
    .viewer-toolbar-item {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }

  .viewer-open-file {
    display: none;
  }
`;

export const Modal = styled(BasicModal)`
  max-height: none;
`;

export const NavWrapper = styled.div`
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @media(max-width:768px){
    border-bottom: 0px solid ${({ theme }) => theme.colorsNewDesign.white};
  }

`;

export const NavRightSection = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  width: 434px;
  @media(max-width:768px){
    position:absolute;
    right:3px;
    justify-content:space-evenly;
    float:right;
    width: auto;
  }
`;



 export const NavSubHeadingContainer = styled.div`
 display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 100%;
  align-items: center;
  justify-content: flex-start;


`;

export const NavSubAddressContainer = styled.div`
display: flex;
 flex-direction: row;
 white-space: nowrap;
 width: 100%;
 align-items: center;
 justify-content: flex-start;
 margin-top:-10px;
 margin-left:40px
`;



export const ContentWrapper = styled.div`
  display: flex;
  min-height: 0;
  flex-grow: 1;
`;

export const PDFWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const MobileNavWrapper = styled.div`
display: flex;
width: 100%;
flex-direction: column;
`;

export const PDFWrapperForMobile = styled.div`
  flex-grow: 1;
  overflow: auto;
  min-height: 80vh;
`;

export const PDFMobileLoader = styled.div`
  display: flex;
  min-height: 80vh;
  justify-content: center;
  align-items: center;
`;

export const SidebarWrapper = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '434px')};
  position: relative;
  z-index: 1;
`;

export const SidebarWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
`;

export const SidebarHeader = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  border-left: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  height: 58px;
  flex-shrink: 0;

  ${({ isCollapsed }) => isCollapsed && `justify-content: center`}
`;

export const SidebarHeaderMobile = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  border-left: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  height: 58px;
  flex-shrink: 0;
`;

export const SidebarButton = styled(Button)`
  padding: 8px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
  }
`;

export const CollapseButton = styled(SidebarButton)`
  width: auto;
  height: auto;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const CollapsedSidebarContent = styled.div`
  padding: 8px 19px;
  border-left: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  flex-grow: 1;
`;

export const DetailsIcon = styled(Details)`
  width: 24px;
  height: 24px;
`;

export const NotesIcon = styled(Notes)`
  width: 15px;
  height: 15px;
`;

export const NavDescription = styled(BasicSubHeading)`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  @media (max-width:768px){
    font-size:10px
  }
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background: ${({ theme }) => theme.colorsNewDesign.gray300};
  margin: 0 8px;
`;

export const SidebarContent = styled.div`
  overflow: auto;
  padding: 16px 16px 0;
  border-left: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const DetailsGroupWrapper = styled.div`
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const HitDetailsWrapper = styled.div`
  display: flex;
  padding: 25px 18px 16px;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  }
`;

export const HitDetailsRow = styled.div`
  ${BasicSubHeading} {
    padding-top: 0;
  }

  & + & {
    margin-top: 8px;
  }
`;

export const ViewLink = styled.div`
  align-self: flex-end;
  margin-left: auto;
`;