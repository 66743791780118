import styled, { css } from 'styled-components';

interface DisableSelectProps {
  disabled?: boolean;
}

const DisableSelect = styled.div<DisableSelectProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
  `}
`;

export default DisableSelect;
