import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { authApi } from 'api';
import Spacing from 'styleguide/components/Spacing';
import Loader from 'styleguide/components/Loader';
import Snackbar from 'styleguide/components/Snackbar';
import SimpleLayout from 'layout/simpleLayout';
import { theme } from 'theme/theme';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ExpiredCodeProps {
  code: string;
  matterId: string;
  inviteeEmail: string;
}

const ExpiredCode: FC<ExpiredCodeProps> = ({ code, matterId, inviteeEmail }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    authApi()
      .resendMagicLink()
      .POST({
        code, 
        matterId, 
        inviteeEmail
      })
      .then((res) => {
        setIsLoading(false);
        window.location.href = res.data.url;
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []); // eslint-disable-line

  return (
    <SimpleLayout>
      <Wrapper>
        <Spacing direction="top" value={80} />
      
        {/* {!isError && (
          <BasicHeading tag="h1" size="xxxl" isCentered>
            Check your email
          </BasicHeading>
        )} */}

        <Spacing direction="top" value={40} />
        {!isLoading && !isError && (
          <Loader
            marginLess
            centered
            background={theme.colorsNewDesign.lightBlue}
          />
        )}
        {!isLoading && isError && (
          <Snackbar type="error">
            Sorry, this matter is not available.
            <br />
            If you think this is a mistake, please contact the matter owner.
          </Snackbar>
        )}
        {isLoading && (
          <Loader
            marginLess
            centered
            background={theme.colorsNewDesign.lightBlue}
          />
        )}
        {/* {!isError && (
          <>
            <Spacing direction="top" value={24} />
            <HorizontalLine width="320px" />
            <Spacing direction="top" value={24} />
            <GrayText>
              <FormattedMessage id="checkYourEmail.paragraph3" />{' '}
              {INFO_EMAIL_ADDRESS}
            </GrayText>
          </>
        )} */}
      </Wrapper>
    </SimpleLayout>
  );
};

export default ExpiredCode;
