import React, { FC, useCallback, useState, useMemo } from 'react';
import { ValueType } from 'react-select';
import useOnclickOutside from 'react-cool-onclickoutside';

import { ReviewHistory, DownloadedFileData } from 'api/matter';
import NavButton from 'styleguide/components/NavButton';
import NavigationButton from 'styleguide/components/NavigationButton';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import Select, { OptionType } from 'styleguide/components/Select';
import { ReactComponent as CloseIcon } from 'components/icons/IconClose.svg';
import { ReactComponent as DownloadIcon } from 'components/icons/IconDownload.svg';
import { ReactComponent as ChevronLeft } from 'components/icons/IconChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as DocumentIcon } from 'components/icons/IconDocument.svg';
import { ReactComponent as EmailIcon } from 'components/icons/IconEmail2.svg';

import ReviewSelectForm from '../ReviewSelectForm';
import { NavWrapper, NavRightSection, NavDescription, Dot } from './styled';
import DealbinderMessage from '../DealbinderMessage';
import styled from 'styled-components';

const NameWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;
const ReviewWrapper = styled.div`
  display: flex;
  align-self: center;
`;
interface NavProps {
  orderLineItemId: string;
  reviewHistory: ReviewHistory[];
  onClose: () => void;
  name: string;
  documentType: string;
  jurisdiction: string;
  showPrevArrow: boolean;
  showNextArrow: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  openDownloadModal: () => void;
  downloadFiles: (filesIds: string[]) => Promise<DownloadedFileData>;
  openEmailModal: () => void;
  dealbinderMessage?: string;
}

const Nav: FC<NavProps> = ({
  orderLineItemId,
  reviewHistory,
  onClose,
  name,
  documentType,
  jurisdiction,
  showPrevArrow,
  showNextArrow,
  onPrevClick,
  onNextClick,
  openDownloadModal,
  downloadFiles,
  openEmailModal,
  dealbinderMessage
}) => {
  const link = React.useRef(null);
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);

  const downloadSelectRef = useOnclickOutside(() => {
    if (isDownloadDropdownOpen) {
      setIsDownloadDropdownOpen(false);
    }
  });
  const handleChange = useCallback(
    (option: ValueType<OptionType>) => {
      const opt = option as OptionType;
      if (opt.value === 'single') {
        downloadFiles([orderLineItemId]).then(fileData => {
          const href = window.URL.createObjectURL(
            new Blob([fileData.data], { type: fileData.type })
          );
          const a = link.current;
          a.download = fileData.filename;
          a.href = href;
          a.click();
          a.href = '';
        });
      }
      if (opt.value === 'multiple') {
        openDownloadModal();
      }

      setIsDownloadDropdownOpen(false);
    },
    [openDownloadModal, orderLineItemId, downloadFiles]
  );

  const DownloadButton: FC = useMemo(
    () => () => (
      <NavButton onClick={() => setIsDownloadDropdownOpen(true)}>
        <DownloadIcon />
        <span>Download</span>
      </NavButton>
    ),
    [setIsDownloadDropdownOpen]
  );

  return (
    <NavWrapper>
      {/* eslint-disable-next-line */}
      <a ref={link} />
      <Spacing direction="left" value={24} />
      <NameWrapper>
        <div>
          <BasicHeading tag="h2" size="xl">
            {name}
          </BasicHeading>
          <div className="d-flex align-items-center">
            <DocumentIcon />
            <Spacing direction="left" value={8} />
            <NavDescription size="sm" isUppercase>
              {documentType}
            </NavDescription>
            {documentType.toLowerCase() !== 'ofac' ? <><Dot />
            <NavDescription className="mr-2" size="sm" isUppercase>
              {jurisdiction}
            </NavDescription></>:null}
            <DealbinderMessage
              id={`${orderLineItemId}.pdf`}
              message={dealbinderMessage}
            />
          </div>

        </div>
        <ReviewWrapper>
          <ReviewSelectForm id={orderLineItemId} reviewHistory={reviewHistory} />
          <Spacing direction="left" value={25} />
        </ReviewWrapper>

      </NameWrapper>
      <NavRightSection>

        <div ref={downloadSelectRef}>
          <Select
            options={[
              { label: 'Download this file', value: 'single' },
              { label: 'Download multiple files', value: 'multiple' }
            ]}
            components={{ Control: DownloadButton }}
            onChange={handleChange}
            value={null}
            menuIsOpen={isDownloadDropdownOpen}
          />
        </div>
        <NavButton
          onClick={openEmailModal}
          data-tip
          data-for={`pdfviewer.nav.${orderLineItemId}`}
        >
          <EmailIcon />
          <span>Email</span>
        </NavButton>
        <Tooltip id={`pdfviewer.nav.${orderLineItemId}`}>Email file</Tooltip>

        
          <>
            <Tooltip id="pdf-viewer-arrow-left">Previous file</Tooltip>
              <NavigationButton
                data-tip
                data-for="pdf-viewer-arrow-left"
                onClick={onPrevClick}
                disabled={!showPrevArrow}
              >
                <ChevronLeft />
                <span>Back</span>
              </NavigationButton>
          </>
        
        
          <>
            <Tooltip id="pdf-viewer-arrow-right">Next file</Tooltip>
            <NavigationButton
              data-tip
              data-for="pdf-viewer-arrow-right"
              onClick={onNextClick}
              disabled={!showNextArrow}
            >
              <ChevronRight />
              <span>Next</span>
            </NavigationButton>
          </>
        
        <NavButton onClick={onClose}>
          <CloseIcon />
        </NavButton>
      </NavRightSection>
    </NavWrapper>
  );
};

export default Nav;
