import React, { FC } from 'react';
import { isEmpty, some } from 'lodash';

import {
    FiltersButton,
  } from '../../../pages/matter/components/common/styled';
  import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
  import { useMyEstimatesState } from '../../../pages/dashboard/state/myEstimates';





const MatterButton: FC = () => {
  const [state, actions] = useMyEstimatesState();
  const areFiltersApplied = some(state.filters, filter => !isEmpty(filter));


   return(
    <FiltersButton
              active={areFiltersApplied}
              onClick={() => actions.toggleTableFilterSidebar('myEstimates')}
            >
    <FiltersIcon />
  </FiltersButton>
);
}

export default MatterButton;
