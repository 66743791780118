import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import TextInput from 'styleguide/components/TextInput';
import PasswordInput from 'styleguide/components/PasswordInput';
import FormLabel from 'styleguide/components/FormLabel';
import Button from 'styleguide/components/Button';
import Error from 'styleguide/components/Error';
import Snackbar from 'styleguide/components/Snackbar';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';

import { INFO_EMAIL_ADDRESS } from 'helpers/constants';
import { CONTACT_MAIL, FORGOT_PASSWORD_URL } from 'helpers/routes';

import { LoginFormRenderProps } from './LoginForm';
import Loader from 'styleguide/components/Loader';

const RegistrationFirstStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledFormLabel = styled(FormLabel)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledTextInput = styled(TextInput)`
  margin: 8px 0;
`;

const StyledPasswordInput = styled(PasswordInput)`
  margin: 8px 0;
`;

const SubmitButton = styled(Button)`
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
`;

const SignUpText = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

const StyledSnackbar = styled(Snackbar)`
  margin: 16px 0;
`;

const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/too-many-requests': {
      return (
        <>
          Too many login attempts. Try again in a few minutes or{' '}
          <Link to={FORGOT_PASSWORD_URL}>reset your password</Link>.
        </>
      );
    }
    default: {
      return (
        <>
          Oops! That email or password is incorrect. Try again or{' '}
          <Link to={FORGOT_PASSWORD_URL}>reset it</Link>.
        </>
      );
    }
  }
};

const LoginFormPresentational: FC<LoginFormRenderProps> = ({
  validationSchema,
  onSubmit,
  initialValues,
  isError,
  errorCode,
  isLoading,
  isPending
}) => {
  const { formatMessage } = useIntl();

  return (
    <RegistrationFirstStepWrapper>
      <BasicHeading tag="h2" size="xxxl">
        <FormattedMessage id="shared.signIn" />
      </BasicHeading>

      <Spacing direction="top" value={40} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <StyledFormLabel as="label">
              <FormattedMessage id="shared.email" />
              <StyledTextInput
                onChange={handleChange}
                onBlur={handleBlur}
                name="email"
                value={values.email}
                placeholder={formatMessage({
                  id: 'login.emailFieldPlaceholder'
                })}
                invalid={Boolean(touched.email && errors.email)}
                autoFocus
              />
            </StyledFormLabel>
            {touched.email && errors.email && <Error>{errors.email}</Error>}
            <Spacing direction="top" value={16} />

            <StyledFormLabel as="label">
              <FormattedMessage id="shared.password" />
              <StyledPasswordInput
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                name="password"
                value={values.password}
                placeholder={formatMessage({
                  id: 'login.passwordFieldPlaceholder'
                })}
                invalid={Boolean(touched.password && errors.password)}
              />
            </StyledFormLabel>
            {touched.password && errors.password && (
              <Error>{errors.password}</Error>
            )}
            <Spacing direction="top" value={16} />

            {isError && (
              <StyledSnackbar type="error">
                {getErrorMessage(errorCode)}
              </StyledSnackbar>
            )}

            {isPending && (
              <StyledSnackbar type="info">
                <FormattedMessage
                  id="errors.loginUnrecognized"
                  values={{
                    link: <a href={CONTACT_MAIL}>{INFO_EMAIL_ADDRESS}</a>
                  }}
                />
              </StyledSnackbar>
            )}

            <SubmitButton primary disabled={isLoading}>
              {(isLoading && (
                <>
                  <Loader marginLess size={2} background="#dddce7" />
                  <Spacing direction="right" value={16} />
                </>
              )) || <FormattedMessage id="shared.signIn" />}
            </SubmitButton>

            <Spacing direction="top" value={24} />

            <SignUpText className="text-lg-left text-center">
              <Link to={FORGOT_PASSWORD_URL}>
                <FormattedMessage id="login.forgotPassword" />
              </Link>
            </SignUpText>
          </form>
        )}
      </Formik>
    </RegistrationFirstStepWrapper>
  );
};

export default LoginFormPresentational;
