import React, { FC, useMemo } from 'react';
import size from 'lodash/size';
import head from 'lodash/head';
import { compareDesc, parseISO } from 'date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ReactComponent as IconAlert } from 'components/icons/IconAlert.svg';

import { OrderLineItem, ReviewHistory } from 'api/matter';
import { useMatterState } from '../../state/matter';

import { Grid } from './styled';
import InvoicesBox from './InvoicesBox';
import AssignToBox from './AssignToBox';
import ClosingDateBox from './ClosingDateBox';
import SummaryBox, { SummaryBoxHitsData } from './SummaryBox';
import { useDeviceState } from 'state/deviceState';
import Spacing from 'styleguide/components/Spacing';
import BasicText from 'styleguide/components/BasicText';

interface OverviewProps {
  hasSearches: boolean;
  hasOfac: boolean;
  hasOrgDocs: boolean;
  openPermissions: () => void;
}

const getLatestReview = (reviews: ReviewHistory[]): ReviewHistory =>
  head(
    reviews.sort((a, b) =>
      compareDesc(new Date(a.createdAt), new Date(b.createdAt))
    )
  );

const getInProgressItems = (items: OrderLineItem[] = []): OrderLineItem[] =>
  items.filter(item => item.status === 'In Progress');

const getHits = (items: OrderLineItem[] = []): OrderLineItem[] =>
  items.filter(item => item.status === 'Hit');

const getReceived = (items: OrderLineItem[] = []): OrderLineItem[] =>
  items.filter(item => item.status === 'Received');

const getReviewCompleted = (items: OrderLineItem[] = []): OrderLineItem[] =>
  items.filter(
    item =>
      getLatestReview(item.reviewHistory || [])?.reviewStatus ===
      'Review complete'
  );

const getLatestETA = (items: OrderLineItem[]): Date =>
  head(items.map(item => item.estimatedTurnaround && parseISO(item.estimatedTurnaround)).sort(compareDesc));

const getProgressValue = (
  inProgressItems: OrderLineItem[],
  allItems: OrderLineItem[]
): number =>
  Math.round(((size(allItems) - size(inProgressItems)) / size(allItems)) * 100);

const getHitsData = (
  all: OrderLineItem[],
  inProgress: OrderLineItem[],
  role: string,
  type: string
): SummaryBoxHitsData => {
  const hits = getHits(all);
  const received = getReceived(all);
  const completeHits = getReviewCompleted(hits);
  const completeReceived = getReviewCompleted(received);

  if (role === 'owner' || role === 'reviewer') {
    if (type === 'org-docs') {
      if (size(received) === size(completeReceived)) {
        return { label: 'No unreviewed documents' };
      }

      if (size(received) > size(completeReceived)) {
        return { count: size(received) - size(completeReceived) };
      }
    } else {
      if (size(hits) === size(completeHits)) {
        return { label: 'No unreviewed hits' };
      }

      if (size(hits) > size(completeHits)) {
        return { count: size(hits) - size(completeHits) };
      }
    }

    return {};
  }
  else {
    if (type === 'org-docs' && !(role === 'owner' || role === 'reviewer')) {
      if (size(received) === size(completeReceived)) {
        return { label: 'No documents' };
      }

      if (size(received) > size(completeReceived)) {
        return { count: size(received) - size(completeReceived) };
      }
    }
  }

  if (size(inProgress) >= 1 && size(hits) === 0) {
    return {
      label: type === "org-docs" ? 'No docs to date' : 'No hits to date'
    };
  }

  if (size(inProgress) === 0 && size(hits) === 0) {
    return { label: 'No hits found' };
  }

  if (size(hits) > 0) {
    return { count: size(hits) };
  }

  return {};
};

interface NewlineTextProps {
  text: string;
}

const NewlineText: FC<NewlineTextProps> = ({ text }) => {
  return (
    <>
      {text.split("\n").map((str: string, i: number) => <BasicText key={i} size="regular">{str}</BasicText>)}
    </>
  );
};

const Overview: FC<OverviewProps> = ({
  hasSearches,
  hasOfac,
  hasOrgDocs,
  openPermissions
}) => {
  const [state, actions] = useMatterState();

  const searches = state?.view?.searches?.orderLineItems;
  const ofac = state?.view?.ofac?.orderLineItems;
  const orgDocs = state?.view?.documents?.orderLineItems;
  const role = state?.data?.role;
  const [deviceState] = useDeviceState();
  const searchesInProgress = useMemo(() => getInProgressItems(searches), [
    searches
  ]);
  const searchesETA = useMemo(() => getLatestETA(searchesInProgress), [
    searchesInProgress
  ]);
  const searchesProgressValue = getProgressValue(searchesInProgress, searches);
  const searchesHitsData = useMemo(
    () => getHitsData(searches, searchesInProgress, role, 'searches'),
    [searches, searchesInProgress, role]
  );

  const ofacInProgress = useMemo(() => getInProgressItems(ofac), [ofac]);
  const ofacETA = useMemo(() => getLatestETA(ofacInProgress), [ofacInProgress]);
  const ofacProgressValue = getProgressValue(ofacInProgress, ofac);
  const ofacHitsData = useMemo(
    () => getHitsData(ofac, ofacInProgress, role, 'ofac'),
    [ofac, ofacInProgress, role]
  );

  const orgDocsInProgress = useMemo(() => getInProgressItems(orgDocs), [
    orgDocs
  ]);
  const orgDocsETA = useMemo(() => getLatestETA(orgDocsInProgress), [
    orgDocsInProgress
  ]);
  const orgDocsProgressValue = getProgressValue(orgDocsInProgress, orgDocs);
  const orgDocsHitsData = useMemo(
    () => getHitsData(orgDocs, orgDocsInProgress, role, 'org-docs'),
    [orgDocs, orgDocsInProgress, role]
  );

  return (
    <>
      <Grid className={deviceState.isMobileOrTablet ? "container-fluid" : ""}>
        <div className="row">
          <div className="col-4">
            <ClosingDateBox
              isOwner={state.data.role === 'owner'}
              closingDate={state.data.closingDate}
              onClosingDateChange={actions.changeClosingDate}
            />
          </div>
          <div className="col-4">
            <AssignToBox
              isOwner={state.data.role === 'owner'}
              openPermissions={openPermissions}
              assign={actions.assign}
              unassign={actions.unassign}
              assignedTo={state.assignedTo}
            />
          </div>
          <div className="col-4">
            <InvoicesBox
              isOwner={state.data.role === 'owner'}
              orders={state.data.orders}
              name={state.data.name}
            />
          </div>
        </div>
        <div className="row">
          {hasSearches && (
            <div className="col-sm-4">
              <SummaryBox
                id={state.id}
                type="searches"
                hitsData={searchesHitsData}
                progressValue={searchesProgressValue}
                eta={searchesETA}
                role={state.data.role}
              />
              {deviceState.isMobileOrTablet && <Spacing direction="top" value={17} />}
            </div>
          )}
          {hasOfac && (
            <div className="col-sm-4">
              <SummaryBox
                id={state.id}
                type="ofac"
                hitsData={ofacHitsData}
                progressValue={ofacProgressValue}
                eta={ofacETA}
                role={state.data.role}
              />
              {deviceState.isMobileOrTablet && <Spacing direction="top" value={17} />}
            </div>
          )}
          {hasOrgDocs && (
            <div className="col-sm-4">
              <SummaryBox
                id={state.id}
                type="org-docs"
                hitsData={orgDocsHitsData}
                progressValue={orgDocsProgressValue}
                eta={orgDocsETA}
                role={state.data.role}
              />
              {deviceState.isMobileOrTablet && <Spacing direction="top" value={17} />}
            </div>
          )}
        </div>

        {
          (state?.data?.dealbinderMessage && state?.data?.searchNotes && state?.data?.ofacNotes && state?.data?.orgNotes) ? (
            <div className="mt-4">
              <Accordion style={{ borderRadius: '8px' }} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <BasicText size="md" weight="semiBold"><IconAlert className="mr-3" /> MESSAGE ABOUT THIS ORDER</BasicText>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="w-100">
                    {
                      state?.data?.dealbinderMessage && (
                        <>
                          <NewlineText text={state?.data?.dealbinderMessage} />
                          <br />
                        </>
                      )
                    }
                    {
                      state?.data?.searchNotes && (
                        <>
                          <BasicText size="regular"><u>Searches</u></BasicText>
                          <NewlineText text={state?.data?.searchNotes} />
                          <br />
                        </>
                      )
                    }
                    {
                      state?.data?.ofacNotes && (
                        <>
                          <BasicText size="regular"><u>OFAC</u></BasicText>
                          <NewlineText text={state?.data?.ofacNotes} />
                          <br />
                        </>
                      )
                    }
                    {
                      state?.data?.orgNotes && (
                        <>
                          <BasicText size="regular"><u>Organizational Documents</u></BasicText>
                          <NewlineText text={state?.data?.orgNotes} />
                          <br />
                        </>
                      )
                    }
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : null
        }
      </Grid>
    </>
  );
};

export default Overview;
