import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from 'react-sweet-state';

export type Product = {
  family: string;
  gridName: string;
  id: string;
  name: string;
  pdfName: string;
  sortOrder?: number;
};

export type ProductState = {
  products: Product[];
};

type StoreApi = StoreActionApi<ProductState>;
type Actions = typeof actions;

const initialState: ProductState = {
  products: []
};

export const SEARCHES = 'Searches';
export const ORGANIZATIONAL_DOCUMENTS = 'Organizational Documents';
export const CHARTER_DOCUMENTS = 'Charter Documents';
export const GOOD_STANDING = 'Good Standing';

export const getSearches = (products: Product[]) => products.filter(product => product.family === SEARCHES);
export const getOrgDocs = (products: Product[]) => products.filter(product => product.family === ORGANIZATIONAL_DOCUMENTS); 
export const getCharterDocs = (state: ProductState) => state.products.find(product => product.family === ORGANIZATIONAL_DOCUMENTS && product.name === CHARTER_DOCUMENTS);
export const getGoodStanding = (state: ProductState) => state.products.find(product => product.family === ORGANIZATIONAL_DOCUMENTS && product.name === GOOD_STANDING);


export interface ProductFetchResponse {
  data: Product[];
}

export const actions = {
  handleResponse: (response: ProductFetchResponse) => ({
    setState,
    getState
  }: StoreApi) => {
    const state = getState();
    setState({
      ...state,
      products: response.data.sort((p1, p2) => p1.sortOrder - p2.sortOrder)
  });
  }
};

const Store = createStore<ProductState, Actions>({
  initialState,
  actions,
  name: 'products'
});

export const useProductState = createHook(Store);
export const ProductStateSubscriber = createSubscriber(Store);
