import React, { FC, useContext, useCallback } from 'react';
import head from 'lodash/head';
import defaultTo from 'lodash/defaultTo';
import { useHistory } from 'react-router-dom';
import {useMediaQuery} from "@material-ui/core";
import { InvitedUser, Owner } from 'api/matter';
import BasicText from 'styleguide/components/BasicText';
import Spacing from 'styleguide/components/Spacing';
import { isMagicLink } from 'helpers/helpers';
import { AuthContext } from 'components/app/Auth';
import { MATTERS_URL } from 'helpers/routes';

import UserAccessRow from './UserAccessRow';
import { ContentWrapper, HorizontalLine, Link, AccessRow } from './styled';

interface ManageAccessProps {
  invites: InvitedUser[];
  updateInvite: (roleId: string, role: string) => Promise<void>;
  transferMatter: (email: string) => Promise<void>;
  deleteInvite: (roleId: string) => Promise<void>;
  owner: Owner;
  openPermissions: () => void;
  isOwner: boolean;
}

const ManageAccess: FC<ManageAccessProps> = ({
  invites,
  updateInvite,
  deleteInvite,
  owner,
  openPermissions,
  transferMatter,
  isOwner
}) => {
  const history = useHistory();
  const currentUser = useContext(AuthContext);
  const isMobile = useMediaQuery('(max-width:768px)');
  const isAdmin = currentUser?.claims?.admin;

  const handleRoleChange = useCallback(
    (inviteId, email, role) => {
      if (role === 'owner') {
        transferMatter(email).then(() => {
          if (isOwner) {
            history.push(MATTERS_URL);
          }
        });
      } else {
        updateInvite(inviteId, role);
      }
    },
    [transferMatter, updateInvite, isOwner, history]
  );

  return (
    <ContentWrapper>
      <AccessRow>
        <BasicText size="regular">
          The people below can view this matter with the following roles.
        </BasicText>
        {!isMobile && <Spacing direction="left" value="auto" />}
        <Link onClick={openPermissions} size="regular">
          Learn more
        </Link>
      </AccessRow>
      <Spacing direction="top" value={24} />
      <HorizontalLine width="100%" />
      <UserAccessRow
        name={`${owner.firstName} ${owner.lastName}`}
        initials={`${head(owner.firstName)}${head(owner.lastName)}`}
        description={owner.email}
        role="owner"
        index={0}
        isEditable={false}
        isMagicLink={false}
      />
      {invites.map((invite, index) => {
        const email = defaultTo(
          isMagicLink(invite.contact)
            ? invite.shareContact?.firstName
            : invite.contact,
          ''
        );
        return (
          <UserAccessRow
            key={invite.id}
            name={email}
            initials={email.replace(/\W/g, '').slice(0, 2)}
            role={invite.role}
            index={index + 1}
            onRoleChange={role => handleRoleChange(invite.id, email, role)}
            onRoleDelete={() => deleteInvite(invite.id)}
            isEditable
            isMagicLink={isMagicLink(invite.contact)}
            isAdmin={isAdmin}
          />
        );
      })}
      <Spacing direction="top" value={260} />
    </ContentWrapper>
  );
};

export default ManageAccess;
