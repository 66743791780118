import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { ReCaptcha } from 'react-recaptcha-v3';
import { Link } from 'react-router-dom';

import { LOGIN_URL } from 'helpers/routes';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Spacing from 'styleguide/components/Spacing';
import Error from 'styleguide/components/Error';
import Loader from 'styleguide/components/Loader';
import PasswordStrength from 'styleguide/components/PasswordStrength';

import { getRecaptchaKey } from '../contants';
import { RegistrationFirstStepRenderProps } from './RegistrationFirstStep';
import {
  FormLabel,
  TextInput,
  PasswordInput,
  Button,
  SignUpText
} from './styled';
import { useDeviceState } from 'state/deviceState';

const RegistrationFirstStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledColumn = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

const RegistrationFirstStepPresentational: FC<RegistrationFirstStepRenderProps> = ({
  validationSchema,
  onSubmit,
  initialValues,
  verifyCallback,
  getVerifyIsInvalid,
  getVerifyFeedback,
  isLoading
}) => {
  const { formatMessage } = useIntl();
  const [state] = useDeviceState();

  return (
    <RegistrationFirstStepWrapper>
      <div className="clearfix">
        <div className="float-left">
          {state.isDesktop ? (
            <BasicHeading tag="h2" size="xxxl">
              <FormattedMessage id="shared.signUp" />
            </BasicHeading>
          ) : (
            <BasicHeading tag="h1" size="xl">
              <FormattedMessage id="shared.signUp" />
            </BasicHeading>
          )}
        </div>
        <div className="float-right">
          <BasicSubHeading size="xxs" className="mt-2" isUppercase>
            <FormattedMessage
              id="registration.step"
              values={{ currentStep: 1 }}
            />
          </BasicSubHeading>
        </div>
      </div>
      <Spacing direction="top" value={state.isDesktop ? 40 : 16} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <StyledRow className="row">
              <StyledColumn className="col-sm">
                <FormLabel as="label">
                  <FormattedMessage id="registration.firstName" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="firstName"
                    name="firstName"
                    value={values.firstName}
                    placeholder={formatMessage({
                      id: 'registration.firstNamePlaceholder'
                    })}
                    invalid={Boolean(touched.firstName && errors.firstName)}
                    autoFocus
                  />
                </FormLabel>
                {touched.firstName && errors.firstName && (
                  <Error>{errors.firstName}</Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn>

              <StyledColumn className="col-sm">
                <FormLabel as="label">
                  <FormattedMessage id="registration.lastName" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="lastName"
                    name="lastName"
                    value={values.lastName}
                    placeholder={formatMessage({
                      id: 'registration.lastNamePlaceholder'
                    })}
                    invalid={Boolean(touched.lastName && errors.lastName)}
                  />
                </FormLabel>
                {touched.lastName && errors.lastName && (
                  <Error>{errors.lastName}</Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>

            <StyledRow>
              <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.email" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="email"
                    name="email"
                    value={values.email}
                    placeholder={formatMessage({
                      id: 'registration.emailPlaceholder'
                    })}
                    invalid={Boolean(
                      touched.email && (errors.email || getVerifyIsInvalid())
                    )}
                  />
                </FormLabel>
                {touched.email && (errors.email || getVerifyIsInvalid()) && (
                  <Error>
                    <div>{errors.email || getVerifyFeedback()}</div>
                  </Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>

            <StyledRow>
              <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.password" />
                  <PasswordInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="password"
                    name="password"
                    value={values.password}
                    placeholder={formatMessage({
                      id: 'registration.passwordPlaceholder'
                    })}
                    invalid={Boolean(touched.password && errors.password)}
                  />
                </FormLabel>
                {touched.password && errors.password && (
                  <>
                    <Error>{errors.password}</Error>
                    <Spacing direction="top" value={8} />
                  </>
                )}
                <PasswordStrength password={values.password} />
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>

            <ReCaptcha
              sitekey={getRecaptchaKey()}
              verifyCallback={verifyCallback}
              action=""
            />

            <Button width="100%" primary disabled={isLoading}>
              {(isLoading && (
                <>
                  <Loader marginLess size={2} background="#dddce7" />
                  <Spacing direction="right" value={16} />
                </>
              )) || <FormattedMessage id="shared.next" />}
            </Button>

            {/* {isLoading && (
              <>
                <Spacing direction="top" value={16} />
                <Loader marginLess centered />
              </>
            )} */}

            <Spacing direction="top" value={24} />

            <SignUpText className="text-lg-left text-center">
              <FormattedMessage id="registration.signInText" />{' '}
              <Link to={LOGIN_URL}>
                <FormattedMessage id="shared.signIn" />
              </Link>
            </SignUpText>
          </Form>
        )}
      </Formik>
    </RegistrationFirstStepWrapper>
  );
};

export default RegistrationFirstStepPresentational;
