import { createStore, createHook, StoreActionApi } from 'react-sweet-state';
import { compareAsc } from 'date-fns';

import {
  fetchMatterData,
  MatterData,
  MatterFetchError,
  fetchFilesData,
  FilesList,
  downloadFiles,
  downloadReport,
  DownloadedFileData,
  editMatter,
  downloadOrderLineItemFile,
  ReviewHistory,
  HitOrderLineItem,
  fetchHitData,
  Priority,
  fetchCoworkersEmail,
  inviteToMatter,
  fetchInvitesList,
  InvitedUser,
  updateInvite,
  deleteInvite,
  sendMagicLink,
  CoworkerInfo,
  assign,
  getAssign,
  unassign,
  transferMatter
} from 'api/matter';
import { MatterView, matterView } from './matterView';
import { fetchNotes } from 'api/notes';

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

interface TableState {
  search?: string;
  filters: {
    [key: string]: string[] | string;
  };
  expanded?: boolean;
}

interface EmailModalState {
  open: boolean;
  matterId: string;
  orderId: string;
  orderLineItemId: string;
}

export type Tables = 'searches' | 'ofac' | 'documents' | 'hitDetails';

interface State {
  id?: string;
  data?: MatterData;
  view?: MatterView;
  isLoading?: boolean;
  isToggleLoading?: boolean;
  error?: number;
  filesList?: FilesList;
  tables: {
    searches: TableState;
    ofac: TableState;
    documents: TableState;
    hitDetails: TableState;
  };
  hits?: HitOrderLineItem[];
  coworkersEmail: CoworkerInfo[];
  invites: InvitedUser[];
  assignedTo?: string;
  emailModal?: EmailModalState;
  getReports?: boolean;
  getDownloads?: boolean;
  shareModal?: string;
  viewAccessModal?: boolean;
  tableFilterSidebar?: {
    searches: boolean,
    ofac: boolean,
    documents: boolean,
    hitDetails: boolean
  }
}

const initialState: State = {
  data: null,
  isLoading: true,
  isToggleLoading: false,
  error: null,
  filesList: null,
  tables: {
    searches: { search: '', filters: {}, expanded: false },
    ofac: { search: '', filters: {} },
    documents: { search: '', filters: {}, expanded: true },
    hitDetails: { search: '', filters: {}, expanded: false }
  },
  coworkersEmail: [],
  invites: [],
  assignedTo: null,
  getReports:false,
  getDownloads:false,
  tableFilterSidebar: {
    searches: false,
    ofac: false,
    documents: false,
    hitDetails: false
  }
};
interface CreatedAtComparable {
  createdAt: string | Date;
}

const createdByComparator = (direction = 1) => (
  el1: CreatedAtComparable,
  el2: CreatedAtComparable
) => {
  if (el1.createdAt < el2.createdAt) {
    return direction * 1;
  }
  if (el1.createdAt > el2.createdAt) {
    return direction * -1;
  }
  return 0;
};

const createdByDescComparator = createdByComparator(1);

const fetchInvitesListAction = async ({
  setState,
  getState
}: StoreApi): Promise<void> => {
  const invites = await fetchInvitesList(getState().id);
  const sortedInvites = invites.sort((a, b) =>
    compareAsc(new Date(a.createdAt), new Date(b.createdAt))
  );

  setState({ invites: sortedInvites });
};

const fetchAssign = async ({ setState, getState }: StoreApi): Promise<void> => {
  try {
    const assignedTo = await getAssign(getState().id);

    setState({ assignedTo });
  } catch (e:any) {
    if (e?.response?.data?.statusCode === 404) {
      setState({ assignedTo: null });
    }
  }
};

const actions = {
  fetchData: (id: string) => async ({
    setState,
    getState
  }: StoreApi): Promise<void> => {
    setState({
      ...initialState,
      id,
      isLoading: true,
      data: null,
      error: null,
      view: null,
      hits: null
    });
    const response = await fetchMatterData(id);
    if (response.type === 'error') {
      setState({
        isLoading: false,
        error: (response.payload as MatterFetchError).status
      });
    } else {
      setState({
        ...getState(),
        isLoading: false,
        data: response.payload as MatterData,
        view: matterView(response.payload as MatterData)
      });
    }
  },
  editMatterName: (name: string) => async ({
    setState,
    getState
  }: StoreApi): Promise<void> => {
    const state = getState();
    const { id } = state;
    const prevName = state.data?.name;

    setState({
      ...getState,
      data: {
        ...getState().data,
        name
      }
    });

    try {
      await editMatter(id, { name });
    } catch (e) {
      setState({
        ...getState,
        data: {
          ...getState().data,
          name: prevName
        }
      });
    }
  },
  setFilter: (table: Tables, field: string, filter: string[]) => ({
    setState,
    getState
  }: StoreApi) => {
    const { tables } = getState();
    setState({
      tables: {
        ...tables,
        [table]: {
          ...tables[table],
          expanded: true,
          filters: { ...tables[table].filters, [field]: filter }
        }
      }
    });
  },
  clearFilter: (table: Tables) => ({ setState, getState }: StoreApi) => {
    const { tables } = getState();
    setState({
      tables: { ...tables, [table]: { ...tables[table], filters: {} } }
    });
  },
  setSearch: (table: Tables, search: string) => ({
    setState,
    getState
  }: StoreApi) => {
    const { tables } = getState();

    setState({
      tables: {
        ...tables,
        [table]: {
          ...tables[table],
          search,
          // clear filters and expand rows on search:
          ...(search.length ? { filters: {}, expanded: true } : {})
        }
      }
    });
  },
  toggleExpanded: (table: Tables) => ({ setState, getState }: StoreApi) => {
    const { tables } = getState();

    setState({ isToggleLoading: true });
    setTimeout(() => {
      setState({
        isToggleLoading: false,
        tables: {
          ...tables,
          [table]: {
            ...tables[table],
            expanded: !tables[table].expanded
          }
        }
      });
    });
  },
  fetchFilesList: () => async ({
    getState,
    setState
  }: StoreApi): Promise<void> => {
    const filesList = await fetchFilesData(getState().id);
    setState({ ...getState(), filesList });
  },
  downloadFiles: (filesIds: string[]) => async ({
    getState
  }: StoreApi): Promise<DownloadedFileData> =>
    await downloadFiles(getState().id, filesIds),
  downloadReport: (docType:string,includedFiles?: string[]) => async ({
    getState
  }: StoreApi): Promise<DownloadedFileData> =>
    await downloadReport(getState().id,docType,includedFiles),
  downloadOpenPDFFile: (orderLineItemId: string) => async ({
    getState
  }: StoreApi): Promise<BlobPart> => {
    const state = getState();
    const matterId = state.id;
    const orderId = state.data.orders.find(
      order =>
        order.orderLineItems.find(item => item.id)?.id === orderLineItemId
    )?.id;

    if (!orderLineItemId) {
      return Promise.reject();
    }

    return downloadOrderLineItemFile(matterId, orderId, orderLineItemId);
  },
  changeClosingDate: (closingDate: string) => async ({
    getState,
    setState
  }: StoreApi): Promise<void> => {
    const state = getState();
    const { id } = state;
    const prevClosingDate = state.data?.closingDate;

    setState({
      ...getState,
      data: {
        ...getState().data,
        closingDate
      }
    });

    try {
      await editMatter(id, { closingDate });
    } catch (e) {
      setState({
        ...getState,
        data: {
          ...getState().data,
          closingDate: prevClosingDate
        }
      });
    }
  },
  updateReviews: (
    orderId: string,
    orderLineItemId: string,
    reviewHistory: ReviewHistory[]
  ) => ({ getState, setState }: StoreApi) => {
    const { data, hits } = getState();
    const orderIndex = data.orders.findIndex(o => o.id === orderId);
    const orderLineItemIndex = data.orders[orderIndex].orderLineItems.findIndex(
      oli => oli.id === orderLineItemId
    );

    const hitIndex = (hits || []).findIndex(oli => oli.id === orderLineItemId);

    const newData = {
      ...data,
      orders: [
        ...data.orders.slice(0, orderIndex),
        {
          ...data.orders[orderIndex],
          orderLineItems: [
            ...data.orders[orderIndex].orderLineItems.slice(
              0,
              orderLineItemIndex
            ),
            {
              ...data.orders[orderIndex].orderLineItems[orderLineItemIndex],
              reviewHistory
            },
            ...data.orders[orderIndex].orderLineItems.slice(
              orderLineItemIndex + 1
            )
          ]
        },
        ...data.orders.slice(orderIndex + 1)
      ]
    };

    setState({
      data: newData,
      view: matterView(newData),
      ...(hits && hitIndex >= 0
        ? {
          hits: [
            ...hits.slice(0, hitIndex),
            {
              ...hits[hitIndex],
              reviewHistory
            },
            ...hits.slice(hitIndex + 1)
          ]
        }
        : {})
    });
  },
  fetchHitData: () => async ({ getState, setState }: StoreApi) => {
    const {
      id,
      view: {
        hitDetails: { orderLineItems }
      }
    } = getState();
    const hits = await fetchHitData(id);
    // get product and jurisdiction from matter order line items
    const decoratedHits = hits.map(hit => {
      const { product, jurisdiction, reviewHistory } =
        orderLineItems.find(oli => oli.id === hit.id) || {};
      return {
        ...hit,
        product,
        jurisdiction,
        ...(hit.notes
          ? { notes: hit.notes.sort(createdByDescComparator) }
          : {}),
        ...(hit.reviewHistory
          ? {
            reviewHistory: hit.reviewHistory
              .sort(createdByDescComparator)
              .map(review => {
                const review2 = (reviewHistory || []).find(
                  r => r.id === review.id
                );
                return {
                  ...review,
                  ...(review2 ? { reviewer: review2.reviewer } : {})
                };
              })
          }
          : {}),
        ...(hit.priorities
          ? {
            priorities: (hit.priorities || []).sort(createdByDescComparator)
          }
          : {})
      };
    });

    setState({ hits: decoratedHits });
  },
  fetchNotes: (orderId: string, orderLineItemId: string) => async ({
    getState,
    setState
  }: StoreApi) => {
    const { id, hits } = getState();
    const notesData = await fetchNotes(id, orderId, orderLineItemId);
    const notes = notesData.sort(createdByDescComparator);

    const orderLineItemIndex = (hits || []).findIndex(
      oli => oli.id === orderLineItemId
    );

    if (orderLineItemIndex === -1) {
      // if no hits defined:
      // setState({
      //   hits: [
      //     {
      //       id: orderLineItemId,
      //       hitDetails: null,
      //       priorities: null,
      //       status: null,
      //       diligenceName: null,
      //       jurisdiction: null,
      //       product: null,
      //       notes
      //     }
      //   ]
      // });
    } else {
      setState({
        hits: [
          ...hits.slice(0, orderLineItemIndex),
          {
            ...hits[orderLineItemIndex],
            notes
          },
          ...hits.slice(orderLineItemIndex + 1)
        ]
      });
    }
  },
  updatePriority: (orderLineItemId: string, priority: Priority) => ({
    getState,
    setState
  }: StoreApi) => {
    const { hits } = getState();
    const orderLineItemIndex = hits.findIndex(
      oli => oli.id === orderLineItemId
    );
    setState({
      hits: [
        ...hits.slice(0, orderLineItemIndex),
        {
          ...hits[orderLineItemIndex],
          priorities: [priority, ...hits[orderLineItemIndex].priorities]
        },
        ...hits.slice(orderLineItemIndex + 1)
      ]
    });
  },
  fetchCoworkersEmail: () => async ({
    getState,
    setState
  }: StoreApi): Promise<void> => {
    const coworkersEmail = await fetchCoworkersEmail(getState().id);
    setState({ coworkersEmail });
  },
  inviteToMatter: (
    email: string,
    role: string,
    doNotify: boolean,
    message?: string
  ) => async ({ getState, dispatch }: StoreApi): Promise<void> => {
    await inviteToMatter(getState().id, email, role, doNotify, message);
    dispatch(fetchInvitesListAction);
  },
  fetchInvitesList: () => async ({ dispatch }: StoreApi): Promise<void> => {
    dispatch(fetchInvitesListAction);
  },
  updateInvite: (roleId: string, role: string) => async ({
    getState,
    dispatch
  }: StoreApi): Promise<void> => {
    await updateInvite(getState().id, roleId, role);

    dispatch(fetchInvitesListAction);
  },
  transferMatter: (email: string) => async ({
    getState,
    dispatch
  }: StoreApi): Promise<void> => {
    await transferMatter(getState().id, email);

    dispatch(fetchInvitesListAction);
  },
  deleteInvite: (roleId: string) => async ({
    getState,
    dispatch
  }: StoreApi): Promise<void> => {
    await deleteInvite(getState().id, roleId);
    dispatch(fetchInvitesListAction);
  },
  sendMagicLink: (email: string) => async ({
    getState,
    dispatch
  }: StoreApi): Promise<void> => {
    await sendMagicLink(getState().id, email);
    dispatch(fetchInvitesListAction);
  },
  getAssign: () => ({ dispatch }: StoreApi): void => {
    dispatch(fetchAssign);
  },
  assign: (assignedTo: string, email: string) => async ({
    getState,
    dispatch
  }: StoreApi): Promise<void> => {
    await assign(getState().id, assignedTo, email);
    dispatch(fetchAssign);
  },
  unassign: () => async ({ getState, dispatch }: StoreApi): Promise<void> => {
    await unassign(getState().id);
    dispatch(fetchAssign);
  },
  openEmailModal: (orderLineItemId: string) => ({ getState, setState }: StoreApi) => {
    const state = getState();
    const matterId = state.id;
    const orderId = selectors.findOrderForLineItem(state)(orderLineItemId).id;
    setState({ emailModal: { open: true, matterId, orderId, orderLineItemId } });
  },
  closeEmailModal: () => ({ setState }: StoreApi) => {
    setState({ emailModal: null });
  },
  toggleGetReports: (val:boolean) => ({ setState }: StoreApi) => {
    setState({ getReports: val });
  },
  toggleGetDownloads: (val:boolean) => ({ setState }: StoreApi) => {
    setState({ getDownloads: val });
  },
  toggleShareModal: (val:string) => ({ setState }: StoreApi) => {
    setState({ shareModal: val });
  },
  toggleViewAccessModal: (val:boolean) => ({ setState }: StoreApi) => {
    setState({ viewAccessModal: val });
  },
  toggleTableFilterSidebar: (table: Tables) => ({ getState, setState }: StoreApi) => {
    let { tableFilterSidebar } = getState();

    setState({
      tableFilterSidebar: {
        ...tableFilterSidebar,
        [table]: !tableFilterSidebar[table]
      }
    })
  }
};

export const selectors = {
  findOrderForLineItem: (state: State) => (oliId: string) =>
    state.data.orders.find(o => o.orderLineItems.find(oli => oli.id === oliId))
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'matter'
});

export const useMatterState = createHook(Store);
