import React, { FC, useEffect, useState, createContext } from 'react';

import { auth } from 'firebaseConfig';
import LoadingLayout from 'layout/loadingLayout/LoadingLayout';

export const AuthContext = createContext(null);

declare global {
  interface Window {
    _hsq: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
  }
}

export const AuthProvider: FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  interface Identity {
    email: string;
    firstName: string;
    lastName?: string;
  }

  useEffect(() => {
    if (currentUser) {
      const { email, displayName } = currentUser;
      const fullName = displayName.split(' ');

      const identity: Identity = {
        email,
        firstName: fullName[0]
      };
      if (fullName.length > 1) identity.lastName = fullName[1];

      const onConversationsAPIReady = () => {
        var _hsq = (window._hsq = window._hsq || []);
        // console.log('ready');
        _hsq.push(['identify', identity]);
        _hsq.push(['trackPageView']);
        setTimeout(() => {
          // console.log('refresh');
          window.HubSpotConversations.widget.refresh();
        }, 1000);
      };

      const sendIdentify = () => {
        if (window.HubSpotConversations) {
          onConversationsAPIReady();
        } else {
          window.hsConversationsOnReady = [onConversationsAPIReady];
        }
      };

      sendIdentify();
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribeAuthStateChanged = auth.onAuthStateChanged(
      async (user: any) => {
        if (!currentUser && user) {
          setPending(true);
        }

        if (user) {
          const { claims } = await user.getIdTokenResult();
          setCurrentUser({ ...user, claims });
        } else {
          setCurrentUser(null);
        }
        if (pending) {
          setPending(false);
        }
      }
    );

    const unsubscribeIdTokenChanged = auth.onIdTokenChanged(async () => {
      const user = await auth.currentUser;

      if (user) {
        const { claims } = await user.getIdTokenResult();
        setCurrentUser({ ...user, claims });
      } else {
        setCurrentUser(null);
      }
    });

    return () => {
      unsubscribeAuthStateChanged();
      unsubscribeIdTokenChanged();
    };
  }, []); //eslint-disable-line

  if (pending) {
    return <LoadingLayout />;
  }

  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
};
