import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const getReviewsUrl = (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): string =>
  `${url}/matters/${matterId}/orders/${orderId}/order-line-items/${orderLineItemId}/reviews`;

export interface ReviewData {
    review_id?: string;
    review_reviewStatus?: string;
    review_createdAt?: string;
    orderLineItem_id?: string;
    orderLineItem_diligenceName?: string;
    contact_id?: number;
    contact_email?: string;
    contact_firstName?: string;
    contact_lastName?: string;
}

export const fetchReviews = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): Promise<ReviewData[]> => {
  const client = await getAuthorizedApi();
  try {
    const response = await client.get<ReviewData[]>(
      getReviewsUrl(matterId, orderId, orderLineItemId)
    );
    return response.data;
  } catch (err) {
    return [];
  }
};

export const createReview = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  reviewStatus: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getReviewsUrl(matterId, orderId, orderLineItemId), {
    reviewStatus
  });
};
