import React, { FC, ReactNode } from 'react';

import Spacing from 'styleguide/components/Spacing';
import { useDeviceState } from 'state/deviceState';

import {
  SummaryWrapper,
  SummaryIconWrapper,
  SummarySubHeading,
  SummaryHeading,
  SummaryCount,
  SummaryRow
} from './styled';

interface SummaryProps {
  icon: ReactNode;
  subheading: string;
  heading: string;
  count: number;
  isLight?: boolean;
}

const Summary: FC<SummaryProps> = ({
  icon,
  subheading,
  heading,
  count,
  isLight = false
}) => {
  const [deviceState] = useDeviceState();


  return (
    <SummaryWrapper isLight={isLight}>
      <div  className={deviceState.isMobileOrTablet?"row":""}>
        <SummaryIconWrapper isLight={isLight}>{icon}</SummaryIconWrapper>
        <Spacing direction="top" value={17} />
        <SummaryRow className={deviceState.isMobileOrTablet?"col":""}>
          <div>
            <SummarySubHeading size="xxs" isUppercase>
              {subheading}
            </SummarySubHeading>
            <SummaryHeading tag="h3" size="xxl">
              {heading}
            </SummaryHeading>
          </div>
          <SummaryCount tag="div" size="xxxxxl">
            {count}
          </SummaryCount>
        </SummaryRow>
      </div>
    </SummaryWrapper>
  );
}

export default Summary;
