import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';

import { theme } from 'theme/theme';
import { LOGIN_URL } from 'helpers/routes';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import Error from 'styleguide/components/Error';
import Button from 'styleguide/components/Button';
import Loader from 'styleguide/components/Loader';

import { ForgotPasswordFirstStepProps } from './ForgotPassword';
import { StyledBaseLink, TextInput, LoaderWrapper } from './styled';

const ForgotPasswordFirstStep: FC<ForgotPasswordFirstStepProps> = ({
  onSubmit,
  validationSchema,
  initialValues,
  emailPlaceholder,
  isLoading
}) => (
  <div>
    <Spacing direction="top" value={95} />
    <BasicHeading tag="h1" size="xxxl" isCentered>
      <FormattedMessage id="forgotPassword.title" />
    </BasicHeading>
    <Spacing direction="top" value={54} />

    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <TextInput
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            value={values.email}
            placeholder={emailPlaceholder}
            invalid={Boolean(touched.email && errors.email)}
            autoFocus
          />
          {touched.email && errors.email && <Error>{errors.email}</Error>}

          <Spacing direction="top" value={36} />

          <Button primary width="100%">
            <FormattedMessage id="shared.resetPassword" />
          </Button>

          {isLoading && (
            <LoaderWrapper>
              <Spacing direction="top" value={16} />
              <Loader background={theme.colorsNewDesign.lightBlue} />
            </LoaderWrapper>
          )}

          <Spacing direction="top" value={24} />

          <StyledBaseLink to={LOGIN_URL}>
            <FormattedMessage id="shared.signIn" />
          </StyledBaseLink>
        </Form>
      )}
    </Formik>
  </div>
);

export default ForgotPasswordFirstStep;
