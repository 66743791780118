import { createStore, createHook, StoreActionApi } from 'react-sweet-state';
import { OptionType } from 'styleguide/components/Select';
import { uniqBy } from 'lodash';

import { fetchEstimates, deleteEstimate, Estimate } from 'api/estimates';

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
export type Tables = 'myEstimates';

export interface Filters {
  matterNames?: string[];
  dates?: string[];
}

interface State {
  filters?: Filters;
  matterFilterOptions: OptionType[];
  dateFilterOptions: OptionType[];
  isLoading: boolean;
  totalCount: number;
  modalShow: boolean;
  currentPage: number;
  estimates: Estimate[];
  tableFilterSidebar?: {
    myEstimates: boolean,
  }
  
}

const initialState: State = {
  filters: {},
  matterFilterOptions: [],
  dateFilterOptions: [],
  isLoading: false,
  modalShow: false,
  totalCount: null,
  currentPage: 1,
  estimates: [],
  tableFilterSidebar: {
    myEstimates: false,
  }
};

const fetchEstimatesAction = async ({
  setState,
  getState
}: StoreApi): Promise<void> => {
  setState({ ...getState(), isLoading: true });
  const { currentPage: page, filters } = getState();

  const { data, totalCount } = await fetchEstimates({ page, ...filters });

  setState({
    ...getState(),
    estimates: data,
    isLoading: false,
    modalShow:false,
    totalCount: totalCount
  });
};
const setDeleteModal = async ({
  setState,
  getState
}: StoreApi): Promise<void> => {
  setState({ ...getState(), modalShow:!getState().modalShow });
};

const actions = {
  fetchData: () => async ({ dispatch }: StoreApi): Promise<void> => {
    dispatch(fetchEstimatesAction);
  },
  triggerDeleteModal : () => async ({ dispatch }: StoreApi) => {
    dispatch(setDeleteModal);
  },
  changePage: (page: number) => async ({
    getState,
    setState,
    dispatch
  }: StoreApi): Promise<void> => {
    setState({ ...getState(), currentPage: page });

    dispatch(fetchEstimatesAction);
  },
  fetchMatterFilterOptions: () => async ({ getState, setState }: StoreApi) => {
    const { data } = await fetchEstimates({ pageSize: 999 });
    const matterFilterOptions = uniqBy(
      data.map(({ matterName }) => ({
        value: matterName,
        label: matterName
      })),
      'label'
    );
    const dateFilterOptions = uniqBy(
      data.map(({ date }) => {
        const dateFormatted = new Date(date).toLocaleDateString([], {
          dateStyle: 'short'
        } as any);
        return { value: date, label: dateFormatted };
      }),
      'label'
    );

    setState({
      ...getState(),
      matterFilterOptions,
      dateFilterOptions
    });
  },
  updateFilters: (filters: Filters) => ({
    getState,
    setState,
    dispatch
  }: StoreApi) => {
    setState({ ...getState(), filters, currentPage: 1 });
    dispatch(fetchEstimatesAction);
  },
  resetFilters: () => ({ getState, setState }: StoreApi) => {
    setState({ ...getState(), filters: {}, currentPage: 1 });
  },

  deleteEstimate: (id: string, matterId: string) => async ({
    dispatch
  }: StoreApi) => {
    await deleteEstimate({ id, matterId });
    dispatch(fetchEstimatesAction);
  },
  toggleTableFilterSidebar: (table: Tables) => ({ getState, setState }: StoreApi) => {
    let { tableFilterSidebar } = getState();

    setState({
      tableFilterSidebar: {
        ...tableFilterSidebar,
        [table]: !tableFilterSidebar[table]
      }
    })
  }
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'dashboardMyEstimates'
});

export const useMyEstimatesState = createHook(Store);
