import React, { FC } from 'react';
import { FieldArray } from 'formik';
import ProductSelect from './ProductSelect';
import { Product } from '../state/productsState';

interface ProductSelectCellFormProps {
  name: string;
  value: string[];
  productFamily: string;
  products: Product[];
  col?: number;
}

const ProductSelectCellForm: FC<ProductSelectCellFormProps> = ({
  name,
  value,
  productFamily,
  col,
  products
}) => {
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <ProductSelect
          disabled={[]}
          name={name}
          products={products}
          selected={value}
          select={(id: string) => {
            arrayHelpers.push(id);
          }}
          deselect={(id: string) => {
            arrayHelpers.remove(value.indexOf(id));
          }}
          selectAll={() => {
            value.forEach(() => {
              arrayHelpers.remove(0);
            });
            products.forEach(({id}, i: number) =>
              arrayHelpers.insert(i, id)
            );
          }}
          deselectAll={() => {
            value.forEach(() => {
              arrayHelpers.remove(0);
            });
          }}
          allSelected={value.length === products.length}
          selectAllLabel={
            productFamily === 'Searches' ? products.length <= 1 ? "" : "Select all (Comprehensive)" : null
          }
        />
      )}
    />
  );
};

export default ProductSelectCellForm;
