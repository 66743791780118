import React, { FC } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import {
  REGISTRATION_URL,
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  USER_MANAGEMENT_URL,
  DASHBOARD_URL,
  CHECK_YOUR_EMAIL_URL,
  ORDER_URL,
  LOGOUT_URL,
  ORDER_URL_MOBILE
} from 'helpers/routes';

import Dashboard from 'pages/dashboard';
import Registration from 'pages/registration';
import CheckYourEmail from 'pages/checkYourEmail/CheckYourEmail';
import Login from 'pages/login';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import UserManagement from 'pages/userManagement/UserManagement';
import Order from 'pages/order';
import Estimation from 'pages/order/estimation';
import AdminOrder from 'pages/order/admin';
import NotFound from 'pages/notFound';
import AdminRoute from './AdminRoute';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Logout from 'pages/logout';
import NotFoundOrder from 'pages/notFoundOrder';

const Routes: FC = () => (
  <Switch>
    <PrivateRoute path={DASHBOARD_URL} component={Dashboard} />
    <AdminRoute
      path="/order/admin/contact/:contactid/matter/:matterid/order/:orderid"
      component={AdminOrder}
    />
    <AdminRoute path="/order/admin/contact/:contactid" component={AdminOrder} />
    <PrivateRoute
      path="/order/estimation/matter/:matterid/order/:id"
      component={Estimation}
    />
    <PrivateRoute path={ORDER_URL} component={Order} />
    <PrivateRoute
      exact
      path="/"
      component={() => <Redirect to={DASHBOARD_URL} />}
    />
    <PublicRoute exact path={REGISTRATION_URL} component={Registration} />
    <Route exact path={CHECK_YOUR_EMAIL_URL} component={CheckYourEmail} />
    <PublicRoute exact path={LOGIN_URL} component={Login} />
    <Route exact path={FORGOT_PASSWORD_URL} component={ForgotPassword} />
    <Route exact path={USER_MANAGEMENT_URL} component={UserManagement} />
    <Route exact path={ORDER_URL_MOBILE} component={NotFoundOrder} />
    <Route exact path={LOGOUT_URL} component={Logout} />
    <Route component={NotFound} />
  </Switch>
);

export default Routes;
