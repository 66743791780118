import styled from 'styled-components';

import BasicHeading from 'styleguide/components/BasicHeading';
import { ReactComponent as EditIcon } from 'components/icons/IconEdit.svg';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  position: relative;
`;

export const Edit = styled(EditIcon)`
  margin-left: 8px;
  opacity: 0;

  path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }

  :hover {
    path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
  }
`;

export const Title = styled(BasicHeading)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${Edit} {
      opacity: 1;
    }
  }
`;

export const TitleInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.primary};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xl};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  border-radius: 4px;
  padding: 4px 8px;
  width: auto;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export const InvitesWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
`;
