import React, { FC, useState, useCallback } from 'react';

import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Spacing from 'styleguide/components/Spacing';
import { ReactComponent as AssigneeIcon } from 'components/icons/IconAssignee.svg';
import { SmallBoxAssign, SmallBoxTitle, AddReviewerText, ClosingDateText } from './styled';
import AssignToModal from './AssignToModal';
import UnassignModal from './UnassignModal';
import { useDeviceState } from 'state/deviceState';

interface AssignToBoxProps {
  isOwner: boolean;
  openPermissions: () => void;
  assign: (assignedTo: string, email: string) => Promise<void>;
  unassign: () => Promise<void>;
  assignedTo?: string;
}

const AssignToBox: FC<AssignToBoxProps> = ({
  isOwner,
  openPermissions,
  assign,
  unassign,
  assignedTo
}) => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [deviceState] = useDeviceState();

  const handleBoxClick = useCallback(() => {
    if (assignedTo) {
      setIsUnassignModalOpen(true);
    } else {
      setIsAssignModalOpen(true);
    }
  }, [assignedTo]);

  return (
    <>
      <SmallBoxAssign
        isClickable={isOwner}
        onClick={() => isOwner && handleBoxClick()}
        isOwner={isOwner}
      >
        <SmallBoxTitle>
          <AssigneeIcon />
          <Spacing direction="left" value={8} />
          <BasicSubHeading size="regular" isUppercase>
            {assignedTo ? 'Assigned to' : 'Unassigned'}
          </BasicSubHeading>
        </SmallBoxTitle>
        {!assignedTo && isOwner ? (
          <AddReviewerText size={deviceState.isMobileOrTablet?'xs':'regular'}>Add a reviewer</AddReviewerText>
        ) : (
          <ClosingDateText size={deviceState.isMobileOrTablet?'xs':'regular'}>{assignedTo}</ClosingDateText>
        )}
      </SmallBoxAssign>
      <AssignToModal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        openPermissions={openPermissions}
        assign={assign}
      />
      <UnassignModal
        isOpen={isUnassignModalOpen}
        onClose={() => setIsUnassignModalOpen(false)}
        openPermissions={openPermissions}
        unassign={unassign}
      />
    </>
  );
};

export default AssignToBox;
