import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';

import BasicSelect, { OptionType } from 'styleguide/components/BasicSelect';
import { Response, OptionsList } from 'react-select-async-paginate';
import {
  useJurisdictionState,
  Jurisdiction
} from '../state/jurisdictionsState';
import { SelectComponent } from './TableForm';

import { fetchJurisdictions } from 'api/data';

const jurisdictionToOption = (jurisdiction: Jurisdiction) => ({
  label: jurisdiction.name,
  value: jurisdiction.id
});

const optionsReducer = (
  prev: OptionsList<OptionType>,
  loaded: OptionsList<OptionType>
): OptionsList<OptionType> => loaded;

const JurisdictionSelectField: SelectComponent = ({
  name,
  setRef,
  disabledIds = [],
  ...props
}) => {
  const [state, actions] = useJurisdictionState();

  useEffect(() => {
    if (!state.isLoaded) {
      actions.loadInitial();
    }
  }, [actions, state.isLoaded]);

  const loadOptions = useCallback(
    async (search: string): Promise<Response<OptionType, any>> => {
      if (search && search.length) {
        const resultsResponse = await fetchJurisdictions({
          s: { name: { $contL: search.toLowerCase() } }
        });

        return {
          options: [
            {
              label: '',
              options: resultsResponse.data.filter((j: Jurisdiction) => !j.county && j.name.toLowerCase() !== 'federal').map(jurisdictionToOption)
            }
          ],
          hasMore: false
        };
      }

      return {
        options: [
          {
            label: 'Popular',
            options: state.popular
              .filter(jurisdiction => !jurisdiction.county)
              .map(jurisdictionToOption)
          },
          {
            label: 'All jurisdictions',
            options: state.states.map(jurisdictionToOption)
            .filter((jurisdiction:any) => jurisdiction.label.toLowerCase() !== 'federal')
            .sort((a,b)=> (a.label > b.label) ? 1 : (b.label > a.label) ? -1 : 0)
          }
        ],
        hasMore: !state.isLoaded
      };
    },
    [state.isLoaded, state.popular, state.states]
  );

  const noOptionsMessage = React.useCallback(
    ({ inputValue }: any) =>
      !inputValue.length && state.isLoading ? 'Loading...' : 'No options',
    [state.isLoading]
  );

  return (
    <Field name={name}>
      {({ field: { value }, meta: { touched, error }, form: { setFieldValue } }: any) => (
        <BasicSelect
          loadOptions={loadOptions}
          placeholder="Jurisdiction"
          reduceOptions={optionsReducer}
          value={value}
          autoFocus
          onChange={(val: any) => {
            setFieldValue(name, val);
          }}
          selectRef={(asyncRef: any) => {
            if (asyncRef) {
              setRef(asyncRef.select?.inputRef as HTMLInputElement);
            }
          }}
          invalid={!!(touched && error)}
          isOptionDisabled={(option: any) => disabledIds.includes(option.value)}
          noOptionsMessage={noOptionsMessage}
          {...props}
        ></BasicSelect>
      )}
    </Field>
  );
};

export default JurisdictionSelectField;
