import React from 'react';
import styled from 'styled-components';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

const StyledTooltip = styled(ReactTooltip)`
  padding: 16px 24px;
  opacity: 1 !important;
  background-color: ${({ theme }) => theme.colors.darkest} !important;
  color: ${({ theme }) => theme.colors.lightest} !important;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  line-height: 1.3;

  @media (max-width: 768px) {
    padding: 0px 0px;
  }  

`;

const Tooltip = (props: TooltipProps) => (
  <StyledTooltip effect="solid" {...props} scrollHide={true} globalEventOff="click"/>
);

export default Tooltip;
