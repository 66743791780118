export default {
  title: `Change your password`,
  confirmation: `Password successfully updated`,
  errorContent: `That link has expired.`,
  backToForgot: `Click here to reset your password`,
  passwordPlaceholder: 'Enter password',
  passwordConfirmationPlaceholder: 'Confirm password',
  successHelpText: 'You will now be redirected to your dashboard, or',
  successHelpTextLink: 'click here to proceed',
  errorButton: 'Reset your password'
};
