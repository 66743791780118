import styled from 'styled-components';

const Center = styled.div`
    text-align: center;
    & > * {
        margin-left: auto;
        margin-right: auto;
    }
`;

export default Center;