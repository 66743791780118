import React, { FC, useState, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

import { getParamFromUrl } from 'helpers/helpers';
import { auth } from 'firebaseConfig';
import { EMAIL_VALIDATION } from 'helpers/validation';
import { DASHBOARD_URL } from 'helpers/routes';
import { PENDING, UNRECOGNIZED } from 'helpers/constants';
import { AuthContext } from 'components/app/Auth';

import LoginFormPresentational from './LoginFormPresentational';

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface LoginFormRenderProps {
  onSubmit: (values: LoginFormValues) => void;
  validationSchema: yup.ObjectSchema<LoginFormValues | undefined>;
  initialValues: LoginFormValues;
  isError: boolean;
  errorCode: string;
  isLoading: boolean;
  isPending: boolean;
}

const LoginForm: FC = () => {
  const { formatMessage } = useIntl();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const currentUser = useContext(AuthContext);
  const history = useHistory();

  const initialValues = { email: '', password: '' };
  const emailError = formatMessage({
    id: `errors.email`
  });

  const passwordError = formatMessage({
    id: `errors.passwordLogin`
  });

  useEffect(() => {
    if (currentUser?.claims?.status === PENDING) {
      setIsPending(true);
      auth.signOut();
    }
    if (currentUser?.claims?.status === UNRECOGNIZED) {
      setError(2);
      auth.signOut();
    }
  }, [currentUser]);

  const validationSchema = yup.object().shape<LoginFormValues>({
    email: yup
      .string()
      .email(emailError)
      .required(emailError)
      .matches(EMAIL_VALIDATION, {
        message: emailError,
        excludeEmptyString: true
      }),
    password: yup.string().required(passwordError)
  });

  const onSubmit = async ({ email, password }: LoginFormValues) => {
    try {
      setIsLoading(true);
      const continueUrl = getParamFromUrl('continueUrl') || DASHBOARD_URL;
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      setIsLoading(false);

      const { claims } = await user.getIdTokenResult();

      if (claims?.status === UNRECOGNIZED) {
        setError(2);
        setIsLoading(false);
      } else if (claims?.status !== PENDING) {
        history.push(continueUrl);
      }
    } catch (error:any) {
      setError(error.code);
      setIsLoading(false);
    }
  };

  return (
    <LoginFormPresentational
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={initialValues}
      isError={Boolean(error)}
      errorCode={error}
      isLoading={isLoading}
      isPending={isPending}
    />
  );
};

export default LoginForm;
