import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import FormLabel from 'styleguide/components/FormLabel';
import IconDone from 'components/icons/IconDone.svg';

export interface ItemProps {
  name: string;
  value: string;
  checked?: boolean;
  onChange?: any;
  disabled?: boolean;
  withPadding?: boolean;
}

interface WrapperStyleProps {
  isRow?: boolean;
  hasMargin?: boolean;
}

interface LabelStyleProps {
  isLabelFullWidth?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;

  ${({ isRow, hasMargin = true }) =>
    isRow
      ? css`
          & > *:not(:first-child) {
            ${hasMargin && `margin-left: 14px;`}
          }
        `
      : css`
          flex-direction: column;

          & > *:not(:first-child) {
            ${hasMargin && `margin-top: 12px;`}
          }
        `}
`;

const ItemWrapper = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Checkbox = styled.span`
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray200};
  border-radius: ${({ theme }) => theme.borderRadius.regular};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked {
    & ~ ${Checkbox} {
      border-color: ${({ theme }) => theme.colorsNewDesign.primary};
      background-color: ${({ theme }) => theme.colorsNewDesign.primary};

      &:before {
        background: url(${IconDone}) center no-repeat;
      }
    }
  }
`;

const StyledFormLabel = styled(FormLabel)<LabelStyleProps>`
  margin-left: 8px;
  width: ${({ isLabelFullWidth }) => isLabelFullWidth && `100%`};
  color: ${({ disabled }) => disabled && `#84848D`};
`;

const Item: FC<ItemProps & LabelStyleProps> = ({
  name,
  value,
  children,
  checked,
  onChange,
  disabled,
  isLabelFullWidth
}) => {
  const id = `${name}-${value}`;

  return (
    <ItemWrapper>
      <StyledInput
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <Checkbox />
      <StyledFormLabel htmlFor={id} isLabelFullWidth={isLabelFullWidth} disabled={disabled}>
        {children}
      </StyledFormLabel>
    </ItemWrapper>
  );
};

const BasicCheckboxList = {
  Wrapper,
  Item
};

export default BasicCheckboxList;
