import React, { FC, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconExit } from 'components/icons/IconExit.svg';
// import { ReactComponent as BackExit } from 'components/icons/IconExit.svg';

interface ExitButtonProps {
  className?: string;
  deviceState?: any;
}

const StyledExitButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  width: 66px;
  background-color: ${({ theme }) => theme.colorsNewDesign.transparent};
  border: none;

  @media (max-width:768px){
    float:right
  }

  &:focus {
    outline: none;
  }
`;

const ExitButton: FC<ExitButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...restProps }) => (
  <StyledExitButton className={className} {...restProps}>
    <IconExit />
  </StyledExitButton>
);

export default ExitButton;
