import React, { FC, useRef, useState } from 'react';
import { useField } from 'formik';
import ReactTooltip from 'react-tooltip';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Cell } from 'styleguide/components/Table';
import Dropdown from 'styleguide/components/Dropdown';
import CellFormAccessory from 'styleguide/components/CellForm';
import ProductSelect from '../ProductSelectCellForm';
import { Product } from '../../state/productsState';

interface CellFormProps {
  row: number;
  col: number;
  valueComponent: React.FC<{ value: string[]; col: number; row: number }>;
  productFamily: string;
  products: Product[];
}

const CellForm: FC<CellFormProps> = ({
  row,
  col,
  productFamily,
  products,
  valueComponent: Component
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const fieldName = `selection.${col}.${row}`;
  const tooltip = useRef(null);
  const [, { value }, { setValue }] = useField(`selection.${col}.${row}`);

  const tooltipContentRef = useOnclickOutside(() => {
    if (isOpen) {
      setIsOpen(false);
      ReactTooltip.hide(tooltip?.current);
    }
  });


  return (
    <Cell isFullSize isSelected={!!value}>
      <div
        data-tip="tooltip"
        data-for={fieldName}
        data-event="noevent"
        ref={tooltip}
        style={{ height: '100%' }}
      >
        <CellFormAccessory
          onDelete={() => setValue(null)}
          onEdit={() => {
            setIsOpen(true);
            ReactTooltip.show(tooltip?.current);
          }}
        >
          <Component value={value} row={row} col={col} />
        </CellFormAccessory>
      </div>

      <Dropdown
        heading={`Select ${productFamily}`}
        submitButtonText="Close"
        onSubmit={(customRef) => {
          customRef.tooltipRef = null;
          setIsOpen(false);
          ReactTooltip.hide(tooltip?.current);
        }}
        id={fieldName}
        contentRef={tooltipContentRef}
        customRef={tooltip}
      >
        <ProductSelect
          col={col}
          name={fieldName}
          value={value || []}
          productFamily={productFamily}
          products={products}
        />
      </Dropdown>
    </Cell>
  );
};

export default CellForm;
