import styled, { css } from 'styled-components';
import ErrorIcon from './error.svg';

interface ErrorProps {
  isCentered?: boolean;
}

const Error = styled.div<ErrorProps>`
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  line-height: 1.3;
  color: ${({ theme }) => theme.colorsNewDesign.error};
  display: flex;
  flex: none;
  align-items: center;

  ${({ isCentered }) =>
    isCentered &&
    css`
      justify-content: center;
    `};

  &::before {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    background: url(${ErrorIcon});
    display: block;
    content: '';
    flex-shrink: 0;
  }
`;

export default Error;
