import styled from 'styled-components';

interface HorizontalLineProps {
  width: string;
}

const HorizontalLine = styled.div<HorizontalLineProps>`
  height: 1px;
  background: ${({ theme }) => theme.colorsNewDesign.gray200};
  width: ${({ width }) => width};
`;

const HorizontalLineForMobile = styled.div<HorizontalLineProps>`
  height: 1px;
  background: ${({ theme }) => theme.colorsNewDesign.gray200};
  width: ${({ width }) => width};
`;


export default HorizontalLine;
export {HorizontalLineForMobile}