import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOrderFormState } from '../../state/state';
import { STEP_NAMES } from '../../consts';
import InlineEdit, {
  InlineEditWrapper
} from 'styleguide/components/InlineEdit';
import BasicSection from 'styleguide/components/BasicSection';
import Button from 'styleguide/components/Button';
import { DILIGENCE_STEPS } from '../../consts';
import Tooltip from 'styleguide/components/Tooltip';
import styled from 'styled-components';

const documentsLabel = (charter: boolean, goodStanding: boolean) => {
  if (charter && goodStanding) return 'Organizational Documents';
  if (charter) return 'Charter Documents';
  return 'Good Standings';
};
const StepWrapper = styled.div`
  font-weight: bold;
`;
const DiligenceWrapper = styled.div`
  margin-top:-17px;
`;
const DiligenceLineEdit = styled.div`
  height: 48px;
  display: flex;
`;
const StepsReview = () => {
  const [
    { steps, documents },
    { setDeleteModal, addStep, setStep, addDocs }
  ] = useOrderFormState();
  const canDelete =
    steps.filter(step => DILIGENCE_STEPS.includes(step)).length > 1;
  return (
    <BasicSection title="Diligence">
      <DiligenceWrapper>
        {DILIGENCE_STEPS.map((step,index) =>
          steps.includes(step) ? (
            <InlineEditWrapper key={index} style={{ height: 48 }}>
              {step === STEP_NAMES.Documents ? (
                <div style={{fontWeight: 'bold'}}>
                  {documentsLabel(
                    documents?.charterDocuments,
                    documents?.goodStanding
                  )}
                </div>

              ) : (
                <StepWrapper key={index}>
                  <FormattedMessage id={`reviewStepsList.${step}`} />
                </StepWrapper>
              )}
              <Tooltip />
              <InlineEdit
                dataTip="Edit order"
                onEdit={() => setStep(step)}
                onDelete={canDelete ? () => setDeleteModal(step) : null}
              />
            </InlineEditWrapper>
          ) : (
            <DiligenceLineEdit>
              <Button variant="inline" onClick={() => addStep(step)}>
                Add <FormattedMessage id={`reviewStepsList.${step}`} />
              </Button>
            </DiligenceLineEdit>
          )
        )}
        {documents && (
          <DiligenceLineEdit>
            {documents.charterDocuments && !documents.goodStanding && (
              <Button variant="inline" onClick={() => addDocs('goodStanding')}>
                Add <FormattedMessage id={`reviewStepsList.goodStanding`} />
              </Button>
            )}
            {documents.goodStanding && !documents.charterDocuments && (
              <Button
                variant="inline"
                onClick={() => addDocs('charterDocuments')}
              >
                Add <FormattedMessage id={`reviewStepsList.charterDocuments`} />
              </Button>
            )}
          </DiligenceLineEdit>
        )}
      </DiligenceWrapper>
    </BasicSection>
  );
};

export default StepsReview;
