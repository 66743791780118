import React, {FC } from 'react';
import { Helmet } from 'react-helmet';
import AuthenticationLayout from 'layout/authenticationLayout/AuthenticationLayout';
import LoginLeftColumn from './components/LoginLeftColumn';
import LoginRightColumn from './components/LoginRightColumn';


const Login: FC = () =>{   
  return(
  <>
    <Helmet>
      <title>Sign in - DealBinder</title>
    </Helmet>

    <AuthenticationLayout
      leftColumn={<LoginLeftColumn />}
      rightColumn={<LoginRightColumn />}
    />
  </>
);
}

export default Login;
