import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Spacing from 'styleguide/components/Spacing';
import { CONTACT_MAIL } from 'helpers/routes';

import {
  LeftColumnContent,
  Title,
  Subtitle,
  HelpText,
  HelpTextLink
} from './styled';

const LoginLeftColumn: FC = () => (
  <LeftColumnContent>
    <Spacing direction="top" value={165} />
    <Title tag="h1" size="xxxxl">
      <FormattedMessage id="login.title" />
    </Title>
    <Spacing direction="top" value={67} />
    <Subtitle>
      <FormattedMessage id="login.subtitle" />
    </Subtitle>
    <HelpText>
      <FormattedMessage id="login.help" />{' '}
      <HelpTextLink href={CONTACT_MAIL}>
        <FormattedMessage id="links.contactUs" />
      </HelpTextLink>
    </HelpText>
    <Spacing direction="top" value={40} />
  </LeftColumnContent>
);

export default LoginLeftColumn;
