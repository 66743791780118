import React, { useMemo } from 'react';
import styled from 'styled-components';

const Footer = styled.div`
  box-shadow: 0 -1px 0 #e2e6f0;
  padding: 22px 24px;
  margin: -2px -5px 0 -24px;
  color: #84848d;
  font-size: 13px;
`;

const TableFooter = ({ count }: any) => {
  // eslint-disable-next-line
  const all = useMemo(() => count, []);
  return (
    <Footer>
      Showing {count} of {all} names
    </Footer>
  );
};

export default TableFooter;
