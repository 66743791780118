import React from 'react';
import {
    ExpandButton,
    FiltersButton,
    CountBadge
  } from '../../../pages/matter/components/common/styled';
  import { ReactComponent as IconExpand } from 'components/icons/IconExpand.svg';
  import { useMatterState } from '../../../pages/matter/state/matter';
  import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';




const HitdetailButton = () => {

    const [
        {
          tables: {
            hitDetails: {
              filters: { priority, ...filters },
              expanded
            }
          },
        },
        {
         toggleExpanded,toggleTableFilterSidebar
        }
      ] = useMatterState(); 
      const countBadge = React.useMemo(() => Object.keys(filters).length, [filters]);
      
      return(
    <>
          <ExpandButton
            onClick={() => toggleExpanded('hitDetails')}
            data-tip
            data-for="expandrows"
            data-place="bottom"
            resetOrder
            active={expanded}
          >
            <IconExpand />
          </ExpandButton>
          <FiltersButton
            active={Boolean(Object.keys(filters).length)}
            onClick={() => toggleTableFilterSidebar('hitDetails')}
            resetOrder
          >
            <FiltersIcon />
            { countBadge ? <CountBadge>{ countBadge }</CountBadge> : null }
          </FiltersButton>

   </>
);
}

export default HitdetailButton;
