import React, { useState, FC, useEffect } from 'react';
import { Field } from 'formik';
import { ValueType } from 'react-select';
import Select, { OptionType } from 'styleguide/components/Select';
import { fetchMatters } from 'api/data';
import { fetchUserDetails } from 'api/settings';

interface MatterSelectProps {
  name: string;
}

const MatterSelect: FC<MatterSelectProps> = ({ name }) => {
  const [matters, setMatters] = useState([]);

  useEffect(() => {
    Promise.all([
      fetchUserDetails(),
      fetchMatters()
    ])
      .then(([user, { data }]) => {
        data = data.filter(m => m.role === "owner" && m.contact === user.email);
        setMatters(
          data.map(({ matter: { id, name } }) => ({ value: id, label: name }))
        )
      });
  }, []);

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }: any) => (
        <Select
          options={matters}
          placeholder="Select matter"
          value={matters.find(m => m.value === value?.value)}
          onChange={(val: ValueType<OptionType>) => setFieldValue(name, val)}
        />
      )}
    </Field>
  );
};

export default MatterSelect;
