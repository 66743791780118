import React, { FC } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createNote } from 'api/notes';
import { useMatterState } from '../../state/matter';
import IconSend from 'components/icons/IconSend.svg';
import Button from 'styleguide/components/Button';
import Error from 'styleguide/components/Error';
import Loader from 'styleguide/components/Loader';
import Spacing from 'styleguide/components/Spacing';

const AddNote = styled.textarea`
  background: #fff url(${IconSend}) right 18px center no-repeat;
  border: none;
  width: 100%;
  height: 38px;
  padding: 12px 18px 0 18px;
  transition: height 0.2s;
  resize: none;
  &::placeholder {
    color: #a8a8b4;
  }
  svg {
    margin-left: auto;
  }
`;

const NotesFormActions = styled.div`
  display: flex;
  border-top: 1px solid #ebecef;
  padding: 0 24px;
  align-items: center;

  ${Loader} {
    font-size: 3.2px;
    margin-left: 10px;
  }
`;

const NotesForm = styled.div`
  width: 100%;
  margin: 12px 0;
  box-sizing: border-box;

  border: 1px solid #ebecef;
  border-radius: 4px;

  ${NotesFormActions} {
    display: none;
  }

  &.open {
    padding: 16px 0 0 0;
    ${AddNote} {
      height: 100px;
      padding-top: 0;
      background: #fff;
    }
    ${NotesFormActions} {
      display: flex;
      button {
        padding: 10px 24px !important;
        order: 0;
      }
    }
    ${Error} {
      margin-left: 20px;
      margin-bottom: 10px;
    }

    @media (max-width:768px)
    {
     
  &.open {
    padding: 16px 0 0 0;
    ${AddNote} {
      height: 300px;
      padding-top: 0;
      background: #fff;
    }
    }
  }
`;

interface AddProps {
  matterId: string;
  orderId: string;
  id: string;
  addCallback?: () => void;
}

interface AddNoteFormValues {
  text: string;
}
const initialValues: AddNoteFormValues = { text: '' };
const AddNoteFormSchema = Yup.object().shape<AddNoteFormValues>({
  text: Yup.string()
    .required('Please include a message')
    .min(1, 'Please include a message')
});

const Add: FC<AddProps> = ({ matterId, orderId, id, addCallback }) => {
  const [, { fetchNotes }] = useMatterState();
  const [open, setOpen] = React.useState(false);

  return (
    <NotesForm className={open ? 'open' : ''}>
      <Formik<AddNoteFormValues>
        initialValues={initialValues}
        validationSchema={AddNoteFormSchema}
        onSubmit={async (values, { resetForm }) => {
          await createNote(matterId, orderId, id, values.text);
          fetchNotes(orderId, id);
          resetForm();
          setOpen(false);
          if (addCallback) {
            addCallback();
          }
        }}
      >
        {({ resetForm, submitCount, isSubmitting }) => (
          <Form>
            <Field name="text">
              {({ field, meta }: any) => (
                <>
                  <AddNote
                    onClick={() => setOpen(true)}
                    placeholder="Add a note"
                    {...field}
                  />
                  {submitCount > 0 && meta.error && <Error>{meta.error}</Error>}
                </>
              )}
            </Field>
            <NotesFormActions>
              <Button
                type="button"
                onClick={() => {
                  resetForm();
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="ml-3 p-0"
                primary
                type="submit"
                disabled={isSubmitting}
              >
                {(isSubmitting && (
                  <>
                   <Spacing direction="left" value={16} />
                    <Loader marginLess size={1} background="#dddce7" />
                    <Spacing direction="right" value={16} />
                  </>
                )) ||
                  'Post'}
              </Button>
              {/* {isSubmitting && <Loader />} */}
            </NotesFormActions>
          </Form>
        )}
      </Formik>
    </NotesForm>
  );
};

export default Add;
