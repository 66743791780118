import React, { FC } from 'react';

import LoaderComponent from 'styleguide/components/Loader';

import { LoaderWrapper, ListWrapper } from './styled';

const Loader: FC = () => (
  <ListWrapper>
    <LoaderWrapper>
      <LoaderComponent marginLess />
    </LoaderWrapper>
  </ListWrapper>
);

export default Loader;
