import { JurisdictionValue } from '../searches';

export const reduceSelection = (
  condition: (row: string[], colIndex: number) => boolean
) => (selection: string[][][]) =>
  selection
    ? selection
        .map((col, colIndex) =>
          col
            .map(row =>
              row && row.length >= 0 ? condition(row, colIndex) : true
            )
            .reduce((acc, val) => acc && val === true, true)
        )
        .reduce((acc, val) => acc && val === true, true)
    : false;

export const mapSelection = (
  map: (row: string[], colIndex: number) => string[]
) => (selection: string[][][]) =>
  selection.map((col, colIndex) =>
    col.map(row => (row && row.length >= 0 ? map(row, colIndex) : row))
  );

interface FormValues {
  names?: string[];
  jurisdictions?: JurisdictionValue[];
  selection?: string[][][];
}

export function filterEmptyRows<T extends FormValues>(values: T): T {
  const emptyRowIndexes = values.names
    .map((v: string, i: number) => (v && v.length ? -1 : i))
    .filter((i: number) => i >= 0);
  if (!emptyRowIndexes.length) return values;
  return {
    ...values,
    names: values.names.filter(
      (val: string, i: number) => !emptyRowIndexes.includes(i)
    ),
    selection: values.selection.map((val: string[][]) =>
      val.filter(
        (values: string[], row: number) => !emptyRowIndexes.includes(row)
      )
    )
  };
}

export function filterEmptyColumns<T extends FormValues>(values: T): T {
  const emptyColumnIndexes = values.jurisdictions
    .map((v: JurisdictionValue, i: number) => (v ? -1 : i))
    .filter((i: number) => i >= 0);
  return {
    ...values,
    jurisdiction: values.jurisdictions.filter(
      (val, i) => !emptyColumnIndexes.includes(i)
    ),
    selection: values.selection.filter(
      (val: string[][], col: number) => !emptyColumnIndexes.includes(col)
    )
  };
}

export function filterEmptyValues<T extends FormValues>(values: T): T {
  return filterEmptyColumns(filterEmptyRows(values));
}
