import React, {
  FC,
  useCallback,
  MutableRefObject,
  useLayoutEffect,
  useState
} from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';

import StyleguideButton from 'styleguide/components/Button';
import { ReactComponent as ChevronLeft } from 'components/icons/IconChevronLeft.svg';

const Wrapper = styled.div<{ isVisible: boolean }>`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 100;
  transition: opacity 100ms ease-in;

  ${({ isVisible }) => !isVisible && `opacity: 0;`}
`;

const Button = styled(StyleguideButton)`
  padding: 8px;

  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
    transition: fill 100ms ease-in;
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
  }
`;

const VISIBILITY_OFFSET = 30;

interface ScrollToTopProps {
  scrollingContainerRef: MutableRefObject<HTMLElement>;
}

const ScrollToTop: FC<ScrollToTopProps> = ({ scrollingContainerRef }) => {
  const [isVisible, setIsVisible] = useState(
    scrollingContainerRef?.current?.scrollTop > VISIBILITY_OFFSET
  );

  const handleScrollToTop = useCallback(() => {
    if (scrollingContainerRef?.current) {
      scrollingContainerRef.current.scrollTop = 0;
    }
  }, [scrollingContainerRef]);

  const handleScroll = useCallback(
    throttle(() => {
      if (scrollingContainerRef?.current?.scrollTop > VISIBILITY_OFFSET) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }, 100),
    [scrollingContainerRef.current, isVisible]
  );

  useLayoutEffect(() => {
    scrollingContainerRef?.current?.addEventListener('scroll', handleScroll);

    return () =>
      scrollingContainerRef?.current?.removeEventListener(
        'scroll',
        handleScroll
      );
  }, [scrollingContainerRef.current]); // eslint-disable-line

  return (
    <Wrapper isVisible={isVisible}>
      <Button medium onClick={handleScrollToTop}>
        <ChevronLeft />
      </Button>
    </Wrapper>
  );
};

export default ScrollToTop;
