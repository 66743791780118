import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import CenteredLayout from 'styleguide/components/CenteredLayout';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import ExitButton from 'styleguide/components/ExitButton';
import BasicSelectButtons from 'styleguide/components/BasicSelectButtons';
import { ReactComponent as IconSearches } from 'components/icons/IconSearches.svg';
import { ReactComponent as IconOfac } from 'components/icons/IconOfac.svg';
import { ReactComponent as IconOrgDocuments } from 'components/icons/IconOrgDocuments.svg';
import { ReactComponent as IconTooltip } from 'components/icons/IconTooltip.svg';
import { ChevronRight } from 'styleguide/components/Icon';
import Button, { RightButtonIcon } from 'styleguide/components/Button';
import Error from 'styleguide/components/Error';
import Alert from 'styleguide/components/Alert';
import Tooltip from 'styleguide/components/Tooltip';

import { OrderFormStateSubscriber } from './state/state';
import DeleteModal from './components/DeleteModal';
import ExitModal from './components/ExitModal';
import styled from 'styled-components';

type DiligenceKeys = 'searches' | 'ofac' | 'documents';

const StyledCenteredLayout = styled(CenteredLayout)`
  padding-top: 3.5rem;
`;
const MailText = styled.div`
text-align: center;
font-weight:600;
font-size: 18px;
`;

export interface DiligenceFormValues {
  diligence: Record<DiligenceKeys, boolean>;
}

export const DiligenceSchema = Yup.object().shape<DiligenceFormValues>({
  diligence: Yup.object()
    .shape({
      searches: Yup.boolean(),
      ofac: Yup.boolean(),
      documents: Yup.boolean()
    })
    .test(
      'diligence',
      'Please select a diligence type',
      ({ searches, ofac, documents }) => searches || ofac || documents
    )
});

const Diligence: FC = () => (
  <OrderFormStateSubscriber>
    {({ diligence }, { setDiligence }) => (
      <StyledCenteredLayout
        anchor={
          <ExitModal
            trigger={open => <ExitButton type="button" onClick={open} />}
          />
        }
      >
        <div>
          <BasicHeading tag="h1" size="xxxl" isCentered>
            How can we help you?
          </BasicHeading>

          <Spacing direction="top" value={40} />
          <Alert isCentered>Click all that apply</Alert>

          <Formik<DiligenceFormValues>
            initialValues={{
              diligence: {},
              ...((diligence || {}) as DiligenceFormValues)
            }}
            onSubmit={values => {
              setDiligence(values);
            }}
            validationSchema={DiligenceSchema}
          >
            {({ values, errors, setFieldValue }) => (
              <Form>
                <BasicSelectButtons.Wrapper hasSpacingBetweenItems>
                  <BasicSelectButtons.Button
                    name={`diligence[searches]`}
                    type="checkbox"
                    value={true}
                    icon={<IconSearches />}
                    isActive={values.diligence['searches']}
                    onChange={() => {
                      setFieldValue(
                        `diligence[searches]`,
                        !Boolean(values.diligence['searches'])
                      );
                    }}
                    label="Searches"
                    accessory={
                      <>
                        <IconTooltip
                          data-tip
                          data-for="diligenceSearchesTooltip"
                        />

                        <Tooltip id="diligenceSearchesTooltip">
                          <>
                            <strong>UCC</strong> (debtor) <br />{' '}
                            <strong>Tax lien</strong> (debtor)
                            <br /> <strong>Judgment lien</strong> (debtor){' '}
                            <br />
                            <strong>Suits and judgments</strong> (pending/open,
                            civil defendant) <br />
                            <strong>Bankruptcy</strong> (defendant)
                          </>
                        </Tooltip>
                      </>
                    }
                  />

                  <BasicSelectButtons.Button
                    name={`diligence[ofac]`}
                    type="checkbox"
                    value={true}
                    icon={<IconOfac />}
                    isActive={values.diligence['ofac']}
                    onChange={() => {
                      setFieldValue(
                        `diligence[ofac]`,
                        !Boolean(values.diligence['ofac'])
                      );
                    }}
                    label="OFAC"
                    accessory={
                      <IconTooltip data-tip="A search of the Office of Foreign Assets Control Sanctions List" />
                    }
                  />

                  <BasicSelectButtons.Button
                    name={`diligence[documents]`}
                    type="checkbox"
                    value={true}
                    icon={<IconOrgDocuments />}
                    isActive={values.diligence['documents']}
                    onChange={() => {
                      setFieldValue(
                        `diligence[documents]`,
                        !Boolean(values.diligence['documents'])
                      );
                    }}
                    label="Org Docs"
                    accessory={
                      <>
                        <IconTooltip
                          data-tip
                          data-for="diligenceOrgDocsTooltip"
                        />

                        <Tooltip id="diligenceOrgDocsTooltip">
                          <>
                            Charter Documents <br /> Certificates of Good
                            Standing / Status
                          </>
                        </Tooltip>
                      </>
                    }
                  />
                </BasicSelectButtons.Wrapper>

                {errors.diligence && (
                  <>
                    <Spacing direction="top" value={20} />
                    <Error isCentered>{errors.diligence}</Error>
                  </>
                )}

                <Spacing direction="top" value={40} />

                <div
                  data-tip="Select diligence type"
                  data-tip-disable={Boolean(
                    Object.keys(values.diligence).length
                  )}
                >
                  <Button
                    width="200px"
                    primary
                    isCentered
                    disabled={!Object.keys(values.diligence).length}
                    data-tip-disable={false}
                  >
                    Next
                    <RightButtonIcon>
                      <ChevronRight />
                    </RightButtonIcon>
                  </Button>
                </div>

                <Tooltip />
                <DeleteModal />
              </Form>
            )}
          </Formik>
          <Spacing direction="top" value={60} />
          <MailText>
            For filings, formations, and other services,{' '}
            <a href="mailto:orders@dealbinder.com">please email us.</a>
          </MailText>
        </div>
      </StyledCenteredLayout>
    )}
  </OrderFormStateSubscriber>
);

export default Diligence;
