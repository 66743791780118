import React, { useCallback } from 'react';
import { useMatterState } from '../../state/matter';

const DownloadFileLink = ({ orderLineItemId, children }: any) => {
  const [, { downloadFiles }] = useMatterState();
  const link = React.useRef(null);

  const handleClick = useCallback(() => {
    downloadFiles([orderLineItemId]).then(fileData => {
      const href = window.URL.createObjectURL(
        new Blob([fileData.data], { type: fileData.type })
      );
      const a = link.current;
      a.download = fileData.filename;
      a.href = href;
      a.click();
      a.href = '';
    });
  }, [downloadFiles, orderLineItemId]);

  return (
    <>
      {/* eslint-disable-next-line */}
        <a ref={link} />
      <div onClick={handleClick}>{children}</div>
    </>
  );
};

export default DownloadFileLink;
