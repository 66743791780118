import React, { FC } from 'react';
import styled from 'styled-components';
import { theme } from 'theme/theme';

import BasicModal, {
  BasicModalProps,
  Container
} from 'styleguide/components/BasicModal';

interface SidesheetProps {
  width?: string;
}

const ModalWrapper: FC<SidesheetProps & BasicModalProps> = ({
  width = '328px',
  className,
  ...props
}) => {
  const customStyles = {
    content: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      right: 0,
      left: 'initial',
      top: 0,
      height: '100%',
      transform: 'none',
      marginRight: 0,
      borderRadius: 0,
      boxShadow: `1px 0 0 ${theme.colorsNewDesign.gray100}`,
      border: 'none',
      width
    }
  };

  return (
    <BasicModal
      portalClassName={className}
      customStyles={customStyles}
      {...props}
    />
  );
};

const Sidesheet = styled(ModalWrapper)`
  ${Container} {
    max-height: none;
    flex-grow: 1;
  }
`;

export default Sidesheet;
