import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import size from 'lodash/size';
import { compareAsc } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { HitDetails } from 'api/matter';
import Tabs from 'styleguide/components/Tabs';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as ChevronLeft } from 'components/icons/IconChevronLeft.svg';
import { useMatterState } from '../../state/matter';

import SidebarDetailsGroup from './SidebarDetailsGroup';
import SidebarNotes from './SidebarNotes';
import {
  SidebarWrapper,
  SidebarHeader,
  CollapseButton,
  SidebarButton,
  CollapsedSidebarContent,
  DetailsIcon,
  NotesIcon,
  SidebarContent
} from './styled';
import _, { sortBy } from 'lodash';

interface SidebarProps {
  id: string;
  productFamily: string;
  productName: string;
  hitDetails?: HitDetails[];
  role: string;
}

const Sidebar: FC<SidebarProps> = ({
  id,
  productFamily,
  productName,
  hitDetails = [],
  role
}) => {
  const [{ hits }] = useMatterState();
  const hit = useMemo(() => hits ? hits.find(h => h.id === id) : null, [id, hits]);

  const showDetailsTab = productFamily === 'Searches' && size(hitDetails) > 0;
  const showNotesTab = role !== 'limitedViewer';
  const history = useHistory();
  const tabs = useMemo(
    () =>
      [
        showDetailsTab && { id: 'details', label: 'Details', badge: 0 },
        showNotesTab && { id: 'notes', label: 'Notes', badge: hit ? hit.notes.length : 0 }
      ].filter(Boolean),
    [showDetailsTab, showNotesTab.valueOf, hit]
  );

  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const routeState:any = history.location.state;

  const handleToggle = useCallback(
    () => (isCollapsed ? setIsCollapsed(false) : setIsCollapsed(true)),
    [isCollapsed, setIsCollapsed]
  );

  const handleButtonClick = useCallback(
    (tab: string) => {
      setIsCollapsed(false);
      setActiveTab(tab);
    },
    [setActiveTab, setIsCollapsed]
  );

  useEffect(() => {
    console.log('routeState >>',routeState)
    if(routeState === undefined && showDetailsTab){
      setActiveTab('details');
    }
    if(routeState === undefined && !showDetailsTab){
      setActiveTab('notes');
    }
    if(routeState?.fromButton === "file" && showDetailsTab){
      setActiveTab('details');
    }
    else if(routeState?.fromButton === "file" && !showDetailsTab && showNotesTab){
      setActiveTab('notes');
    }
    else if(routeState?.fromButton === "notes" && showDetailsTab && !showNotesTab) {
      setActiveTab('details');
    }
    else if(routeState?.fromButton === "notes" && showDetailsTab && showNotesTab) {
      setActiveTab('notes');
    }
  // }, [showDetailsTab,routeState]); // eslint-disable-line
  }, [showDetailsTab]); // eslint-disable-line

  useEffect(() => { if (loaded) setActiveTab('details'); }, [id]);
  useEffect(() => setLoaded(true), []);

  const groupedHitDetails = useMemo(
    () =>
      mapValues(groupBy(hitDetails, 'group'), group =>
        group.sort((a, b) =>
          compareAsc(new Date(a.fileDate), new Date(b.fileDate))
        )
      ),
    [hitDetails]
  );

  if (tabs.length === 0) {
    return null;
  }

  return (
    <SidebarWrapper isCollapsed={isCollapsed}>
      <SidebarHeader isCollapsed={isCollapsed}>
        {!isCollapsed && (
          <>
            <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
            <Spacing direction="left" value="auto" />
          </>
        )}
        <Tooltip place="left" id="sidebar-collapse-button">
          {isCollapsed ? 'Open' : 'Hide'}
        </Tooltip>
        <CollapseButton
          resetOrder
          onClick={handleToggle}
          data-tip
          data-for="sidebar-collapse-button"
        >
          {isCollapsed ? <ChevronLeft /> : <ChevronRight />}
        </CollapseButton>
        {!isCollapsed && <Spacing direction="left" value={19} />}
      </SidebarHeader>
      {isCollapsed && (
        <CollapsedSidebarContent>
          {showDetailsTab && (
            <>
              <Tooltip id="sidebar-details-button">Details</Tooltip>
              <SidebarButton
                resetOrder
                onClick={() => handleButtonClick('details')}
                data-tip
                data-for="sidebar-details-button"
              >
                <DetailsIcon />
              </SidebarButton>
              <Spacing direction="top" value={16} />
            </>
          )}
          <Tooltip id="sidebar-notes-button">Notes</Tooltip>
          <SidebarButton
            resetOrder
            onClick={() => handleButtonClick('notes')}
            data-tip
            data-for="sidebar-notes-button"
          >
            <NotesIcon />
          </SidebarButton>
        </CollapsedSidebarContent>
      )}
      {!isCollapsed && (
        <>
          {activeTab === 'details' && (
            <SidebarContent>
              {sortBy(_.values(groupedHitDetails),'0.group').map(hitDetails => (
                <SidebarDetailsGroup
                  productName={productName}
                  hitDetails={hitDetails}
                />
              ))}
            </SidebarContent>
          )}
          {activeTab === 'notes' && <SidebarNotes id={id} />}
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
