import React, { FC, useEffect, useState, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';

import Sidesheet from 'styleguide/components/Sidesheet';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Button from 'styleguide/components/Button';
import Spacing from 'styleguide/components/Spacing';
import { OptionType } from 'styleguide/components/Select';
import { Filters as FiltersType } from '../../state/myEstimates';

import FiltersSelect from './FiltersSelect';
import { ModalContentWrapper, ModalContent, ModalFooter } from './styled';

interface FiltersProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (filters: FiltersType) => void;
  fetchMatterFilterOptions: () => Promise<void>;
  matterFilterOptions: OptionType[];
  dateFilterOptions: OptionType[];
  filters: FiltersType;
}

interface FiltersFormValue {
  matterNames: OptionType[];
  dates: OptionType[];
}

const Filters: FC<FiltersProps> = ({
  isOpen,
  onClose,
  onSubmit,
  fetchMatterFilterOptions,
  matterFilterOptions,
  dateFilterOptions,
  filters
}) => {
  const [areMattersLoading, setAreMattersLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (isEmpty(matterFilterOptions)) {
        setAreMattersLoading(true);
        fetchMatterFilterOptions().then(() => setAreMattersLoading(false));
      }
    }
  }, [isOpen, fetchMatterFilterOptions]); // eslint-disable-line

  const initialValues = useMemo(() => {
    const matterNames = matterFilterOptions.filter(
      matter =>
        filters.matterNames && filters.matterNames.includes(matter.value)
    );
    const dates = dateFilterOptions.filter(
      date => filters.dates && filters.dates.includes(date.value)
    );
    return {
      matterNames,
      dates
    };
  }, [filters, dateFilterOptions, matterFilterOptions]);

  if (!isOpen) {
    return null;
  }

  return (
    <Sidesheet
      title={
        <BasicHeading tag="h2" size="xxl">
          Filters
        </BasicHeading>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik<FiltersFormValue>
        initialValues={initialValues}
        onSubmit={values => {
          onSubmit({
            matterNames: values.matterNames
              ? values.matterNames.map(option => option.value)
              : [],
            dates: values.dates ? values.dates.map(option => option.value) : []
          });
          onClose();
        }}
      >
        {() => (
          <Form>
            <ModalContentWrapper>
              <ModalContent>
                <BasicText size="sm">Matter</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="matterNames"
                  placeholder="Select matter"
                  options={matterFilterOptions}
                  isLoading={areMattersLoading}
                />
                <Spacing direction="top" value={16} />
                <BasicText size="sm">Estimate date</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="dates"
                  placeholder="Select estimate date"
                  options={dateFilterOptions}
                  isLoading={areMattersLoading}
                />
              </ModalContent>
              <ModalFooter>
                <Button medium primary type="submit">
                  Apply
                </Button>
                <Button
                  medium
                  onClick={() => {
                    onSubmit({});
                    onClose();
                  }}
                >
                  Clear filters
                </Button>
              </ModalFooter>
            </ModalContentWrapper>
          </Form>
        )}
      </Formik>
    </Sidesheet>
  );
};

export default Filters;
