import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<ColumnAttachmentProps>`
    position: relative;
    & > * {
        position: absolute;
        left: ${({left}) => left};
        
        @media (max-width: 768px) {
            left: auto;
            right: 0;
        }
        
    }
`;

interface ColumnAttachmentProps {
    left: string;
}

const ColumnAttachment: FC<ColumnAttachmentProps> = ({ left, children }) => (
    <Wrapper left={left}>
        <div>
            {children}
        </div>
    </Wrapper>
)

export default ColumnAttachment;
