import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { FORGOT_PASSWORD_URL } from 'helpers/routes';
import Spacing from 'styleguide/components/Spacing';
import RoundIcon from 'styleguide/components/RoundIcon';
import BasicHeading from 'styleguide/components/BasicHeading';
import Button from 'styleguide/components/Button';
import { ReactComponent as IconInfo } from 'components/icons/IconInfo.svg';
import SimpleLayout from 'layout/simpleLayout';

import { HelpText } from './styled';

const ResetPasswordError: FC = () => (
  <SimpleLayout>
    <Spacing direction="top" value={80} />
    <RoundIcon>
      <IconInfo />
    </RoundIcon>
    <Spacing direction="top" value={55} />
    <BasicHeading tag="h1" size="xxxl" isCentered>
      <FormattedMessage id="shared.error" />
    </BasicHeading>
    <Spacing direction="top" value={16} />
    <HelpText size="regular">
      <FormattedMessage id="verify.errorContent1" />
    </HelpText>
    <Spacing direction="top" value={40} />
    <Link to={FORGOT_PASSWORD_URL}>
      <Button primary>
        <FormattedMessage id="resetPassword.errorButton" />
      </Button>
    </Link>
  </SimpleLayout>
);

export default ResetPasswordError;
