import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import { shortDateFormat, currencyFormat } from 'helpers/formatters';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as ChevronDown } from 'components/icons/IconChevronDown.svg';
import { HitOrderLineItem, HitDetails } from 'api/matter';
import { useDeviceState } from 'state/deviceState';
import _ from 'lodash';

const Wrapper = styled.div`
  padding: 0px;
  width: 100%;

  table {
    width: 100%;

    td,
    th {
      &:first-child {
        width: 50px;
        text-align: center;
        svg {
          cursor: pointer;
        }
      }
    }
    tr {
      &.group {
        border-top: 4px solid #fff;
        border-radius: 4px;
        cursor:pointer;
        td {
          color: #0f1027;
        }
      }
      td {
        color: #777B86;
        font-size: 13px;
        padding: 12px 20px;
      }
    }
    th {
      font-weight: 600;
      font-size: 10px;
      color: #84848d;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding: 8px 20px;
    }
    tbody {
      tr.rowData:hover {
        background: ${({theme}) => theme.colorsNewDesign.lightBlue};
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0px;
    width: 100%;
  
    table {
      width: 0px;
      td,
      th {
        &:first-child {
          width: 0px;
          text-align: center;
          svg {
            cursor: pointer;
          }
        }
      }
      tr {
        &.group {
          border-top: 0px solid #fff;
          border-radius: 0px;
        }
        td {
          color: #777B86;
          font-size: 10px;
          padding: 8px 7px;
        }
      }
      th {
        font-weight: 600;
        font-size: 10px;
        color: #84848d;
        letter-spacing: 0px;
        text-transform: uppercase;
        padding: 4px 8px;
      }
    }
  }
`;

const columns = (product: string) => {
  if (product === 'UCC - State' || product === 'UCC - County') {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOCUMENT #',
      type: 'DOC TYPE',
      securedParty: 'SECURED PARTY'
    };
  }
  if (
    product === 'Suits & Judgments - Federal' ||
    product === 'Suits & Judgments - State'
  ) {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOCUMENT #',
      plaintiff: 'Plaintiff'
    };
  }
  if (product === 'Bankruptcy' || product === 'Judgment Lien') {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOCUMENT #'
    };
  }
  if (product === 'Tax Lien - State' || product === 'Tax Lien - Federal')
    return {
      fileDate: 'FILED',
      fileNumber: 'DOCUMENT #',
      amount: 'AMOUNT'
    };

  return {};
};

const columnsForMobile = (product: string) => {
  if (product === 'UCC - State' || product === 'UCC - County') {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOC #',
      type: 'DOC TYPE',
      securedParty: 'SECURED PARTY'
    };
  }
  if (
    product === 'Suits & Judgments - Federal' ||
    product === 'Suits & Judgments - State'
  ) {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOC #',
      plaintiff: 'Plaintiff'
    };
  }
  if (product === 'Bankruptcy' || product === 'Judgment Lien') {
    return {
      fileDate: 'FILED',
      fileNumber: 'DOC #'
    };
  }
  if (product === 'Tax Lien - State' || product === 'Tax Lien - Federal')
    return {
      fileDate: 'FILED',
      fileNumber: 'DOC #',
      amount: 'AMOUNT'
    };

  return {};
};

const hitDetailsComparator = (el1: HitDetails, el2: HitDetails) => {
  if (el1.fileDate > el2.fileDate) {
    return -1;
  }
  if (el1.fileDate < el2.fileDate) {
    return 1;
  }
  return 0;
};

const hitDetailsASCComparator = (el1: HitDetails, el2: HitDetails) => {
  let a = el1.fileDate;
  let b = el2.fileDate;
  return a > b ? 1 : a < b ? -1 : 0;
};

const formatTableValue = (key: string, value: string) => {
  if (key === 'fileDate') return shortDateFormat(value);
  if (key === 'amount') {
    const formatted = currencyFormat(Number(value));
    return formatted ? `$${formatted}` : '';
  }
  return value;
};

const HitDetailsRow = (props: HitOrderLineItem) => {
  const [collapsed, setCollapsed] = useState<string[]>([]);
  const [deviceState] = useDeviceState();


  const groups = useMemo(() => {
    let groups = groupBy(props.hitDetails.sort(hitDetailsComparator), 'group');
    Object.keys(groups).forEach(key => {
      groups[key] = groups[key].sort(hitDetailsASCComparator);
    });
    return groups;
  }, [props.hitDetails]);
  const cols = useMemo(() => columns(props.product.name), [props.product.name]);
  const colsForMobile = useMemo(() => columnsForMobile(props.product.name), [props.product.name]);

  if (!props.hitDetails.length) return null;

  return (
    <Wrapper>
      <table>
        <thead>
          <tr>
            {<th></th>}
            {Object.values(deviceState.isDesktop ? cols : colsForMobile).map((col, index) => {
              const rowSize = Object.values(deviceState.isDesktop ? cols : colsForMobile).length;
              return (
              deviceState.isMobileOrTablet ? index < 4 ? <th key={index}>{col}</th> : null : <th key={index} style={{width: index === (rowSize-1) ? 'auto' : `18%`}}>{col}</th>
            )})
            }
          </tr>
        </thead>
        <tbody>
          {_.sortBy(Object.keys(groups)).map((group, index) => {
            
            return (
            <React.Fragment key={index}>
              <tr
                key={groups[group][0].id}
                className="group rowData"
                onClick={() =>
                  collapsed.includes(group)
                    ? setCollapsed(c => c.filter(g => g !== group))
                    : setCollapsed(c => [...c, group])
                }
              >
                {<td>
                  {groups[group].length > 1 &&
                    (collapsed.includes(group) ? (
                      <ChevronRight />
                    ) : (
                      <ChevronDown />
                    ))}
                </td>}
                {Object.keys(deviceState.isDesktop ? cols : colsForMobile).map((key, index) => (
                  deviceState.isMobileOrTablet ? index < 4 ? <td key={index}>
                    {formatTableValue(
                      key,
                      groups[group][0][key as keyof HitDetails]
                    )}
                  </td> : null : <td key={index}>
                    {formatTableValue(
                      key,
                      groups[group][0][key as keyof HitDetails]
                    )}
                  </td>
                ))}
              </tr>
              {!collapsed.includes(group) &&
                groups[group].slice(1).map((el) => (
                  <tr className='rowData' key={el.id}>
                    <td></td>
                    {Object.keys(deviceState.isDesktop ? cols : colsForMobile).map((key, index) => (
                      deviceState.isMobileOrTablet ? index < 4 ? <td key={index}>
                        {formatTableValue(key, el[key as keyof HitDetails])}
                      </td> : null : <td key={index}>
                        {formatTableValue(key, el[key as keyof HitDetails])}
                      </td>
                    ))}
                  </tr>
                ))}
            </React.Fragment>
          )})}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default HitDetailsRow;
