import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { AuthProvider } from './Auth';

import Routes from './Routes';

const App: FC = () => (
  <>
    <Helmet>
      <title>DealBinder</title>
    </Helmet>
    <AuthProvider>
      <Router>
        <Routes />
      </Router>
    </AuthProvider>
  </>
);

export default App;
