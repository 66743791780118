import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'styleguide/components/Button';
import { useMatterState } from 'pages/matter/state/matter';
import { useOrderFormState } from 'pages/order/state/state';
import { ORDER_URL } from 'helpers/routes';

const StyledButton = styled(Button)`
  margin-left: auto;
  margin-right: 16px;
  padding: 8px 12px;
`;

const AddToOrder = () => {
  const [
    {
      id,
      data: { role }
    }
  ] = useMatterState();
  const [, { loadState }] = useOrderFormState();
  const history = useHistory();

  const handleClick = React.useCallback(() => {
    loadState({ matterId: id });
    history.push(ORDER_URL);
  }, [id, loadState, history]);

  if (role !== 'owner') return null;

  return (
    <StyledButton primary onClick={handleClick}>
      Add to order
    </StyledButton>
  );
};

export default AddToOrder;
