import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const sendSupportEmailUrl = `${url}/support/email`;

export interface EmailData {
  message: string;
  refersToMatter: 'yes' | 'no';
  matterName?: string;
}

export const sendSupportEmail = async (data: EmailData): Promise<void> => {
  const client = await getAuthorizedApi();
  const refersToMatterBool = data.refersToMatter === 'yes';
  await client.post(sendSupportEmailUrl, {
    ...data,
    refersToMatter: refersToMatterBool
  });
};
