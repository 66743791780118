import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import useOnclickOutside from 'react-cool-onclickoutside';
import Dropdown from 'styleguide/components/Dropdown';
import Button from 'styleguide/components/Button';
import ProductSelect from './ProductSelect';
import { useAllColumnProductSelect } from './searches/useProducts';
import { HeaderSelectComponent } from './TableForm';

const StyledButton = styled(Button)`
  font-size: 13px;
`;

const ProductSelectColumnForm: HeaderSelectComponent = ({ col, title }) => {
  const tooltip = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const productSelectProps = useAllColumnProductSelect(col);
  const name = `columnProducts${col}`;
  const tooltipContentRef = useOnclickOutside(() => {
    if (isOpen) {
      setIsOpen(false);
      ReactTooltip.hide(tooltip?.current);
    }
  });
  return (
    <>
      <div
        data-tip="tooltip"
        data-for={name}
        data-event="noevent"
        ref={tooltip}
      >
        <StyledButton
          type="button"
          variant="inline"
          onClick={() => {
            setIsOpen(true);
            ReactTooltip.show(tooltip?.current);
          }}
          style={{ margin: 0 }}
        >
          Edit searches
        </StyledButton>
      </div>
      <Dropdown
        heading={`Select searches ${title ? `for ${title}` : ''}`}
        submitButtonText="Close"
        onSubmit={(customRef) => {
          customRef.tooltipRef = null;
          setIsOpen(false);
          ReactTooltip.hide(tooltip?.current);
        }}
        id={name}
        contentRef={tooltipContentRef}
        customRef={tooltip}
        place='left'
      >
        <ProductSelect
          name={name}
          selectAllLabel={productSelectProps.products.length <= 1 ? "" : "Select all (Comprehensive)"}
          {...productSelectProps}
          disabled={[]}
        />
      </Dropdown>
    </>
  );
};

export default ProductSelectColumnForm;
