import { auth } from 'firebaseConfig';
import axios from 'axios';

const api = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export const getAuthorizedApi = async () => {
  const token = await auth.currentUser.getIdToken();
  return axios.create({
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${token}`
    }
  });
};

export default api;
