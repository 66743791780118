import { createStore, createHook, StoreActionApi } from 'react-sweet-state';

export type DragState = {
  active: boolean;
  currentCell: {
    row?: number;
    col?: number;
  };
};

type StoreApi = StoreActionApi<DragState>;
type Actions = typeof actions;

const initialState: DragState = {
  active: false,
  currentCell: {
    row: null,
    col: null
  }
};

export const actions = {
  setActive: (active: boolean) => ({ setState, getState }: StoreApi) => {
    setState({ ...getState(), active });
  },
  setCurrentCell: (row: number, col: number) => ({
    setState,
    getState
  }: StoreApi) => {
    setState({ ...getState(), currentCell: { row, col } });
  }
};

const Store = createStore<DragState, Actions>({
  initialState,
  actions,
  name: 'Drags'
});

export const useDragState = createHook(Store);
