import React, { FC } from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

import Logo from 'components/shared/logo/Logo';

const LoadingLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  height: 100vh;
  position: relative;
`;

const StyledSpinner = styled(Spinner)`
  color: ${({ theme }) => theme.colors.primary300} !important;
  height: 50px;
  width: 50px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);

  @media(max-width:768px){
    bottom: 200px;

  }
`;

const LoadingLayout: FC = () => (
  <LoadingLayoutWrapper>
    <StyledSpinner animation="border" variant="primary" />

    <LogoWrapper>
      <Logo width={150} isColored />
    </LogoWrapper>
  </LoadingLayoutWrapper>
);

export default LoadingLayout;
