import React, { FC } from 'react';

import Spacing from 'styleguide/components/Spacing';

import { GrayText, Check, List, ListItem, Dot } from './styled';

interface PasswordStrengthProps {
  password: string;
}

const getHasLowerCase = (str: string): boolean => /(?=.*[a-z])/.test(str);
const getHasUpperCase = (str: string): boolean => /(?=.*[A-Z])/.test(str);
const getHasSpecialCharacter = (str: string): boolean =>
  /(?=.*[^A-Za-z0-9])/.test(str);
const getHasNumber = (str: string): boolean => /(?=.*\d)/.test(str);
const getHasEightCharacters = (str: string): boolean => /(?=.{8,})/.test(str);

const PasswordStrength: FC<PasswordStrengthProps> = ({ password }) => {
  const hasLowerCase = getHasLowerCase(password);
  const hasUpperCase = getHasUpperCase(password);
  const hasSpecialCharacter = getHasSpecialCharacter(password);
  const hasNumber = getHasNumber(password);
  const hasEightCharacters = getHasEightCharacters(password);

  return (
    <div>
      <GrayText size="sm">Password requirements:</GrayText>
      <Spacing direction="top" value={8} />
      <List>
        <ListItem size="sm" isComplete={hasLowerCase}>
          {hasLowerCase ? <Check /> : <Dot />}
          <Spacing direction="left" value={8} />
          At least one lowercase letter
        </ListItem>
        <ListItem size="sm" isComplete={hasUpperCase}>
          {hasUpperCase ? <Check /> : <Dot />}
          <Spacing direction="left" value={8} />
          At least one uppercase letter
        </ListItem>
        <ListItem size="sm" isComplete={hasSpecialCharacter}>
          {hasSpecialCharacter ? <Check /> : <Dot />}
          <Spacing direction="left" value={8} />
          At least one special character
        </ListItem>
        <ListItem size="sm" isComplete={hasNumber}>
          {hasNumber ? <Check /> : <Dot />}
          <Spacing direction="left" value={8} />
          At least one number
        </ListItem>
        <ListItem size="sm" isComplete={hasEightCharacters}>
          {hasEightCharacters ? <Check /> : <Dot />}
          <Spacing direction="left" value={8} />
          At least 8 characters
        </ListItem>
      </List>
    </div>
  );
};

export default PasswordStrength;
