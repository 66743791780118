import api from 'api/apiInstance';

interface ConfirmPostParams {
  password: string;
  confirmPassword: string;
  oobCode: string;
}

export const confirmApi = (path: string) => ({
  path,
  POST: (config: ConfirmPostParams) => api.post(path, config)
});
