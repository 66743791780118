import React, { FC, useCallback } from 'react';
import { isNil } from 'lodash';
import { useHistory } from 'react-router-dom';

import MaterialTable from 'styleguide/components/MaterialTable';
import { currencyFormat } from 'helpers/formatters';
import { getOrderEstimateFormUrl } from 'helpers/routes';
import { ReactComponent as EditIcon } from 'components/icons/IconEdit.svg';
import { ReactComponent as DeleteIcon } from 'components/icons/IconDelete2.svg';
import { Estimate } from 'api/estimates';

import { TableButton, TableButtonWrapper, GrayText, EstimateLink } from './styled';
import { DeleteEstimateModalData } from './';
import Tooltip from 'styleguide/components/Tooltip';
import { useDeviceState } from 'state/deviceState';

interface TableProps {
  setDeleteEstimateData: (data: DeleteEstimateModalData) => void;
  estimates: Estimate[];
  isLoading: boolean;
  totalCount: number;
  currentPage: number;
  onChangePage: (page: number) => Promise<void>;
  onDelete: () => void;
}

const Table: FC<TableProps> = ({
  estimates,
  isLoading,
  totalCount,
  currentPage,
  onChangePage,
  setDeleteEstimateData,
  onDelete
}) => {
  const history = useHistory();
  const [deviceState] = useDeviceState();

  const handleRedirectToOrderForm = useCallback(
    (matterId: string, orderId: string) => {
      history.push(getOrderEstimateFormUrl(matterId, orderId));
    },
    [history]
  );

  return (
    <MaterialTable
      paginationItemLabel="Estimates"
      footer={
        <GrayText size="xs">
          * Estimates do not include taxes or copy costs.
          <br />
          <br />
          Please note that estimates are not guarantees. Cost and turnaround can be affected by variables unique to your order or beyond our control (e.g. copious document results, travel to remote counties, interruptions in government database access, etc.).
        </GrayText>
      }
      totalCount={totalCount}
      currentPage={currentPage - 1}
      style={{ 
      
      }}
      onChangePage={(event, page) => {
        onChangePage(page + 1);
      }}
      materialTableProps={{
        columns: [
          {
            title: 'Matter',
            field: 'matterName',
            cellStyle: { minWidth: '160px' },
            headerStyle: { minWidth: '160px' },
            render: ({ id, matterName, matterId }) => {
              return (
                <EstimateLink onClick={() => {
                  handleRedirectToOrderForm(matterId, id)
                }}>
                  { matterName }
                </EstimateLink>
              );
            }
          },
          {
            title: 'Estimate *',
            field: 'estimate',
            render: ({ estimate }) =>
              isNil(estimate) ? null : `$${currencyFormat(estimate)}`
          },
          {
            title: 'Date',
            field: 'date',
            render: ({ date }) =>
              new Date(date).toLocaleDateString([], {
                dateStyle: 'short'
              } as any)
          },
          {
            title: '',
            field: 'actions',
            render: ({ id,matterName, matterId }) => (
              <TableButtonWrapper>
               {deviceState.isMobile?"": <TableButton
                  primary
                  onClick={() => handleRedirectToOrderForm(matterId, id)}
                >
                  Place Order
                </TableButton>}

                <TableButton
                  onClick={() => handleRedirectToOrderForm(matterId, id)}
                  data-tip
                  data-for={`myestimates.edit.${id}`}
                >
                  <EditIcon />
                </TableButton>
                <TableButton
                  onClick={() =>
                    {onDelete(); setDeleteEstimateData({ id, name: matterName, matterId });}
                  }
                  data-tip
                  data-for={`myestimates.delete.${id}`}
                >
                  <DeleteIcon />
                </TableButton>
                <Tooltip id={`myestimates.edit.${id}`}>
                  Edit draft order
                </Tooltip>
                <Tooltip id={`myestimates.delete.${id}`}>
                  Delete this estimate
                </Tooltip>
              </TableButtonWrapper>
            )
          }
        ],
        isLoading,
        data: estimates
      }}
    />
  );
};

export default Table;
