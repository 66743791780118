export default {
  firstName: `Enter first name`,
  lastName: `Enter last name`,
  email: `Please enter a valid work email address`,
  emailTaken: `This email is already taken. {signIn} or {resetPassword}`,
  emailRejected: `This is not a recognized firm email address. Contact us if you have questions: {emailAddress}`,
  emailSubmitted: `Oops! It looks like this email has already been submitted. We will be in touch ASAP about your registration. Contact us if you have questions: {emailAddress}`,
  emailNotConfirmed: `Oops! It looks like this email has already been submitted, but needs to be confirmed. {link} to confirm your email address.`,
  password: `Must meet password requirements`,
  passwordLogin: `Please enter your password`,
  firmName: `Please tell us the name of your firm`,
  firmAddress: `Please tell us your street address`,
  city: `Please enter a city`,
  state: `Please enter a state`,
  postalCode: `Please enter a valid zip code`,
  phone: `Please enter a valid 10-digit phone number`,
  terms: `Please review and agree to proceed`,
  test: `click next link {link}`,
  login: `Oops! That email or password is incorrect. Try again or {link}.`,
  loginUnrecognized: `Oops!  It looks like this email address is awaiting activation. We will be in touch ASAP about your registration. Contact us if you have questions: {link}`,
  confirmPassword: `Passwords must match`
};
