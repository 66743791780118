import React from 'react';
import styled from 'styled-components';

import BasicAside from 'styleguide/components/BasicAside';
import ProductSelect from '../ProductSelect';
import { useAllProductSelect } from './useProducts';
import Bg from 'components/icons/Group.png';

const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;
const AsideInstruct = styled(BasicAside.Wrapper)`
  padding: 10px;
  background: url(${Bg});
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% 135%;
  span.heading {
    font-size: 16px;
    font-weight: 600;
    border-left: 3px solid #3dbc70;
    padding-left: 6px;
  }
  p.content {
    padding: 8px 15px;
    font-size: 12px;
    color: #84848d;
  }
`;

const SelectSearchTypesAside = () => {
  const productSelectProps = useAllProductSelect();
  return (
    <>
      <AsideHead>
        <BasicAside.SubHeading>SEARCHES: STEP 1 OF 2</BasicAside.SubHeading>
        <BasicAside.Heading>
          Create your <br /> Searches grid
        </BasicAside.Heading>
      </AsideHead>
      <AsideInstruct>
        <BasicAside.Content>
          <ProductSelect
            name="allProducts"
            selectAllLabel={
              productSelectProps.products.length <= 1
                ? ''
                : 'Select all (Comprehensive)'
            }
            {...productSelectProps}
          />
        </BasicAside.Content>
        <BasicAside.Content>
          <span className="heading">Or select searches by:</span>
          <p className="content">
            Jurisdiction (click 'Edit Searches') Cell (hover, click 'Edit')
          </p>
        </BasicAside.Content>
      </AsideInstruct>
    </>
  );
};

export default SelectSearchTypesAside;
