import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MaterialTable from 'styleguide/components/MaterialTable';
import Tag from 'styleguide/components/Tag';
import Tooltip from 'styleguide/components/Tooltip';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import { ReactComponent as IconTooltip } from 'components/icons/IconTooltip.svg';
import { shortDateFormat, etaDateFormat } from 'helpers/formatters';
import { useMatterState } from './state/matter';
import {
  TableAccessory,
  FiltersButton,
  CountBadge
} from './components/common/styled';
import InlineFilters from 'styleguide/components/InlineFilters';
import { optionsToLookup } from './utils';
import Filters from './components/OfacFilters';
import SearchInput from 'styleguide/components/SearchInput';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
import { TableFooter } from 'styleguide/components/MaterialTable/styled';
import { getFilePreviewUrl } from './constants';
import ReviewSelectForm from './components/ReviewSelectForm';
import DealbinderMessage from './components/DealbinderMessage';
import ColumnAttachment from './components/ColumnAttachment';
import { useDeviceState } from 'state/deviceState';
import _ from 'lodash';

// First column in ofac table should be indented on right:
const OfacTableWrapper = styled.div`
  .MuiTableRow-root td:first-child,
  .MuiTableRow-root th:first-child {
    padding-left: 48px;
    width: auto !important;
  }
  .MuiTableCell-root:nth-child(2) {
    width: 200px !important;
  }
  .MuiTableCell-root:nth-child(3) {
    width: 180px !important;
  }
  .MuiPaper-root div:first-child tr {
    height: 58px !important;
  }
  @media (max-width: 768px) {
    .MuiTableRow-root td:first-child,
    .MuiTableRow-root th:first-child {
      padding-left: 5px;
      width: 0px !important;
    }
    .MuiTableCell-root:nth-child(2) {
      width: 0px !important;
    }
    .MuiTableCell-root:nth-child(3) {
      width: 0px !important;
    }
    .MuiTableCell-root:nth-child(4) {
      width: 0px !important;
    }
  }
`;

const GrayText = styled.div`
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  color: inherit;
  font-size: inherit;
`;
const OFACStatus = styled.div`
  position: relative;
  @media (max-width: 768px) {
    top: -4px;
  }
`;

const Ofac = () => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [
    {
      id: matterId,
      view: { ofac },
      tables: {
        ofac: { filters, search }
      },
      tableFilterSidebar
    },
    { clearFilter, setFilter, setSearch, toggleTableFilterSidebar }
  ] = useMatterState();
  const [deviceState] = useDeviceState();

  const statusFilters = React.useMemo(() => {
    const statuses = ofac.orderLineItems.map(oli => oli.status);
    return [
      { id: '', label: 'All' },
      { id: 'In Progress', label: 'In Progress' },
      { id: 'Hit', label: 'Hits' },
      { id: 'Clear', label: 'Clear' }
    ].filter(el => el.id === '' || statuses.includes(el.id));
  }, [ofac.orderLineItems]);

  const countBadge = React.useMemo(() => Object.keys(filters).length, [
    filters
  ]);

  const columns = [
    {
      title: 'Name',
      field: 'diligenceName',
      lookup: optionsToLookup(ofac.filterOptions.name),
      render: ({ diligenceName, id, dealbinderMessage }: any) => (
        <>
          <ColumnAttachment left="-31px">
            <OFACStatus>
              <DealbinderMessage
                id={id}
                message={dealbinderMessage}
                clickable
              />
            </OFACStatus>
          </ColumnAttachment>
          <BasicHeading
            tag={deviceState.isMobile ? 'div' : 'div'}
            size={deviceState.isMobile ? 'xxs' : 'md'}
            style={{ textTransform: 'uppercase' }}
          >
            {diligenceName}
          </BasicHeading>
        </>
      )
    },
    {
      title: 'Status',
      field: 'status',
      lookup: optionsToLookup(ofac.filterOptions.status),
      render: ({ status, id, estimatedTurnaround, ...rowData }: any) =>
        status === 'In Progress' ? (
          <>
            <Tag
              color="orange"
              cursorPointer={true}
              data-tip
              data-for={`status.${id}`}
            >
              {deviceState.isMobile ? 'In Prog' : status}
            </Tag>
            {estimatedTurnaround ? (
              <Tooltip id={`status.${id}`}>
                ETA: {etaDateFormat(estimatedTurnaround)}
              </Tooltip>
            ) : null}
          </>
        ) : (
          <>
            {rowData.source1Date || rowData.source2Date ? (
              <ColumnAttachment left="-27px">
                <IconTooltip
                  data-tip
                  data-event={deviceState.isMobile ? 'click focus' : ''}
                  data-for={`jurisdiction.${id}`}
                  style={{
                    width: '14px',
                    position: 'relative',
                    left: deviceState.isMobile ? '5px' : '0px'
                  }}
                />

                <Tooltip
                  id={`jurisdiction.${id}`}
                  isCapture={deviceState.isMobile}
                >
                  Last updated:
                  {rowData.source1Date && (
                    <p>SDN List: {shortDateFormat(rowData.source1Date)}</p>
                  )}
                  {rowData.source2Date && (
                    <p>Non-SDN List: {shortDateFormat(rowData.source2Date)}</p>
                  )}
                </Tooltip>
              </ColumnAttachment>
            ) : null}

            <Tag color={status === 'Clear' ? 'cyan' : 'red'} isFilled>
              {status}
            </Tag>
          </>
        )
    },
    {
      title: 'File',
      field: 'file',
      lookup: { true: 'true' },
      render: ({ id, file, status }: any) =>
        status === 'Hit' && file ? (
          <Link to={getFilePreviewUrl(matterId, 'ofac', id)}>
            {deviceState.isMobile ? 'View' : 'View file'}
          </Link>
        ) : (
          '-'
        )
    },
    {
      title: 'Review',
      field: 'review',
      render: ({ status, id, file, reviewHistory }: any) =>
        status === 'Hit' && file ? (
          <ReviewSelectForm id={id} reviewHistory={reviewHistory} />
        ) : null
    },
    {
      title: 'reviewStatus',
      field: 'reviewStatus',
      lookup: optionsToLookup(ofac.filterOptions.reviewStatus),
      render: () => '',
      customFilterAndSearch: (term: any, rowData: any) => {
        if (rowData.reviewHistory.length) {
          const reviewHistory = _.sortBy(rowData.reviewHistory) 
          return term.includes(reviewHistory[0].reviewStatus);
        }
        if (!rowData.reviewHistory.length && term[0] === 'Not reviewed yet') {
          return true;
        }
        return false;
      },
      cellStyle: { display: 'none' },
      headerStyle: { display: 'none' }
    }
  ];

  return (
    <>
      <TableAccessory>
        <div className="col-sm-2 col"></div>
        {deviceState.isMobileOrTablet ? (
          ''
        ) : (
          <InlineFilters
            tabs={statusFilters}
            activeTab={filters.status?.length === 1 ? filters.status[0] : ''}
            onChange={(id: string) => {
              clearFilter('ofac');
              if (id !== '') {
                setFilter('ofac', 'status', [id]);
              }
            }}
          />
        )}

        <div style={{ height: '40px' }} className="col-sm-4 col">
          {deviceState.isMobileOrTablet ? (
            ''
          ) : (
            <>
              <FiltersButton
                active={Boolean(Object.keys(filters).length)}
                onClick={() => toggleTableFilterSidebar('ofac')}
                resetOrder
              >
                <FiltersIcon />
                Filters
                {countBadge ? <CountBadge>{countBadge}</CountBadge> : null}
              </FiltersButton>
            </>
          )}
          <SearchInput
            type="text"
            value={search}
            onChange={evt => setSearch('ofac', evt.target.value)}
            placeholder="Search"
          />
        </div>
      </TableAccessory>
      <OfacTableWrapper>
        <MaterialTable
          filters={filters}
          search={search}
          stickyRows
          hover
          materialTableProps={{
            columns: columns,
            data: _.sortBy(ofac.orderLineItems, "jurisdiction.name"),
            options: {
              paging: true,
              filtering: true,
              rowStyle: {}
            },
            components: {
              Pagination: ({ count }: any) => (
                <TableFooter>
                  Showing {count} of {ofac.orderLineItems.length} names
                  {ofac.orderLineItems.some(
                    oli => oli.status !== 'In Progress'
                  ) ? (
                    <>
                      <Spacing direction="top" value="10px" />
                      {ofac.ofacSourceDetail.split('\n').map(text => (
                        <GrayText>{text}&nbsp;</GrayText>
                      ))}
                    </>
                  ) : null}
                </TableFooter>
              )
            }
          }}
        />
      </OfacTableWrapper>
      <Filters
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
      />
      <Filters
        isOpen={tableFilterSidebar.ofac}
        onClose={() => toggleTableFilterSidebar('ofac')}
      />
    </>
  );
};

export default Ofac;
