import React from 'react';

export const ChevronRight = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.02 8.02C10.1138 8.11368 10.1665 8.24077 10.1667 8.37333V8.62666C10.1651 8.75894 10.1126 8.88552 10.02 8.98L6.59333 12.4C6.53074 12.4631 6.44554 12.4986 6.35666 12.4986C6.26779 12.4986 6.18259 12.4631 6.12 12.4L5.64666 11.9267C5.58396 11.8652 5.54862 11.7811 5.54862 11.6933C5.54862 11.6055 5.58396 11.5214 5.64666 11.46L8.61333 8.5L5.64666 5.54C5.58356 5.47741 5.54807 5.39221 5.54807 5.30333C5.54807 5.21445 5.58356 5.12925 5.64666 5.06666L6.12 4.6C6.18259 4.53689 6.26779 4.5014 6.35666 4.5014C6.44554 4.5014 6.53074 4.53689 6.59333 4.6L10.02 8.02Z"
      fill="white"
    />
  </svg>
);

export const ChevronLeft = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.14667 8.48C6.05287 8.38632 6.00012 8.25923 6 8.12667V7.87334C6.00154 7.74106 6.05408 7.61448 6.14667 7.52L9.57333 4.1C9.63592 4.0369 9.72112 4.0014 9.81 4.0014C9.89888 4.0014 9.98408 4.0369 10.0467 4.1L10.52 4.57334C10.5827 4.63478 10.618 4.71888 10.618 4.80667C10.618 4.89446 10.5827 4.97856 10.52 5.04L7.55333 8L10.52 10.96C10.5831 11.0226 10.6186 11.1078 10.6186 11.1967C10.6186 11.2855 10.5831 11.3707 10.52 11.4333L10.0467 11.9C9.98408 11.9631 9.89888 11.9986 9.81 11.9986C9.72112 11.9986 9.63592 11.9631 9.57333 11.9L6.14667 8.48Z"
      fill="white"
    />
  </svg>
);

export default {
  ChevronRight,
  ChevronLeft
};
