import styled, { css } from 'styled-components';

interface LoaderProps {
  size?: number;
  marginLess?: boolean;
  centered?: boolean;
  background?: string;
}

// Source: https://projects.lukehaas.me/css-loaders/
const Loader = styled.span<LoaderProps>`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  text-indent: -99999em;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  margin-right: ${({ marginLess }) => (marginLess ? '0' : '10px')};
  vertical-align: middle;

  ${({ centered }) =>
    centered &&
    css`
      display: block;
      margin-left: auto;
      margin-right: auto;
    `}

  ${({ size = 3 }) => css`
    font-size: ${size}px;
  `}

  &::before,
  &::after {
    border-radius: 50%;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
  }
  &::before {
    width: 6em;
    height: 11.2em;
    background: ${({ theme, background = theme.colorsNewDesign.lightest }) =>
      background};
    border-radius: 11.2em 0 0 11.2em;
    top: -0.3em;
    left: -0.3em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
  }
  &::after {
    width: 6em;
    height: 11.2em;
    background: ${({ theme, background = theme.colorsNewDesign.lightest }) =>
      background};
    border-radius: 0 11.2em 11.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
  }
  @-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export default Loader;
