import { url } from 'api';

import { verifyApi } from './verify';
import { registerApi } from './register';
import { emailApi } from './email';
import { resetPasswordApi } from './resetPassword';
import { resendMagicLink } from './magicLink';
import io from "socket.io-client";

const path = `${url}/auth`;

export const authApi = () => ({
  path,
  verify: () => verifyApi(`${path}/verify`),
  register: () => registerApi(`${path}/register`),
  email: () => emailApi(`${path}/email`),
  resetPassword: () => resetPasswordApi(`${path}/reset-password`),
  resendMagicLink: () => resendMagicLink(`${path}/magic-link/resend`),
  socket: io(url.replace('/v1',''), {
    transports: ["websocket"], reconnection: false,
  })
});
