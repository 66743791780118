import styled, { css } from 'styled-components';

export default styled.button<{ borderDirection?: 'left' | 'right',disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colorsNewDesign.primary};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.regular}; 
  color: white;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  transition: color 100ms ease-in;
  // padding: 20px;
  min-height: 50px;
  min-width: 50px;
  margin: 7px;
  padding-right: 7px;
  
  
  
  ${({ theme, borderDirection }) => css`
    
  `}

  &:disabled {
    background-color: white;
    color: ${({ theme }) => theme.colorsNewDesign.gray300};
    // color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      transition: fill 100ms ease-in;
      fill: white;
      ${({ disabled }) => disabled && css`
        fill: ${({ theme }) => theme.colorsNewDesign.gray300};
        // fill: ${({ theme }) => theme.colorsNewDesign.primary};
      `}
    }
  }

  // svg + * {
  //   margin-left: 8px;
  // }

  // &:hover {
  //   color: ${({ theme }) => theme.colorsNewDesign.primaryHover};

  //   svg path {
  //     fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  //     ${({ disabled }) => disabled && css`
  //       fill: ${({ theme }) => theme.colorsNewDesign.gray800};
  //     `}
  //   }
  // }
  @media (max-width:768px){
    border: none;
    padding: 8px;

  }
`;
