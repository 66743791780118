import React, { FC, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import Button from 'styleguide/components/Button';
import HorizontalLine from 'styleguide/components/HorizontalLine';
import TextInput from 'styleguide/components/TextInput';
import BasicRadioList from 'styleguide/components/BasicRadioList';
import Snackbar from 'styleguide/components/Snackbar';
import Loader from 'styleguide/components/Loader';
import Error from 'styleguide/components/Error';
import { OptionType } from 'styleguide/components/Select';

import { fetchMatters } from 'api/data';
import { sendSupportEmail } from 'api/support';

import SupportModalMatterSelect from './SupportModalMatterSelect';
import {
  SupportModalContent,
  SupportModalFooter,
  SupportModalReferToMatterContainer,
  SupportModalRadioList,
  SupportModalConfirmationWrapper
} from './styled';

interface SupportModal {
  isOpen: boolean;
  onClose: () => void;
}

interface FormValues {
  message: string;
  refersToMatter: 'yes' | 'no';
  matterName?: OptionType;
}

const SupportModal: FC<SupportModal> = ({ isOpen, onClose }) => {
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [matters, setMatters] = useState(null);

  useEffect(() => {
    if (isOpen && !matters) {
      setIsLoading(true);
      fetchMatters().then(({ data }) => {
        setIsLoading(false);
        setMatters(
          data.map(({ matter: { name } }) => ({ value: name, label: name }))
        );
      });
    }
  }, [isOpen, matters]);

  const messageError = 'Please enter a message';

  const validationSchema = yup.object().shape<{ message: string }>({
    message: yup.string().required(messageError)
  });

  useEffect(() => {
    if (isOpen) {
      setIsSent(false);
    }
  }, [isOpen]);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        isSent ? (
          ' '
        ) : (
          <BasicHeading tag="h2" size="xl">
            Have a question?
          </BasicHeading>
        )
      }
    >
      {isSent ? (
        <SupportModalConfirmationWrapper>
          <Snackbar type="success">
            Message sent! We will respond to you ASAP.
            <br />
            <br />A confirmation has been emailed to you.
          </Snackbar>
        </SupportModalConfirmationWrapper>
      ) : (
        <Formik<FormValues>
          initialValues={{ message: '', refersToMatter: 'no' }}
          validationSchema={validationSchema}
          onSubmit={values => {
            sendSupportEmail({
              ...values,
              matterName: values.matterName?.value
            }).then(() => setIsSent(true));
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            isSubmitting,
            touched
          }) => (
            <Form onSubmit={handleSubmit}>
              <SupportModalContent>
                <BasicHeading tag="h3" size="md">
                  We will respond to you quickly via email or phone. You may also email us at <a href='mailto:info@dealbinder.com'>info@dealbinder.com</a>.
                </BasicHeading>
                <Spacing direction="top" value={20} />
                <HorizontalLine width="100%" />
                <Spacing direction="top" value={23} />
                <BasicHeading tag="h4" size="regular">
                  Message
                </BasicHeading>
                <Spacing direction="top" value={8} />
                <TextInput
                  hasNoMargin
                  as="textarea"
                  rows={3}
                  name="message"
                  value={values.message}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  invalid={Boolean(touched.message && errors.message)}
                />
                {touched.message && errors.message && (
                  <>
                    <Spacing direction="top" value={8} />
                    <Error>{errors.message}</Error>
                  </>
                )}
                <Spacing direction="top" value={20} />
                {isLoading && <Loader marginLess />}
                {matters && matters.length > 0 && (
                  <>
                    <BasicHeading tag="h4" size="regular">
                      Does this refer to an existing matter?
                    </BasicHeading>
                    <Spacing direction="top" value={16} />
                    <SupportModalReferToMatterContainer>
                      <SupportModalRadioList>
                        <BasicRadioList.Item
                          name="refersToMatter"
                          value="yes"
                          checked={values.refersToMatter === 'yes'}
                          onChange={handleChange}
                        >
                          Yes
                        </BasicRadioList.Item>
                        <BasicRadioList.Item
                          name="refersToMatter"
                          value="no"
                          checked={values.refersToMatter === 'no'}
                          onChange={handleChange}
                        >
                          No
                        </BasicRadioList.Item>
                      </SupportModalRadioList>
                      {values.refersToMatter === 'yes' && (
                        <>
                          <Spacing direction="top" value={16} />
                          <BasicHeading tag="h4" size="regular">
                            Which matter?
                          </BasicHeading>
                          <SupportModalMatterSelect
                            matters={matters}
                            isLoading={isLoading}
                            name="matterName"
                          />
                        </>
                      )}
                    </SupportModalReferToMatterContainer>
                  </>
                )}
              </SupportModalContent>
              <SupportModalFooter>
                {isSubmitting && <Loader />}
                <Button medium resetOrder onClick={onClose}>
                  Cancel
                </Button>
                <Spacing direction="left" value={8} />
                <Button medium primary resetOrder disabled={isSubmitting}>
                  Send
                </Button>
              </SupportModalFooter>
            </Form>
          )}
        </Formik>
      )}
    </BasicModal>
  );
};

export default SupportModal;
