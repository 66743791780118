export default {
  title: `Thank you!`,
  helper: 'Pending request',
  errorContent1: `That link has expired.`,
  errorContent2: `{loginLink} to verify your account.`,
  contact: `We will be in touch ASAP about your registration request. Please {contactLink} with any questions.`,
  contactUsLink: 'contact us',
  redirected: `You will be redirected to your dashboard in a moment. You can start ordering right away.`,
  logIn: 'Log in',
  verifyButton: 'Verify your account',
  dashboardLink: 'Go to dashboard'
};
