import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ORDER_URL } from 'helpers/routes';
import { fetchProducts } from 'api/data';
import { useProductState } from './state/productsState';
import useOrderNavigationHandler from './state/useOrderNavigationHandler';
import { STEPS } from './consts';

const Order: FC = () => {
  const [state, actions] = useProductState();
  useOrderNavigationHandler();

  React.useEffect(() => {
    localStorage.removeItem('searches-form');
    const load = async () => {
      if (!state.products.length) {
        const response = await fetchProducts();
        actions.handleResponse(response);
      }
    };
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>Order - DealBinder</title>
      </Helmet>
      <Switch>
        {STEPS.map(step => (
          <Route
            exact
            path={step.path}
            key={step.name}
            component={step.component}
          />
        ))}
        <Route render={() => <Redirect to={ORDER_URL} />} />
      </Switch>
    </>
  );
};

export default Order;
