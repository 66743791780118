import React, { FC } from 'react';

export interface LoaderProps {
  className?: string;
}

const Loader: FC<LoaderProps> = ({ className }) => (
  <img className={className} src="/loader.gif" alt="loader" />
);

export default Loader;
