import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Radio from 'styleguide/components/Radio';
import Loader from 'styleguide/components/Loader';
import Spacing from 'styleguide/components/Spacing';
import Snackbar from 'styleguide/components/Snackbar';
import Button from 'styleguide/components/Button';

const Wrapper = styled.div`
  padding: 24px;
  width: 600px;
  max-width: 100vw;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  > * {
    flex: 1;
  }
`;
const Column = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
    font-weight: 400;
  }
`;

const SnackbarLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

interface DownloadReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => Promise<void>;
  onDecline: () => Promise<void>;
  setDocumentType: (type: string) => void;
  documentType: string;
  multipleFiles: boolean;
}

const DownloadReportModal: FC<DownloadReportModalProps> = ({
  isOpen,
  onClose,
  onAccept,
  onDecline,
  documentType,
  setDocumentType,
  multipleFiles
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [isYes, setIsYes] = useState(true);
  useEffect(() => {
    if (isOpen) {
      setIsDownloaded(false);
      setIsLoading(false);
      setIsYes(true);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!documentType) {
      setDocumentType('pdf');
    }
  }, [setDocumentType, documentType]);
  const onDownloadClick = () => {
    if (isYes) {
      setIsLoading(true);
      onAccept()
        .then(() => {
          setIsLoading(false);
          setIsDownloaded(true);
        })
        .catch(() => setIsLoading(false));
    } else {
      setIsLoading(true);
      onDecline()
        .then(() => {
          setIsLoading(false);
          setIsDownloaded(true);
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <BasicHeading tag="h2" size="xl">
          Get Report
        </BasicHeading>
      }
    >
      <Wrapper>
        {isDownloaded ? (
          <>
            <Spacing direction="top" value={16} />
            <Snackbar type="success">
              Download complete.{' '}
              <SnackbarLink onClick={onClose}>Return to matter</SnackbarLink>
            </Snackbar>
            <Spacing direction="top" value={16} />
          </>
        ) : (
          <Column>
            <Row>
              <BasicText size="md" weight="semiBold">
                Report type:
              </BasicText>
              <Column>
                <div>
                  <Radio
                    id="pdf"
                    showLabel
                    defaultChecked
                    color="primary"
                    name="docType"
                    onChange={() => setDocumentType('pdf')}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />{' '}
                  <label htmlFor="pdf"> PDF (Official Report)</label>
                </div>
                <div>
                  <Spacing direction="left" value={15} />
                  <Radio
                    id="doc"
                    showLabel
                    color="primary"
                    name="docType"
                    onChange={() => setDocumentType('docx')}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />{' '}
                  <label htmlFor="doc">
                    {' '}
                    Word (Hits Details only, editable)
                  </label>
                </div>
                <div>
                  <Radio
                    id="xlsx"
                    showLabel
                    color="primary"
                    name="docType"
                    onChange={() => setDocumentType('xlsx')}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />{' '}
                  <label htmlFor="xlsx">Excel</label>
                </div>
              </Column>
            </Row>
            <Spacing direction="top" value={10} />
            {multipleFiles && (
              <Row>
                <BasicText size="md" weight="semiBold">
                  Download with files?
                </BasicText>
                <Column>
                  <div>
                    <Radio
                      id="withFile"
                      showLabel
                      defaultChecked
                      color="primary"
                      name="file"
                      onChange={() => setIsYes(true)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />{' '}
                    <label htmlFor="withFile">Yes</label>
                  </div>
                  <div>
                    <Radio
                      id="withoutFile"
                      showLabel
                      color="primary"
                      name="file"
                      onChange={() => setIsYes(false)}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />{' '}
                    <label htmlFor="withoutFile">No</label>
                  </div>
                </Column>
              </Row>
            )}
            <Spacing direction="top" value={10} />
            <Row>
              <div className="text-center">
                <Button
                  primary
                  medium
                  onClick={() => onDownloadClick()}
                  className="m-auto"
                  disabled={isLoading}
                >
                  {(isLoading && (
                    <>
                     <Spacing direction="left" value={24} />
                      <Loader marginLess size={2} background="#dddce7" />
                     <Spacing direction="right" value={24} />

                    </>
                  )) ||
                    'Download'}
                </Button>
              </div>
            </Row>
          </Column>
        )}
      </Wrapper>
    </BasicModal>
  );
};

export default DownloadReportModal;
