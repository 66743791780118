import { theme } from 'theme/theme';

export default {
  bold: {
    fontSize: theme.fontSizes.md2,
    fontWeight: theme.fontWeights.semiBold
  },
  gray: {
    color: theme.colorsNewDesign.gray400
  },
  blue : {
    color: theme.colorsNewDesign.persianBlueLight
  },
  green: {
    color: theme.colorsNewDesign.cyan110
  },
  red: {
    color: theme.colorsNewDesign.error
  },
  withoutBorder: {
    boxShadow: 'none'
  }
};
