import React, { FC, useCallback, useMemo } from 'react';

import { FileData } from 'api/matter';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import { useDeviceState } from 'state/deviceState';
import Tooltip from 'styleguide/components/Tooltip';
import {
  TableWrapper,
  FullHeaderRow,
  HeaderCellContent,
  CellContent,
  CheckboxCellContent,
  HeaderCheckboxCellContent,
  FullRow,
  Checkbox
} from './styled';
import sortBy from 'lodash/sortBy';
import { Icon } from 'styleguide/components/ReviewSelect';

interface FullTableProps {
  list: FileData[];
  selectFiles: (ids: string[]) => void;
  unselectFiles: (ids: string[]) => void;
  selectedFiles: string[];
  header: string;
  role?: string;
}

const colors: any = {
  'Not reviewed yet': 'lightBlue',
  'Under review': 'orange',
  'Requires follow up': 'red',
  'Review complete': 'cyan'
};

const FullTable: FC<FullTableProps> = ({
  list,
  selectedFiles,
  selectFiles,
  unselectFiles,
  header,
  role
}) => {
  const [state] = useDeviceState();

  const isAllChecked = useMemo(
    () => list.every(row => selectedFiles.includes(row.id)),
    [list, selectedFiles]
  );

  const handleAllCheckboxClick = useCallback(() => {
    const allIds = list.map(row => row.id);
    if (isAllChecked) {
      unselectFiles(allIds);
    } else {
      selectFiles(allIds);
    }
  }, [list, selectFiles, unselectFiles, isAllChecked]);

  const handleCheckboxClick = useCallback(
    (id: string, isChecked: boolean) => {
      if (isChecked) {
        unselectFiles([id]);
      } else {
        selectFiles([id]);
      }
    },
    [selectFiles, unselectFiles]
  );

  const sortedList = useMemo(() => {
    return sortBy(list, ["name", "jurisdictionShort", "productName"])
  }, [list]);

  return (
    <TableWrapper>
      <FullHeaderRow onClick={handleAllCheckboxClick}>
        <HeaderCheckboxCellContent>
          <Checkbox showLabel={false} checked={isAllChecked} />
        </HeaderCheckboxCellContent>
        <HeaderCellContent>
          <BasicHeading tag="h4" size="md">
            {header}
          </BasicHeading>
        </HeaderCellContent>
        <HeaderCellContent />
        <HeaderCellContent />
        {
          state.isDesktop && ["owner", "reviewer"].includes(role) && <HeaderCellContent />
        }
      </FullHeaderRow>
      {sortedList.map(row => {
        const isChecked = selectedFiles.includes(row.id);
        const isRole = state.isDesktop && ["owner", "reviewer"].includes(role);
        return (
          <FullRow
            isRole={isRole}
            onClick={() => handleCheckboxClick(row.id, isChecked)}
            isSelected={isChecked}
            key={row.id}
          >
            <CheckboxCellContent>
              <Checkbox showLabel={false} checked={isChecked} />
            </CheckboxCellContent>
            <CellContent>
              <BasicText size="sm">{row.name}</BasicText>
            </CellContent>
            <CellContent>
              <BasicText size="sm">{state.isDesktop ? row.productName : row.productNameMobile}</BasicText>
            </CellContent>
            <CellContent>
              <BasicText size="sm">{row.jurisdictionShort}</BasicText>
            </CellContent>
            {
              state.isDesktop && ["owner", "reviewer"].includes(role) && (
                <CellContent>
                  <Tooltip/>
                  <BasicText size="sm"><Icon data-tip={row.reviewStatus} color={colors[row.reviewStatus]} /></BasicText>
                </CellContent>
              )
            }
          </FullRow>
        );
      })}
    </TableWrapper>
  );
};

export default FullTable;
