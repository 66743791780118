import React, { FC, useEffect, useState, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Spacing from 'styleguide/components/Spacing';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import { ReactComponent as MatterIcon } from 'components/icons/IconMatterFilled.svg';
import { ReactComponent as EstimatesIcon } from 'components/icons/IconEstimatesFilled.svg';
import { ReactComponent as InvoiceIcon } from 'components/icons/IconBookmark.svg';
import { useDashboardHomeState } from '../../state/home';
import Summary from './Summary';
// import List from './List';
import { Wrapper } from './styled';
import { useDeviceState } from 'state/deviceState';
import { authApi } from 'api';
import Loader from './Loader';



const List = React.lazy(() => import('./List'));
const Home: FC = () => {
  const [state, actions] = useDashboardHomeState();
  const [isLoading, setIsLoading] = useState(false);
  const [deviceState] = useDeviceState();
  const { socket } = authApi();

  useEffect(() => {
    setIsLoading(true);
    actions.fetchData().then(() => setIsLoading(false));
  }, [actions]);

  useEffect(() => {
    // console.log('liveDashboard|socket', socket);
    
    socket.on('liveDashboard', (data: any) => {
      // console.log('liveDashboard', socket, data);
      actions.fetchLiveData(data);
    });
  }, [])
  
  return (
    <>
      <Helmet>
        <title>Dashboard - DealBinder</title>
      </Helmet>
      <Wrapper className="container-fluid">
        <div>
          {deviceState.isMobileOrTablet ? (
            <Spacing direction="top" value={0} />
          ) : (
            <BasicSubHeading size="xss" isUppercase>
              Welcome to dealbinder
            </BasicSubHeading>
          )}
          <Spacing
            direction="top"
            value={deviceState.isMobileOrTablet ? 15 : 24}
          />
        </div>
        <div className="row">
          <div
            className={
              deviceState.isMobileOrTablet
                ? 'col-lg-4 d-flex flex-column order-3'
                : 'col-lg-4 d-flex flex-column'
            }
          >
            <Summary
              icon={<EstimatesIcon />}
              subheading="saved"
              heading="Estimates"
              count={state.data.estimates.count}
            />
            <Spacing direction="top" value={17} />
            <Suspense fallback={<Loader/>}>
              <List
                isLoading={isLoading}
                type="estimates"
                items={state.data.estimates.list}
              />
            </Suspense>
            {deviceState.isMobileOrTablet && (
              <Spacing direction="top" value={17} />
            )}
          </div>
          <div
            className={
              deviceState.isMobileOrTablet
                ? 'col-lg-4 d-flex flex-column order-1'
                : 'col-lg-4 d-flex flex-column'
            }
          >
            <Summary
              isLight
              icon={<MatterIcon />}
              subheading="in progress"
              heading="Matters"
              count={state.data.orders.count}
            />
            <Spacing direction="top" value={17} />
            <Suspense fallback={<Loader/>}>
              <List
                isLoading={isLoading}
                type="orders"
                items={state.data.orders.list}
              />
            </Suspense>
            {deviceState.isMobileOrTablet && (
              <Spacing direction="top" value={17} />
            )}
          </div>
          <div
            className={
              deviceState.isMobileOrTablet
                ? 'col-lg-4 d-flex flex-column order-2'
                : 'col-lg-4 d-flex flex-column'
            }
          >
            <Summary
              icon={<InvoiceIcon />}
              subheading="outstanding"
              heading="Invoices"
              count={state.data.outstandingMatters.count}
            />
            <Spacing direction="top" value={17} />
            <Suspense fallback={<Loader/>}>
              <List
                isLoading={isLoading}
                type="outstandingMatters"
                items={state.data.outstandingMatters.list}
              />
            </Suspense>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default Home;
