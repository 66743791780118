import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import CenteredLayout from 'styleguide/components/CenteredLayout';
import BasicSelectButtons from 'styleguide/components/BasicSelectButtons';
import { ReactComponent as IconEstimate } from 'components/icons/IconEstimate.svg';
import { ReactComponent as IconOrder } from 'components/icons/IconOrder.svg';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import BasicList from 'styleguide/components/BasicList';
import { ChevronRight } from 'styleguide/components/Icon';
import Button, { RightButtonIcon } from 'styleguide/components/Button';
import ExitButton from 'styleguide/components/ExitButton';
import Spacing from 'styleguide/components/Spacing';
import Error from 'styleguide/components/Error';
import FadedText from 'styleguide/components/FadedText';
import DeleteModal from './components/DeleteModal';
import ExitModal from './components/ExitModal';
import { OrderFormStateSubscriber } from './state/state';

const orderTypes = ['order', 'estimate'];

type OrderType = typeof orderTypes[number];

const StyledCenteredLayout = styled(CenteredLayout)`
  padding-top: 3.5rem;
`;
export interface StartFormValues {
  orderType: OrderType;
}

export const StartSchema = Yup.object().shape<StartFormValues>({
  orderType: Yup.string()
    .required('Please select an order type')
    .oneOf(['estimate', 'order'], 'Please select a valid order type')
});

const Start: FC = () => {
  const location = useLocation();

  const urlInitialOrderType = new URLSearchParams(location.search).get(
    'initialOrderType'
  );

  const initialOrderType = orderTypes.includes(urlInitialOrderType)
    ? urlInitialOrderType
    : 'order';

  return (
    <OrderFormStateSubscriber>
      {(_, { submitStep, setStart }) => (
        <StyledCenteredLayout
          anchor={
            <ExitModal
              trigger={open => <ExitButton type="button" onClick={open} />}
            />
          }
        >
          <div>
            <BasicSubHeading size="regular" isSlim isCentered>
              Let's start
            </BasicSubHeading>

            <BasicHeading tag="h1" size="xxxl" isCentered>
              What would you like to do?
            </BasicHeading>
            <Spacing direction="top" value={40} />

            <Formik<StartFormValues>
              validationSchema={StartSchema}
              initialValues={{ orderType: initialOrderType }}
              onSubmit={values => {
                setStart(values);
                submitStep(values);
              }}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <BasicSelectButtons.Wrapper>
                    <BasicSelectButtons.Button
                      name="orderType"
                      value="estimate"
                      type="radio"
                      icon={<IconEstimate />}
                      isActive={values.orderType === 'estimate'}
                      onChange={({ value }) =>
                        setFieldValue('orderType', value)
                      }
                      label="Get an estimate"
                    />

                    <BasicSelectButtons.Button
                      name="orderType"
                      value="order"
                      type="radio"
                      icon={<IconOrder />}
                      isActive={values.orderType === 'order'}
                      onChange={({ value }) =>
                        setFieldValue('orderType', value)
                      }
                      label="Place an order"
                    />
                  </BasicSelectButtons.Wrapper>

                  {errors.orderType && (
                    <>
                      <Spacing direction="top" value={10} />
                      <Error isCentered>{errors.orderType}</Error>
                    </>
                  )}

                  <Spacing direction="top" value={40} />

                  <BasicHeading tag="h5" size="md">
                    Tips
                  </BasicHeading>

                  <Spacing direction="top" value={11} />

                  <BasicList.Wrapper>
                    <BasicList.Item>
                      <FadedText>Estimates are always provided</FadedText>
                    </BasicList.Item>
                    <BasicList.Item>
                      <FadedText>
                        Orders can be edited before submission
                      </FadedText>
                    </BasicList.Item>
                    <BasicList.Item>
                      <FadedText>
                        Estimates can be saved and converted to orders later
                      </FadedText>
                    </BasicList.Item>
                  </BasicList.Wrapper>

                  <Spacing direction="top" value={40} />

                  <Button width="200px" primary isCentered>
                    Next
                    <RightButtonIcon>
                      <ChevronRight />
                    </RightButtonIcon>
                  </Button>
                </Form>
              )}
            </Formik>
            <DeleteModal />
          </div>
        </StyledCenteredLayout>
      )}
    </OrderFormStateSubscriber>
  );
};

export default Start;
