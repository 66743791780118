import React, { useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import BasicModal from 'styleguide/components/BasicModal';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import Center from 'styleguide/components/Center';
import { useOrderFormState } from '../state/state';
import { DASHBOARD_URL } from 'helpers/routes';


const BlackText = styled.span`
  color: ${({ theme }) => theme.colorsNewDesign.black}
  line-height: 1.7;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.md};
`;

const ModalFooter = styled.div`
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 8px 16px;
`;
interface ExitModalProps {
  trigger: (toggle: () => void) => React.ReactNode;
}

const ExitModal: FC<ExitModalProps> = ({ trigger }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [, { exit }] = useOrderFormState();

  return (
    <>
      {trigger(() => setOpen(true))}
      <BasicModal
        isOpen={open}
        onClose={() => setOpen(false)}
        title={
          <BasicHeading tag="h2" size="l">
            Do you want to close this form?
          </BasicHeading>
        }
      >
        <div style={{ width: '400px', padding: '30px' }}>
          <Center>
            {/* <BasicHeading tag="div" size="xl">
              Do you want to close this form?
            </BasicHeading> */}
            <div>
              <BlackText>Your progress will be lost.</BlackText>
            </div>
            <div>
              <BlackText>
                Continue to the end of the form to save a draft for later.
              </BlackText>
            </div>
          </Center>
        </div>
        <ModalFooter>
          <div style={{display: "flex"}}>
          <Button
            isCentered
            type="button"
            onClick={() => {
              exit();
              history.push(DASHBOARD_URL);
            }}
            danger
            variant="outlined"
          >
            Close without saving
                </Button>
          <Button
            isCentered
            primary
            type="button"
            onClick={() => {
              setOpen(false);
            }}
            className="ml-3"
          >
            Back to form
                </Button>
          </div>
        </ModalFooter>
      </BasicModal>
    </>
  );
};

export default ExitModal;
