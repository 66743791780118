import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

export interface StatusProps {
  className?: string;
  isSuccess: boolean;
  successText: ReactNode;
  failText: ReactNode;
}

const StyledStatus = styled.span<Pick<StatusProps, 'isSuccess'>>`
  text-transform: uppercase;
  color: ${({ isSuccess, theme }) =>
    isSuccess ? theme.colors.success : theme.colors.error};
`;

const Status: FC<StatusProps> = ({
  className,
  successText,
  failText,
  isSuccess = false
}) => (
  <StyledStatus className={className} isSuccess={isSuccess}>
    {isSuccess ? successText : failText}
  </StyledStatus>
);

export default Status;
