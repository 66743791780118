import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { getParamFromUrl } from 'helpers/helpers';
import { authApi } from 'api';
import { auth } from 'firebaseConfig';
import { noSpecialCharacters } from 'helpers/formatters';
import { DASHBOARD_URL } from 'helpers/routes';

import {
  // POSTAL_CODE_VALIDATION,
  PHONE_NUMBER_VALIDATION
} from 'helpers/validation';

import RegistrationSecondStepPresentational from './RegistrationSecondStepPresentational';
import { RegistrationFormValues } from './RegistrationRightColumn';
import { STATES } from '../contants';

export interface RegistrationSecondStepProps {
  initialValues: RegistrationFormValues;
}

type RegistrationSecondStepFormValues = Pick<
  RegistrationFormValues,
  | 'firmName'
  // | 'firmAddress'
  // | 'city'
  // | 'state'
  // | 'postalCode'
  | 'phone'
  | 'hasAcceptedTerms'
> & {
  hasAcceptedTerms?: true;
};

export interface RegistrationSecondStepRenderProps {
  onSubmit: (values: RegistrationFormValues) => void;
  validationSchema: yup.ObjectSchema<
    RegistrationSecondStepFormValues | undefined
  >;
  initialValues: RegistrationFormValues;
  states: string[];
  verifyCallback: (token: string) => void;
  isLoading: boolean;
  isError: boolean;
}

const RegistrationSecondStep: FC<RegistrationSecondStepProps> = ({
  initialValues
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const firmNameError = formatMessage({
    id: `errors.firmName`
  });
  // const firmAddressError = formatMessage({
  //   id: `errors.firmAddress`
  // });
  // const cityError = formatMessage({
  //   id: `errors.city`
  // });
  // const stateError = formatMessage({
  //   id: `errors.state`
  // });
  // const postalCodeError = formatMessage({
  //   id: `errors.postalCode`
  // });
  const phoneError = formatMessage({
    id: `errors.phone`
  });
  const termsError = formatMessage({
    id: `errors.terms`
  });

  const validationSchema = yup
    .object()
    .shape<RegistrationSecondStepFormValues>({
      firmName: yup
        .string()
        .required(firmNameError)
        .min(5, firmNameError)
        .max(256, firmNameError),
      // firmAddress: yup
      //   .string()
      //   .required(firmAddressError)
      //   .min(5, firmAddressError)
      //   .max(256, firmNameError),
      // city: yup
      //   .string()
      //   .required(cityError)
      //   .min(2, cityError),
      // state: yup
      //   .string()
      //   .required(stateError)
      //   .oneOf(STATES, stateError),
      // postalCode: yup
      //   .string()
      //   .required(postalCodeError)
      //   .matches(POSTAL_CODE_VALIDATION, postalCodeError),
      phone: yup
        .string()
        .required(phoneError)
        .matches(PHONE_NUMBER_VALIDATION, phoneError),
      hasAcceptedTerms: yup.boolean().oneOf([true], termsError)
        .nullable()
    });

  let gRecaptchaResponse = '';

  const verifyCallback = (recaptchaToken: string) => {
    gRecaptchaResponse = recaptchaToken;
  };

  const onSubmit = async (values: RegistrationFormValues) => {
    try {
      setIsLoading(true);
      setIsError(false);
      const formattedPhone = values.phone.replace(noSpecialCharacters, '');
      // const formattedPostalCode = values.postalCode.replace(
      //   noSpecialCharacters,
      //   ''
      // );

      const continueUrl = getParamFromUrl('continueUrl') || DASHBOARD_URL;

      await authApi()
        .register()
        .POST({
          ...values,
          gRecaptchaResponse,
          phone: formattedPhone,
          // postalCode: formattedPostalCode,
          redirectUrl: `${window.location.origin}${continueUrl}`
        });
      setIsLoading(false);

      await auth.signInWithEmailAndPassword(values.email, values.password);
      history.push(continueUrl);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  };

  return (
    <RegistrationSecondStepPresentational
      validationSchema={validationSchema}
      initialValues={initialValues}
      states={STATES}
      onSubmit={onSubmit}
      verifyCallback={verifyCallback}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default RegistrationSecondStep;
