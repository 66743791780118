import api from 'api/apiInstance';

interface VerifyPostParams {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  gRecaptchaResponse: string;
}

export const verifyApi = (path: string) => ({
  path,
  POST: (config: VerifyPostParams): any => api.post(path, config)
});
