import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colorsNewDesign.lightBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  
`;
