import React, { useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import BasicPredictText, {
  OptionType
} from 'styleguide/components/BasicPredictText';
import {
  Response,
  OptionsList,
  UseAsyncPaginateParams
} from 'react-select-async-paginate';

const companyToOption = (company: string) => ({
  label: company,
  value: company
});

const optionsReducer = (
  prev: OptionsList<OptionType>,
  loaded: OptionsList<OptionType>
): OptionsList<OptionType> => loaded;

export type SelectComponent = React.FC<
  {
    name: string;
  } & Pick<
    UseAsyncPaginateParams<OptionType, any>,
    'menuIsOpen' | 'onMenuOpen' | 'onMenuClose'
  >
>;

const state = [
  'Abrams Fensterman',
  'Adams & Reese',
  'Akerman LLP',
  'Akin Gump',
  'Allen Matkins',
  'Alston & Bird',
  'Archer Attorneys at Law',
  'ArentFox Schiff',
  'Armstrong Teasdale',
  'Arnall Golden',
  'Arnold & Porter',
  'Atkinson Andelson',
  'Axinn Veltrop',
  'Babst Calland',
  'Baker Botts',
  'Baker Donelson',
  'BakerHostetler',
  'Baker McKenzie',
  'Balch & Bingham',
  'Ballard Spahr',
  'Banner Witcoff',
  'Barack Ferrazzano',
  'Barclay Damon',
  'Barley Snyder',
  'Barnes & Thornburg',
  'Bass Berry',
  'Becker & Poliakoff',
  'Benesch',
  'Bernstein Shur',
  'Berry Appleman',
  'Best Best & Krieger',
  'Beveridge & Diamond',
  'Blank Rome',
  'Bodman PLC',
  'Boies Schiller',
  'Bond Schoeneck',
  'Bowles Rice',
  'Bowman & Brooke',
  'Bracewell LLP',
  'Bradley Arant',
  'Bremer Whyte',
  'Bressler Amery',
  'Bricker & Eckler',
  'Brown & James',
  'Brown Rudnick',
  'Brownstein Hyatt',
  'Bryan Cave Leighton Paisner',
  'Buchalter APC',
  'Buchanan Ingersoll',
  'Buckley LLP',
  'Burke Williams',
  'Burns & Levinson',
  'Burns White',
  'Burr & Forman',
  'Butler Snow LLP',
  'Butler Weihmuller',
  'Butzel Long',
  'Cadwalader Wickersham',
  'Cahill Gordon',
  'Calfee Halter',
  'Carlton Fields',
  'Carr Allison',
  'Chamberlain Hrdlicka',
  'Chapman & Cutler',
  'Chartwell Law',
  'Chiesa Shahinian',
  'Choate Hall',
  'Cipriani & Werner',
  'Clark Hill',
  'Clausen Miller',
  'Cleary Gottlieb',
  'Coblentz Patch',
  'Cohen Milstein',
  'Cole Schotz',
  'Cole Scott',
  'Connell Foley',
  'Conner & Winters',
  'Conroy Simberg',
  'Constangy Brooks',
  'Cooley LLP',
  'Cordell & Cordell',
  'Covington & Burling',
  'Cox Castle',
  "Cozen O'Connor",
  'Cravath Swaine',
  'Crowe & Dunlevy',
  'Crowell & Moring',
  'Crowley Fleck',
  'Cullen and Dykman',
  'Davis+Gilbert LLP',
  'Davis Graham',
  'Davis Polk',
  'Davis Wright Tremaine',
  'Day Pitney',
  'Debevoise & Plimpton',
  'Dechert',
  'Dentons',
  'DeWitt LLP',
  'Dickie McCamey',
  'Dickinson Wright',
  'Dinsmore & Shohl',
  'DLA Piper',
  'Dorsey & Whitney',
  'Drew Eckl',
  'Duane Morris LLP',
  'Dykema',
  'Eckert Seamans',
  'Epstein Becker Green',
  'Eversheds Sutherland',
  'Faegre Drinker',
  'Farella Braun',
  'Fennemore Craig',
  'Fenwick & West',
  'Finnegan',
  'FisherBroyles',
  'Fisher Phillips',
  'Fish & Richardson',
  'Foley Hoag',
  'Foley & Lardner',
  'Foley & Mansfield',
  'FordHarrison',
  'Foster Garvey',
  'Fox Rothschild',
  'Fragomen Del Rey',
  'Fredrikson & Byron',
  'Freeborn & Peters',
  'Freeman Mathis',
  'Fried Frank',
  'Frost Brown',
  'Galloway Johnson',
  'Gibbons PC',
  'Gibson Dunn',
  'Gilbert LLP',
  'Godfrey & Kahn',
  'Goldberg Segalla',
  'Goodwin Procter',
  'Gordon & Rees',
  'Goulston & Storrs',
  'Gray Reed',
  'GrayRobinson',
  'Greenberg Traurig',
  'Greensfelder Hemker',
  'Greenspoon Marder',
  'Gunderson Dettmer',
  'Gunster Yoakley',
  'Hahn Loeser',
  'Hall Booth',
  'Hall Estill',
  'Hall Render',
  'Hanna Brophy',
  'Hanson Bridgett',
  'Harris Beach',
  'Harter Secrest',
  'Hawkins Parnell',
  'Haynes & Boone',
  'Haynsworth Sinkler',
  'HeplerBroom LLC',
  'Herrick Feinstein',
  'Hill Ward Henderson',
  'Hinckley Allen',
  'Hinshaw & Culbertson',
  'Hodgson Russ',
  'Hogan Lovells',
  'Holland & Hart',
  'Holland & Knight',
  'Honigman LLP',
  'Howard & Howard',
  'Hughes Hubbard',
  'Hunton Andrews',
  'Husch Blackwell',
  'Ice Miller',
  'Jackson Kelly PLLC',
  'Jackson Walker LLP',
  'Jaffe Raitt',
  'Jeffer Mangels',
  'Jenner & Block',
  'Jones Day',
  'Jones Walker',
  'Kasowitz Benson',
  'Katten Muchin',
  'Kaufman Borgeest',
  'Kaufman Dolowich',
  'Kean Miller',
  'Keating Muething',
  'Keker Van Nest & Peters',
  'Kelley Drye',
  'Kelley Kronenberg',
  'Kellogg Hansen',
  'Kelly Hart',
  'Kilpatrick Townsend',
  'King & Spalding',
  'Kirkland & Ellis',
  'Kirton McConkie',
  'Kitch Drutchas',
  'K&L Gates',
  'Knobbe Martens',
  'Kramer Levin',
  'Kubicki Draper',
  'Kutak Rock',
  'Lane Powell',
  'Latham & Watkins',
  'Lathrop GPM',
  'Laughlin Falbo',
  'Lewis Brisbois',
  'Lewis Rice',
  'Lewis Roca',
  'Lewis Thomason',
  'Linebarger Goggan',
  'Lippes Mathias',
  'Liskow & Lewis',
  'Litchfield Cavo',
  'Littler Mendelson',
  'Locke Lord',
  'Loeb & Loeb',
  'Lowenstein Sandler',
  'Lozano Smith',
  'Luks Santaniello',
  'Manatt Phelps',
  'Manning Gross',
  'Manning & Kass',
  'Marshall Dennehey',
  'Mayer Brown',
  'Maynard Cooper',
  'McAfee & Taft',
  'McAngus Goudelock',
  'McCarter & English',
  'McDermott Will',
  'McDonald Hopkins',
  'McElroy Deutsch',
  'McGlinchey Stafford',
  'McGuireWoods',
  'McKool Smith',
  'McLane Middleton',
  'McLaughlin & Stern',
  'McNees Wallace',
  'Messner Reeves',
  'Michael Best',
  'Milbank LLP',
  'Milber Makris',
  'Miles & Stockbridge',
  'Miller Canfield',
  'Miller Johnson',
  'Miller & Martin',
  'Miller Nash LLP',
  'Mintz Levin',
  'Mitchell Silberberg',
  'Montgomery McCracken',
  'Moore & Van Allen',
  'Morgan Lewis',
  'Morgan & Morgan PA',
  'Morris Bart',
  'Morris Manning',
  'Morrison Foerster',
  'Morrison Mahoney',
  'Motley Rice',
  'Munger Tolles',
  'Munsch Hardt',
  'Neal Gerber',
  'Nelson Mullins',
  'Nexsen Pruet',
  'Nixon Peabody',
  'Norris McLaughlin',
  'Norton Rose',
  'Nossaman LLP',
  'Nutter McClennen',
  'Obermayer Rebmann',
  'Offit Kurman',
  'Ogletree Deakins',
  "O'Hagan Meyer",
  "O'Melveny & Myers",
  'Orrick Herrington',
  'Parker Poe',
  'Parsons Behle',
  'Patterson Belknap',
  'Paul Hastings',
  'Paul Weiss',
  'Peckar & Abramson',
  'Perkins Coie',
  'Phelps Dunbar',
  'Phillips Lytle',
  'Pierce Atwood',
  'Pillsbury Winthrop',
  'Plunkett Cooney',
  'Polsinelli PC',
  'Porter Hedges',
  'Porter Wright',
  'Post & Schell',
  'Procopio Cory',
  'Proskauer Rose',
  'Pryor Cashman',
  'Quarles & Brady',
  'Quinn Emanuel',
  'Quintairos Prieto',
  'Reed Smith',
  'Reinhart Boerner',
  'Reminger Co.',
  'Resnick & Louis',
  'Richards Layton',
  'Riker Danzig',
  'Rivkin Radler',
  'Robbins Geller',
  'Robins Kaplan',
  'Robinson Bradshaw',
  'Robinson & Cole',
  'Roetzel & Andress',
  'Ropers Majeski',
  'Ropes & Gray',
  'Rutan & Tucker',
  'Sandberg Phoenix',
  'Saul Ewing',
  'Schnader Harrison',
  'Schulte Roth',
  'Schwabe Williamson',
  'Schwegman Lundberg',
  'Segal McCambridge',
  'Selman Breitman',
  'Seward & Kissel',
  'Seyfarth Shaw',
  'Shearman & Sterling',
  'Sheppard Mullin',
  'Sherman & Howard',
  'Shipman & Goodwin',
  'Shook Hardy',
  'Shumaker Loop',
  'Shutts & Bowen',
  'Sidley Austin',
  'Sills Cummis',
  'Simpson Thacher',
  'Skadden Arps',
  'SmithAmundsen LLC',
  'Smith Anderson',
  'Smith Gambrell',
  'Snell & Wilmer',
  'Spencer Fane',
  'Spilman Thomas',
  'Squire Patton',
  'Stearns Weaver',
  'Steptoe & Johnson LLP',
  'Steptoe & Johnson PLLC',
  'Sterne Kessler',
  'Stevens & Lee',
  'Stinson LLP',
  'Stites & Harbison',
  'Stoel Rives',
  'Stoll Keenon',
  'Stradley Ronon',
  'Stradling Yocca',
  'Stroock & Stroock',
  'Sullivan & Cromwell',
  'Sullivan & Worcester',
  'Susman Godfrey',
  'Swanson Martin',
  'Swift Currie',
  'Taft Stettinius',
  'Taylor English',
  'Thomas J. Henry Law',
  'Thompson Coburn',
  'Thompson Coe',
  'Thompson Hine',
  'Troutman Pepper',
  'Tucker Ellis',
  'Tyson & Mendes',
  'Ulmer & Berne',
  'Varnum LLP',
  'Vedder Price',
  'Venable LLP',
  'Vernis & Bowling',
  'Verrill Dana',
  'Vinson & Elkins',
  'von Briesen & Roper',
  'Vorys',
  'Wachtell Lipton',
  'Waller Lansden',
  'Warner Norcross',
  'Weber Gallagher',
  'Weil Gotshal',
  'White and Williams',
  'White & Case',
  'Whiteford Taylor',
  'Wicker Smith',
  'Wiggin and Dana',
  'Wiley Rein',
  'Williams & Connolly',
  'Williams Mullen',
  'Willkie Farr',
  'WilmerHale',
  'Wilson Elser',
  'Wilson Sonsini',
  'Windels Marx',
  'Winstead PC',
  'Winston & Strawn',
  'Winthrop & Weinstine',
  'Wolf Greenfield',
  'Womble Bond Dickinson',
  'Wood Smith',
  'Wyatt Tarrant',
  'Young Conaway'
];

const CompanySelectField: SelectComponent = ({ name, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const loadOptions = useCallback(
    async (
      search: string,
      prevOptions: OptionsList<OptionType>
    ): Promise<Response<OptionType, any>> => {
      if (search && search.length) {
        const searchedState = state.filter(s =>
          s.toLowerCase().includes(search.toLocaleLowerCase())
        );
        if (searchedState.length === 0) setFieldValue(name, search);
        return {
          options: [
            {
              label: '',
              options: searchedState
                .map(companyToOption)
                .sort((a, b) =>
                  a.label > b.label ? 1 : b.label > a.label ? -1 : 0
                )
            }
          ],
          hasMore: false
        };
      }
      return { options: [] };
    },
    [state]
  );

  const noOptionsMessage = React.useCallback(({ inputValue }: any) => null, []);
  return (
    <Field name={name}>
      {({
        field: { value },
        meta: { touched, error },
        form: { setFieldValue }
      }: any) => (
        <BasicPredictText
          loadOptions={loadOptions}
          placeholder="Enter Company Name"
          reduceOptions={optionsReducer}
          value={value ? { label: value, value } : null}
          autoFocus
          isClearable
          onChange={(val: any) => {
            if (val) setFieldValue(name, val.value);
            else setFieldValue(name, '');
          }}
          invalid={!!(touched && error)}
          {...props}
          noOptionsMessage={noOptionsMessage}
        ></BasicPredictText>
      )}
    </Field>
  );
};

export default CompanySelectField;
