import React, { FC } from 'react';
import { useSearchesProductsForColumn } from './useProducts';
import StatusBox, { colors } from 'styleguide/components/StatusBox';
import { Product } from '../../state/productsState';
import Tooltip from 'styleguide/components/Tooltip';

interface CellProductValueProps {
  value?: string[];
  row: number;
  col: number;
}

const getCellColor = (count: number) => (count < 8 ? colors[count] : colors[8]);
const getCellLabel = (value: string[], products: Product[]) => {
  if (value.length === 1)
    return `${(products.find(p => p.id === value[0]) || {}).gridName}`;
  if (value.length === products.length) return 'Comprehensive';
  return `${value.length} searches`;
};

const CellProductValue: FC<CellProductValueProps> = ({ value, row, col }) => {
  const products = useSearchesProductsForColumn(col);
  if (!value || value.length === 0) return null;
  const name = `cellProductValue${row}.${col}`;
  return (
    <>
      <div data-tip data-for={name} style={{ height: '100%' }}>
        <StatusBox
          color={getCellColor(value.length)}
          hasBackgroundColor={value.length > 0}
        >
          {getCellLabel(value, products)}
        </StatusBox>
      </div>
      <Tooltip id={name}>
        <ul>
          {products
            .filter(p => value.includes(p.id))
            .map(p => (
              <li key={p.id}>{p.gridName}</li>
            ))}
        </ul>
      </Tooltip>
    </>
  );
};

export default CellProductValue;
