export const theme = {
  colors: {
    primary300: '#4b91e2',
    primary500: '#2376d7',
    link: '#007ad9',
    linkHover: '#116fbf',
    accent: '#50E3C2',
    background: '#EFF3F6',
    error: '#bd4145',
    warn: '#f2994a',
    success: '#27ae60',
    successHover: '#208f4f',
    lightest: '#fff',
    navbar: '#1b3553',
    navbarLinkHover: '#353b54',
    darkest: '#292929',
    gray100: '#d8dcde',
    gray200: '#a3a5a4',
    gray300: '#848484',
    grayBlue100: '#e6eaee',
    grayBlue200: '#ced4da',
    grayBlue500: '#68737d',
    grayBlue600: '#49545c',
    grayBlue900: '#212529',
    transparent: 'transparent'
  },
  colorsNewDesign: {
    primary: '#0930CC',
    primaryHover: '#0a2aab',
    background: '#F7F8FC',
    backgroundGray:'#EFF3F6',
    error: '#E81414',
    warn: '#f2994a',
    success: '#47CB9D',
    lightest: '#FFFFFF',
    lightBlue: '#f1f4f8',
    darkest: '#0F1027',
    gray50: '#EFEFEF',
    gray100: '#EBECEF',
    gray200: '#DCDCE8',
    gray300: '#A9ADBB',
    gray400: '#A8A8B4',
    gray500: '#9192A2',
    gray600: '#84848D',
    gray700: '#6D6D6D',
    gray800:'#EDEDED',
    grayBlue50: '#E6E9F4',
    grayBlue100: '#EEF1FF',
    grayBlue200: '#D2D8F1',
    separator: '#EBEEF6',
    selectHover: '#EEEEFF',
    transparent: 'transparent',
    modalBackground: 'rgba(7, 13, 38, 0.6)',
    cyan110: '#17A5AE',
    orange100: '#E57A16',
    blueDark: '#04052B',
    dodgerBlue: '#1EAEFF',
    dodgerBlueLight: '#139FEE',
    persianBlue: '#223FB3',
    persianBlueLight: '#1E43D6',
    scooter: '#2FC3CC',
    black:'#000000',
    white:'#FFFFFF',
  },
  statusColors: {
    lightBlue: {
      background: '#D2D8F1',
      border: '#B6BFE5'
    },
    orange: {
      background: '#FFEBE0',
      border: '#F4D3C0',
      font: '#C37C55'
    },
    green: {
      background: '#DBF5D7',
      border: '#C2E7BC',
      font: '#4A8245'
    },
    mint: {
      background: '#CBEBE3',
      border: '#B0DCD1',
      font: '#1D836B'
    },
    yellow: {
      background: '#FFEDB7',
      border: '#EEDFB2',
      font: '#BE9204'
    },
    pink: {
      background: '#F0CDE6',
      border: '#E3B5D6',
      font: '#BC1C8F'
    },
    teal: {
      background: '#B9EFF2',
      border: '#AEE5E9',
      font: '#05AEB9'
    },
    purple: {
      background: '#D8D0FF',
      border: '#CCC3F5',
      font: '#5235E3'
    },
    blue: {
      background: '#B1D5FF',
      border: '#7BA5D6',
      font: '#0063D8'
    }
  },
  tableCellColors: {
    highlighted: '#F6F7FA',
    selected: '#D2D8F1',
    hover: '#F1F4F8'
  },
  badgeColors: {
    text: {
      red: '#E81414',
      green: '#17A5AE',
      grey: '#84848D'
    },
    background: {
      red: '#fde8e8',
      green: '#e8f6f7',
      grey: '#f3f3f4'
    }
  },
  tagColors: {
    filled: {
      red: '#E81414',
      orange: '#EE9C22',
      cyan: '#17A5AE',
      grey: '#9192A2',
      blue: '#0930CC',
      lightBlue: '#1EAEFF',
      orange2: '#E35435'
    },
    regular: {
      red: '#FDE8E8',
      orange: '#FFF1E4',
      cyan: '#DEF3F4',
      grey: '#EBECEF',
      blue: '#EEF1FF',
      lightBlue: '#e8f7ff'
    },
    regularFontColor: {
      red: '#E81414',
      orange: '#E57A16',
      cyan: '#17A5AE',
      grey: '#0F1027',
      blue: '#0930CC'
    }
  },
  fontSizes: {
    xxxs: '10px',
    xxs: '11px',
    xs: '12px',
    sm: '13px',
    md: '14px',
    md2: '15px',
    l: '16px',
    xl: '24px',
    xxl: '28px',
    xxxl: '32px',
    xxxxl: '40px'
  },
  fontSizesNewDesign: {
    xxxs: '9px',
    xxs: '10px',
    xss: '11px',
    xs: '12px',
    sm: '13px',
    regular: '14px',
    normal: '15px',
    md: '16px',
    l: '18px',
    xl: '20px',
    xxl: '24px',
    xxxl: '30px',
    xxxxl: '40px',
    xxxxxl: '50px'
  },
  fontWeights: {
    light: 100,
    regular: 300,
    semiBold: 500,
    bold: 700
  },
  fontFamilies: {
    openSans: "'Open Sans', sans-serif",
    gilroy: "'Gilroy'"
  },
  borderRadius: {
    strong: '8px',
    regular: '4px'
  },
  boxShadow: {
    strong: '0px 1px 1px rgba(19, 31, 21, 0.04)',
    light: '0px 1px 0px #e2e2e2'
  },
  userCircleColors: {
    cyan: '#17A5AE',
    pink: '#E265D6',
    brick: '#E35435',
    orange: '#F2994A',
    yellow: '#DEB00D',
    purple: '#5235E3',
    blue: '#0930CC',
    lightBlue: '#1EAEFF',
    green: '#3DBC70',
    black: '#0F1027'
  }
};

export type ThemeType = typeof theme;
