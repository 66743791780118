import styled, { css } from 'styled-components';

import StyleguideCheckbox from 'styleguide/components/Checkbox';
import StyleguideSnackbar from 'styleguide/components/Snackbar';

export const Wrapper = styled.div`
  padding: 0px;
  overflow-y: scroll;
  height: 400px;
  @media (max-width: 500px) {
    min-width: 0px;
    padding: 0px;
    height: 580px;
  }
`;
// padding: 16px;
// min-width: 774px;

export const LoaderWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const TableWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  background: ${({ theme }) => theme.colorsNewDesign.lightBlue};
`;

export const CellContent = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 16px;
  background: white;
  @media (max-width: 500px) {
    padding: 7px 16px;
  }
`;

export const HeaderCellContent = styled(CellContent)`
  background: ${({ theme }) => theme.colorsNewDesign.separator};
`;

export const CheckboxCellContent = styled(CellContent)`
  padding: 0 12px;
`;

export const HeaderCheckboxCellContent = styled(CheckboxCellContent)`
  background: ${({ theme }) => theme.colorsNewDesign.separator};
`;
type FullRowType = {
  isSelected?: boolean; ///Passing Optional Props
  isRole?:boolean;
};
export const FullRow = styled.div<FullRowType>`
  display: grid;
  grid-template-columns: ${ ({isRole}) => isRole ? '40px 2fr 1fr 1fr 60px': '40px 2fr 1fr 1fr'};
  grid-gap: 1px;
  margin-top: 1px;
  cursor: pointer;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      ${CellContent} {
        background: ${theme.colorsNewDesign.grayBlue200};
      }
    `}

  @media (max-width: 768px) {
    grid-template-columns: 40px 2fr 1fr 1fr;
    grid-gap: 1px;
  }
`;

export const FullHeaderRow = styled(FullRow)`
  margin-top: 0;
  height: 40px;
  @media (max-width: 500px) {
    height: 60px;
  }
`;

export const SimpleRow = styled(FullRow)`
  grid-template-columns: 40px 401px 296px;
`;

export const SimpleHeaderRow = styled(FullHeaderRow)`
  grid-template-columns: 40px 401px 296px;
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 8px 0px;
  @media(max-width:500px){
    position: absolute;
    bottom: 0;
    padding: 0px 25px;
    width: 100%;
  }
 
`;

export const Checkbox = styled(StyleguideCheckbox)`
  display: flex;

  span {
    background: white;
  }
`;

export const Snackbar = styled(StyleguideSnackbar)`
  flex-grow: 1;
`;

export const SnackbarLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
