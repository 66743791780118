import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'styleguide/components/Button';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import { getOrderUrlWithInitialType } from 'helpers/routes';
import { ReactComponent as Icon } from 'components/icons/IconNoEstimates.svg';
import { useDeviceState } from 'state/deviceState';

import { EmptyStateWrapper, EmptyStateIcon } from './styled';

const EmptyState: FC = () => {
  const history = useHistory();
  const [deviceState] = useDeviceState();

  const handleRedirectToOrderForm = useCallback(() => {
    history.push(getOrderUrlWithInitialType('estimate'));
  }, [history]);

  return (
    <EmptyStateWrapper>
      <Spacing direction="top" value={deviceState.isMobileOrTablet?80:136} />
      <EmptyStateIcon>
        <Icon />
      </EmptyStateIcon>
      <Spacing direction="top" value={deviceState.isMobileOrTablet?10:42} />
      <BasicHeading tag={deviceState.isMobileOrTablet ?"div":"h2"} size={deviceState.isMobileOrTablet?"sm":"xxl"}>
        You have no saved estimates
      </BasicHeading>
      <Spacing direction="top" value={deviceState.isMobileOrTablet?10:62} />
      {deviceState.isDesktop && <Button onClick={handleRedirectToOrderForm} resetOrder medium primary>
        Get an estimate
      </Button>}
      <Spacing direction="top" value={deviceState.isMobileOrTablet?100:242} />
    </EmptyStateWrapper>
  );
};

export default EmptyState;
