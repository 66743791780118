import styled from 'styled-components';
import { Link } from 'react-router-dom';

import BasicHeading from 'styleguide/components/BasicHeading';
import BaseLink from 'components/shared/baseLink/BaseLink';
import { ReactComponent as LogoColor } from 'components/icons/LogoColor.svg';

export const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 595px;
  
`;

export const Title = styled(BasicHeading)`
  color: white;
`;

export const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizesNewDesign.l};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const HelpText = styled.div`
  margin-top: auto;
  color: white;
  opacity: 0.4;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
`;

export const HelpTextLink = styled(BaseLink)`
  color: inherit;

  &:hover {
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
`;

export const RightColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  margin: 64px 0 40px 0;
`;

export const Logo = styled(LogoColor)`
  width: 133px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
  },

  a {
    
    img {
      width: 133px;

    }
 

  }
`;

export const HeaderLink = styled(Link)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  margin-left: auto;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;

export const FooterText = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: auto;

  a {
    color: ${({ theme }) => theme.colorsNewDesign.gray600};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colorsNewDesign.gray700};
    }
  }
`;
