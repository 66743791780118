import React, { FC } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { theme } from 'theme/theme';
import { ReactComponent as IconInfo } from 'components/icons/IconInfo.svg';
import { ReactComponent as IconCheckCircle } from 'components/icons/IconCheckCircle.svg';

const IconError = styled(IconInfo)`
  path {
    fill: ${({ theme }) => theme.colorsNewDesign.error};
  }
`;

const ColoredIconInfo = styled(IconInfo)`
  path {
    fill: ${({ theme }) => theme.colorsNewDesign.orange100};
  }
`;

type SnackbarType = 'error' | 'success' | 'info';

interface SnackbarProps {
  type: SnackbarType;
  width?: string;
}

const textColors = {
  error: theme.colorsNewDesign.error,
  success: theme.colorsNewDesign.cyan110,
  info: theme.colorsNewDesign.orange100
};

const snackbarIcons = {
  error: IconError,
  success: IconCheckCircle,
  info: ColoredIconInfo
};

const Wrapper = styled.div<SnackbarProps>`
  display: flex;
  color: ${({ type }) => textColors[type || 'info']};
  background: ${({ type }) => rgba(textColors[type || 'info'], 0.1)};
  border-radius: 4px;
  ${({ width }) => width && `width: ${width};`}
`;

const IconWrapper = styled.div`
  margin: 12px 8px 12px 13px;
`;

const ContentWrapper = styled.div`
  padding: 13px 16px 13px 0;
`;

const Snackbar: FC<SnackbarProps> = ({ children, ...props }) => {
  const Icon = snackbarIcons[props.type];

  return (
    <Wrapper {...props}>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <ContentWrapper className="snackbar-content">{children}</ContentWrapper>
    </Wrapper>
  );
};

export default Snackbar;
