import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import FormLabel from 'styleguide/components/FormLabel';
import IconCircle from 'components/icons/IconCheckNewDesign.svg';

interface ItemProps
  extends Pick<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  name: string;
  value: string;
  checked?: boolean;
}

interface WrapperStyleProps {
  isRow?: boolean;
}

const Wrapper = styled.div<WrapperStyleProps>`
  display: flex;
  ${({ isRow }) =>
    isRow
      ? css`
          & > *:not(:first-child) {
            margin-left: 14px;
          }
        `
      : css`
          flex-direction: column;

          & > *:not(:first-child) {
            margin-top: 7px;
          }
        `}
`;

const ItemWrapper = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const Radio = styled.span`
  height: 16px;
  width: 16px;
  border: 2px solid ${({ theme }) => theme.colorsNewDesign.gray200};
  border-radius: 50%;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked {
    & ~ ${Radio} {
      background: url(${IconCircle}) center no-repeat;
      border-color: ${({ theme }) => theme.colorsNewDesign.primary};
    }
  }
`;

const StyledFormLabel = styled(FormLabel)`
  margin-left: 8px;
`;

const Item: FC<ItemProps> = ({ name, value, children, checked, ...rest }) => {
  const id = `${name}-${value}`;

  return (
    <ItemWrapper>
      <StyledInput
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        {...rest}
      />
      <Radio />
      <StyledFormLabel htmlFor={id}>{children}</StyledFormLabel>
    </ItemWrapper>
  );
};

const BasicRadioList = {
  Wrapper,
  Item
};

export default BasicRadioList;
