import styled from 'styled-components';
import StyleguideFormLabel from 'styleguide/components/FormLabel';
import StyleguideTextInput from 'styleguide/components/TextInput';
import StyleguideSelect from 'styleguide/components/Select';
import BasicText from 'styleguide/components/BasicText';
import BasicHeading from 'styleguide/components/BasicHeading';
import Button from 'styleguide/components/Button';

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;

  @media (max-width: 500px) {
    padding: 0 20px;
  }


`;
export const DeleteAccountHeader = styled.div`
  height: 65px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsNewDesign.error};
  & h1,h2 {
    color: #fff;
    margin: 0 auto;
  }
`;

export const TrashContainer = styled.div`
display: flex;
padding: 35px 20px 0px;
& span{
  border: ${({ theme }) => `1px solid ${theme.colorsNewDesign.error}`};
  margin: auto;
  width: 54px;
  height: 54px;
  border-radius: 30px;
  padding: 14px;
  color: #e81414;
}
`;

export const Box = styled.div`
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  background: white;
  border-radius: 4px;

  @media (max-width: 500px) {
    border-radius: 4px;
    padding: 20px;

  }
`;

export const FormLabel = styled(StyleguideFormLabel)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: 8px;
  cursor: default;
  ${({ theme, color }) =>
    color &&
    `
      color: ${theme.colorsNewDesign[color]};
    `};
`;

export const InlineLabel = styled(StyleguideFormLabel)`
  margin-top: 8px;
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  align-items: center;

  label {
    display: flex;
  }

  span {
    font-size: inherit;
  }
`;

export const TextInput = styled(StyleguideTextInput)`
  margin: 8px 0;
`;

export const Select = styled(StyleguideSelect)`
  && .react-basic-select__control {
    margin: 8px 0;

    ${({ theme, invalid }) =>
      invalid &&
      `
      border: 1px solid ${theme.colorsNewDesign.error};    
    `}
  }

  && .react-basic-select__value-container {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-left: -11px;
  margin-right: -11px;
`;

export const Col = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 11px;
  padding-right: 11px;

`;

export const GrayText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  @media (max-width: 768px) {
    text-align: center;

  }
`;

export const BlackText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.black};
`;
export const CheckboxWrapper = styled.div`
  font-weight: 400;
  padding: 10px;
  text-align: center;
`;

export const PasswordResetRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width:768px){
    justify-content: flex-end;

  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

export const DeleteAccountButton = styled(BasicHeading)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorsNewDesign.primary};
`;

export const DeleteAccountButtonForMobile = styled(BasicHeading)`
  cursor: pointer;
  color: ${({ theme }) => theme.colorsNewDesign.error};
`;

export const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 32px;

  @media (max-width: 768px) {
    width: 350px;

  }

`;

export const DeleteModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 8px 16px;
  align-items: center;
`;
export const CancelButton = styled(Button)`
  font-size: 14px;
`;
export const DeleteButton = styled(Button)`
  margin-left: 8px;
  font-size: 13px;
  ${({ disabled, theme }) =>
    !disabled &&
    `
      background: ${theme.colorsNewDesign.error};

      :hover {
        background: ${theme.colorsNewDesign.error};
      }
  `}
`;
