import React, { FC, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

export interface BaseModalProps {
  className?: string;
  onClose: () => void;
  isOpen?: boolean;
  title?: ReactNode | string;
  deviceState?: any;
  hasCloseButton?: boolean;
  modalBodyClass?: string;
}

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-dialog {
    height: 100%;
    max-width: 800px;
    margin-top: 0;
    padding-top: 1.75rem;
    margin-bottom: 0;
    padding-bottom: 1.75rem;

    @media (max-width: 769px) {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  }

  .modal-content {
    max-height: 100%;

    @media (max-width: 769px) {
      min-height: 100%;
    }
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header {
    flex-shrink: 0;
  }

  .modal-footer {
    flex-shrink: 0;
  }
  .hello{
    background: red;
  }
`;

const BaseModal: FC<BaseModalProps> = ({
  className,
  modalBodyClass,
  children,
  onClose,
  title,
  isOpen,
  hasCloseButton,
  deviceState
}) => {
  return (
    <>
      <StyledModal backdropClassName={className} show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body className={modalBodyClass}>{children}</Modal.Body>
      </StyledModal>
    </>
  );
};

BaseModal.defaultProps = {
  hasCloseButton: true
};

export default BaseModal;
