export default {
  title: `We sent a verification link to`,
  helper: 'Check your email',
  paragraph: 'Please click on it to confirm your account.',
  paragraph1: `We sent a verification link to {emailAddress}.`,
  paragraph2: `It expires soon. Please click on it to confirm your account.`,
  paragraph3: `You might need to check your spam folder or unblock`,
  didntReceive: `Didn't receive it? {link}`,
  sendingMailCondition: `You can resend your email only once per minute. Please try again later.`
};
