import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { authApi } from 'api';
import { INFO_EMAIL_ADDRESS } from 'helpers/constants';
import { CONTACT_MAIL, LOGIN_URL, FORGOT_PASSWORD_URL } from 'helpers/routes';
import { PASSWORD_VALIDATION, EMAIL_VALIDATION } from 'helpers/validation';
import BaseLink from 'components/shared/baseLink/BaseLink';

import RegistrationFirstStepPresentational from './RegistrationFirstStepPresentational';
import { RegistrationFormValues } from './RegistrationRightColumn';

export interface RegistrationFirstStepProps {
  onNextStep: (values: RegistrationFormValues) => void;
  initialValues: RegistrationFormValues;
}

type RegistrationFirstStepFormValues = Pick<
  RegistrationFormValues,
  'firstName' | 'lastName' | 'email' | 'password'
>;

export interface RegistrationFirstStepRenderProps {
  onSubmit: (values: RegistrationFormValues) => void;
  verifyCallback: (token: string) => void;
  validationSchema: yup.ObjectSchema<
    RegistrationFirstStepFormValues | undefined
  >;
  initialValues: RegistrationFormValues;
  getVerifyFeedback: () => any;
  getVerifyIsInvalid: () => boolean;
  isLoading: boolean;
}

const RegistrationFirstStep: FC<RegistrationFirstStepProps> = ({
  initialValues,
  onNextStep
}) => {
  const { formatMessage } = useIntl();
  const [verifyStatus, setVerifyStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  let gRecaptchaResponse = '';

  const firstNameError = formatMessage({
    id: `errors.firstName`
  });
  const lastNameError = formatMessage({
    id: `errors.lastName`
  });
  const emailError = formatMessage({
    id: `errors.email`
  });
  const passwordError = formatMessage({
    id: `errors.password`
  });

  const validationSchema = yup.object().shape<RegistrationFirstStepFormValues>({
    firstName: yup
      .string()
      .required(firstNameError)
      .min(1, firstNameError),
    lastName: yup
      .string()
      .required(lastNameError)
      .min(2, lastNameError),
    email: yup
      .string()
      .email(emailError)
      .required(emailError)
      .matches(EMAIL_VALIDATION, {
        message: emailError,
        excludeEmptyString: true
      }),
    password: yup
      .string()
      .required(passwordError)
      .matches(PASSWORD_VALIDATION, passwordError)
  });

  const verifyCallback = (recaptchaToken: string) => {
    gRecaptchaResponse = recaptchaToken;
  };

  const getVerifyIsInvalid = () =>
    verifyStatus === 5 || verifyStatus === 0 ? false : true;

  const getVerifyFeedback = () => {
    switch (verifyStatus) {
      case 1:
        return (
          <FormattedMessage
            id="errors.emailTaken"
            values={{
              signIn: (
                <BaseLink to={LOGIN_URL}>
                  <FormattedMessage id="shared.signIn" />
                </BaseLink>
              ),
              resetPassword: (
                <BaseLink to={FORGOT_PASSWORD_URL}>
                  <FormattedMessage id="shared.resetYourPassword" />
                </BaseLink>
              )
            }}
          />
        );
      case 2:
        return (
          <FormattedMessage
            id="errors.emailRejected"
            values={{
              emailAddress: (
                <BaseLink href={CONTACT_MAIL}>{INFO_EMAIL_ADDRESS}</BaseLink>
              )
            }}
          />
        );
      case 3:
        return (
          <FormattedMessage
            id="errors.emailSubmitted"
            values={{
              emailAddress: (
                <BaseLink href={CONTACT_MAIL}>{INFO_EMAIL_ADDRESS}</BaseLink>
              )
            }}
          />
        );
      case 4:
        return (
          <FormattedMessage
            id="errors.emailNotConfirmed"
            values={{
              link: (
                <BaseLink to={LOGIN_URL}>
                  <FormattedMessage id="shared.signIn" />
                </BaseLink>
              )
            }}
          />
        );
      default:
        return undefined;
    }
  };

  const onSubmit = async (values: RegistrationFormValues) => {
    try {
      setIsLoading(true);
      const { data } = await authApi()
        .verify()
        .POST({ gRecaptchaResponse, ...values });

      setIsLoading(false);
      setVerifyStatus(data.message);

      if (data.message === 5) {
        onNextStep(values);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <RegistrationFirstStepPresentational
      onSubmit={onSubmit}
      verifyCallback={verifyCallback}
      validationSchema={validationSchema}
      initialValues={initialValues}
      getVerifyFeedback={getVerifyFeedback}
      getVerifyIsInvalid={getVerifyIsInvalid}
      isLoading={isLoading}
    />
  );
};

export default RegistrationFirstStep;
