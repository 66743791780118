import React, { useCallback, useEffect } from 'react';
import { Field } from 'formik';
import BasicSelect, { OptionType } from 'styleguide/components/BasicSelect';
import { Response, OptionsList } from 'react-select-async-paginate';
import {
  useJurisdictionState,
  Jurisdiction
} from '../state/jurisdictionsState';
import { fetchJurisdictions } from 'api/data';
import { SelectComponent } from './TableForm';
import { useNormalizeProductsForJurisdiction } from './searches/useProducts';

const jurisdictionToOption = (jurisdiction: Jurisdiction) => ({
  label: jurisdiction.name && !jurisdiction.name.includes(",") ? jurisdiction.name +" - SOS Only" : jurisdiction.name,
  value: jurisdiction.id
});

const optionsReducer = (
  prev: OptionsList<OptionType>,
  loaded: OptionsList<OptionType>
): OptionsList<OptionType> => loaded;

const JurisdictionSelectField: SelectComponent = ({
  name,
  setRef,
  col,
  disabledIds = [],
  ...props
}) => {
  const [state, actions] = useJurisdictionState();
  const normalize = useNormalizeProductsForJurisdiction(col);
  useEffect(() => {
    if (!state.isLoaded) {
      actions.loadInitial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadOptions = useCallback(
    async (
      search: string,
      prevOptions: OptionsList<OptionType>
    ): Promise<Response<OptionType, any>> => {
      if (search && search.length) {
        const resultsResponse = await fetchJurisdictions({
          s: { name: { $contL: search.toLowerCase() } }
        });
        return {
          options: [
            {
              label: '',
              options: resultsResponse.data.map(jurisdictionToOption)
              .filter((jurisdiction:any) => jurisdiction.label.toLowerCase() !== 'federal')
            }
          ],
          hasMore: false
        };
      }

      if (prevOptions.length) {
        actions.loadNextPage();
      }
      return {
        options: [
          {
            label: 'Popular',
            options: state.popular.map(jurisdictionToOption)
          },
          {
            label: 'All jurisdictions',
            options: state.all.map(jurisdictionToOption)
            .filter(jurisdiction => jurisdiction.label.toLowerCase() !== 'federal')
            .sort((a,b)=> (a.label > b.label) ? 1 : (b.label > a.label) ? -1 : 0)
           
          }
        ],
        hasMore: state.page + 1 < state.pageCount || !state.isLoaded
        
      };
     
    },
    [
      actions,
      state.all,
      state.page,
      state.pageCount,
      state.popular,
      state.isLoaded
    ]
  );
 
  const noOptionsMessage = React.useCallback(
    ({ inputValue }: any) =>
      !inputValue.length && state.isLoading ? 'Loading...' : 'No options',
    [state.isLoading]
  );

  return (
    <Field name={name}>
      {({
        field: { value },
        meta: { touched, error },
        form: { setFieldValue }
      }: any) => (
        <BasicSelect
          loadOptions={loadOptions}
          placeholder="Jurisdiction"
          reduceOptions={optionsReducer}
          value={value}
          autoFocus
          isClearable
          onChange={(val: any) => {
            setFieldValue(name, val);
            normalize(val?.value);
          }}
          selectRef={(asyncRef: any) => {
            if (asyncRef) {
              setRef(asyncRef.select?.inputRef as HTMLInputElement);
            }
          }}
          isOptionDisabled={(option: any) => disabledIds.includes(option.value)}
          invalid={!!(touched && error)}
          {...props}
          noOptionsMessage={noOptionsMessage}
        ></BasicSelect>
      )}
    </Field>
  );
};

export default JurisdictionSelectField;
