import { auth } from 'firebaseConfig';
import { AxiosRequestConfig } from 'axios';
import { getAuthorizedApi } from '../apiInstance';

import api from '../apiInstance';
import { url } from '../url';
import { OrderData } from './index';

const authorizedPostRequest = async (
  endpoint: string,
  data: any,
  config: AxiosRequestConfig = {}
) => {
  const token = await auth.currentUser.getIdToken();
  return api.post(`${url}${endpoint}`, data, {
    ...config,
    headers: { ...(config.headers || {}), Authorization: `Bearer ${token}` }
  });
};

export const postAdminMatter = (data:any) =>
  authorizedPostRequest('/admin/matters', data, {});

export const postAdminMatterOrder = (data:any) =>
  authorizedPostRequest(`/admin/orders`, data, {});

export const deleteAdminOrder = async (orderId: string): Promise<void> => {
  const client = await getAuthorizedApi();
  return client.delete(`${url}/admin/orders/${orderId}`);
};

export interface ContactData {
    id: string;
    firstName?: string;
    lastName?: string;
    firmName?: string;
    email: string;
}

export const fetchContactData = async (
    contactId: string
  ): Promise<ContactData> => {
    const client = await getAuthorizedApi();
    const response = await client.get<any>(`${url}/admin/contacts/${contactId}`);
    return response.data;
  };

  export const fetchContactMatters = async (
    contactId: string
  ): Promise<any> => {
    const client = await getAuthorizedApi();
    const response = await client.get<any>(`${url}/admin/contacts/${contactId}/matters`);
    return response.data;
  };
  

 export const fetchAdminOrderData = async (
    orderId: string
  ): Promise<OrderData> => {
    const client = await getAuthorizedApi();
    const response = await client.get<OrderData>(`${url}/admin/orders/${orderId}`);
    return response.data;
  };
  