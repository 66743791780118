import styled from 'styled-components';
import { ReactComponent as IconCheck } from 'components/icons/IconCheck.svg';
import { ReactComponent as IconClose } from 'components/icons/IconClose.svg';

export const DescriptionWrapper = styled.div`
  padding: 16px;
`;

export const TableWrapper = styled.div`
  width: 720px;
  max-width: 100vw;

  .MuiTableHead-root .MuiTableCell-root {
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
  }

  .MuiTableBody-root .MuiTableRow-root {
    height: 33px;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 6px 9px;
  }
`;

export const Check = styled(IconCheck)`
  display: block;
  margin: 0 auto;

  path {
    fill: ${({ theme }) => theme.colorsNewDesign.cyan110};
  }
`;

export const Close = styled(IconClose)`
  display: block;
  margin: 0 auto;

  path {
    fill: ${({ theme }) => theme.colorsNewDesign.error};
  }
`;
