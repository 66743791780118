import React, { FC, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import { DASHBOARD_URL } from 'helpers/routes';

import { AuthContext } from './Auth';

export interface PrivateRouteProps extends RouteProps {
  component: FC;
}

const AdminRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const currentUser = useContext(AuthContext);

  const onRender = (props: any) => {
    if (currentUser && currentUser.claims && currentUser.claims.admin) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: DASHBOARD_URL,
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route {...rest} render={onRender} />;
};

export default AdminRoute;
