import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { useOrderFormState } from '../state/state';

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

const FormikEffect: () => null = () => {
  const [, { stepFormChanged, stepFormUnmounted }] = useOrderFormState();
  const { values } = useFormikContext<any>();
  const prevValues = usePrevious(values);

  useEffect(() => {
    // Don't run effect on form init
    if (prevValues) stepFormChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(
    () => () => {
      stepFormUnmounted(values);
    },
     // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );


  return null;
};

export default FormikEffect;
