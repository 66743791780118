import styled, { css } from 'styled-components';

export const TableStyleWrapper = styled.div<{
  hover?: boolean;
  stickyRows?: boolean;
}>`
  ${({ stickyRows }) =>
    stickyRows &&
    css`
      .MuiPaper-root > div {
        overflow: initial !important;
        & > div > div {
          overflow: initial !important;
        }
      }
    `}

  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  }

  .MuiTableCell-root {
    font-family: ${({ theme }) => theme.fontFamilies.gilroy};
    padding: 6px 16px 6px 24px;
    border: none;
    font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    line-height: 1.5;

    &.MuiTableCell-head {
      padding: 11px 16px 11px 24px;
    }
  }

  .MuiTableBody-root {
    font-size: 13px;
    .MuiTableRow-root {
      height: 35px;

      &.MuiTableRow-head {
        height: 40px;
      }
    }

    .MuiTableCell-root {
      box-shadow: 0 -1px 0 #e2e6f0;
    }
  }

  .MuiTableHead-root {
    .MuiTableRow-root {
      box-shadow: 0 1px 0 #e2e6f0;
    }

    .MuiTableCell-root {
      background: ${({ theme }) => theme.colorsNewDesign.background};
      font-size: ${({ theme }) => theme.fontSizesNewDesign.xxs};
      color: ${({ theme }) => theme.colorsNewDesign.gray600};
      text-transform: uppercase;
      letter-spacing: 1px;
      box-shadow: none;
    }
  }

  .groupHeaderRow ~ .groupContentRow {
    td:first-child,
    th:first-child {
      padding-left: 85px;
    }
    & + .groupHeaderRow td {
      border-top: 4px solid rgba(235, 238, 246, 0.5);
    }
  }

  .groupContentRow--hitDetails {
    & > td {
      box-shadow: none;
    }
    &.MuiTableRow-root {
      height: 56px;
    }
    & > td:first-child {
      display: none;
    }
    & > td:nth-child(2) {
      padding-left: 59px;
      border-radius: 5px 0 0 5px;
    }
    & > td:last-child {
      border-radius: 0 5px 5px 0;
    }
    & + .MuiTableRow-root:hover > td {
      background: ${({ theme }) => theme.colorsNewDesign.lightest};
    }
    & + .MuiTableRow-root {
      height: 5px;
    }
  }

  .MuiTableRow-root:hover .MuiTableCell-body {
    ${({ hover, theme }) =>
      hover &&
      css`
        background: ${theme.colorsNewDesign.lightBlue};
      `}
  }

  .MuiTableCell-footer {
    border-bottom: none;
  }

  .MuiTableRow-footer {
    padding: 2px 4px 2px 24px;

    .MuiTableCell-root {
      box-shadow: none;
    }

    .MuiButton-root {
      border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
      border-radius: 4px;
      color: ${({ theme }) => theme.colorsNewDesign.gray300};
      margin: 0 4px;

      &.Mui-disabled {
        background: ${({ theme }) => theme.colorsNewDesign.grayBlue100};
        border: 1px solid ${({ theme }) => theme.colorsNewDesign.grayBlue200};
        color: ${({ theme }) => theme.colorsNewDesign.primary};
      }
    }
  }

  .MuiTablePagination-root {
    :last-child {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    .MuiTableRow-footer {
      padding: 2px 4px 2px 24px;
    }
    .groupHeaderRow ~ .groupContentRow {
      td:first-child,
      th:first-child {
        padding-left: 10px;
      }
      & + .groupHeaderRow td {
        border-top: 4px solid rgba(235, 238, 246, 0.5);
      }
    }

    .MuiTableCell-root {
      font-family: ${({ theme }) => theme.fontFamilies.gilroy};
      padding: 5px 5px 5px 5px;
      border: none;
      font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
      color: ${({ theme }) => theme.colorsNewDesign.darkest};
      line-height: 1.5;

      &.MuiTableCell-head {
        padding: 3px 5px 5px 5px;
      }
    }

    .MuiTableBody-root {
      font-size: 12px;
      .MuiTableRow-root {
        height: 35px;

        &.MuiTableRow-head {
          height: 0px;
        }
      }

      .MuiTableCell-root {
        box-shadow: 0 -1px 0 #e2e6f0;
      }
    }

    .groupContentRow--hitDetails {
      &.MuiTableRow-root {
        background: ${({ theme }) => theme.colorsNewDesign.gray800};
        height: 0px;
      }
      & > td:first-child {
        display: none;
      }
      & > td:nth-child(2) {
        padding-left: 20px;
      }
      & + .MuiTableRow-root:hover > td {
        background: ${({ theme }) => theme.colorsNewDesign.lightest};
      }
      & + .MuiTableRow-root {
        height: 5px;
      }
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationCountInfo = styled.td`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  flex-shrink: 0;
`;

export const TableFooter = styled.div`
  box-shadow: 0 -1px 0 #e2e6f0;
  margin-left: -24px;
  margin-right: -4px;
  padding: 24px;
  padding-Left: 48px;
  @media (max-width: 1024px) {
    
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding: 5px 5px;
    font-size: 10px;
  }
`;

// .MuiTableRow-root  td:nth-child(0),
// .MuiTableRow-root  th:nth-child(0) {
//   display: none;
//  }

//  .MuiTableRow-root  td:nth-child(0),
//  .MuiTableRow-root  th:nth-child(0) {
//    display: none;
//   }
