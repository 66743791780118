import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Field, FieldArray, useFormikContext } from 'formik';
import Table, { Row, Cell } from 'styleguide/components/Table';
import DeleteButton from 'styleguide/components/DeleteButton';
import TextInput from 'styleguide/components/TextInput';
import TableAccessory from 'styleguide/components/TableAccessory';
import Checkbox from 'styleguide/components/Checkbox';
import AddElement from './AddElement';
import { handlePasteEvent, useRowKeyNavigation } from './utils';
import { useOrderFormState } from 'pages/order/state/state';
import { STEP_NAMES } from 'pages/order/consts';

const NamesLabel = styled.label`
  display: block;
  width: 100%;
  min-height: 35px;
  padding: 6px 10px;
  font-size: 13px;
  cursor: pointer;
`;

interface NamesFormProps {
  previouslySelected: string[];
}

const StyledTextInput = styled(TextInput)`
  padding: 6px 10px;
  font-size: 13px;
  min-height: 35px;
`;

const StyledCheckbox = styled(Checkbox)`
  & > label {
    padding: 9px 14px;
    min-height: 35px;
  }
`;

const StyledCell = styled(Cell)`
  padding: 10px 7px;
`;

const NamesForm: FC<NamesFormProps> = ({ previouslySelected }) => {
  const [{ steps, currentStep }] = useOrderFormState();

  const isShowCheckbox = useMemo(() => {
    if (STEP_NAMES.Ofac === currentStep) {
      return steps.includes(STEP_NAMES.Searches) || steps.includes(STEP_NAMES.Documents);
    } else if (STEP_NAMES.Documents === currentStep) {
      return steps.includes(STEP_NAMES.Searches) || steps.includes(STEP_NAMES.Ofac);
    }
    return true;
  }, [steps, currentStep]);

  const { values, setFieldValue } = useFormikContext<{
    names: string[];
    previousNames: string[];
  }>();

  const callback = React.useCallback(
    () => setFieldValue('names', [...values.names, '']),
    [setFieldValue, values.names]
  );
  const { handleKeyDown, inputRefs } = useRowKeyNavigation(
    values.names,
    callback
  );

  const handlePaste = React.useCallback(
    (event: React.ClipboardEvent, row: number) => {
      const pastedData = handlePasteEvent(event);
      if (pastedData) {
        const [firstValue, ...rest] = pastedData;
        const names = [...values.names];
        names.splice(row, 1, firstValue);
        setFieldValue('names', [...names, ...rest]);
      }
    },
    [setFieldValue, values.names]
  );

  return (
    <TableAccessory.Wrapper>
      <TableAccessory.Right>
        <Table>
          <FieldArray
            name="previousNames"
            render={({ push, remove }) => (
              <>
                {!!previouslySelected.length ? (
                  <Row>
                    <StyledCell isHighlighted></StyledCell>
                    {
                      isShowCheckbox && (
                        <Cell isHighlighted style={{ minWidth: '40px' }}>
                          <StyledCheckbox
                            id={`prevSelall`}
                            name={`prevSelall`}
                            value={`prevSelall`}
                            checked={
                              values.previousNames.length ===
                              previouslySelected.length
                            }
                            onChange={(e: any) => {
                              if (e.currentTarget.checked) {
                                setFieldValue('previousNames', [
                                  ...previouslySelected
                                ]);
                              } else {
                                setFieldValue('previousNames', []);
                              }
                            }}
                          />
                        </Cell>
                      )
                    }
                    <Cell isHighlighted>
                      <label htmlFor="prevSelall" style={{ fontSize: '13px' }}>
                        <strong>Select all</strong>
                      </label>
                    </Cell>
                  </Row>
                ) : null}
                {previouslySelected.map(name => (
                  <Row key={`prevSel${name}`}>
                    <StyledCell>
                      <DeleteButton type="button" disabled />
                    </StyledCell>
                    <Cell style={{ minWidth: '40px', padding: '6px 15px' }}>
                      <StyledCheckbox
                        id={`prevSel${name}`}
                        name={`prevSel`}
                        value={`prevSel${name}`}
                        checked={values.previousNames.includes(name)}
                        onChange={(e: any) => {
                          if (e.currentTarget.checked) {
                            push(name);
                          } else {
                            const index = values.previousNames.indexOf(name);
                            remove(index);
                          }
                        }}
                      />
                    </Cell>
                    <Cell style={{ minWidth: '383px' }} isFullSize>
                      <NamesLabel htmlFor={`prevSel${name}`}>{name}</NamesLabel>
                    </Cell>
                  </Row>
                ))}
              </>
            )}
          />
          {values.names.map((name: string, row: number) => (
            <Row key={row}>
              <StyledCell>
                <FieldArray
                  name="names"
                  render={({ remove }) => (
                    <DeleteButton
                      type="button"
                      onClick={() => {
                        remove(row);
                      }}
                    />
                  )}
                />
              </StyledCell>
              {
                isShowCheckbox && (
                  <Cell style={{ minWidth: '35px', padding: '6px 15px' }}>
                    <Checkbox checked={name.length} disabled />
                  </Cell>
                )
              }
              <Cell isFullSize style={{ minWidth: '383px' }}>
                <Field name={`names.${row}`}>
                  {({ field, meta: { error, touched } }: any) => (
                    <StyledTextInput
                      {...field}
                      type="text"
                      placeholder="Enter new name"
                      onPaste={(e: React.ClipboardEvent) => handlePaste(e, row)}
                      onKeyDown={(e: React.KeyboardEvent) =>
                        handleKeyDown(e, row)
                      }
                      ref={inputRefs.current[row]}
                      autoFocus
                      invalid={!!(touched && error?.length)}
                      // isHighlighted={currentCell.row === row}
                      isCellInput
                      autocomplete="ignoreThisField"
                      list="autoCompleteOff"
                    />
                  )}
                </Field>
              </Cell>
            </Row>
          ))}
        </Table>
      </TableAccessory.Right>
      <TableAccessory.Bottom>
        <AddElement name="names" />
      </TableAccessory.Bottom>
    </TableAccessory.Wrapper>
  );
};

export default NamesForm;