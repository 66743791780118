import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import BasicDescription from 'styleguide/components/BasicDescription';

interface ItemProps {
  description?: string | ReactNode;
  tip?: ReactNode;
}

interface StyledItemProps {
  hasSpacing?: boolean;
  className?: string;
}

const Wrapper = styled.ul``;

const StyledItem = styled.li<StyledItemProps>`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.fontWeights.normal};

  ${({ hasSpacing }) =>
    hasSpacing &&
    css`
      margin-bottom: 20px;
    `};
`;

const StyledItemHeadingWrapper = styled.div`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colorsNewDesign.primary};
  }
`;

const StyledBasicDescription = styled(BasicDescription)`
  margin-top: 8px;
  margin-left: 20px;
`;


const Item: FC<ItemProps & StyledItemProps> = ({
  children,
  description,
  tip,
  hasSpacing,
  className
}) => (
  <StyledItem hasSpacing={hasSpacing} className={className}>
    <StyledItemHeadingWrapper>{children}</StyledItemHeadingWrapper>

    {description && (
      <StyledBasicDescription>{description}</StyledBasicDescription>
    )}
    {tip && (<StyledBasicDescription>{tip}</StyledBasicDescription>)}
  </StyledItem>
);

const BasicList = {
  Wrapper,
  Item
};

export default BasicList;
