import React, {
  FC,
  useState,
  useEffect,
  Suspense,
  lazy,
  useMemo,
  useCallback
} from 'react';
import { createGlobalStyle } from 'styled-components';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import flatten from 'lodash/flatten';
import defaultTo from 'lodash/defaultTo';
import sortBy from 'lodash/sortBy';

import { OrderLineItem } from 'api/matter';
import { getMatterTabUrl, getFilePreviewUrl } from '../../constants';
import { useMatterState } from '../../state/matter';
import CircularProgress from '@material-ui/core/CircularProgress';
import Nav from './Nav';
import Sidebar from './Sidebar';
import { Wrapper, Modal, ContentWrapper, PDFWrapper } from './styled';
import { debounce, uniqBy } from 'lodash';

const Viewer = lazy(() => import('./Viewer'));
const Fallback = (): any => null;

const GlobalStyle = createGlobalStyle`
  .viewer-body-printing .ReactModalPortal {
    display: none;
  }
  body div#hubspot-messages-iframe-container {
    visibility: hidden;
}
  @media print {
    body {
      min-width: auto !important;
    }
  }
`;

interface PDFViewerMatch {
  matterId: string;
  matterTab: string;
  orderLineItemId: string;
}

interface PDFViewerProps {
  openDownloadModal: () => void;
}

const PDFViewer: FC<RouteComponentProps<PDFViewerMatch> & PDFViewerProps & any> = ({
  match,
  openDownloadModal
}) => {
  const history = useHistory();
  const [state, actions] = useMatterState();
  const [fileUrl, setFileUrl] = useState('');
  const { matterId, matterTab, orderLineItemId } = match.params;

  useEffect(() => {
    if (!state.hits) {
      actions.fetchHitData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Boolean(orderLineItemId)) {
      setFileUrl('');
      actions.downloadOpenPDFFile(orderLineItemId).then(fileBlob => {
        setFileUrl(
          window.URL.createObjectURL(
            new Blob([fileBlob], { type: 'application/pdf' })
          )
        );
      });
    }
  }, [orderLineItemId]); //eslint-disable-line react-hooks/exhaustive-deps

  const orderLineItem = useMemo(
    () =>
      flatten(state.data.orders.map(order => order.orderLineItems)).find(
        item => item.id === orderLineItemId
      ),
    [state.data.orders, orderLineItemId]
  );

  const orderLineItemHitDetails = useMemo(
    () => defaultTo(state.hits, []).find(item => item.id === orderLineItemId),
    [state.hits, orderLineItemId]
  );

  const allOrderLineItemsForType = useMemo(() => {

    // Grouping orderLineItems by diligenceName for org-docs (PDF Viewer)
    const diligenceNames = uniqBy(
      state.view.documents.orderLineItems,
      'diligenceName'
    ).map(({ diligenceName }) => diligenceName);
    const orgDocsOrderLineItems: OrderLineItem[] = [];

    diligenceNames.forEach(diligenceName => {
      orgDocsOrderLineItems.push(
        ...state.view.documents.orderLineItems.filter(
          item => item.diligenceName === diligenceName
        )
      );
    });

    const stateMap: { [key: string]: OrderLineItem[] } = {
      searches: sortBy(state.view.searches.orderLineItems, 'jurisdiction.name'),
      ofac: state.view.ofac.orderLineItems,
      // 'org-docs': sortBy(state.view.documents.orderLineItems, 'diligenceName').filter(item => item.status === "Received")
      'org-docs': orgDocsOrderLineItems.filter(item => item.status === "Received")
    };
    
    const stateKey = matterTab === 'hit-details' ? 'searches' : matterTab;

    const items: OrderLineItem[] = stateMap[stateKey];

    return items.filter(
      item => (item.status === 'Hit' || matterTab === 'org-docs') && item.file
    );
  }, [state.view, matterTab]);

  const currentOrderLineItemIndex = useMemo(
    () =>
      allOrderLineItemsForType.findIndex(item => item.id === orderLineItemId),
    [allOrderLineItemsForType, orderLineItemId]
  );

  const showPrevArrow = currentOrderLineItemIndex !== 0;
  const showNextArrow =
    currentOrderLineItemIndex !== allOrderLineItemsForType.length - 1;

  const onClose = useCallback(() => {
    history.push(getMatterTabUrl(matterId, matterTab));
  }, [history, matterId, matterTab]);

  const onArrowClick = useCallback(debounce((type: string) => {
    const item =
      type === 'prev'
        ? allOrderLineItemsForType[currentOrderLineItemIndex - 1]
        : allOrderLineItemsForType[currentOrderLineItemIndex + 1];

    history.push(getFilePreviewUrl(matterId, matterTab, item.id));
  }, 300),
    [
      history,
      allOrderLineItemsForType,
      currentOrderLineItemIndex,
      matterId,
      matterTab
    ]
  );

  return (
    <>
      <Modal
        isOpen
        onClose={onClose}
        customStyles={{
          content: {
            borderRadius: 0,
            border: 'none',
            marginRight: 0,
            transform: 'none',
            left: 0,
            top: 0
          },
          overlay: {
            zIndex: 9990
          }
        }}
      >
        <Wrapper>
          <Nav
            orderLineItemId={orderLineItemId}
            reviewHistory={orderLineItem?.reviewHistory}
            name={orderLineItem?.diligenceName}
            documentType={orderLineItem?.product.name}
            jurisdiction={orderLineItem?.jurisdiction.name}
            onClose={onClose}
            showPrevArrow={showPrevArrow}
            showNextArrow={showNextArrow}
            onPrevClick={() => onArrowClick('prev')}
            onNextClick={() => onArrowClick('next')}
            openDownloadModal={openDownloadModal}
            downloadFiles={actions.downloadFiles}
            openEmailModal={() => actions.openEmailModal(orderLineItemId)}
            dealbinderMessage={orderLineItem.dealbinderMessage}
          />
          <ContentWrapper>
            <PDFWrapper>
              {fileUrl ? (
                <Suspense fallback={<Fallback />}>
                  <Viewer fileUrl={fileUrl} />
                </Suspense>
              ):<CircularProgress />}
            </PDFWrapper>
            <Sidebar
              id={orderLineItem.id}
              hitDetails={orderLineItemHitDetails?.hitDetails}
              productFamily={orderLineItem?.product.family}
              productName={orderLineItem?.product.name}
              role={state?.data?.role}
            />
          </ContentWrapper>
        </Wrapper>
      </Modal>
      <GlobalStyle />
    </>
  );
};

export default PDFViewer;
