import React, { FC, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import Notes from '../Notes';
import { useMatterState } from '../../state/matter';

interface SidebarNotesProps {
  id: string;
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  
`;

const SidebarNotes: FC<SidebarNotesProps> = ({ id }) => {
  const [{ hits, data: { orders } }, { fetchNotes }] = useMatterState();

  const hit = useMemo(() => hits ? hits.find(h => h.id === id) : null, [id, hits]);

  const orderId = useMemo(
    () =>
      (orders.find(o => o.orderLineItems.findIndex(oli => oli.id === id) !== -1) || {}).id,
    [orders, id]
  );

  // load notes if the hits were not loaded before:
  useEffect(() => {
    if (!hit) {
      fetchNotes(orderId, id);
    }
  }, []); //eslint-disable-line

  if (!hit) return null;

  return <Wrapper>{hit && <Notes {...hit} />}</Wrapper>;
};

export default SidebarNotes;
