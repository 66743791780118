import React, { FC, ReactNode, useState } from 'react';

import { legalPagesApi } from 'api';
import BaseModal from 'components/shared/baseModal/BaseModal';

export interface LegalPagesModalProps {
  title: ReactNode;
  type: 'termsOfService' | 'termsOfUse' | 'privacyPolicy';
}

const LegalPagesModal: FC<LegalPagesModalProps> = ({ title, type }) => {
  const [content, setContent] = useState('');

  const getApi = () => {
    switch (type) {
      case 'privacyPolicy':
        return legalPagesApi()
          .privacyPolicy()
          .GET();
      case 'termsOfService':
        return legalPagesApi()
          .termsOfService()
          .GET();
      case 'termsOfUse':
        return legalPagesApi()
          .termsOfUse()
          .GET();
      default:
        return null;
    }
  };

  const getContent = async () => {
    try {
      const { data } = await getApi();

      setContent(data.text);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BaseModal
      title={title}
      buttonText={title}
      content={content}
      onOpen={getContent}
    />
  );
};

export default LegalPagesModal;
