import React, { FC, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import useOnclickOutside from 'react-cool-onclickoutside';
import { formatISO, format } from 'date-fns';

import Tooltip from 'styleguide/components/Tooltip';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Spacing from 'styleguide/components/Spacing';
import { ReactComponent as DateIcon } from 'components/icons/IconCalendar.svg';
import { useDeviceState } from 'state/deviceState';

import {
  ClosingDateSmallBox,
  SmallBoxTitle,
  ClosingDateText,
  Calendar,
  CalenderWrapper,
  CalenderButton,
  AddReviewerText,
} from './styled';

interface ClosingDateBoxProps {
  closingDate: string;
  onClosingDateChange: (date: string) => Promise<void>;
  isOwner: boolean;
}

const ClosingDateBox: FC<ClosingDateBoxProps> = ({
  closingDate,
  onClosingDateChange,
  isOwner
}) => {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [deviceState] = useDeviceState();

  const [dateValue, setDateValue] = useState<Date>(
    closingDate ? new Date(closingDate) : new Date()
  );

  const onClose = () => {
    if (isCalendarVisible) {
      setIsCalendarVisible(false);
    }
  };

  const onChange = (date: Date | Date[]): void => {
    setDateValue(date as Date);
    const formattedDate = formatISO(date as Date);
    if (closingDate !== formattedDate) {
      onClosingDateChange(formattedDate);
    }
    setIsCalendarVisible(false);
  };
  const resetCalender = React.useCallback((e:any) => {
    e.stopPropagation();
    if(closingDate !== null){
      onClosingDateChange(null);
    }
    setIsCalendarVisible(false);
  },[closingDate,onClosingDateChange])


  useEffect(() => {
    setDateValue(closingDate ? new Date(closingDate) : new Date());
  }, [closingDate]);

  const ref = useOnclickOutside(onClose);

  const noDateText = isOwner ? 'Add a date' : 'TBD';
  return (
    <>
      <ClosingDateSmallBox
        isOwner={isOwner}
        ref={ref}
        onClick={() => isOwner && setIsCalendarVisible(true)}
        isClickable={isOwner}
        data-tip
        data-for="closing-date-help-text"
      >
        <SmallBoxTitle>
          <DateIcon />
          {deviceState.isDesktop && <Spacing direction="left" value={8} />}
          <BasicSubHeading size={deviceState.isMobileOrTablet ? 'xs' : 'regular'} isUppercase>
            {'Closing date'}
          </BasicSubHeading>
        </SmallBoxTitle>
        {closingDate ? (
          <ClosingDateText size={deviceState.isMobileOrTablet ? 'xs' : 'regular'}>
            {/* {isOwner && <Clear onClick={onClear} />} */}
            {format(new Date(closingDate), 'iii., MMM. dd')}
          </ClosingDateText>
        ) :
          <AddReviewerText size={deviceState.isMobileOrTablet ? 'xs' : 'regular'}>{noDateText}</AddReviewerText>
        }

        {isCalendarVisible ? (
          <CalenderWrapper>
            <Calendar value={dateValue} onChange={onChange} />
            <CalenderButton>
              <button onClick={resetCalender}>Clear</button>
            </CalenderButton>
          </CalenderWrapper>
        ):null}
      </ClosingDateSmallBox>
      {!closingDate && !isCalendarVisible && isOwner && (
        <Tooltip place="bottom" id="closing-date-help-text">
          Add a closing date for your reference
        </Tooltip>
      )}
    </>
  );
};

export default ClosingDateBox;
