import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'styleguide/components/Button';
import BasicText from 'styleguide/components/BasicText';
import StyleguideRoundIcon from 'styleguide/components/RoundIcon';
import StyleguideSelect from 'styleguide/components/Select';

export const Wrapper = styled.div`
padding: 40px;
table .MuiTableHead-root {
  .MuiTableCell-root {
    font-size: ${({ theme }) => theme.fontSizesNewDesign.xss};
  }
}
@media (max-width: 768px) {
  padding: 0px;
}    
`;


export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const FiltersButton = styled(Button)`
  padding: 12px 16px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  svg {
    margin-right: 8px;

    ${({ active, theme }) =>
      !active &&
      css`
        path {
          fill: ${theme.colorsNewDesign.gray300};
        }
      `}
  }
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 1px 1px rgba(19, 31, 21, 0.04);
  border-radius: 4px;
  background: white;
`;

export const EmptyStateIcon = styled(StyleguideRoundIcon)`
  background: ${({ theme }) => theme.colorsNewDesign.background};
`;

export const TableButton = styled(Button)`
  margin-top: 0;
  margin-bottom: 0;
  padding: 8px;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
`;

export const MatterLink = styled(Link)`
  color: inherit;
`;

export const TableButtonWrapper = styled.div`
  display: flex;

  > ${TableButton}:first-of-type {
    margin-right: 8px;
  }

  svg path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }
`;

export const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 0 32px;
  text-align: center;
`;

export const DeleteModalConfirmationWrapper = styled.div`
  width: 500px;
  padding: 0 39px;
`;

export const DeleteModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 8px 16px;
`;

export const DeleteButton = styled(Button)`
  margin-left: 8px;
  background: ${({ theme }) => theme.colorsNewDesign.error};

  :hover {
    background: ${({ theme }) => theme.colorsNewDesign.error};
  }
`;

export const GrayText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;

export const RoundIcon = styled(StyleguideRoundIcon)`
  background: white;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.error};
  margin-left: auto;
  margin-right: auto;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${({ theme }) => theme.colorsNewDesign.error};
    }
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalContent = styled.div`
  padding: 24px;
`;

export const ModalFooter = styled.div`
  display: flex;
  padding: 8px 24px;
  box-shadow: 0 -1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  margin-top: auto;

  > * {
    margin-right: 8px;
  }
`;

export const Select = styled(StyleguideSelect)`
  .react-basic-select__control {
    margin: 0;
  }
`;

export const MiddleIcon = styled.img`
  vertical-align: middle;
`;