import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import FullLayout from 'styleguide/components/FullLayout';
import Button, { RightButtonIcon } from 'styleguide/components/Button';
import { ChevronLeft, ChevronRight } from 'styleguide/components/Icon';
import Error from 'styleguide/components/Error';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import { useOrderFormState } from './state/state';
import Navigation from './components/Navigation';
import OfacAside from './components/ofac/OfacAside';
import NamesForm from './components/TableForm/NamesForm';
import uniq from 'lodash/uniq';

import DeleteModal from './components/DeleteModal';
import OrderFormObserver from './components/OrderFormObserver';

const LayoutAside = styled(FullLayout.Aside)`
  padding: 0px;
`;

const OfacSchema = Yup.object().shape({
  names: Yup.array()
    .of(
      Yup.string().test({
        name: 'unique',
        message: 'Remove duplicate name',
        test: function(val: any) {
          if (val) {
            const valueIndex = this.parent.findIndex(
              (name: string) => name && name.toLowerCase() === val.toLowerCase()
            );
            return `names[${valueIndex}]` === this.path;
          }
          return true;
        }
      })
    )
    .when('previousNames', {
      is: (val: string[]) => val.length === 0,
      then: Yup.array()
        .of(
          Yup.string()
            .test({
              name: 'req',
              message: 'Please add a name',
              test: function(val: any) {
                return (
                  (val && val.length > 0) ||
                  this.parent.some((el: string) => el && el.length > 2)
                );
              }
            })
            .min(3, 'Names must be 3 or more characters')
        )
        .min(1, 'Please add a name')
    })
    .when('previousNames', {
      is: (val: string[]) => val.length > 0,
      then: Yup.array().test({
        name: 'unique',
        message: 'Remove duplicate name',
        test: function(value: any) {
          if (value) {
            const previousNames = this.parent.previousNames.map(
              (name: string) => name.toLowerCase()
            );
            const valueIndex = value
              .map((name: string) =>
                name ? previousNames.includes(name.toLowerCase()) : false
              )
              .filter((v: boolean) => v === true);
            return valueIndex.length === 0;
          }
          return true;
        }
      })
    })
});

export interface OfacFormValues {
  names?: string[];
  previousNames?: string[];
}

const StyledContentWrapper = styled.div`
  width: 400px;
`;

const Ofac: FC = () => {
  const [{ ofac, searches }, actions] = useOrderFormState();
  const previousNames = searches ? searches.names : [];
  return (
    <Formik<OfacFormValues>
      initialValues={{
        previousNames:
          ofac && ofac.names
            ? ofac.names.filter(name => previousNames.includes(name))
            : [],
        ...(ofac || {}),
        names:
          ofac && ofac.names
            ? ofac.names.filter(name => !previousNames.includes(name))
            : ['']
      }}
      onSubmit={values => {
        actions.submitStep({
          names: uniq([...values.names, ...values.previousNames]).filter(
            name => name?.length > 0
          )
        });
      }}
      validationSchema={OfacSchema}
    >
      {({ values, errors, touched, submitCount, setFieldValue }) => (
        <Form>
          <OrderFormObserver />
          <FullLayout.Wrapper>
            <Navigation />
            <FullLayout.Container>
              <LayoutAside>
                <OfacAside />
              </LayoutAside>
              <FullLayout.Content autoScroll>
                <Spacing direction="top" value={40} />
                <StyledContentWrapper>
                  <NamesForm previouslySelected={previousNames} />
                </StyledContentWrapper>
                <DeleteModal />
              </FullLayout.Content>
            </FullLayout.Container>

            <FullLayout.Footer>
              <FullLayout.Left>
                <Tooltip />
                <Button
                  data-tip="Delete everything in this section"
                  small
                  type="button"
                  onClick={actions.showDeleteModal}
                >
                  Delete
                </Button>
              </FullLayout.Left>

              <FullLayout.Center>
                {touched.names &&
                  submitCount > 0 &&
                  errors.names &&
                  errors.names.length && (
                    <>
                      {typeof errors.names === 'string' ? (
                        <Error>{errors.names}</Error>
                      ) : (
                        <Error>
                          {(errors.names as string[]).filter(Boolean)[0]}
                        </Error>
                      )}
                    </>
                  )}
              </FullLayout.Center>

              <FullLayout.Right>
                <Button type="button" onClick={actions.previousStep}>
                  <ChevronLeft />
                </Button>

                <Button type="submit" primary className="mr-5">
                  Next
                  <RightButtonIcon>
                    <ChevronRight />
                  </RightButtonIcon>
                </Button>
              </FullLayout.Right>
            </FullLayout.Footer>
          </FullLayout.Wrapper>
        </Form>
      )}
    </Formik>
  );
};

export default Ofac;
