import React from 'react';
import BasicAside from 'styleguide/components/BasicAside';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicDescription from 'styleguide/components/BasicDescription';
import styled from 'styled-components';
import Bg from 'components/icons/Group.png';

const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;
const AsideInstruct = styled(BasicAside.Wrapper)`
  padding: 10px;
  background: url(${Bg});
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% 135%;
  span.heading {
    font-size: 16px;
    font-weight: 600;
    border-left: 3px solid #3dbc70;
    padding-left: 6px;
  }
  p.content {
    padding: 8px 15px;
    font-size: 12px;
    color: #84848d;
  }
`;

const OfacAside = () => (
  <>
    <AsideHead>
      <BasicAside.Heading>OFAC Searches</BasicAside.Heading>
      <BasicAside.Content>
        <BasicHeading tag="div" size="md">
          Click or add the names you want to search
        </BasicHeading>
      </BasicAside.Content>
    </AsideHead>
    <AsideInstruct>
      <BasicAside.Content>
        <BasicHeading tag="div" size="xs">
          Tip:
        </BasicHeading>

        <BasicDescription>
          If you have a list of names, you can copy and paste the whole list
          into a cell
        </BasicDescription>
      </BasicAside.Content>
    </AsideInstruct>
  </>
);

export default OfacAside;
