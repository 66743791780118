import React, { useState, useContext } from 'react';
import { useMatterState, selectors } from '../../state/matter';
import { ValueType } from 'react-select';
import ReviewSelect, {
  ReviewOptionType
} from 'styleguide/components/ReviewSelect';
import Tooltip from 'styleguide/components/Tooltip';
import { createReview, fetchReviews } from 'api/reviews';
import { ReviewHistory } from 'api/matter';
import { AuthContext } from 'components/app/Auth';
import { useDeviceState } from 'state/deviceState';

const options: ReviewOptionType[] = [
  { label: 'Not reviewed yet', value: 'Not reviewed yet', color: 'lightBlue' },
  { label: 'Under review', value: 'Under review', color: 'orange' },
  { label: 'Requires follow up', value: 'Requires follow up', color: 'red' },
  { label: 'Review complete', value: 'Review complete', color: 'cyan' }
];

interface ReviewSelectProps {
  id: string;
  reviewHistory?: ReviewHistory[];
  matterId?: string;
  orderId?: string;
}

const ReviewSelectForm = ({
  id,
  matterId,
  orderId,
  reviewHistory = []
}: ReviewSelectProps) => {
  const currentUser = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [, { updateReviews }] = useMatterState();
  const [deviceState] = useDeviceState();
  const review = reviewHistory[0];

  const handleChange = React.useCallback(
    async (val: ValueType<ReviewOptionType>) => {
      setIsLoading(true);
      await createReview(
        matterId,
        orderId,
        id,
        (val as ReviewOptionType).value
      );

      const newReviewsData = await fetchReviews(matterId, orderId, id);

      const newReviews = newReviewsData
        .map(el => ({
          createdAt: el.review_createdAt,
          id: el.review_id,
          reviewStatus: el.review_reviewStatus,
          reviewer: {
            email: el.contact_email,
            firstName: el.contact_firstName,
            lastName: el.contact_lastName
          }
        }))
        .sort((el1, el2) => {
          if (el1.createdAt < el2.createdAt) {
            return 1;
          }
          if (el1.createdAt > el2.createdAt) {
            return -1;
          }
          return 0;
        });

      updateReviews(orderId, id, newReviews);
      setIsLoading(false);
    },
    [matterId, orderId, id, updateReviews, setIsLoading]
  );

  return (
    <>
     <div data-tip data-for={`review.${id}`}>
        <ReviewSelect
          options={options}
          value={options.find(
            opt => opt.value === (review?.reviewStatus)
          )||options[0]}
          deviceState={deviceState}
          isLoading={deviceState.isDesktop && isLoading}
          onChange={handleChange}
        />
      </div>
      { deviceState.isDesktop &&  review &&
      (!review.reviewer || currentUser?.email !== review.reviewer?.email) ? (
       <Tooltip id={`review.${id}`}>
          {review.reviewer?.firstName} {review.reviewer?.lastName} marked review{' '}
          {review.reviewStatus}
        </Tooltip>
      ) : null}
    </>
  );
};

const ReviewSelectGuard = (props: ReviewSelectProps) => {
  const [state] = useMatterState();
  const {
    id,
    data: { role }
  } = state;
  const order = selectors.findOrderForLineItem(state)(props.id);

  if (role !== 'owner' && role !== 'reviewer') return null;

  return <ReviewSelectForm matterId={id} orderId={order.id}   {...props} />;
};

export default ReviewSelectGuard;
