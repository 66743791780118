import React, { FC, useState, useEffect, useCallback } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Spacing from 'styleguide/components/Spacing';
import Loader from 'styleguide/components/Loader';
import Button from 'styleguide/components/Button';
import Snackbar from 'styleguide/components/Snackbar';

import { AssignToContentWrapper, Link, Footer } from './styled';

interface UnassignModalProps {
  isOpen: boolean;
  onClose: () => void;
  openPermissions: () => void;
  unassign: () => Promise<void>;
}

const UnassignModal: FC<UnassignModalProps> = ({
  isOpen,
  onClose,
  openPermissions,
  unassign
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(false);
      setIsSubmitted(false);
    }
  }, [isOpen]);

  const handleUnassign = useCallback(() => {
    setIsLoading(true);
    unassign().then(() => {
      setIsSubmitted(true);
      setIsLoading(false);
    });
  }, [unassign]);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <Spacing direction="left" value={8} />
          <BasicHeading tag="h2" size="xl">
            Unassign this person?
          </BasicHeading>
        </>
      }
    >
      {isSubmitted && (
        <AssignToContentWrapper>
          <Spacing direction="top" value={16} />
          <Snackbar width="100%" type="success">
            Person has been unassigned
          </Snackbar>
          <Spacing direction="top" value={24} />
          <BasicText size="regular">
            This user still has Reviewer role permissions. Click "Share" to
            change this.
          </BasicText>
          <Spacing direction="top" value={16} />
        </AssignToContentWrapper>
      )}
      {!isSubmitted && (
        <>
          <AssignToContentWrapper>
            <BasicText size="regular">
              This person will still have access to this matter.  To change roles and permissions, click Share and go to the Manage Access tab.
            </BasicText>
            <Spacing direction="top" value={16} />
            <Link onClick={openPermissions} size="regular">
              Learn more about roles
            </Link>
          </AssignToContentWrapper>
          <Footer>
            {isLoading && <Loader />}
            <Button resetOrder medium onClick={onClose}>
              Cancel
            </Button>
            <Spacing direction="left" value={8} />
            <Button
              onClick={handleUnassign}
              resetOrder
              medium
              primary
              disabled={isLoading}
            >
              Unassign
            </Button>
          </Footer>
        </>
      )}
    </BasicModal>
  );
};

export default UnassignModal;
