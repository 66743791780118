import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import BasicBox from 'styleguide/components/BasicBox';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSection from 'styleguide/components/BasicSection';
import { useOrderFormState } from './state/state';
import FullLayout from 'styleguide/components/FullLayout';
import Loader from 'styleguide/components/Loader';
import Navigation from './components/Navigation';
import { Row, Col, Container } from 'react-bootstrap';
import { currencyFormat } from 'helpers/formatters';
import PriceDetailsModal from './components/review/PriceDetailsModal';
import TurnaroundDetails from './components/review/TurnaroundDetails';
import StepsReview from './components/review/StepsReview';
import DeleteModal from './components/DeleteModal';
import ReviewForm from './components/review/ReviewForm';
import AdminReviewForm from './components/review/AdminReviewForm';
import BasicText from 'styleguide/components/BasicText';
import { useDeviceState } from 'state/deviceState';
import Spacing from 'styleguide/components/Spacing';
import PersistentDrawerLeft from 'layout/authenticationLayout/component/PersistentDrawerLeft';
import Sidebar from '../../pages/dashboard/components/Sidebar';

const DiscountNotice = styled.div`
  color: ${({ theme }) => theme.userCircleColors.green};
`;

const StyledBasicBox = styled(BasicBox)`
  & > div:last-child {
    padding-top: 0;
    padding-bottom: 0;

    @media (max-width:768px) {
      padding-top: 0;
      padding-bottom: 10px;

    }
  }
`;

const GrayText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-style: italic;
`;

const Review: FC = () => {
  const [
    { estimateResponse, start, steps, turnarounds, contactId },
    { requestEstimate }
  ] = useOrderFormState();
  const [deviceState] = useDeviceState();
  const [discount, setDiscount] = useState("" || 0)
  useEffect(() => {
    requestEstimate();
    // estimate should be refreshed if steps or turnaround changed
  }, [requestEstimate, steps, turnarounds]);

  const orderType = start?.orderType || 'order';
  return (
    <FullLayout.Wrapper>
      {deviceState.isDesktop ? <Navigation /> : <PersistentDrawerLeft sidebar={<Sidebar />} />}
      <FullLayout.Container>

        <FullLayout.Content autoScroll noFooter>
          <Container>
            <Row>

              <Col className={deviceState.isMobileOrTablet ? "col-sm-6 order-1" : ""}>

                <StyledBasicBox title={orderType === 'order' ? 'Please review your order details' : 'Please review your estimate details'}>

                  <BasicSection title="Estimated price">
                    {estimateResponse.estimate ? (
                      <>
                        <BasicHeading tag="div" size="xxl">
                          ${currencyFormat(estimateResponse.estimate.netPrice - discount)}
                        </BasicHeading>
                        {discount ? (
                          <DiscountNotice>
                            A ${discount} admin discount was applied to this order.
                          </DiscountNotice>
                        ) : null}
                        {estimateResponse.estimate.hasDiscount ? (
                          <DiscountNotice>
                            A {estimateResponse.estimate.discount100}% volume discount was applied.
                          </DiscountNotice>
                        ) : null}
                        <PriceDetailsModal estimate={estimateResponse} adminDiscount={discount} />
                        <GrayText size="sm"><u>Includes</u> state and correspondent fees.</GrayText>
                      </>
                    ) : (
                      <BasicHeading tag="div" size="regular">
                        <Loader size={3}>Loading...</Loader>
                        Updating estimate
                      </BasicHeading>
                    )}
                  </BasicSection>
                  <BasicSection title="Turnaround">
                    <BasicHeading tag="div" size="xxl">
                      {estimateResponse.estimate?.estimate}
                    </BasicHeading>
                    <TurnaroundDetails estimate={estimateResponse} />
                  </BasicSection>
                  {deviceState.isDesktop && <StepsReview />}
                </StyledBasicBox>
                {deviceState.isMobileOrTablet && <Spacing direction="top" value={20} />}
              </Col>


              <Col className={deviceState.isMobileOrTablet ? "col-sm-6 order-2" : ""}>
                {/* if contactId is set then order is made by admin on behalf of an user: */}
                {contactId ? <AdminReviewForm discount={discount} setDiscount={setDiscount} /> :
                  <ReviewForm discount={discount} setDiscount={setDiscount} />}
              </Col>
            </Row>
          </Container>
          <DeleteModal />
        </FullLayout.Content>
      </FullLayout.Container>
    </FullLayout.Wrapper>
  );
};

export default Review;
