import React, { FC } from 'react';
import ReactTagsInput, {
  ReactTagsInputProps,
  RenderTagProps
} from 'react-tagsinput';

import { ReactComponent as CloseIcon } from 'components/icons/IconClose.svg';
import { Wrapper } from './styled';

const Tag: FC<RenderTagProps> = ({
  tag,
  key,
  disabled,
  onRemove,
  getTagDisplayValue,
  ...props
}) => {
  return (
    <span key={key} {...props}>
      {getTagDisplayValue(tag)}
      {!disabled && <CloseIcon onClick={() => onRemove(key)} />}
    </span>
  );
};

const TagsInput: FC<ReactTagsInputProps> = props => (
  <Wrapper>
    <ReactTagsInput {...props} renderTag={Tag} />
  </Wrapper>
);

export default TagsInput;
