import { createStore, createHook, StoreActionApi } from 'react-sweet-state';
import { auth } from 'firebaseConfig';

import {
  UserDetails,
  fetchUserDetails,
  updateUserDetails,
  deleteAccount
} from 'api/settings';

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

interface State {
  userDetails: UserDetails;
}

const initialState: State = {
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    firmName: '',
    phone: '',
    firmAddress: '',
    city: '',
    state: '',
    postalCode: '',
    isReceivingDailyUpdates: false
  }
};

const actions = {
  fetchData: () => async ({ setState, getState }: StoreApi): Promise<void> => {
    const data = await fetchUserDetails();

    setState({ ...getState(), userDetails: data });
  },
  updateUser: (user: UserDetails) => async (): Promise<void> => {
    await updateUserDetails(user);

    await auth.currentUser.reload();
    const currentUser = await auth.currentUser;
    await auth.updateCurrentUser(currentUser);
  },
  deleteAccount: () => async (): Promise<void> => {
    await deleteAccount();
    await auth.signOut();
  }
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'settings'
});

export const useSettingsState = createHook(Store);
