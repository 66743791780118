import styled, { css } from 'styled-components';

import { ThemeType } from 'theme/theme';

interface BasicTextProps {
  size: keyof ThemeType['fontSizesNewDesign'];
  weight?: keyof ThemeType['fontWeights'];
  faded?: boolean;
}

const BasicText = styled.div<BasicTextProps>`
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme, size }) => theme.fontSizesNewDesign[size]};
  font-weight: ${({ theme, weight }) => theme.fontWeights[weight]};

  ${({ faded }) =>
    faded &&
    css`
      color: ${({ theme }) => theme.colorsNewDesign.gray500};
    `};
`;

export default BasicText;
