import styled from 'styled-components';

import BaseLink from 'components/shared/baseLink/BaseLink';

export const VerificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledBaseLink = styled(BaseLink)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const GrayText = styled.div`
  text-align: center;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizes.s};
  max-width: 320px;

  > * {
    font-size: inherit;
  }
`;
