import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import BasicText from 'styleguide/components/BasicText';
import Checkbox from 'styleguide/components/Checkbox';
import Button, { LeftButtonIcon, RightButtonIcon } from 'styleguide/components/Button';
import { ChevronLeft, ChevronRight } from 'styleguide/components/Icon';
import FormLabel from 'styleguide/components/FormLabel';
import instruction1 from 'components/icons/instruction1.svg';
import instruction2 from 'components/icons/instruction2.svg';
import instruction3 from 'components/icons/instruction3.svg';

const Wrapper = styled.div`
    width: 500px
`;

const StyledFormLabel = styled(FormLabel)`
    display: flex;
    justify-content: center;
`;

interface InstructionModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const InstructionModal: FC<InstructionModalProps> = ({
    isOpen,
    onClose
}) => {
    const handleCheck = (e:any) => {
        localStorage.setItem("instruction",e.target.checked);
        setCheck(e.target.checked);
    }
    const [check, setCheck] = useState(false);
    const [current, setCurrent] = useState(0);
    const sliderRef = useRef(null);

    const next = () => {
        sliderRef.current && sliderRef.current.slickNext();
    };

    const prev = () => {
        sliderRef.current && sliderRef.current.slickPrev();
    };

    const afterChange = (page: number) => setCurrent(page);

    const onReadyClick = () => {
        onClose();
    };

    return (
        <BasicModal
            title={null}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Wrapper>
                <Slider ref={sliderRef} dots={false} infinite={false} arrows={false} afterChange={afterChange}>
                    <div className="text-center">
                        <img src={instruction1} alt="Instruction 1" className="w-100" />
                        <Spacing direction="top" value={24} />
                        <BasicHeading tag="h1" size="xxl">1.  Add names</BasicHeading>
                        <Spacing direction="top" value={14} />
                        <BasicText size="md">In the left column, type or paste your list of names.</BasicText>
                        <Spacing direction="top" value={24} />
                    </div>
                    <div className="text-center">
                        <img src={instruction2} alt="Instruction 2" className="w-100" />
                        <Spacing direction="top" value={24} />
                        <BasicHeading tag="h1" size="xxl">2.  Add jurisdictions</BasicHeading>
                        <Spacing direction="top" value={14} />
                        <BasicText size="md">In the top row, type all of the jurisdictions from which <br /> you need searches or documents.</BasicText>
                        <Spacing direction="top" value={24} />
                    </div>
                    <div className="text-center">
                        <img src={instruction3} alt="Instruction 3" className="w-100" />
                        <Spacing direction="top" value={24} />
                        <BasicHeading tag="h1" size="xxl">3.  Match names to jurisdictions</BasicHeading>
                        <Spacing direction="top" value={14} />
                        <BasicText size="md">Click or drag across cells, or click "Select all", to indicate <br /> where to search or get documents for each name.</BasicText>
                        <Spacing direction="top" value={24} />
                    </div>
                </Slider>
                <div className="text-center">
                    <StyledFormLabel as="label" htmlFor="do_not_show">
                        <Checkbox
                            onChange={(e: any) => {
                                handleCheck(e)
                                // setCheck(e.target.checked);
                            }}
                            id="do_not_show"
                            checked={check}
                        />
                        <Spacing direction="right" value={7} />
                        <BasicText size="regular">Do not show me this again.</BasicText>
                    </StyledFormLabel>
                </div>
                <Spacing direction="top" value={14} />
                <div className="d-flex justify-content-center">
                    <Button medium onClick={prev} disabled={current === 0} >
                        <LeftButtonIcon>
                            <ChevronLeft />
                        </LeftButtonIcon>
                                Back
                        </Button>
                    <Button className="ml-3" medium primary onClick={current === 2 ? onReadyClick : next}>
                        {current === 2 ? "Ready" : "Next"}
                        <RightButtonIcon>
                            <ChevronRight />
                        </RightButtonIcon>
                    </Button>
                </div>
                <Spacing direction="top" value={10} />
            </Wrapper>
        </BasicModal>
    );
};

export default InstructionModal;
