import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'styleguide/components/MaterialTable';
import { useMatterState } from './state/matter';
import styled from 'styled-components';
import {
  TableAccessory,
  FiltersButton,
  ExpandButton,
  CountBadge
} from './components/common/styled';
import InlineFilters from 'styleguide/components/InlineFilters';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
import Filters from './components/DocumentsFilters';
import { ReactComponent as IconExpand } from 'components/icons/IconExpand.svg';
import Tooltip from 'styleguide/components/Tooltip';
import SearchInput from 'styleguide/components/SearchInput';
import GroupRow from './components/GroupRow';
import { optionsToLookup } from './utils';
import Tag from 'styleguide/components/Tag';
import { etaDateFormat } from 'helpers/formatters';
import { getFilePreviewUrl } from './constants';
import TableFooter from './components/TableFooter';
import ReviewSelectForm from './components/ReviewSelectForm';
import DealbinderMessage from './components/DealbinderMessage';
import ColumnAttachment from './components/ColumnAttachment';
import { useDeviceState } from 'state/deviceState';

const Documents = () => {
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [deviceState] = useDeviceState();
  const DocStatus = styled.div`
    position: relative;
    @media (max-width: 768px) {
      left: 7px;
    }
  `;
  const [
    {
      id: matterId,
      view: { documents },
      tables: {
        documents: { filters, search, expanded }
      },
      tableFilterSidebar
    },
    {
      setSearch,
      toggleExpanded,
      setFilter,
      clearFilter,
      toggleTableFilterSidebar
    }
  ] = useMatterState();

  const statusFilters = React.useMemo(() => {
    const statuses = documents.orderLineItems.map(oli => oli.status);
    return [
      { id: '', label: 'All' },
      { id: 'In Progress', label: 'In Progress' },
      { id: 'Received', label: 'Received' },
      { id: 'Not Available', label: 'Not Available' }
    ].filter(el => el.id === '' || statuses.includes(el.id));
  }, [documents.orderLineItems]);

  const countBadge = React.useMemo(() => Object.keys(filters).length, [
    filters
  ]);

  return (
    <>
      <TableAccessory>
        <div className="col-sm-2 col"></div>

        {deviceState.isMobileOrTablet ? (
          ''
        ) : (
          <InlineFilters
            tabs={statusFilters}
            activeTab={filters.status?.length === 1 ? filters.status[0] : ''}
            onChange={(id: string) => {
              clearFilter('documents');
              if (id !== '') {
                setFilter('documents', 'status', [id]);
              }
            }}
          />
        )}

        <div style={{ height: '40px' }} className="col-sm-4 col">
          {deviceState.isMobileOrTablet ? (
            ''
          ) : (
            <>
              {' '}
              <ExpandButton
                onClick={() => toggleExpanded('documents')}
                data-tip
                data-for="expandrows"
                data-place="bottom"
                resetOrder
                active={expanded}
              >
                <IconExpand /> {expanded ? 'Collapse' : 'Expand'} rows
              </ExpandButton>
              <Tooltip id="expandrows">
                {expanded ? 'Collapse rows' : 'Expand rows'}
              </Tooltip>
              <FiltersButton
                active={Boolean(Object.keys(filters).length)}
                onClick={() => toggleTableFilterSidebar('documents')}
                resetOrder
              >
                <FiltersIcon />
                Filters
                {countBadge ? <CountBadge>{countBadge}</CountBadge> : null}
              </FiltersButton>
            </>
          )}
          <SearchInput
            type="text"
            value={search}
            onChange={evt => setSearch('documents', evt.target.value)}
            placeholder="Search"
          />
        </div>
      </TableAccessory>
      <MaterialTable
        filters={filters}
        search={search}
        expanded={expanded}
        hover
        stickyRows
        materialTableProps={{
          components: {
            GroupRow,
            Pagination: TableFooter
          },
          columns: [
            {
              title: 'Name',
              field: 'diligenceName',
              defaultGroupOrder: 0,
              customSort: () => 0,
              lookup: optionsToLookup(documents.filterOptions.name)
            },
            {
              title: 'Status',
              field: 'status',
              lookup: optionsToLookup(documents.filterOptions.status),
              render: ({
                id,
                estimatedTurnaround,
                status,
                dealbinderMessage
              }: any) => (
                <>
                  <ColumnAttachment left="-46px">
                    <DocStatus>
                      <DealbinderMessage
                        id={id}
                        message={dealbinderMessage}
                        clickable
                      />
                    </DocStatus>
                  </ColumnAttachment>
                  {status === 'In Progress' ? (
                    <>
                      <Tag
                        color="orange"
                        cursorPointer={true}
                        data-tip
                        data-for={`status.${id}`}
                      >
                        {deviceState.isMobile ? 'In Prog' : status}
                      </Tag>
                      {estimatedTurnaround ? (
                        <Tooltip id={`status.${id}`}>
                          ETA: {etaDateFormat(estimatedTurnaround)}
                        </Tooltip>
                      ) : null}
                    </>
                  ) : status === 'Not Available' ? (
                    <Tag isFilled color="orange2">
                      {status}
                    </Tag>
                  ) : (
                    <Tag color={status === 'hit' ? 'red' : 'cyan'} isFilled>
                      {status}
                    </Tag>
                  )}
                </>
              )
            },
            {
              title: 'Document Type',
              field: 'product.name',
              lookup: optionsToLookup(documents.filterOptions.product)
            },
            {
              title: 'Jurisdiction',
              field: 'jurisdiction.name',
              lookup: optionsToLookup(documents.filterOptions.jurisdiction)
            },
            {
              title: 'File',
              field: 'file',
              lookup: { true: 'true' },
              render: ({ id, file, status }: any) =>
                file && status === 'Received' ? (
                  <Link to={getFilePreviewUrl(matterId, 'org-docs', id)}>
                    {deviceState.isMobile ? 'View' : 'View file'}
                  </Link>
                ) : (
                  '-'
                )
            },
           
            {
              title: 'Review status',
              field: 'reviewStatus',
              lookup: optionsToLookup(documents.filterOptions.reviewStatus),
              render: ({ status, id, file, reviewHistory }: any) => {
               
                reviewHistory.sort((el1: any, el2: any) => {
                  if (el1.createdAt < el2.createdAt) {
                    return 1;
                  }
                  if (el1.createdAt > el2.createdAt) {
                    return -1;
                  }
                  return 0;
                });
                return status === "Received"  ? (
                  <ReviewSelectForm
                    id={id}
                    reviewHistory={reviewHistory}
                  />
                ) : null;
              },
              customFilterAndSearch: (term, rowData) => {
                if (rowData.reviewHistory?.length) {
                  const reviewHistory = rowData.reviewHistory.sort(
                    (el1: any, el2: any) => {
                      if (el1.createdAt < el2.createdAt) {
                        return 1;
                      }
                      if (el1.createdAt > el2.createdAt) {
                        return -1;
                      }
                      return 0;
                    }
                  );
                  return term.includes(reviewHistory[0].reviewStatus);
                }
                if (!rowData.reviewHistory.length && term[0] === 'Not reviewed yet') {
                  return true;
                }
                return false;
              }
            }
          ],
          data: documents.orderLineItems,
          options: {
            paging: true,
            grouping: true,
            header: false,
            filtering: true,
            pageSize: 9999
          },
          style: { border: 'none' }
        }}
      />
      <Filters
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
      />
      <Filters
        isOpen={tableFilterSidebar.documents}
        onClose={() => toggleTableFilterSidebar('documents')}
      />
    </>
  );
};

export default Documents;
