import { INFO_EMAIL_ADDRESS } from './constants';

export const LOGIN_URL = '/login';

export const LOGOUT_URL = '/logout';

export const REGISTRATION_URL = '/registration';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const ERROR_URL = '/404';
export const USER_MANAGEMENT_URL = '/user-management';
export const CHECK_YOUR_EMAIL_URL = '/check-your-email';

export const ORDER_URL = '/order';
export const ORDER_URL_MOBILE = '/order-restrict-mobile';

export const getOrderUrlWithInitialType = (initialType: string): string =>
  `${ORDER_URL}/?initialOrderType=${initialType}`;
export const getOrderEstimateFormUrl = (matterId: string, orderId: string) =>
  `${ORDER_URL}/estimation/matter/${matterId}/order/${orderId}`;

export const DASHBOARD_URL = '/dashboard';
export const MATTERS_URL = `${DASHBOARD_URL}/matters`;
export const ESTIMATES_URL = `${DASHBOARD_URL}/estimates`;
export const SETTINGS_URL = `${DASHBOARD_URL}/settings`;
export const getMatterUrl = (id: string): string => `${MATTERS_URL}/${id}`;
export const getEstimateUrl = (id: string): string => `${ESTIMATES_URL}/${id}`;

export const CONTACT_MAIL = `mailto:${INFO_EMAIL_ADDRESS}`;
