import React, { FC } from 'react';
import styled from 'styled-components';
import ReactSelect, { components, Props } from 'react-select';
import iconNotReviewed from 'components/icons/IconNotReviewed.svg';
import iconUnderReview from 'components/icons/IconUnderReview.svg';
import iconRequiresFollowUp from 'components/icons/IconRequiresFollowUp.svg';
import iconReviewComplete from 'components/icons/IconReviewComplete.svg';

const ICONS = {
  lightBlue: iconNotReviewed,
  orange: iconUnderReview,
  red: iconRequiresFollowUp,
  cyan: iconReviewComplete,
};

export const Icon = styled.span<{ color: string }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: ${({ theme, color }) => theme.tagColors.regular[color]}
    url(${({ color }) => ICONS[color as keyof typeof ICONS]}) center center
    no-repeat;
  color: ${({ theme, color }) => theme.tagColors.filled[color]};
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;

  @media (max-width: 500px) {
    width: 20px;
    height: 20px;
  }  
`;

const Value = styled.span<{ color: string }>`
  color: white !important;
`;

const ControlWrapper = styled.div<{ color: string }>`
  ${({ color, theme }) =>
    color &&
    `
        & > * {
            border-color: ${theme.tagColors.filled[color]} !important;
            box-shadow: none !important;
            background-color: ${theme.tagColors.filled[color]} !important;
            @media (max-width: 768px) {
              border-color: white !important;
              background-color: white !important;

            }  
        }
        svg {
            fill: white !important;
            @media (max-width: 768px) {
              fill:  ${theme.tagColors.filled[color]} !important;

            } 

        }
    `}
`;

const Control = ({ ...props }: any) => (
  <ControlWrapper color={props.hasValue ? props.getValue()[0].color : null}>
    <components.Control {...props} />
  </ControlWrapper>
);

const SingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <Icon color={props.data.color} />
    <Value color={props.data.color}>{children}</Value>
  </components.SingleValue>
);

export type ReviewOptionType = {
  label: string;
  value: string;
  color: 'lightBlue' | 'orange' | 'red' | 'cyan';
};

const ReviewSelect: FC<Props<ReviewOptionType>> = props => (
  <ReactSelect<ReviewOptionType>
    placeholder="Select"
    styles={{
      valueContainer: (provided) => ({
        ...provided,
        padding: '4px'
      }),
      loadingIndicator: (provided) => ({
        ...provided,
        color: "white"
      }),
      indicatorSeparator: () => ({ display: 'none' }),
      control: styles => (props.deviceState.isMobileOrTablet ? { ...styles, width: '70px', zIndex: 0 } : { ...styles, width: '220px', zIndex: 0 }),
      menu: provided => (props.deviceState.isDesktop ? { ...provided, zIndex: 999,marginLeft:'0',width:'100%'}:{ ...provided, zIndex: 999,marginLeft:'-100px',width:'150px' }),
    }}
    components={{ SingleValue, Control }}
    isSearchable={false}
    {...props}
  ></ReactSelect>
);

export default ReviewSelect;
