import styled from 'styled-components';
import StyleguideCheckbox from 'styleguide/components/Checkbox';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 600px;
  min-height: 400px;
  max-width: 100vw;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  padding: 5rem;
  text-align: center;
`;

export const Checkbox = styled(StyleguideCheckbox)`
  display: flex;
`;

export const SuggestionText = styled.p`
  font-size: 15px;
  line-height: 22px;
`;
