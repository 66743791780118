import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { authApi } from 'api';
import { PASSWORD_VALIDATION } from 'helpers/validation';
import SimpleLayout from 'layout/simpleLayout';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';

import ResetPasswordFirstStep from './ResetPasswordFirstStep';
import ResetPasswordSecondStep from './ResetPasswordSecondStep';
import ResetPasswordError from './ResetPasswordError';
import { Wrapper } from './styled';

export interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

export interface ResetPasswordFirstStepProps {
  onSubmit: (values: ResetPasswordFormValues) => void;
  validationSchema: yup.ObjectSchema<ResetPasswordFormValues | undefined>;
  initialValues: ResetPasswordFormValues;
  isLoading: boolean;
  passwordPlaceholder: string;
  confirmPasswordPlaceholder: string;
}
export interface ResetPasswordSecondStepProps {
  currentPassword?: string;
  currentEmail?: string;
}
export interface ResetPasswordProps {
  oobCode: string;
}

const ResetPassword: FC<ResetPasswordProps> = ({ oobCode }) => {
  const { formatMessage } = useIntl();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const initialValues = { password: '', confirmPassword: '' };
  const passwordPlaceholder = formatMessage({
    id: 'resetPassword.passwordPlaceholder'
  });
  const confirmPasswordPlaceholder = formatMessage({
    id: 'resetPassword.passwordConfirmationPlaceholder'
  });
  const passwordError = formatMessage({
    id: `errors.password`
  });
  const confirmPasswordError = formatMessage({
    id: `errors.confirmPassword`
  });

  const validationSchema = yup.object().shape<ResetPasswordFormValues>({
    password: yup
      .string()
      .required(passwordError)
      .matches(PASSWORD_VALIDATION, passwordError),
    confirmPassword: yup
      .string()
      .required(passwordError)
      .matches(PASSWORD_VALIDATION, passwordError)
      .oneOf([yup.ref('password'), ''], confirmPasswordError)
  });

  const onNextStep = () => {
    setCurrentStep(prevState => {
      const nextStep = prevState + 1;
      return nextStep < steps.length ? nextStep : prevState;
    });
  };

  const onSubmit = async ({
    password,
    confirmPassword
  }: ResetPasswordFormValues) => {
    setIsLoading(true);

    try {
      await authApi()
        .resetPassword()
        .confirm()
        .POST({ password, confirmPassword, oobCode });
      setCurrentPassword(password);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(true);
    onNextStep();
  };

  const verifyOobCode = useCallback<() => void>(async () => {
    try {
      const userData = await authApi()
        .resetPassword()
        .verify()
        .POST({ oobCode });
      setCurrentEmail(userData.data);
      setIsLoaded(true);
      setIsError(false);
    } catch {
      setIsLoaded(true);
      setIsError(true);
    }
  }, [oobCode]);

  useEffect(() => {
    verifyOobCode();
    localStorage.removeItem('pendingEmail');
  }, [verifyOobCode]);

  const steps = [
    <ResetPasswordFirstStep
      onSubmit={onSubmit}
      passwordPlaceholder={passwordPlaceholder}
      confirmPasswordPlaceholder={confirmPasswordPlaceholder}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={isLoading}
    />,
    <ResetPasswordSecondStep
      currentEmail={currentEmail}
      currentPassword={currentPassword}
    />
  ];

  if (!isLoaded) {
    return null;
  }

  if (isError) {
    return <ResetPasswordError />;
  }

  return (
    <SimpleLayout>
      <Wrapper>
        <Spacing direction="top" value={95} />
        <BasicHeading tag="h1" size="xxxl" isCentered>
          <FormattedMessage id="resetPassword.title" />
        </BasicHeading>
        <Spacing direction="top" value={54} />

        {steps[currentStep]}
      </Wrapper>
    </SimpleLayout>
  );
};

export default ResetPassword;
