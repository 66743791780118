export const permissions = [
  {
    name: 'View the shared matter',
    limitedViewer: true,
    viewer: true,
    collaborator: true,
    reviewer: true,
    owner: true
  },
  {
    name: 'Download files and reports',
    limitedViewer: true,
    viewer: true,
    collaborator: true,
    reviewer: true,
    owner: true
  },
  {
    name: 'View invoices',
    limitedViewer: true,
    viewer: true,
    collaborator: true,
    reviewer: true,
    owner: true
  },
  {
    name: 'View notes',
    limitedViewer: false,
    viewer: true,
    collaborator: true,
    reviewer: true,
    owner: true
  },
  {
    name: 'Add notes',
    limitedViewer: false,
    viewer: false,
    collaborator: true,
    reviewer: true,
    owner: true
  },
  {
    name: 'View or change review status',
    limitedViewer: false,
    viewer: false,
    collaborator: false,
    reviewer: true,
    owner: true
  },
  // {
  //   name: 'View or change priority flags',
  //   limitedViewer: false,
  //   viewer: false,
  //   collaborator: false,
  //   reviewer: true,
  //   owner: true
  // },
  {
    name: 'Manage access\n(send invites,\ncreate shareable links)',
    limitedViewer: false,
    viewer: false,
    collaborator: false,
    reviewer: false,
    owner: true
  },
  {
    name: 'Add to the order',
    limitedViewer: false,
    viewer: false,
    collaborator: false,
    reviewer: false,
    owner: true
  },
  {
    name: 'Assign a reviewer',
    limitedViewer: false,
    viewer: false,
    collaborator: false,
    reviewer: false,
    owner: true
  }
];
