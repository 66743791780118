import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';

const Wrapper = styled.div`
  padding: 0 0px;
  display: flex;
  align-items: center;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  line-height: 1.3;
  padding: 20px 24px 17px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  @media (max-width:768px){
    padding: 20px 18px 18px;

  }

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primary};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      border-color: ${theme.colorsNewDesign.primaryHover};
      color: ${theme.colorsNewDesign.primaryHover};
      font-weight: ${theme.fontWeights.semiBold};
    `}
`;

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 10,
      top: 30,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      fontFamily: 'Gilroy',
      fontWeight: 'normal',
      backgroundColor: "#eb3434",
    },
  }),
)(Badge);

interface Tab {
  id: string;
  label: string;
  badge: number;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onChange: (id: string) => void;
  className?: string;
}

const Tabs: FC<TabsProps> = ({ tabs, activeTab, onChange, className }) => (
  <Wrapper className={className}>
    {tabs.map(tab => (
      <div key={tab.id}>
        {tab.badge > 0 ? <StyledBadge badgeContent={tab.badge} color="error">
          <Tab
            isActive={activeTab === tab.id}
            onClick={() => onChange(tab.id)}
          >
            {tab.label}
          </Tab>
        </StyledBadge> : <Tab
          isActive={activeTab === tab.id}
          onClick={() => onChange(tab.id)}
        >
          {tab.label}
        </Tab>}
      </div>
    ))}
  </Wrapper>
);

export default Tabs;
