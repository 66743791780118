import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/app';

const devConfig = {
  // apiKey: "AIzaSyBF-PPW0M25uvw6BXg9D2tuv92kwufzqZI",
  // authDomain: "dealbinder-live-dev.firebaseapp.com",
  // databaseURL: 'https://dealbinder-live-dev.firebaseio.com',
  // projectId: "dealbinder-live-dev",
  // storageBucket: "dealbinder-live-dev.appspot.com",
  // messagingSenderId: "610805849791",
  // appId: "1:610805849791:web:0fd17f658a2094abb5b00a",
  // measurementId: "G-BNNNXLC59Q"
  apiKey: "AIzaSyD5JHVdkmeweSa2G3EpEohJcKjBIM4bvog",
  authDomain: "dealbinder-live-develop.firebaseapp.com",
  databaseURL: 'https://dealbinder-live-develop.firebaseio.com',
  projectId: "dealbinder-live-develop",
  storageBucket: "dealbinder-live-develop.appspot.com",
  messagingSenderId: "420347923551",
  appId: "1:420347923551:web:b9712b97106ffabbb15fea",
  measurementId: "G-C8CN0FNH4M"
  // apiKey: "AIzaSyBfskl2tmkR4U_Tev13ukrI0rrH0l4SdyY",
  // authDomain: "dealbinder-dev.firebaseapp.com",
  // databaseURL: "https://dealbinder-dev.firebaseio.com",
  // projectId: "dealbinder-dev",
  // storageBucket: "dealbinder-dev.appspot.com",
  // messagingSenderId: "820266560808",
  // appId: "1:820266560808:web:e10a009763281bba89f41d",
  // measurementId: "G-68T68S0NE7"
};

const stagingConfig = {
  apiKey: 'AIzaSyBUPUfXvkfbKeATeKCHca-aDfhVh5A5qWQ',
  authDomain: 'dealbinder-staging.firebaseapp.com',
  databaseURL: 'https://dealbinder-staging.firebaseio.com',
  projectId: 'dealbinder-staging',
  storageBucket: 'dealbinder-staging.appspot.com',
  messagingSenderId: '1067881874291',
  appId: '1:1067881874291:web:90d4a575617557aee67b06',
  measurementId: 'G-RT9TM9GF2D'
};

const productionConfig = {
  // apiKey: 'AIzaSyAA-uB_wFdMjqnReCx6CR9o800Xn5d9HaY',
  // authDomain: 'dealbinder-prod.firebaseapp.com',
  // databaseURL: 'https://dealbinder-prod.firebaseio.com',
  // projectId: 'dealbinder-prod',
  // storageBucket: 'dealbinder-prod.appspot.com',
  // messagingSenderId: '89988291198',
  // appId: '1:89988291198:web:b786569b6c5e1f1ce5162f',
  // measurementId: 'G-VFQ5XYJ4J8'
  apiKey: "AIzaSyAkQNa030yUdGUXXu0JUm1v7EiOgqFyTvk",
  authDomain: "dealbinder-live-prod.firebaseapp.com",
  projectId: "dealbinder-live-prod",
  databaseURL: 'https://dealbinder-live-prod.firebaseio.com',
  storageBucket: "dealbinder-live-prod.appspot.com",
  messagingSenderId: "380976969575",
  appId: "1:380976969575:web:a8bf3c330060452b3661c2",
  measurementId: "G-D06C41RXVY"
};

let config: typeof devConfig;

if (!firebase.apps.length) {
  switch (process.env.REACT_APP_ENVIROMENT) {
    case 'STAGING':
      config = stagingConfig;
      break;
    case 'PRODUCTION':
      config = productionConfig;
      break;
    default:
      config = devConfig;
  }
  firebase.initializeApp(config);
}
// Initialize Firebase
const auth = firebase.auth();

export { auth, firebase, config };
