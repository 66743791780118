import formatString from 'format-string-by-pattern';
import { format, parseISO } from 'date-fns';

export type maskType = 'postalCode' | 'phoneNumber';

export const postalCodeFormatter = (postalCode: string) =>
  formatString('12345-1234', postalCode);

export const phoneNumberFormatter = (phoneNumber: string) =>
  formatString('(123) 123-1234', phoneNumber.replace(/[^\d]/g, ''));

export const getFormatter = (mask: maskType) => {
  switch (mask) {
    case 'postalCode':
      return postalCodeFormatter;
    case 'phoneNumber':
      return phoneNumberFormatter;
    default:
      return (textToFormat: string) => textToFormat;
  }
};

export const noSpecialCharacters = new RegExp(/[^a-zA-Z0-9]/g);

export const currencyFormat = (value: any) =>
  !isNaN(value)
    ? Number(parseFloat(value).toFixed(2)).toLocaleString('en', {
        minimumFractionDigits: 2
      })
    : '';

export const shortDateFormat = (date: string) =>
  new Date(date).toLocaleDateString([], {
    dateStyle: 'short'
  } as any);

export const etaDateFormat = (date: string) =>
  format(parseISO(date), 'EEE, MM/dd');
