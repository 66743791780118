import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import BasicHeading from 'styleguide/components/BasicHeading';

import { ReactComponent as IconCircle } from 'components/icons/IconCheckNewDesign.svg';

interface WrapperProps {
  hasSpacingBetweenItems?: boolean;
}

export type onChangeProps = {
  value: boolean | number | string;
  name?: string;
  isActive?: boolean;
};

interface ButtonProps {
  icon?: ReactNode;
  label: string | ReactNode;
  onChange: (props: onChangeProps) => void;
  accessory?: ReactNode;
  value: boolean | string | number;
  name: string;
  type: 'checkbox' | 'radio';
  wide?: boolean;
}

interface ButtonChildrenStyledProps {
  isActive?: boolean;
  wide?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;

  ${({ theme, hasSpacingBetweenItems }) =>
    hasSpacingBetweenItems
      ? css`
          & > * {
            border-radius: ${theme.borderRadius.strong};
          }

          & > *:not(:first-child) {
            margin-left: 24px;
          }
        `
      : css`
          & > * {
            border-radius: 0;
            margin: 0;
          }

          & > *:first-child {
            border-top-left-radius: ${theme.borderRadius.strong};
            border-bottom-left-radius: ${theme.borderRadius.strong};
          }

          & > *:last-child {
            border-top-right-radius: ${theme.borderRadius.strong};
            border-bottom-right-radius: ${theme.borderRadius.strong};
          }

          & > *:not(:first-child) {
            border-left-color: transparent;
          }
        `};
`;

const ButtonHeading = styled(BasicHeading)<ButtonChildrenStyledProps>`
  ${({ theme, isActive }) =>
    isActive &&
    css`
      color: ${theme.colorsNewDesign.primary};
    `}
`;

const ButtonIconWrapper = styled.div<ButtonChildrenStyledProps>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};

  ${({ theme, isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colorsNewDesign.lightest};
    `}
`;

const ButtonWrapper = styled.div<ButtonChildrenStyledProps>`
  position: relative;
  cursor: pointer;
  width: 210px;
  height: 204px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  box-shadow: ${({ theme }) => theme.boxShadow.strong};

  &:hover {
    background-color: ${({ theme }) => theme.colorsNewDesign.grayBlue100};

    & ${ButtonHeading} {
      color: ${({ theme }) => theme.colorsNewDesign.primary};
    }

    & ${ButtonIconWrapper} {
      background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
    }
  }

  ${({ theme, isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colorsNewDesign.grayBlue100};
      border: 2px solid ${({ theme }) => theme.colorsNewDesign.primary} !important;
    `}
  
  ${({ wide }) => wide && `
    width: 300px;
  `}
`;

const ButtonAccessory = styled.div`
  position: absolute;
  top: 18px;
  left: 18px;
`;

const StyledCheckIcon = styled(IconCircle)`
  position: absolute;
  top: 18px;
  right: 18px;
  height: 21px;
  width: 21px;
`;

const ButtonContent = styled.div<{hasIcon?:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({hasIcon}) => hasIcon && css`
    & > *:not(:first-child) {
      margin-top: 24px;
    }
  `}
`;

const ButtonInput = styled.input`
  display: none;
`;

const Button: FC<ButtonProps & ButtonChildrenStyledProps> = ({
  icon,
  label,
  isActive,
  onChange,
  value,
  name,
  accessory,
  type = 'checkbox',
  wide
}) => {
  const id = `${name}-${value}`;
  const onClick = () => {
    onChange({ value, name, isActive });
  };

  return (
    <ButtonWrapper onClick={onClick} isActive={isActive} wide={wide}>
      {accessory && <ButtonAccessory>{accessory}</ButtonAccessory>}
      {isActive && <StyledCheckIcon />}

      <ButtonContent hasIcon={!!icon}>
        {icon && (
          <ButtonIconWrapper isActive={isActive}>{icon}</ButtonIconWrapper>
        )}

        <ButtonInput id={id} type={type} name={name} value={value.toString()} />

        <ButtonHeading tag="h4" size="l" isActive={isActive}>
          {label}
        </ButtonHeading>
      </ButtonContent>
    </ButtonWrapper>
  );
};

export default {
  Wrapper,
  Button
};
