import React, { FC, useState, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import BaseLink from 'components/shared/baseLink/BaseLink';
import StyleguideButton from 'styleguide/components/Button';
export interface BaseModalProps {
  title: ReactNode;
  content: string;
  onOpen: () => void | Promise<void>;
  buttonText: ReactNode;
}

const StyledTitle = styled(Modal.Title)`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

const StyledBaseLink = styled(BaseLink)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

const Button = styled(StyleguideButton)`
  order: 0;
  padding: 8px 23px;
`;

const StyledModal = styled(Modal)`
  z-index: 9999;
  .modal-dialog {
    height: 100%;
    margin-top: 0;
    padding-top: 1.75rem;
    margin-bottom: 0;
    padding-bottom: 1.75rem;
    max-width: 600px;

    @media (max-width: 768px) {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      max-width: 100%;
    }
  }

  .modal-content {
    max-height: 100%;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header {
    flex-shrink: 0;
  }

  .modal-footer {
    flex-shrink: 0;
  }
`;

const BaseModal: FC<BaseModalProps> = ({
  title,
  content,
  onOpen,
  buttonText
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onClick = async () => {
    await onOpen();
    handleShow();
  };

  return (
    <>
      <StyledBaseLink onClick={onClick} href="#">
        {buttonText}
      </StyledBaseLink>

      <StyledModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <StyledTitle>{title}</StyledTitle>
        </Modal.Header>

        <Modal.Body dangerouslySetInnerHTML={{ __html: content }} />

        <Modal.Footer>
          <Button primary medium onClick={handleClose}>
            <FormattedMessage id="shared.close" />
          </Button>
        </Modal.Footer>
      </StyledModal>
    </>
  );
};

export default BaseModal;
