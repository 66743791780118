import React from 'react';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useHistory } from 'react-router-dom';
import { useOrderFormState } from './state/state';
import { STEP_NAMES, DILIGENCE_STEPS, CHARTER_DOCS_PRODUCT_ID } from './consts';
import { SearchFormStates } from './searches';
import { DocumentsFormStates } from './documents';
import LoadingLayout from 'layout/loadingLayout/LoadingLayout';
import { fetchOrderData, OrderData } from 'api/order';

export const orderToFormData = (response: OrderData) => {
  const oli = response.orderLineItems;

  const products = oli.map(o => o.product);
  const hasSearches = products.some(p => p.family === 'Searches');
  const hasOFAC = products.some(p => p.family === 'OFAC');
  const hasDocuments = products.some(
    p => p.family === 'Organizational Documents'
  );

  const diligence = {
    diligence: {
      searches: hasSearches,
      ofac: hasOFAC,
      documents: hasDocuments
    }
  };

  const steps = [
    STEP_NAMES.Start,
    STEP_NAMES.Diligence,
    ...DILIGENCE_STEPS.filter(step => diligence.diligence[step as keyof {}]),
    STEP_NAMES.Review
  ];

  let searches;

  if (hasSearches) {
    const searchesOli = oli.filter(o => o.product.family === 'Searches');
    const searchesNames = uniq(searchesOli.map(o => o.diligenceName));
    const searchesJurisdictions = uniqBy(
      searchesOli.map(o => ({
        value: o.jurisdiction.id,
        label: o.jurisdiction.name
      })),
     el => el.value
    );
    const searchesSelection: string[][][] = [
      ...searchesJurisdictions.map(() => [])
    ];
    searchesOli.forEach(o => {
      const column = searchesJurisdictions.findIndex(
        j => j.value === o.jurisdiction.id
      );
      const row = searchesNames.findIndex(n => n === o.diligenceName);
      searchesSelection[column][row] = searchesSelection[column][row] || [];
      searchesSelection[column][row].push(o.product.id);
    });

    searches = {
      names: searchesNames,
      jurisdictions: searchesJurisdictions,
      selection: searchesSelection,
      state: SearchFormStates.Types
    };
  }

  let ofac;
  if (hasOFAC) {
    const ofacNames = oli
      .filter(o => o.product.name === 'OFAC')
      .map(o => o.diligenceName);

    ofac = {
      previousNames: hasSearches ? searches.names : [],
      names: ofacNames
    };
  }

  let documents;
  if (hasDocuments) {
    const docsOli = oli.filter(
      o => o.product.family === 'Organizational Documents'
    );
    const docsNames = uniq(docsOli.map(o => o.diligenceName));
    const docsJurisdictions = uniqBy(
      docsOli.map(o => ({
        value: o.jurisdiction.id,
        label: o.jurisdiction.name
      })),
      el => el.value
    );
    const docsSelection: string[][][] = [...docsJurisdictions.map(() => [])];
    docsOli.forEach(o => {
      const column = docsJurisdictions.findIndex(
        j => j.value === o.jurisdiction.id
      );
      const row = docsNames.findIndex(n => n === o.diligenceName);
      docsSelection[column][row] = docsSelection[column][row] || [];
      docsSelection[column][row].push(o.product.id);
    });

    const hasCharterDocs = docsOli.some(
      o => o.product.id === CHARTER_DOCS_PRODUCT_ID
    );
    const hasGoodStanding = docsOli.some(
      o => o.product.name === 'Good Standing'
    );
    const isWithAmendments = response.charterHistoryType === 'withAmendments';
    const isCertifiedCopy = response.charterFormat === 'Certified';

    documents = {
      names: docsNames,
      previousNames: uniq([
        ...(searches ? searches.names : []),
        ...(ofac ? ofac.names : [])
      ]),
      jurisdictions: docsJurisdictions,
      charterDocuments: hasCharterDocs,
      goodStanding: hasGoodStanding,
      withAmendments: isWithAmendments,
      certifiedCopy: isCertifiedCopy,
      selection: docsSelection,
      state: DocumentsFormStates.Review
    };
  }
  const review = {};

  return {
    steps,
    validSteps: steps,
    currentStep: STEP_NAMES.Review,
    diligence,
    searches,
    documents,
    ofac,
    review,
    order: response
  };
};

const Estimation = (props: any) => {
  const history = useHistory();
  const [, { loadState }] = useOrderFormState();
  const matterId = props.match.params.matterid;
  const id = props.match.params.id;

  React.useEffect(() => {
    fetchOrderData(matterId, id).then(order => {
      if (order.stage === 'estimate') {
        const data = orderToFormData(order);
        loadState({
          ...data,
          matterId,
          orderId: id,
          shouldDeleteEstimate: true
        });
        history.push('/order/review');
      } else {
        history.push('/');
      }
    });
  }, []); // eslint-disable-line

  return <LoadingLayout />;
};

export default Estimation;
