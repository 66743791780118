import React, { FC } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Tag from 'styleguide/components/Tag';
import MaterialTable, { cellStyles } from 'styleguide/components/MaterialTable';
import { Order } from 'api/matter';
import { format, parseISO } from 'date-fns';

import { InvoicesModalContent } from './styled';

interface InvoicesModalProps {
  isOpen: boolean;
  onClose: () => void;
  orders: Order[];
  matterName: string;
}

const InvoicesModal: FC<InvoicesModalProps> = ({
  matterName,
  isOpen,
  onClose,
  orders
}) => {
  return (
  <BasicModal
    isOpen={isOpen}
    onClose={onClose}
    title={
      <BasicHeading tag="h2" size="xl">
        {matterName}
      </BasicHeading>
    }
  >
    <InvoicesModalContent>
      <MaterialTable
        materialTableProps={{
          columns: [
            {
              title: 'Order date',
              field: 'orderDate',
              render: ({ orderDate, createdAt }) => {
                const date = orderDate || createdAt;
                return date ? format(parseISO(date), 'M/dd/yy') : null;
              }
            },
            {
              title: 'Invoice status',
              field: 'invoiceStatus',
              cellStyle: cellStyles.gray,
              render: ({ invoiceStatus }) => {
                if (invoiceStatus === 'Outstanding') {
                  return (
                    <Tag color="red" isFilled>
                      {invoiceStatus}
                    </Tag>
                  );
                }
                if (invoiceStatus === 'Paid') {
                  return (
                    <Tag color="cyan" isFilled>
                      {invoiceStatus}
                    </Tag>
                  );
                }
                return invoiceStatus;
              }
            },
            {
              title: 'Invoice',
              field: 'invoiceLink',
              cellStyle: cellStyles.gray,
              render: ({ invoiceLink }) =>
                invoiceLink ? (
                  <a
                    href={invoiceLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View
                  </a>
                ) : (
                  '-'
                )
            }
          ],
          data: orders,
          options: { paging: false }
        }}
      />
    </InvoicesModalContent>
  </BasicModal>
)};

export default InvoicesModal;
