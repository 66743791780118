import styled from 'styled-components';
import { Tab } from 'styleguide/components/Tabs';

export const Wrapper = styled.div`
  background: white;
  box-shadow: 0 1px 0 ${({ theme }) => theme.colorsNewDesign.gray100};
  width: 100%;
  display: flex;
  ${Tab} {
      font-size: ${({ theme }) => theme.fontSizesNewDesign.md};
    }

    @media (max-width: 500px) {
      ${Tab} {
      font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
    }
    } 
`;