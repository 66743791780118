import React, { FC } from 'react';
import { FieldArray } from 'formik';
import styled from 'styled-components';

import Button from 'styleguide/components/Button';

interface FieldProps {
  name: string;
  callback?: () => void;
}

const StyledButton = styled(Button)`
  padding: 11px;
  width: 35px;
  height: 35px;
`;

const AddElement: FC<FieldProps> = ({ name, callback }) => (
  <FieldArray
    name={name}
    render={elements => (
      <StyledButton primary type="button" onClick={() => { elements.push(''); callback && callback(); }}>
        +
      </StyledButton>
    )}
  />
);

export default AddElement;
