import React, {
  FC,
  useState,
  useEffect,
  useMemo
} from 'react';
import styled from 'styled-components';
// import { FormattedMessage } from 'react-intl';
import { auth } from 'firebaseConfig';
import { useHistory } from 'react-router-dom';

import { theme } from 'theme/theme';
import { ERROR_URL } from 'helpers/routes';
// import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
import SimpleLayout from 'layout/simpleLayout';
import Spacing from 'styleguide/components/Spacing';
// import BasicText from 'styleguide/components/BasicText';
import Error from 'styleguide/components/Error';
import Loader from 'styleguide/components/Loader';

import ExpiredCode from './ExpiredCode';

// import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
// import BasicText from 'styleguide/components/BasicText';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
`;

// const FooterText = styled(BasicText)`
//   span {
//     font-size: inherit;
//   }
// `;

interface MagicLinkProps {
  continueUrl: string;
  sharedCode: string;
  inviteeEmail: string;
}

const signInUser = async (email: string): Promise<any> =>
  await auth.signInWithEmailLink(email, window.location.href);

const MagicLink: FC<MagicLinkProps> = ({ continueUrl, sharedCode, inviteeEmail }) => {
  const history = useHistory();
  // const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const matterId = useMemo(() => {
    return continueUrl.split('/').pop();
  }, [continueUrl]);

  const email = useMemo(() => `share-${sharedCode}@dealbinder.com`, [sharedCode]);
  useEffect(() => {
    if (!auth.isSignInWithEmailLink(window.location.href)) {
      history.push(ERROR_URL);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    setIsLoading(true);
    if (auth.currentUser && auth.currentUser.displayName === inviteeEmail) {
      setIsLoading(false);
      window.location.href = continueUrl;
    } else {
      signInUser(email)
        .then(async () => {
          await auth.currentUser.reload();
          const currentUser = await auth.currentUser;
          await auth.updateCurrentUser(currentUser);
          setIsLoading(false);
          window.location.href = continueUrl;
        })
        .catch(e => {
          setIsLoading(false);
          if (e.code === 'auth/expired-action-code' || e.code === 'auth/invalid-action-code') {
            setIsExpired(true);
          } else {
            setIsInvalid(true);
          }
        });
    }
  }, [email, continueUrl, inviteeEmail]);

  if (isExpired) {
    return <ExpiredCode code={sharedCode} matterId={matterId} inviteeEmail={inviteeEmail} />;
  }

  return (
    <SimpleLayout>
      <Wrapper>
        <Spacing direction="top" value={64} />
        {isInvalid && (
          <>
            <Spacing direction="top" value={10} />
            <Error>That code is invalid.</Error>
          </>
        )}
        {isLoading && (
          <>
            <Spacing direction="top" value={16} />
            <Loader
              marginLess
              centered
              background={theme.colorsNewDesign.lightBlue}
            />
          </>
        )}
        <Spacing direction="top" value={14} />
        {/* {(!auth.currentUser || auth.currentUser.displayName !== inviteeEmail) && <FooterText size="sm">
          By accessing this site you agree to our{' '}
          <LegalPagesModal
            title={<FormattedMessage id="links.privacyPolicy" />}
            type="privacyPolicy"
          />{' '}
          and{' '}
          <LegalPagesModal
            title={<FormattedMessage id="links.termsOfService" />}
            type="termsOfService"
          />
          .
        </FooterText>} */}
      </Wrapper>
    </SimpleLayout>
  );
};

export default MagicLink;
