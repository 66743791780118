import styled, { css } from 'styled-components';

export interface TextInputProps {
  hasNoMargin?: boolean;
  invalid?: boolean;
  isHighlighted?: boolean;
  isCellInput?: boolean;
  fontSize?:string;
}

const TextInput = styled.input<TextInputProps>`
  display: block;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.regular};
  padding: 16px;
  margin: ${({ hasNoMargin }) => (hasNoMargin ? '0' : `15px 0`)};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme,fontSize }) => fontSize || theme.fontSizesNewDesign.sm};
  line-height: 1.2;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};

  &::placeholder {
    color: ${({ theme }) => theme.colorsNewDesign.gray400};
  }

  ${({ invalid, theme }) =>
    invalid &&
    css`
      border: 1px solid ${theme.colorsNewDesign.error} !important;
    `}

  &:focus {
    border: 1px solid ${({ theme }) => theme.colorsNewDesign.primary};
    outline: none;
  }

  ${({ isCellInput }) =>
    isCellInput &&
    css`
      width: 100%;
      border: 1px solid transparent;
      margin: 0;
      padding: 12px 16px;
      line-height: inherit;
      border-radius: 0;
      font-size: inherit;
    `}

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    css`
      background: ${theme.colorsNewDesign.background};
      border: 1px solid ${theme.colorsNewDesign.primary};
      box-shadow: ${theme.boxShadow.highlight};
      border-radius: ${theme.borderRadius.regular};
    `}
`;

export default TextInput;
