import React, { FC, useState, useEffect } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import Snackbar from 'styleguide/components/Snackbar';
import BasicText from 'styleguide/components/BasicText';
import { ReactComponent as DeleteIcon } from 'components/icons/IconDelete2.svg';

import {
  DeleteModalContent,
  DeleteModalFooter,
  DeleteButton,
  RoundIcon,
  DeleteModalConfirmationWrapper
} from './styled';
import { DeleteMatterModalData } from './';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  deleteMatterData: DeleteMatterModalData;
  onDelete: (id: string) => void;
}

enum ModalSteps {
  CONFIRMATION,
  SUCCESS
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  deleteMatterData,
  onDelete
}) => {
  const [modalStep, setModalStep] = useState(ModalSteps.CONFIRMATION);

  useEffect(() => {
    if (isOpen) {
      setModalStep(ModalSteps.CONFIRMATION);
    }
  }, [isOpen]);

  if (modalStep === ModalSteps.SUCCESS) {
    return (
      <BasicModal isOpen={isOpen} onClose={onClose} title=" ">
        <DeleteModalConfirmationWrapper>
          <Spacing direction="top" value={32} />
          <Snackbar type="success">Matter deleted</Snackbar>
          <Spacing direction="top" value={93} />
        </DeleteModalConfirmationWrapper>
      </BasicModal>
    );
  }

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <BasicHeading tag="h2" size="xl">
          Delete matter
        </BasicHeading>
      }
    >
      <DeleteModalContent>
        <Spacing direction="top" value={33} />
        <RoundIcon>
          <DeleteIcon />
        </RoundIcon>
        <Spacing direction="top" value={32} />
        <BasicHeading tag="h3" size="xl">
          Are you sure you want to delete this matter:
          <br />
          {deleteMatterData?.matterName}
        </BasicHeading>
        <Spacing direction="top" value={16} />
        <BasicText size="regular">
          This will permanently delete the matter and all order results, files,
          notes, reports, and all other information associated with it. Anyone
          you have invited to view this matter will lose access to it.
        </BasicText>
        <Spacing direction="top" value={32} />
      </DeleteModalContent>
      <DeleteModalFooter>
        <Button medium onClick={() => onClose()}>
          Cancel
        </Button>
        <DeleteButton
          medium
          primary
          onClick={() => {
            onDelete(deleteMatterData?.matterId);
            setModalStep(ModalSteps.SUCCESS);
          }}
        >
          Yes, delete
        </DeleteButton>
      </DeleteModalFooter>
    </BasicModal>
  );
};

export default DeleteModal;
