import React, { FC, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ReactComponent as AppsIcon } from 'components/icons/IconApps.svg';

import Spacing from 'styleguide/components/Spacing';
import {
  LOGIN_URL,
  REGISTRATION_URL,
} from 'helpers/routes';

import {
  Wrapper,
  HorizontalLine,
  SidebarLink,
  LinksContainer,
  Button,
  FooterWrapper,
  ContentContainer,
  BottomLinksWrapper
} from './styled';

const SidebarAuth: FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const handleRedirect = useCallback(
    url => {
      history.push(url);
    },
    [history]
  );

  return (
    <Wrapper>
      <HorizontalLine width="100%" />
      <LinksContainer>
        <Spacing direction="top" value={5} />
        <SidebarLink
          icon={<AppsIcon />}
          active={pathname === LOGIN_URL}
          onClick={() => handleRedirect(LOGIN_URL)}
        >
          Home
        </SidebarLink>
        <Spacing direction="top" value={5} />
        <Button
          primary
          medium
          width="100%"
          active={pathname === LOGIN_URL}
          onClick={() => handleRedirect(REGISTRATION_URL)}
        >
          Sign up
        </Button>
        <Spacing direction="top" value={5} />
        <Button
          primary
          medium
          width="100%"
          active={pathname === REGISTRATION_URL}
          onClick={() => handleRedirect(LOGIN_URL)}
        >
          Sign in
        </Button>
        <BottomLinksWrapper>
        </BottomLinksWrapper>
      </LinksContainer>

      <HorizontalLine width="100%" />
      <ContentContainer>
        <FooterWrapper>
        </FooterWrapper>
      </ContentContainer>
    </Wrapper>
  );
};

export default SidebarAuth;
