import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import RoundIcon from 'styleguide/components/RoundIcon';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import Snackbar from 'styleguide/components/Snackbar';
import HorizontalLine from 'styleguide/components/HorizontalLine';
import { ReactComponent as IconEmail } from 'components/icons/IconEmail.svg';
import { LOGIN_URL } from 'helpers/routes';

import { StyledBaseLink, HelpText } from './styled';

const ForgotPasswordSecondStep: FC = () => (
  <>
    <Spacing direction="top" value={80} />
    <RoundIcon>
      <IconEmail />
    </RoundIcon>
    <Spacing direction="top" value={35} />
    <BasicHeading tag="h1" size="xxxl" isCentered>
      <FormattedMessage id="forgotPassword.confirmationTitle" />
    </BasicHeading>
    <Spacing direction="top" value={46} />
    <Snackbar type="success">
      <FormattedMessage id="forgotPassword.confirmation" />
    </Snackbar>
    <Spacing direction="top" value={39} />
    <HorizontalLine width="320px" />
    <Spacing direction="top" value={24} />
    <HelpText size="regular">
      <FormattedMessage id="forgotPassword.description" />
    </HelpText>
    <Spacing direction="top" value={16} />
    <StyledBaseLink to={LOGIN_URL}>
      <FormattedMessage id="shared.signIn" />
    </StyledBaseLink>
  </>
);

export default ForgotPasswordSecondStep;
