import React, { FC } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { UseAsyncPaginateParams } from 'react-select-async-paginate';
import styled from 'styled-components';
import Table, { Row, Cell } from 'styleguide/components/Table';
import DeleteButton from 'styleguide/components/DeleteButton';
import TextInput from 'styleguide/components/TextInput';
import TableAccessory from 'styleguide/components/TableAccessory';
import DisableSelect from 'styleguide/components/DisableSelect';
import { useDragState } from './dragState';
import AddElement from './AddElement';
import {
  removeCol,
  removeRow,
  handlePasteEvent,
  useColumnKeyNavigation,
  useRowKeyNavigation
} from './utils';
import { SearchesFormValues } from '../../searches';
import { OptionType } from 'styleguide/components/Select';

export type TableFormCell = React.FC<{
  col: number;
  row: number;
}>;

export type SelectComponent = React.FC<
  {
    name: string;
    setRef: (ref: HTMLInputElement) => void;
    onKeyDown: (e: React.KeyboardEvent) => void;
    disabledIds?: string[];
    isHighlighted?: boolean;
    col: number;
  } & Pick<
    UseAsyncPaginateParams<OptionType, any>,
    'menuIsOpen' | 'onMenuOpen' | 'onMenuClose'
  >
>;

export type HeaderSelectComponent = React.FC<{
  col: number;
  title?: string;
}>;

interface TableFormProps {
  component: TableFormCell;
  selectComponent: SelectComponent;
  headerSelectComponent?: HeaderSelectComponent;
}

const StyledTextInput = styled(TextInput)`
  padding: 6px 10px;
  font-size: 13px;
  min-height: 35px;
`;

const StyledCell = styled(Cell)`
  padding: 10px 8px;
  width: 35px;
  height: 35px;
`;



const TableForm: FC<TableFormProps> = ({
  component: Component,
  selectComponent: SelectComponent,
  headerSelectComponent: HeaderSelectComponent
}) => {
  const { values, setFieldValue } = useFormikContext<SearchesFormValues>();
  const [{ active, currentCell }, { setActive }] = useDragState();

  const callback = React.useCallback(
    () => setFieldValue('names', [...values.names, '']),
    [setFieldValue, values.names]
  );
  const { handleKeyDown, inputRefs } = useRowKeyNavigation(
    values.names,
    callback
  );

  const selectCallback = React.useCallback(
    () => setFieldValue('jurisdictions', [...values.jurisdictions, '']),
    [setFieldValue, values.jurisdictions]
  );

  const selectkeys = useColumnKeyNavigation(
    values.jurisdictions,
    selectCallback
  );

  const handlePaste = React.useCallback(
    (event: React.ClipboardEvent, row: number) => {
      const pastedData = handlePasteEvent(event);
      if (pastedData) {
        const [firstValue, ...rest] = pastedData;
        const names = [...values.names];
        names.splice(row, 1, firstValue);
        setFieldValue('names', [...names, ...rest]);
      }
    },
    [setFieldValue, values.names]
  );

  

  return (
    <TableAccessory.Wrapper>
      <TableAccessory.Right>
        <DisableSelect disabled={active} onMouseLeave={() => setActive(false)}>
          <Table>
            <Row>
              <Cell isHighlighted isCentered >
                &nbsp;
              </Cell>
              <Cell isHighlighted isCentered >
                &nbsp;
              </Cell>
              <FieldArray
                name="jurisdictions"
                render={jurisdictions => (
                  <>
                    {values.jurisdictions.map((jurisdiction, col) => (
                      <StyledCell key={col} isCentered >
                        {values.jurisdictions.length > 1 ? (
                          <DeleteButton
                            type="button"
                            onClick={() => {
                              jurisdictions.remove(col);
                              setFieldValue(
                                `selection`,
                                removeCol(values.selection, col)
                              );
                            }}
                          />
                        ) : (
                          <div style={{ height: '14px' }} />
                        )}
                      </StyledCell>
                    ))}
                  </>
                )}
              />
              <Cell isCentered isSticky isFullSize>
                <AddElement
                  name="jurisdictions"
                  callback={selectkeys.openLastDropdown}
                />
              </Cell>
            </Row>
            <Row>
              <Cell isHighlighted isCentered >
                &nbsp;
              </Cell>
              <Cell isHighlighted isCentered>
                &nbsp;
              </Cell>
              {values.jurisdictions.map((jurisdiction, col) => (
                <Cell key={col} isFullSize >
                  <SelectComponent
                    name={`jurisdictions.${col}`}
                    setRef={(ref: HTMLInputElement) => {
                      selectkeys.inputRefs.current[col] = { current: ref };
                    }}
                    onKeyDown={(e: React.KeyboardEvent) =>
                      selectkeys.handleKeyDown(e, col)
                    }
                    menuIsOpen={selectkeys.dropdownOpen === col}
                    onMenuOpen={() => {
                      selectkeys.setDropdownOpen(col);
                    }}
                    onMenuClose={() => {
                      if (selectkeys.dropdownOpen === col)
                        selectkeys.setDropdownOpen(null);
                    }}
                    disabledIds={values?.jurisdictions
                      .map(j => j?.value)
                      .filter(Boolean)}
                    isHighlighted={currentCell.col === col}
                    col={col}
                  />
                </Cell>
              ))}

              <Cell isTransparent></Cell>
            </Row>
            {HeaderSelectComponent && (
              <Row>
                <Cell isHighlighted></Cell>
                <Cell isHighlighted></Cell>
                {values.jurisdictions.map((jurisdiction, col) => (
                  <Cell   isCentered key={col} isHighlighted>
                    <HeaderSelectComponent
                      col={col}
                      title={jurisdiction ? jurisdiction.label : null}
                    />
                  </Cell>
                ))}
                <Cell isTransparent></Cell>
              </Row>
            )}
            {values.names.map((name: string, row: number) => (
              <Row    key={row}>
                <StyledCell>
                  <FieldArray
                    name="names"
                    render={({ remove }) => (
                      <DeleteButton
                        type="button"
                        onClick={() => {
                          remove(row);
                          setFieldValue(
                            `selection`,
                            removeRow(values.selection, row)
                          );
                        }}
                      />
                    )}
                  />
                </StyledCell>
                <Cell
               
                  isFullSize
                >
                  <Field name={`names.${row}`}>
                    {({ field, meta: { error, touched } }: any) => (
                      <StyledTextInput
                        {...field}
                        type="text"
                        placeholder="Enter new name"
                        onPaste={(e: React.ClipboardEvent) =>
                          handlePaste(e, row)
                        }
                        onKeyDown={(e: React.KeyboardEvent) =>
                          handleKeyDown(e, row)
                        }
                        ref={inputRefs.current[row]}
                        autoFocus
                        invalid={!!(touched && error?.length)}
                        isHighlighted={currentCell.row === row}
                        isCellInput
                        autocomplete="ignoreThisField"
                        list="autoCompleteOff"
                      />
                    )}
                  </Field>
                </Cell>

                {values.jurisdictions.map((jurisdiction, col) => (
                  <Component
                    row={row}
                    col={col}
                    key={`jurisdiction.${col}.${row}`}
                  />
                ))}
                <Cell isTransparent></Cell>
              </Row>
            ))}
          </Table>
        </DisableSelect>
      </TableAccessory.Right>
      <TableAccessory.Bottom>
        <AddElement name="names" />
      </TableAccessory.Bottom>
    </TableAccessory.Wrapper>
  );
};

export default TableForm;
