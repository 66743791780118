import { createStore, createHook, StoreActionApi } from 'react-sweet-state';
import { auth } from 'firebaseConfig';

import { fetchDashboardData, DashboardData } from 'api/dashboard';
import { authApi } from 'api';

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;

interface State {
  data: DashboardData;
}

const {socket} = authApi();
const initialState: State = {
  data: {
    orders: {
      count: 0,
      list: []
    },
    estimates: {
      count: 0,
      list: []
    },
    outstandingMatters: {
      count: 0,
      list: []
    }
  }
};

const actions = {
  fetchData: () => async ({ setState, getState }: StoreApi): Promise<void> => {
    await auth.currentUser.getIdToken(true);
    const data = await fetchDashboardData();
    setState({ ...getState(), data });
    // console.log('dashboard', socket.connected);
    socket.emit('dashboard',data);
  },
  fetchLiveData: (data:any) => async ({ setState, getState }: StoreApi): Promise<void> => {
    setState({ ...getState(), data });
  }
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'dashboardHome'
});

export const useDashboardHomeState = createHook(Store);
