import styled from 'styled-components';
import Button from '../Button';

const GroupWrapper = styled.div`
  display: flex;
`;

const GroupButton = styled(Button)`
  border-radius: 0;
  font-size: 14px;
  padding: 12px 16px;
  margin: 0;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  &:hover {
    background: #fff;
    color: ${({theme}) => theme.colorsNewDesign.primary};
  }
  ${({ active, theme }) =>
    active &&
    `
    background: ${theme.colorsNewDesign.grayBlue100};
    color: ${theme.colorsNewDesign.primary};
    border: 1px solid ${theme.colorsNewDesign.grayBlue200};
    margin-left: -1px;
    &:hover {
      background: ${theme.colorsNewDesign.grayBlue100}; 
    }
    &:first-child {
      margin-left: 0;
      margin-right: -1px;
      z-index: 2;
    }
  `}
`;

export default {
  Wrapper: GroupWrapper,
  Button: GroupButton
};
