import React, { useState, useEffect, lazy, Suspense, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import Rollbar from 'rollbar';

import messages, { flattenMessages } from 'i18n';
import { theme } from 'theme/theme';
import GlobalStyles from 'theme/GlobalStyles';
import App from 'components/app/App';

import * as serviceWorker from './serviceWorker';
import { useDeviceState } from 'state/deviceState';

const Admin = lazy(() => import('admin'));

const defaultLocale = 'en';

// FIXME: refactor app wrapper;
const AppWrapper: React.FC = () => {
  const [admin, setAdmin] = useState(null);
  const [, { onSizeChanged }] = useDeviceState();

  useEffect(() => {
    setAdmin(!!window.location.pathname.match(/^\/admin/));
    new Rollbar({
      enabled: process.env.NODE_ENV === 'production',
      accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.REACT_APP_ENVIROMENT
      }
    });
    onSizeChanged(window.innerWidth);
  }, [onSizeChanged]);

  useLayoutEffect(() => {
    function updateSize() {
      onSizeChanged(window.innerWidth);
    }

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [onSizeChanged]);

  
  if (admin === null) return <div />;

  return admin ? (
    <Suspense fallback={null}>
      <Admin />
    </Suspense>
  ) : (
    <IntlProvider
      locale="en"
      messages={flattenMessages(messages[defaultLocale])}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <App />
      </ThemeProvider>
    </IntlProvider>
  );
};


ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
