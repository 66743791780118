import styled, { css } from 'styled-components';

import { ThemeType } from 'theme/theme';

interface StyledSubHeadingProps {
  size: keyof ThemeType['fontSizesNewDesign'];
  isCentered?: boolean;
  isUppercase?: boolean;
  isSlim?: boolean;
}

const StyledSubHeading = styled.h5<StyledSubHeadingProps>`
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme, size }) => theme.fontSizesNewDesign[size]};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  color: ${({ theme, color }) => theme.colorsNewDesign[color] || theme.colorsNewDesign.gray500};

  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `};

  ${({ isUppercase }) =>
    isUppercase &&
    css`
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-top: 2px;

      @media (max-width:769px){
        letter-spacing: 0px;

      }
    `};

  ${({ isSlim }) =>
    isSlim &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.regular};
    `};
`;

export default StyledSubHeading;
