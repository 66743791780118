import styled, { css } from 'styled-components';

export default styled.button<{ borderDirection?: 'left' | 'right',disabled?: boolean }>`
  display: flex;
  align-items: center;
  background: white;
  border: none;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  transition: color 100ms ease-in;
  padding: 20px;
  
  ${({ theme, borderDirection }) => css`
    border-${borderDirection || 'left'}: 1px solid ${
    theme.colorsNewDesign.gray100
  };
  `}

  svg {
    width: 24px;
    height: 24px;

    path {
      transition: fill 100ms ease-in;
      fill: ${({ theme }) => theme.colorsNewDesign.gray300};
      ${({ disabled }) => disabled && css`
        fill: ${({ theme }) => theme.colorsNewDesign.gray800};
      `}
    }
  }

  svg + * {
    margin-left: 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};

    svg path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
      ${({ disabled }) => disabled && css`
        fill: ${({ theme }) => theme.colorsNewDesign.gray800};
      `}
    }
  }
  @media (max-width:768px){
    border: none;
    padding: 8px;

  }
`;
