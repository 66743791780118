import { get } from 'lodash';

import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const fetchDashboardDataUrl = `${url}/dashboard/matters/estimates`;
const getDeleteEstimateUrl = (id: string, matterId: string): string =>
  `${url}/matters/${matterId}/orders/${id}`;

export interface Estimate {
  id: string;
  matterName: string;
  matterId: string;
  estimate: number;
  date: string;
}

export interface EstimateData {
  totalCount: number;
  data: Estimate[];
  page: number;
}

export interface FetchEstimatesFilters {
  page?: number;
  pageSize?: number;
  matterNames?: string[];
  dates?: string[];
}

const createEstimate = (responseEstimate: any): Estimate => ({
  id: get(responseEstimate, 'id'),
  matterName: get(responseEstimate, 'matter.name'),
  matterId: get(responseEstimate, 'matter.id'),
  estimate: get(responseEstimate, 'priceEstimate'),
  date: get(responseEstimate, 'createdAt')
});

export const fetchEstimates = async ({
  page = 1,
  pageSize = 10,
  matterNames,
  dates
}: FetchEstimatesFilters): Promise<EstimateData> => {
  const client = await getAuthorizedApi();
  const skip = (page - 1) * pageSize;
  const {
    data: [estimates, totalCount]
  } = await client.get(fetchDashboardDataUrl, {
    params: { take: pageSize, skip, names: matterNames, dates }
  });

  const estimatesData = {
    totalCount,
    data: estimates.map(createEstimate)
  };

  return {
    ...estimatesData,
    page: page - 1
  };
};

export interface DeleteEstimateArguments {
  id: string;
  matterId: string;
}

export const deleteEstimate = async ({
  id,
  matterId
}: DeleteEstimateArguments): Promise<void> => {
  const client = await getAuthorizedApi();
  const deleteUrl = getDeleteEstimateUrl(id, matterId);

  await client.delete(deleteUrl);
};
