import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { InvitedUser, CoworkerInfo } from 'api/matter';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import Button from 'styleguide/components/Button';
import TagsInput from 'styleguide/components/TagsInput';
import Loader from 'styleguide/components/Loader';
import Snackbar from 'styleguide/components/Snackbar';
import { EMAIL_VALIDATION } from 'helpers/validation';
import { isMagicLink } from 'helpers/helpers';
// import { useMatterState } from '../../state/matter';
import {
  Row,
  GrayText,
  InfoIcon,
  Footer,
  Label,
  SuggestionText,
  EmailWrapper,
  FieldDescription,
  InviteRow,
  ContentWrapperShareModal
} from './styled';

interface MagicLinkProps {
  fetchCoworkersEmail: () => Promise<void>;
  coworkersEmail: CoworkerInfo[];
  openPermissions: () => void;
  sendMagicLink: (email: string) => Promise<void>;
  onClose: () => void;
  invites: InvitedUser[];
}

const MagicLink: FC<MagicLinkProps> = ({
  fetchCoworkersEmail,
  coworkersEmail,
  openPermissions,
  sendMagicLink,
  onClose,
  invites
}) => {
  // const [, actions] = useMatterState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isError, setIsError] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState('');

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const resetValues = () => {
      setIsLoading(false);
      setEmails([]);
      setEmailInputValue('');
    };

    const invited_Emails_Ids = invites
      .map(invite => {
        return {
          email: isMagicLink(invite.contact)
            ? invite.shareContact?.firstName
            : invite.contact,
          id: invite.id,
          magicLink: isMagicLink(invite.contact)
        };
      })
      .filter(Boolean);

    const invitedByMagicLink = invited_Emails_Ids
      .filter(invitee => invitee.magicLink === true)
      .map(invitee => invitee.email);

    const emailWithNoMagicInvite = emails.filter(
      email => !invitedByMagicLink.includes(email)
    );

    Promise.all(
      emailWithNoMagicInvite.map(async email => {
        await sendMagicLink(email);
      })
    )
      .then(() => {
        setIsSubmitted(true);
        resetValues();
      })
      .catch(() => {
        setIsSubmitted(false);
        resetValues();
      });
  }, [emails, sendMagicLink, invites]);

  useEffect(() => {
    if (isEmpty(coworkersEmail)) {
      fetchCoworkersEmail();
    }
  }, []); //eslint-disable-line

  const filteredCoworkersEmail = useMemo(
    () =>
      uniq(
        coworkersEmail
          .filter(
            info =>
              !emails.includes(info.email) &&
              !invites
                .map(invite =>
                  isMagicLink(invite.contact)
                    ? invite.shareContact.firstName
                    : invite.contact
                )
                .includes(
                  isMagicLink(info.email) ? info.shareEmail : info.email
                )
          )
          .map(info => (isMagicLink(info.email) ? info.shareEmail : info.email))
          .filter(email => !emails.includes(email))
      ),
    [coworkersEmail, emails, invites]
  );

  const emailsToShow = useMemo(
    () =>
      isShowMore ? filteredCoworkersEmail : filteredCoworkersEmail.slice(0, 4),
    [isShowMore, filteredCoworkersEmail]
  );

  const handleTagsChange = useCallback(
    (emails: string[]) => {
      if (emails.every(email => EMAIL_VALIDATION.test(email))) {
        setEmails(uniq(emails));
        setEmailInputValue('');
      } else {
        setIsError(true);
      }
    },
    [setEmails, setEmailInputValue, setIsError]
  );

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isError) {
        setIsError(false);
      }
      if (isSubmitted) {
        setIsSubmitted(false);
      }
      if (
        event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Tab' ||
        event.key === ','
      ) {
        event.preventDefault();
        handleTagsChange([...emails, emailInputValue]);
      }
    },
    [
      handleTagsChange,
      emailInputValue,
      emails,
      isError,
      setIsError,
      isSubmitted,
      setIsSubmitted
    ]
  );

  const handleInputBlur = useCallback(() => {
    if (emailInputValue) {
      if (EMAIL_VALIDATION.test(emailInputValue)) {
        setEmails(uniq([...emails, emailInputValue]));
      }
      setIsError(false);
      setEmailInputValue('');
    }
  }, [setEmails, emails, emailInputValue, setIsError]);

  const handleShowMoreClick = useCallback(() => {
    setIsShowMore(true);
  }, [setIsShowMore]);

  return (
    <>
      <ContentWrapperShareModal>
        <Row>
          <BasicHeading tag="h4" size="xl">
            Share a link
          </BasicHeading>
          <Spacing direction="left" value="auto" />
          <div style={{ top: '15%' }}>
            <GrayText>(No log in required)</GrayText>
          </div>
          <Spacing direction="left" value={18} />
          <Tooltip place="left" id="share-invite-info">
            Click to learn more about roles and permissions
          </Tooltip>
          <InfoIcon
            onClick={openPermissions}
            data-tip
            data-for="share-invite-info"
          />
        </Row>
        <Spacing direction="top" value={16} />
        <BasicText size="regular">
          Anyone with the link will have "Limited Viewer" access to this matter.
          They will not have to create an account to view the matter.
        </BasicText>
        <Spacing direction="top" value={16} />

        <InviteRow>
          <TagsInput
            value={emails}
            onChange={handleTagsChange}
            inputProps={{
              placeholder: 'Enter email address(es)',
              autoFocus: true,
              onKeyDown: handleInputKeyDown,
              onBlur: handleInputBlur
            }}
            inputValue={emailInputValue}
            onChangeInput={setEmailInputValue}
            addOnPaste
            pasteSplit={(data): string[] =>
              data.split(/,|\s/g).map(s => s.trim())
            }
          />
        </InviteRow>
        {!isError && emailInputValue && (
          <>
            <Spacing direction="top" value={8} />
            <FieldDescription size="xs">
              Type Enter, space or comma to add multiple emails
            </FieldDescription>
          </>
        )}
        {!isEmpty(emailsToShow) && (
          <>
            <Spacing direction="top" value={16} />
            <Label htmlFor="email">Add</Label>
            <EmailWrapper>
              {emailsToShow.map(email => (
                <GrayText
                  style={{ margin: '3px 0px 3px 10px', cursor: 'pointer' }}
                  key={email}
                  onClick={() => setEmails(prev => [...prev, email])}
                >
                  {email}
                </GrayText>
              ))}
              {!isShowMore &&
                emailsToShow.length &&
                filteredCoworkersEmail.length > 4 && (
                  <SuggestionText
                    onClick={handleShowMoreClick}
                    style={{ margin: '5px 0px 4px 10px' }}
                  >
                    More...
                  </SuggestionText>
                )}
            </EmailWrapper>
          </>
        )}
      </ContentWrapperShareModal>
      <Footer>
        {isSubmitted && (
          <>
            <Snackbar type="success" width="100%">
              Link sent
            </Snackbar>
            <Spacing direction="right" value={8} />
          </>
        )}
        {isError && (
          <>
            <Snackbar type="error" width="100%">
              Please enter a valid email address
            </Snackbar>
            <Spacing direction="right" value={8} />
          </>
        )}
        {/* {isLoading && <Loader />} */}
        <Button resetOrder medium onClick={onClose}>
          Cancel
        </Button>
        <Spacing direction="left" value={8} />
        <Button
          resetOrder
          medium
          primary
          onClick={handleSubmit}
          disabled={
            isLoading || (emails.length === 0 && emailInputValue === '')
          }
        >
          {(isLoading && (
            <>
              <Spacing direction="left" value={22} />
              <Loader marginLess size={2} background="#dddce7" />
              <Spacing direction="right" value={22} />
            </>
          )) ||
            'Send link'}
        </Button>
      </Footer>
    </>
  );
};

export default MagicLink;
