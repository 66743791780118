import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from 'styleguide/components/Tabs';
import AddToOrder from '../AddToOrder';

import {
  getOverviewUrl,
  getSearchesUrl,
  getOfacUrl,
  getDocumentsUrl
} from '../../constants';
import { Wrapper } from './styled';
import { useDeviceState } from 'state/deviceState';

interface SubNavProps {
  id: string;
  hasSearches: boolean;
  hasOfac: boolean;
  hasOrgDocs: boolean;
}

const SubNav: FC<SubNavProps> = ({ id, hasSearches, hasOfac, hasOrgDocs }) => {
  const history = useHistory();
  const location = useLocation();
  const [deviceState] = useDeviceState();

  const tabUrls: { [key: string]: string } = useMemo(
    () => ({
      overview: getOverviewUrl(id),
      searches: getSearchesUrl(id),
      ofac: getOfacUrl(id),
      'org-docs': getDocumentsUrl(id)
    }),
    [id]
  );

  const tabs = useMemo(
    () =>
      [
        { id: 'overview', label: 'Overview', badge: 0 },
        hasSearches && { id: 'searches', label: 'Searches', badge: 0 },
        hasOfac && { id: 'ofac', label: 'OFAC', badge: 0 },
        hasOrgDocs && { id: 'org-docs', label: 'Org Docs', badge: 0 }
      ].filter(Boolean),
    [hasSearches, hasOfac, hasOrgDocs]
  );

  const activeTab = useMemo(
    () => tabs.find(tab => location.pathname.replace('/hit-details','/searches') === tabUrls[tab.id])?.id,
    [tabs, tabUrls, location.pathname]
  );

  const handleTabChange = useCallback(
    (id: string) => {
      history.push(tabUrls[id]);
    },
    [history, tabUrls]
  );

  return ( 
    <Wrapper>
      <Tabs tabs={tabs} onChange={handleTabChange} activeTab={activeTab} />
      {deviceState.isMobileOrTablet?"":<AddToOrder/>}
    </Wrapper>
  );
};

export default SubNav;
