import React, { FC } from 'react';
import { Switch } from 'react-router-dom';
import  HitDeatilsButton  from './hitDetailButton';
import  SearchesButton  from './searchesButton';
import  OfacButton  from './ofacButton';
import  EstimatesButton  from './estimatesButton';
import  MattersButton  from './mattersButton';
import  OrgDoc  from './orgDoc';


import PrivateRoute from '../../../components/app/PrivateRoute';




const AppBarButtons: FC = () => (
    <Switch>
    <PrivateRoute
      path="/dashboard/matters/:id/searches"
      component={SearchesButton} exact
    />
      <PrivateRoute
      path="/dashboard/matters/:id/ofac"
      component={OfacButton} exact
    />
    <PrivateRoute
      path="/dashboard/matters/:id/hit-details"
      component={HitDeatilsButton} exact
    />
    <PrivateRoute
      path="/dashboard/matters"
      component={MattersButton} exact
    />
     <PrivateRoute
      path="/dashboard/estimates"
      component={EstimatesButton} exact
    />
    <PrivateRoute
      path="/dashboard/matters/:id/org-docs"
      component={OrgDoc} exact
    />

    </Switch>

);

export default AppBarButtons;
