import { createStore, createHook, createSubscriber } from 'react-sweet-state';

const Store = createStore({
    initialState: {
        isDesktop: true,
        isTablet: false,
        isMobile: false,
        isMobileOrTablet: false
    },
    actions: {
        onSizeChanged: (width: number ) => ({ setState }) => {
    
            if (width <= 576) {
                setState({
                    isDesktop: false,
                    isTablet: false,
                    isMobile: true,
                    isMobileOrTablet: true,
                });
            } else if (width > 577 && width <= 1024) {
                setState({
                    isDesktop: false,
                    isTablet: true,
                    isMobile: false,
                    isMobileOrTablet: true,
                });
            } else {
                setState({
                    isDesktop: true,
                    isTablet: false,
                    isMobile: false,
                    isMobileOrTablet: false
                });
            }
        }
    },
    name: 'device'
});

export const useDeviceState = createHook(Store);
export const ProductStateSubscriber = createSubscriber(Store);