import React from 'react';
import { useDragState } from '../TableForm/dragState';

export const removeRow = (values: string[][][], row: number) => [
  ...(values
    ? values.map((v: string[][]) => [
        ...(v ? v.slice(0, row) : []),
        ...(v ? v.slice(row + 1, v.length) : [])
      ])
    : [])
];

export const removeCol = (values: string[][][], col: number) => [
  ...(values ? values.slice(0, col) : []),
  ...(values ? values.slice(col + 1, values.length) : [])
];

export const handlePasteEvent = (event: React.ClipboardEvent) => {
  event.preventDefault();
  if (
    event.clipboardData.items[0].kind === 'string' &&
    event.clipboardData.items[0].type === 'text/plain'
  ) {
    return event.clipboardData
      .getData('text/plain')
      .split(/[\n\t;]+/)
      .map((str: string) => str.replace(/(\r\n|\n|\r|\s\r|\r)/gm, ''))
      .filter((str: string) => str.length > 1);
  }
  return null;
};

/**
 * Handles key navigation between selects in table headers
 * On Tab: next select is focused and dropdown open or (if last) callback is called
 * On Enter: Dropdown on current select is open
 * @param {{value: string, label:string}[]} values contains array of current select values for each column
 * @param {() => void} callback action on last column (should create a new column in this case)
 * @returns {Object} Returns keyDownHandler, array of refs which should be passed to inputs
 * and state handling which dropdown is open
 */
export function useColumnKeyNavigation(
  values: { value: string; label: string }[],
  callback: () => void
) {
  const inputRefs = React.useRef([]);
  const [dropdownOpen, setDropdownOpen] = React.useState<number | null>(null);
  const openLastDropdown = React.useCallback(() => {
    setDropdownOpen(inputRefs.current.length);
  }, [inputRefs, setDropdownOpen]);

  React.useEffect(() => {
    if (inputRefs.current.length !== values.length) {
      inputRefs.current = [...Array(values.length)].map(
        (_, i) => inputRefs.current[i] || React.createRef()
      );
    }
  }, [values.length]);

  React.useEffect(() => {
    const len = inputRefs.current.length;
    const lastRef = inputRefs.current[len - 1].current;
    if (len > 1) {
      lastRef?.focus();
    }
  }, [inputRefs.current.length]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent, row: number) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if (row + 1 < inputRefs.current.length) {
          inputRefs.current[row + 1].current.focus();
        } else {
          callback();
        }
        setDropdownOpen(row + 1);
      } else if (event.key === 'Enter' && dropdownOpen !== row) {
        event.preventDefault();
        setDropdownOpen(row);
      }
    },
    [inputRefs, callback, setDropdownOpen, dropdownOpen]
  );

  return {
    handleKeyDown,
    inputRefs,
    dropdownOpen,
    setDropdownOpen,
    openLastDropdown
  };
}

/**
 * Handles key navigation between inputs in table rows
 * On Up/Down: previous/next input is focused
 * On Enter/Tab: If exists, next input is focused, else calback is called
 * @param {string[]} values contains array of current values for each row
 * @param {() => void} callback action on last row (should create a new row in this case)
 * @returns {Object} Returns keyDownHandler and array of refs which should be passed to inputs
 */
export function useRowKeyNavigation(values: string[], callback: () => void) {
  const inputRefs = React.useRef([]);

  React.useEffect(() => {
    if (inputRefs.current.length !== values.length) {
      inputRefs.current = [...Array(values.length)].map(
        (_, i) => inputRefs.current[i] || React.createRef()
      );
    }
  }, [values.length]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent, row: number) => {
      switch (event.key) {
        case 'Up':
        case 'ArrowUp':
          inputRefs.current[row - 1]?.current.focus();
          break;
        case 'Down':
        case 'ArrowDown':
          inputRefs.current[row + 1]?.current.focus();
          break;
        case 'Enter':
        case 'Tab':
          event.preventDefault();
          if (row + 1 < inputRefs.current.length) {
            inputRefs.current[row + 1].current.focus();
          } else {
            callback();
          }
          break;
        default:
          return;
      }
    },
    [inputRefs, callback]
  );

  return { handleKeyDown, inputRefs };
}

export function useDraggableCell(
  row: number,
  col: number,
  isSelected: boolean,
  setValue: (selected: boolean) => void
) {
  const [{ active }, { setActive, setCurrentCell }] = useDragState();
  const [selected, setSelected] = React.useState<boolean>(isSelected);

  const onMouseDown = React.useCallback(() => {
    setSelected(!selected);
    setActive(true);
  }, [selected, setActive]);
  const onMouseOver = React.useCallback(() => {
    if (active) setSelected(selected => !selected);
    setCurrentCell(row, col);
  }, [active, setCurrentCell, row, col]);
  const onMouseUp = React.useCallback(() => {
    setActive(false);
  }, [setActive]);
  const onMouseLeave = React.useCallback(() => {
    setCurrentCell(null, null);
  }, [setCurrentCell]);

  React.useEffect(() => {
    if (!active && selected !== isSelected) {
      setValue(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  React.useEffect(() => {
    if (!active) {
      setSelected(isSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected]);

  return {
    onMouseDown,
    onMouseOver,
    onMouseUp,
    onMouseLeave,
    isSelected: selected
  };
}
