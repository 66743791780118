import React from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import Alert from 'styleguide/components/Alert';
import BasicSelectButtons from 'styleguide/components/BasicSelectButtons';
import BasicRadioList from 'styleguide/components/BasicRadioList';
import Spacing from 'styleguide/components/Spacing';
import { DocumentsFormValues } from 'pages/order/documents';

const Wrapper = styled.div`
  max-width: 624px;
  margin: auto;
  transform: translateX(-137px);
`

const DocumentsConfigStep = () => {
  const { values, setFieldValue } = useFormikContext<DocumentsFormValues>();
  return (
    <Wrapper>
      <Alert isCentered>Click all that apply</Alert>
      <BasicSelectButtons.Wrapper hasSpacingBetweenItems>
        <BasicSelectButtons.Button
          name="charterDocuments"
          type="checkbox"
          value={true}
          isActive={values.charterDocuments}
          onChange={() => {
            setFieldValue(
              `charterDocuments`,
              !Boolean(values.charterDocuments)
            );
          }}
          label="Charter Documents"
          wide
        />

        <BasicSelectButtons.Button
          name="goodStanding"
          type="checkbox"
          value={true}
          isActive={values.goodStanding}
          onChange={() => {
            setFieldValue(`goodStanding`, !values.goodStanding);
          }}
          label="Good Standing Certificates"
          wide
        />
      </BasicSelectButtons.Wrapper>
      <Spacing direction="top" value={25} />
      {values.charterDocuments && (
        <BasicRadioList.Wrapper>
          <BasicRadioList.Item
            name="withAmendments"
            value="true"
            checked={values.withAmendments === true}
            onChange={(e: any) => setFieldValue('withAmendments', true)}
          >
            With amendments
          </BasicRadioList.Item>
          {values.withAmendments && (
            <Spacing direction="left" value={50}>
              <BasicRadioList.Wrapper>
                <BasicRadioList.Item
                  name="certifiedCopy"
                  value="true"
                  checked={values.certifiedCopy === true}
                  onChange={(e: any) => setFieldValue('certifiedCopy', true)}
                >
                  Certified copy
                </BasicRadioList.Item>
                <BasicRadioList.Item
                  name="certifiedCopy"
                  value="false"
                  checked={values.certifiedCopy === false}
                  onChange={(e: any) => setFieldValue('certifiedCopy', false)}
                >
                  Plain copy
                </BasicRadioList.Item>
              </BasicRadioList.Wrapper>
              <Spacing direction="bottom" value={10} />
            </Spacing>
          )}
          <BasicRadioList.Item
            name="withAmendments"
            value="false"
            checked={values.withAmendments === false}
            onChange={(e: any) => setFieldValue('withAmendments', false)}
          >
            Original filling only
          </BasicRadioList.Item>
          {values.withAmendments === false && (
            <Spacing direction="left" value={50}>
              <BasicRadioList.Wrapper>
                <BasicRadioList.Item
                  name="certifiedCopy"
                  value="true"
                  checked={values.certifiedCopy === true}
                  onChange={(e: any) => setFieldValue('certifiedCopy', true)}
                >
                  Certified copy
                </BasicRadioList.Item>
                <BasicRadioList.Item
                  name="certifiedCopy"
                  value="false"
                  checked={values.certifiedCopy === false}
                  onChange={(e: any) => setFieldValue('certifiedCopy', false)}
                >
                  Plain copy
                </BasicRadioList.Item>
              </BasicRadioList.Wrapper>
              <Spacing direction="bottom" value={10} />
            </Spacing>
          )}
        </BasicRadioList.Wrapper>
      )}
    </Wrapper>
  );
};

export default DocumentsConfigStep;
