
import { DEVELOPMENT_BACKEND_URL, STAGING_BACKEND_URL, PRODUCTION_BACKEND_URL } from './constants';

const getUrl = () => {
  switch (process.env.REACT_APP_ENVIROMENT) {
    case 'STAGING':
      return STAGING_BACKEND_URL;
    case 'PRODUCTION':
      return PRODUCTION_BACKEND_URL;
    default:
      return DEVELOPMENT_BACKEND_URL;
  }
}

export const url = getUrl();
