import React, { FC, ReactNode, useEffect, useCallback, useMemo } from 'react';
import { ValueType } from 'react-select';
import styled from 'styled-components';

import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { useDeviceState } from 'state/deviceState';
import { ImageLogo, HeaderLink } from './styled';
import logo from 'components/icons/dealbinder.png';
import BasicHeading from 'styleguide/components/BasicHeading';
import AppBarButtons from './appBarButtons';
import { useMatterState } from '../../../pages/matter/state/matter';
import Select, {
  OptionType
} from 'styleguide/components/Select/reactSelectMobilePdf';
import useOnclickOutside from 'react-cool-onclickoutside';

import Typography from '@material-ui/core/Typography';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import logoSimple from 'components/icons/LogoSimple.svg';
import { LogoSimple } from './styled';

import NavButton from 'styleguide/components/NavButton';
import MoreIcon from '@material-ui/icons/MoreVert';

const drawerWidth = 225;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreButton: {
      width: '0px',
      minWidth: '0px!important'
    },
    grow: {
      flexGrow: 1,
      justifyContent: 'end'
    },
    justifyContent: {
      justifyContent: 'right'
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: theme.spacing(2)
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth,
      background: `#04052B`,
      display: 'flex'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    },
    drawerHeaderStart: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    sectionDesktop: {
      display: 'flex'
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginLeft: -drawerWidth
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }
  })
);

interface PersistentDrawerLeftProps {
  // children: ReactNode,
  sidebar: ReactNode;
}
const NavButtonPdf = styled(NavButton)`
  border: none;
  padding: 0px;
`;

const PersistentDrawerLeft: FC<PersistentDrawerLeftProps> = ({
  children,
  sidebar
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [state] = useDeviceState();
  const { pathname } = useLocation();
  const [matterState, actions] = useMatterState();
  const history = useHistory();
  const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = React.useState(
    false
  );

  const downloadSelectRef = useOnclickOutside(() => {
    if (isDownloadDropdownOpen) {
      setIsDownloadDropdownOpen(false);
    }
  });

  const drawerSelectRef = useOnclickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  const DownloadButton: FC = useMemo(
    () => () => (
      <NavButtonPdf onClick={() => setIsDownloadDropdownOpen(true)}>
        <MoreIcon />
      </NavButtonPdf>
    ),
    [setIsDownloadDropdownOpen]
  );

  const handleChange = useCallback(
    (option: ValueType<OptionType>) => {
      const opt = option as OptionType;
      if (opt.value === 'GET_REPORT') {
        actions.toggleGetReports(true);
      } else if (opt.value === 'GET_FILES') {
        actions.toggleGetDownloads(true);
      } else if (opt.value === "SHARE") {
        if (matterState.data?.role === 'owner') {
          actions.toggleShareModal('manage-access');
        } else {
          actions.toggleViewAccessModal(true);
        }
      }

      setIsDownloadDropdownOpen(false);
    },
    [actions, matterState.data]
  );

  useEffect(() => {
    let unlisten = history.listen(() => {
      handleDrawerClose();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerback = () => {
    history.push('/dashboard');
  };

  const HeadingElement = () => {
    if (pathname === '/dashboard/settings') {
      return (
        <BasicHeading tag="div" size="l">
          Settings
        </BasicHeading>
      );
    } else if (pathname === '/dashboard/matters') {
      return (
        <BasicHeading tag="div" size="l">
          My Matters And Invoices
        </BasicHeading>
      );
    } else if (pathname === '/dashboard/estimates') {
      return (
        <BasicHeading tag="div" size="l">
          My Estimates
        </BasicHeading>
      );
    } else if (
      pathname.substr(0, pathname.indexOf('/', 1) + 9) === '/dashboard/matters/'
    ) {
      return (
        <Typography variant="h6" noWrap>
          {matterState.data?.name}
        </Typography>
      );
    } else {
      return (
        <HeaderLink to="/dashboard">
          <ImageLogo>
            <img src={logo} alt="Dealbinder logo" />
          </ImageLogo>
        </HeaderLink>
      );
    }
  };

  return (
    <div className={classes.grow}>
      <CssBaseline />
      <>
        <AppBar
          color="default"
          position="fixed"
          style={{ background: 'white', boxShadow: 'none' }}
          // className={clsx(classes.appBar, {
          //   open,
          // })}
        >
          <Toolbar>
            {pathname === '/order/review' ? (
              <IconButton onClick={handleDrawerback}>
                <ArrowBackIosIcon />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            )}
            <HeadingElement />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <AppBarButtons />
            </div>
            {pathname === '/login' && (
              <HeaderLink to="/registration">
                <FormattedMessage id="shared.signUp" />
              </HeaderLink>
            )}
            {pathname === '/registration' && (
              <HeaderLink to="/login">
                <FormattedMessage id="shared.signIn" />
              </HeaderLink>
            )}
            {pathname.substr(0, pathname.indexOf('/', 1) + 9) ===
              '/dashboard/matters/' && (
              <div style={{width: '15px'}} ref={downloadSelectRef}>
                <Select
                  options={[
                    { label: 'Get Report', value: 'GET_REPORT' },
                    { label: 'Get Files', value: 'GET_FILES' },
                    { label: 'Share', value: 'SHARE' }
                  ]}
                  onChange={handleChange}
                  components={{ Control: DownloadButton }}
                  value={null}
                  menuIsOpen={isDownloadDropdownOpen}
                  className={classes.moreButton}
                />
              </div>
            )}
          </Toolbar>
        </AppBar>
      </>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
        ref={drawerSelectRef}
      >
        <div className={classes.drawerHeader}>
          <LogoSimple>
            <img src={logoSimple} alt="logo" />
          </LogoSimple>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon style={{ color: '#778899' }} />
          </IconButton>
        </div>
        {sidebar}
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        {state.isMobileOrTablet && (
          <div className={classes.drawerHeaderStart} />
        )}
        {/* {children} */}
      </main>
    </div>
  );
};

export default PersistentDrawerLeft;
