import styled, { css } from 'styled-components';

interface SpacingStyledProps {
  direction: 'top' | 'bottom' | 'left' | 'right';
  value: number | string;
}

const Spacing = styled.div<SpacingStyledProps>`
  ${({ direction, value }) =>
    css`margin-${direction}: ${
      typeof value === 'number' ? `${value}px` : value
    }`};
`;

export default Spacing;
