import React, { FC, ReactNode } from 'react';

import { Container, LeftColumn, RightColumn } from './styled';
import PersistentDrawerLeft from './component/PersistentDrawerLeft';
import { useDeviceState } from 'state/deviceState';
import SidebarAuth from 'pages/dashboard/components/Sidebar/SidebarAuth';

interface AuthenticationLayoutProps {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
}

const AuthenticationLayout: FC<AuthenticationLayoutProps> = ({
  leftColumn,
  rightColumn
}) => {
  const [state] = useDeviceState();

  return (
    <>
   { state.isMobileOrTablet && <PersistentDrawerLeft sidebar={<SidebarAuth/>}/>}
      <Container>
        {state.isDesktop && <LeftColumn>{leftColumn}</LeftColumn>}
        <RightColumn>{rightColumn}</RightColumn>
      </Container>
      </>
    /* </PersistentDrawerLeft> */
  );
};

export default AuthenticationLayout;
