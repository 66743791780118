import React, { FC, useState } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import Loader from 'styleguide/components/Loader';
import { useDeviceState } from 'state/deviceState';
import deleteIcon from 'components/icons/Round icon.png';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import Checkbox from 'styleguide/components/Checkbox';
import {
  GrayText,
  DeleteModalContent,
  DeleteModalFooter,
  DeleteButton,
  DeleteAccountHeader,
  BlackText,
  CheckboxWrapper,
  CancelButton
} from './styled';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

const DeleteModal: FC<DeleteModalProps> = ({ isOpen, onClose, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deviceState] = useDeviceState();

  const handleDelete = () => {
    setIsLoading(true);
    onDelete().then(() => setIsLoading(false));
  };

  const [isChecked, setCheckbox] = useState(false);
  return (
    <BasicModal
      deviceState={deviceState}
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton={false}
    >
      <DeleteAccountHeader>
        <BasicHeading
          tag={deviceState.isMobile ? 'h1' : 'h2'}
          size={deviceState.isMobile ? 'regular' : 'l'}
        >
          Are you sure you want to delete your account?
        </BasicHeading>
      </DeleteAccountHeader>
      <DeleteModalContent>
        <GrayText size="regular"  style={{
            justifyContent: 'center',
            display: 'flex',
            paddingBottom: 25
          }}>
          <img src={deleteIcon} alt="Dealbinder deleteIcon" />
          <Spacing direction="top" value={20} />
        </GrayText>
        <BlackText size="regular">
          This will <strong style={{ textDecoration: 'underline' }}>permanently delete</strong> your account and all matter results,
          files, notes and any other information associated with it. Anyone you
          have invited to your matters will lose access to them.
        </BlackText ><br />
        <BlackText size="regular">
          If you wish to keep any reports or files, you must download them before deleting your account.
        </BlackText>
      </DeleteModalContent>
      <BlackText size="regular">
        <CheckboxWrapper>
          <Checkbox
            onChange={() => setCheckbox(!isChecked)}
            name="terms"
            checked={isChecked}
          />{" "}
          I UNDERSTAND THAT THIS ACTION CANNOT BE REVERSED.
        </CheckboxWrapper>
      </BlackText>
      <DeleteModalFooter>
        {isLoading && <Loader />}
        <CancelButton medium onClick={() => onClose()}>
          Cancel
        </CancelButton>
        <DeleteButton
          medium
          primary
          onClick={handleDelete}
          disabled={isLoading || !isChecked}
        >
          DELETE MY ACCOUNT
          </DeleteButton>

      </DeleteModalFooter>
    </BasicModal>
  );
};

export default DeleteModal;
