import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import BasicHeading from 'styleguide/components/BasicHeading';
import Button from 'styleguide/components/Button';

interface DropdownProps {
  heading: string | ReactChild;
  submitButtonText?: string | ReactChild;
  closeButtonText?: string | ReactChild;
  onSubmit?: (arg: any) => void;
  contentRef?: any;
  customRef?: any;
}

const DropdownWrapper = styled(ReactTooltip)`
  padding: 0;
  opacity: 1 !important;
  width: 415px;
  background-color: ${({ theme }) => theme.colors.lightest} !important;
  color: ${({ theme }) => theme.colors.darkest} !important;
  border: 1px solid ${({ theme }) => theme.colors.gray100} !important;
  border-radius: ${({ theme }) => theme.borderRadius.strong};
  box-shadow: ${({ theme }) => theme.boxShadow.strong} !important;

  &:before {
    border-top-color: ${({ theme }) => theme.colors.gray100} !important;
    border-bottom-color: ${({ theme }) => theme.colors.gray100} !important;
  }

  &:after {
    border-top-color: ${({ theme }) => theme.colors.lightest} !important;
    border-bottom-color: ${({ theme }) => theme.colors.lightest} !important;
  }
`;

const DropdownHeading = styled.header`
  padding: 16px 39px;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;

const DropdownContent = styled.div`
  padding: 17px 24px;
`;

const DropdownFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const Dropdown: FC<DropdownProps & TooltipProps> = ({
  children,
  heading,
  submitButtonText,
  closeButtonText,
  onSubmit,
  id,
  contentRef,
  customRef,
  ...props
}) => (
  <DropdownWrapper id={id} ref={(el) => customRef ? customRef = el : null} effect="solid" clickable {...props}>
    <div ref={contentRef}>
      <DropdownHeading>
        <BasicHeading tag="h5" size="regular" isCentered>
          {heading}
        </BasicHeading>
      </DropdownHeading>
      <DropdownContent>{children}</DropdownContent>
      <DropdownFooter>
        {closeButtonText && (
          <Button type="button" small onClick={() => ReactTooltip.hide()}>
            {closeButtonText}
          </Button>
        )}
        {submitButtonText && (
          <Button type="button" small primary onClick={() => onSubmit(customRef ? customRef : null)}>
            {submitButtonText}
          </Button>
        )}
      </DropdownFooter>
    </div>
  </DropdownWrapper>
);

export default Dropdown;
