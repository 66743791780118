import styled from 'styled-components';

interface AlertProps {
  isCentered?: boolean;
}

const Alert = styled.div<AlertProps>`
  background-color: ${({ theme }) => theme.colorsNewDesign.grayBlue50};
  border-radius: ${({ theme }) => theme.borderRadius.strong};
  margin: 24px 0;
  padding: 20px;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.md};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  line-height: 1.3;
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  ${({ isCentered }) =>
    isCentered &&
    `
        text-align: center;
    `}
`;

export default Alert;
