import React, { FC } from 'react';
import { SingleValueProps, Props } from 'react-select';
import {
  AsyncPaginate,
  UseAsyncPaginateParams
} from 'react-select-async-paginate';
import styled, { css } from 'styled-components';

export type OptionType = { label: string; value: string; isDisabled?: boolean };

interface BasicSelectProps {
  placeholder: string;
  isHighlighted?: boolean;
  invalid?: boolean;
}

const StyledBasicSelect = styled(AsyncPaginate)`
  .react-select__control {
    border: 1px solid transparent;
    border-radius: 0;
    height: 100%;
    position: relative;

    &--is-focused,
    &:hover {
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.colorsNewDesign.primary};
    }

    ${({ isHighlighted, theme }) =>
      isHighlighted &&
      css`
        background: ${theme.colorsNewDesign.background};
        border: 1px solid ${theme.colorsNewDesign.primary};
        box-shadow: ${theme.boxShadow.highlight};
        border-radius: ${theme.borderRadius.regular};
      `}

    ${({ invalid, theme }) =>
      invalid &&
      css`
        border: 1px solid ${theme.colorsNewDesign.error};
      `}
  }

  .react-select__value-container {
    min-height: 60px;
    font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
    padding: 2px 0px 2px 8px;
    width: 110px;
    font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  }

  .react-select__menu {
    width: 300px;
    min-height: 288px;
    margin-top: 0;
    border-radius: 0;
  }

  .react-select__menu-list {
    padding: 0;
  }

  .react-select__option {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    line-height: 1.2;

    &--is-selected {
      background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
      color: ${({ theme }) => theme.colorsNewDesign.gray400};
    }

    &--is-focused {
      background-color: ${({ theme }) => theme.colorsNewDesign.selectHover};
      color: inherit;
    }

    &--is-disabled {
      color: ${({ theme }) => theme.colorsNewDesign.gray400};
    }
  }

  .react-select__group-heading {
    color: ${({ theme }) => theme.colorsNewDesign.success};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
    text-transform: none;
    line-height: 1.2;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .react-select__indicator-separator {
    display: none;
  }
  .css-1hb7zxy-IndicatorsContainer {
    flex-direction: column;
    justify-content: center ;
  }
  .css-1wy0on6{
    flex-direction: column;
    justify-content: center ;
  }

  .css-1wy0on6 .react-select__clear-indicator {
    margin-top:-15px
  }

  .css-1hb7zxy-IndicatorsContainer  .react-select__clear-indicator {
    margin-top:-15px
  }
 
  

  .react-select__indicator {
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    padding: 0 2px 0 0;
   
  }
  

  .react-select__input {
    font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

const SingleValueHeaderStyled = styled.div`
  max-width: 105px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.basicselect--single {
    color: black;
  }
`;

const SingleValueTextStyled = styled.div`
  max-width: 105px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SingleValue: FC<SingleValueProps<OptionType>> = ({ data: { label } }) => {
  const values = label.split(',');
  return (
    <div>
      <SingleValueHeaderStyled
        className={values.length === 1 ? 'basicselect--single' : null}
      >
        {values[0]}
      </SingleValueHeaderStyled>
      <SingleValueTextStyled>{values[1]}</SingleValueTextStyled>
    </div>
  );
};

const BasicSelect: FC<Props<OptionType> &
  UseAsyncPaginateParams<OptionType, any> &
  BasicSelectProps> = ({ placeholder, ...restProps }) => (
  <StyledBasicSelect
    classNamePrefix="react-select"
    placeholder={placeholder}
    components={{ SingleValue: SingleValue }}
    {...restProps}
  />
);

export default BasicSelect;
