import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { authApi } from 'api';
import { EMAIL_VALIDATION } from 'helpers/validation';
import SimpleLayout from 'layout/simpleLayout';

import ForgotPasswordFirstStep from './ForgotPasswordFirstStep';
import ForgotPasswordSecondStep from './ForgotPasswordSecondStep';

export interface ForgotPasswordFormValues {
  email: string;
}

export interface ForgotPasswordFirstStepProps {
  onSubmit: (values: ForgotPasswordFormValues) => void;
  validationSchema: yup.ObjectSchema<ForgotPasswordFormValues | undefined>;
  initialValues: ForgotPasswordFormValues;
  emailPlaceholder: string;
  isLoading: boolean;
}

const ForgotPassword: FC = () => {
  const { formatMessage } = useIntl();
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const emailPlaceholder = formatMessage({
    id: 'forgotPassword.emailPlaceholder'
  });
  const emailError = formatMessage({
    id: `errors.email`
  });

  const initialValues = { email: '' };

  const validationSchema = yup.object().shape<ForgotPasswordFormValues>({
    email: yup
      .string()
      .email(emailError)
      .required(emailError)
      .matches(EMAIL_VALIDATION, {
        message: emailError,
        excludeEmptyString: true
      })
  });

  const onNextStep = () => {
    setCurrentStep(prevState => prevState + 1);
  };

  const onSubmit = async ({ email }: ForgotPasswordFormValues) => {
    setIsLoading(true);
    try {
      await authApi()
        .resetPassword()
        .POST({ email });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
    onNextStep();
  };

  const steps = [
    <ForgotPasswordFirstStep
      onSubmit={onSubmit}
      emailPlaceholder={emailPlaceholder}
      initialValues={initialValues}
      validationSchema={validationSchema}
      isLoading={isLoading}
    />,
    <ForgotPasswordSecondStep />
  ];

  return <SimpleLayout>{steps[currentStep]}</SimpleLayout>;
};

export default ForgotPassword;
