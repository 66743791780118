import get from 'lodash/get';
import { State } from '../state/state';
import {
  OFAC_PRODUCT_ID,
  OFAC_JURISDICTION_ID,
  CHARTER_DOCS_PRODUCT_ID
} from '../consts';

export type OrderLineItem = {
  diligenceName: string;
  product: string;
  jurisdiction: string;
  charterFormat?: 'Certified' | 'Plain';
};

export type Order = {
  stage: 'estimate' | 'order';
  charterCopyCostThreshold: number;
  charterFormat?: 'Certified' | 'Plain';
  charterHistoryType?: 'withAmendments' | 'original';
  specialInstructions: string;
  isDeleted: boolean;
  orderLineItems: OrderLineItem[];
};

export type Matter = {
  name: string;
  order: Order;
};

export type Pricing = {
  charterFormat?: string;
  expediteFee?: number;
  id: string;
  itemNumber?: string;
  price: number;
  searchCategory?: string;
  turnaround: string;
  turnaroundMax: number;
  turnaroundMin: number;
};

export type StateLevelEstimate = {
  [name: string]: {
    pricings?: Pricing[];
    turnaround: string;
  };
};

export type StateLevelEstimates = {
  searchesEstimate: StateLevelEstimate;
  ofacsEstimate: StateLevelEstimate;
  chartersEstimate: StateLevelEstimate;
  goodStandingsEstimate: StateLevelEstimate;
};

export type EstimateData = {
  detailedEstimate?: {
    hasOFAC: boolean;
    hasSearches: boolean;
    hasOrg: boolean;
    names: string;
    county: string;
    state: string;
    quantity: number;
    products: string;
    price: string;
    totalPrice: string;
    label: string;
    hasExpediteFee?: boolean;
    expediteFee?: number;
    expediteAmount?: number;
    turnaroundLanguage: string;
    totalPriceWithExpedite: string;
  }[];
  estimate?: {
    discount: number;
    discount100: number;
    discountAmount: number;
    estimate: string;
    grossPrice: number;
    hasDiscount: boolean;
    netPrice: number;
    stateLevelEstimate: StateLevelEstimates;
  };
};

export const formDataToOrderLineItems = (data: State) => {
  const items: OrderLineItem[] = [];

  data.searches?.selection?.forEach((col: string[][], colIndex: number) =>
    col.forEach((row: string[], rowIndex: number) =>
      row?.forEach((productId: string) => {
        items.push({
          diligenceName: data.searches.names[rowIndex],
          product: productId,
          jurisdiction: (data.searches.jurisdictions[colIndex] as any).value
        });
      })
    )
  );

  data.ofac?.names?.forEach((name: string) => {
    items.push({
      diligenceName: name,
      product: OFAC_PRODUCT_ID,
      jurisdiction: OFAC_JURISDICTION_ID
    });
  });

  data.documents?.selection?.forEach((col: string[][], colIndex: number) =>
    col.forEach((row: string[], rowIndex: number) =>
      (row || []).forEach((productId: string) => {
        items.push({
          diligenceName: data.documents.names[rowIndex],
          product: productId,
          jurisdiction: (data.documents.jurisdictions[colIndex] as any).value,
          ...(productId === CHARTER_DOCS_PRODUCT_ID
            ? {
                charterFormat: data.documents.certifiedCopy
                  ? 'Certified'
                  : 'Plain',
                turnaround: get(data, `turnarounds.charterDocs[${(data.documents.jurisdictions[colIndex] as any).label}]`, undefined),
              }
            : {
                goodStanding: get(data, `turnarounds.goodStanding[${(data.documents.jurisdictions[colIndex] as any).label}]`, undefined),
            })
        });
      })
    )
  );
  return { items };
};

export const formDataToOrder = (data: State): Order => ({
  stage: data.review.stage as any,
  charterCopyCostThreshold: data.review.costThreshold,
  specialInstructions: data.review.specialInstructions,
  isDeleted: false,
  orderLineItems: formDataToOrderLineItems(data).items,
  ...(data.documents?.charterDocuments
    ? {
        charterFormat: data.documents.certifiedCopy ? 'Certified' : 'Plain',
        charterHistoryType: data.documents.withAmendments
          ? 'withAmendments'
          : 'original'
      }
    : {})
});

export const formDataToMatter = (data: State): Matter => ({
  name: data.review.matterName,
  order: formDataToOrder(data)
});
