import React, { FC } from 'react';
import { useField } from 'formik';
import { Cell } from 'styleguide/components/Table';
import StatusBox from 'styleguide/components/StatusBox';
import {
  useProductState,
  getCharterDocs,
  getGoodStanding
} from '../../state/productsState';
import { useDraggableCell } from '../TableForm/utils';
import styled from 'styled-components';
interface CellFormProps {
  row: number;
  col: number;
}

const StyledStatusBox = styled(StatusBox)`
  font-size: 13px;
`;

const CellForm: FC<CellFormProps> = ({ row, col }) => {
  const [state] = useProductState();
  const charterDocs = getCharterDocs(state);
  const goodStanding = getGoodStanding(state);
  const [, { value }, { setValue }] = useField(`selection.${col}.${row}`);
  const isCharterSelected = value?.includes(charterDocs.id);
  const isGoodStandingSelected = value?.includes(goodStanding.id);

  const setValueCb = React.useCallback(
    (selected: boolean) => {
      if (!selected) {
        setValue((value || []).filter((id: string) => id !== goodStanding.id));
      } else {
        setValue(value ? [...value, goodStanding.id] : [goodStanding.id]);
      }
    },
    [setValue, value, goodStanding]
  );
  const { isSelected, ...eventHandlers } = useDraggableCell(
    row,
    col,
    isGoodStandingSelected,
    setValueCb
  );

  return (
    <Cell
      isFullSize
      {...eventHandlers}
      hasHoverState={!isSelected}
      data-hover="Select"
    >
      <StyledStatusBox
        color={isSelected ? 'purple' : 'green'}
        hasBackgroundColor={isSelected}
        hasIcon={false}
      >
        {isSelected ? 'Good Standing' : null}
        {!isSelected && isCharterSelected ? 'Charter' : null}
      </StyledStatusBox>
    </Cell>
  );
};

export default CellForm;
