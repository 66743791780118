// import React from 'react';
import styled from 'styled-components';

const SidebarButton = styled.button`
  border: 1px solid rgba(235, 236, 239, 0.2);
  border-radius: 4px;
  padding: 7px;
  background: transparent;
  display: flex;
  flex-shrink: 0;

  &:hover {
    border-color: rgba(235, 236, 239, 0.6);
  }
`;

export default SidebarButton;
