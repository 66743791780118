import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { Formik, Form, Field } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import { Rifm } from 'rifm';
import { Link, useHistory } from 'react-router-dom';

import { getFormatter } from 'helpers/formatters';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Button from 'styleguide/components/Button';
import Loader from 'styleguide/components/Loader';
import Error from 'styleguide/components/Error';
import Snackbar from 'styleguide/components/Snackbar';
import Checkbox from 'styleguide/components/Checkbox';
import { UserDetails } from 'api/settings';
import { theme } from 'theme/theme';
import { STATES } from 'pages/registration/contants';
import { FORGOT_PASSWORD_URL, DASHBOARD_URL } from 'helpers/routes';
import { useSettingsState } from '../../state/settings';
import {
  Wrapper,
  Box,
  FormLabel,
  InlineLabel,
  TextInput,
  Row,
  Col,
  Select,
  PasswordResetRow,
  FormFooter,
  ButtonsContainer,
  DeleteAccountButton,

} from './styled';
import DeleteModal from './DeleteModal';
import TextField from './TextField';
import { validationSchema } from './utils';
import BasicText from 'styleguide/components/BasicText';
import { useDeviceState } from 'state/deviceState';

const Settings: FC = () => {
  const [phoneMaskedValue, setPhoneMaskedValue] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [state, actions] = useSettingsState();
  const [isLoading, setIsLoading] = useState(true);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [deviceState] = useDeviceState();

  const handleCancelButtonClick = () => history.push(DASHBOARD_URL);

  useEffect(() => {
    actions.fetchData().then(() => setIsLoading(false));
  }, [actions]);

  useEffect(() => {
    const phone = state.userDetails?.phone;
    if (phone) {
      setPhoneMaskedValue(getFormatter('phoneNumber')(phone));
    }
  }, [state.userDetails]);

  const options = STATES.map(state => ({ label: state, value: state }));

  if (isLoading) {
    return (
      <>
        <Spacing direction="top" value={40} />
        <Loader centered background={theme.colorsNewDesign.lightBlue} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>Settings - DealBinder</title>
      </Helmet>
      <Formik<UserDetails>
        initialValues={state.userDetails}
        validationSchema={validationSchema}
        onSubmit={async (values, formActions) => {
          await actions.updateUser(values);
          formActions.setStatus({ success: true });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors,
          isSubmitting,
          status
        }) => (
          <Form onSubmit={handleSubmit}>
            <Wrapper>
              <Spacing direction="top" value={deviceState.isDesktop ? 40 : 20} />
              {deviceState.isMobileOrTablet ? "" :
                <BasicHeading tag="h1" size="xxl">
                  Settings
              </BasicHeading>}
              <Spacing direction="top" value={24} />
              <div className="row">
                <div className="col-12 col-md-6">
                  <Box >
                    <BasicSubHeading size="regular" color="darkest" isUppercase>
                      My information
                      </BasicSubHeading>
                    <Spacing direction="top" value={8} />
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <TextField
                          name="firstName"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched.firstName}
                          error={errors.firstName}
                          value={values.firstName}
                          showLabel={true}
                        />
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <TextField
                          name="lastName"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched.lastName}
                          error={errors.lastName}
                          value={values.lastName}
                          showLabel={true}
                        />
                      </div>
                    </div>
                    <Spacing direction="top" value={8} />
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <FormLabel as="label" color="gray600">
                          <FormattedMessage id="registration.email" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <Spacing direction="top" value={8} />
                        <BasicText size="sm">{values.email}</BasicText>
                        <Spacing direction="top" value={8} />
                      </div>
                    </div>
                  </Box>
                  <Spacing direction="top" value={24} />
                  <Box>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <FormattedMessage id="registration.firmName" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <Spacing direction="top" value={8} />
                        <BasicText size="sm">{values.firmName}</BasicText>
                        <Spacing direction="top" value={8} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <Spacing direction="top" value={24} />
                          <FormattedMessage id="registration.firmAddress" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <TextField
                          name="firmAddress"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched.firmAddress}
                          error={errors.firmAddress}
                          value={values.firmAddress}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <Spacing direction="top" value={24} />
                          <FormattedMessage id="registration.city" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <TextField
                          name="city"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched.city}
                          error={errors.city}
                          value={values.city}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <Spacing direction="top" value={24} />
                          <FormattedMessage id="registration.state" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <FormLabel as="label">
                          <Field name="state">
                            {({
                              field: { value },
                              form: { setFieldValue }
                            }: any) => (
                              <Select
                                options={options}
                                placeholder={formatMessage({
                                  id: 'registration.statePlaceholder'
                                })}
                                value={
                                  value ? { value: value, label: value } : null
                                }
                                onChange={(val: any) => {
                                  setFieldValue('state', val.value);
                                }}
                                invalid={Boolean(touched.state && errors.state)}
                              />
                            )}
                          </Field>
                          {touched.state && errors.state && (
                            <Error>{formatMessage({ id: 'errors.state' })}</Error>
                          )}
                        </FormLabel>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <Spacing direction="top" value={24} />
                          <FormattedMessage id="registration.postalCode" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <TextField
                          name="postalCode"
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          touched={touched.postalCode}
                          error={errors.postalCode}
                          value={values.postalCode}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <FormLabel as="label" color="gray600">
                          <Spacing direction="top" value={24} />
                          <FormattedMessage id="registration.phone" />
                          <Spacing direction="top" value={8} />
                        </FormLabel>
                      </div>
                      <div className="col-12 col-md-8">
                        <FormLabel as="label">
                          <Rifm
                            value={phoneMaskedValue}
                            onChange={setPhoneMaskedValue}
                            format={getFormatter('phoneNumber')}
                          >
                            {({
                              value: valueFromRifm,
                              onChange: onChangeFromRifm
                            }) => (
                              <TextInput
                                id="phone"
                                name="phone"
                                value={valueFromRifm}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  handleChange(e);
                                  onChangeFromRifm(e);
                                }}
                                onBlur={handleBlur}
                                placeholder={formatMessage({
                                  id: 'registration.phonePlaceholder'
                                })}
                                invalid={Boolean(touched.phone && errors.phone)}
                              />
                            )}
                          </Rifm>
                          {touched.phone && errors.phone && (
                            <Error>{formatMessage({ id: 'errors.phone' })}</Error>
                          )}
                        </FormLabel>
                      </div>
                    </div>
                  </Box>
                  <Spacing direction="top" value={24} />
                </div>
                <div className="col-12 col-md-6">
                  <Box>
                    <BasicSubHeading size="regular" color="darkest" isUppercase>
                      Password
                      </BasicSubHeading>
                    <Spacing direction="top" value={8} />
                    <PasswordResetRow>
                      <BasicText size="sm">
                        To change your password{' '}
                        <Link to={FORGOT_PASSWORD_URL}>click here</Link>
                      </BasicText>
                    </PasswordResetRow>
                  </Box>
                  <Spacing direction="top" value={24} />
                  <Box>
                    <BasicSubHeading size="regular" color="darkest" isUppercase>
                      Notifications
                      </BasicSubHeading>
                    <Spacing direction="top" value={8} />
                    <Row>
                      <Col>
                        <InlineLabel as="label">
                          <Checkbox
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="isReceivingDailyUpdates"
                            checked={values.isReceivingDailyUpdates}
                          />
                          <Spacing direction="right" value={8} />
                          <span>Receive daily email updates</span>
                        </InlineLabel>
                      </Col>
                    </Row>
                  </Box>
                  <FormFooter>
                    {deviceState.isDesktop && <DeleteAccountButton
                      tag="h4"
                      size="regular"
                      onClick={() => setIsDeleteModalOpen(true)}
                    >
                      Delete Account
                </DeleteAccountButton>}

                    <ButtonsContainer>

                      {!isSubmitting && status?.success && (
                        <Snackbar type="success">
                          { deviceState.isMobileOrTablet ? 'Saved' : 'Account information saved'}
                        </Snackbar>
                      )}
                      {isSubmitting && (
                        <Loader background={theme.colorsNewDesign.lightBlue} />
                      )}

                      <Spacing direction="left" value={8} />
                      <Button medium resetOrder onClick={handleCancelButtonClick}>
                        Cancel
                      </Button>
                      <Spacing direction="left" value={8} />
                      <Button
                        medium
                        primary
                        resetOrder
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save
                      </Button>
                    </ButtonsContainer>
                  </FormFooter>
                </div>
              </div>
            </Wrapper>
            <DeleteModal
              isOpen={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
              onDelete={actions.deleteAccount}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Settings;