import React, { FC, useMemo, useState } from 'react';

import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Spacing from 'styleguide/components/Spacing';
import { ReactComponent as InvoiceIcon } from 'components/icons/IconInvoice.svg';
import { Order } from 'api/matter';

import InvoicesModal from './InvoicesModal';
import {
  SmallBox,
  SmallBoxTitle,
  InvoiceStatus,
  InvoicesBoxLink
} from './styled';

interface InvoicesBoxProps {
  orders: Order[];
  name: string;
  isOwner: boolean;
}

const InvoicesBox: FC<InvoicesBoxProps> = ({ orders, name,isOwner }) => {
  const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState(false);

  const invoiceType = useMemo(() => {
    const isOutstanding = orders.some(
      order => order.invoiceStatus === 'Outstanding'
    );

    if (isOutstanding) {
      return 'Outstanding';
    }

    const isAllPaid = orders.every(order => order.invoiceStatus === 'Paid');

    if (isAllPaid) {
      return 'Paid';
    }

    return 'Not Invoiced Yet';
  }, [orders]);

  const isAllNotInvoicedYet = useMemo(
    () => orders.every(order => order.invoiceStatus === 'Not Invoiced Yet'),
    [orders]
  );

  const shouldOpenInvoiceLink = useMemo(
    () => orders.length === 1 && !isAllNotInvoicedYet,
    [orders, isAllNotInvoicedYet]
  );
  const shouldOpenInvoiceModal = useMemo(
    () => !shouldOpenInvoiceLink && orders.length > 1 && !isAllNotInvoicedYet,
    [shouldOpenInvoiceLink, orders, isAllNotInvoicedYet]
  );

  const boxContent = useMemo(
    () => (
      <>
        <SmallBoxTitle>
          <InvoiceIcon className='invoiceText'/>
          <Spacing direction="left" value={8} />
          <BasicSubHeading size="regular" isUppercase className='invoiceText'>
            Invoice
          </BasicSubHeading>
        </SmallBoxTitle>
        <InvoiceStatus type={invoiceType} size="regular" isUppercase>
          {invoiceType}
        </InvoiceStatus>
      </>
    ),
    [invoiceType]
  );

  return (
    <>
      {shouldOpenInvoiceLink && (
        <InvoicesBoxLink
          as="a"
          href={orders[0].invoiceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <SmallBox isClickable isOwner={isOwner}>{boxContent}</SmallBox>
        </InvoicesBoxLink>
      )}
      {shouldOpenInvoiceModal && (
        <SmallBox isClickable isOwner={isOwner} onClick={() => setIsInvoicesModalOpen(true)}>
          {boxContent}
        </SmallBox>
      )}
      {!shouldOpenInvoiceLink && !shouldOpenInvoiceModal && (
        <SmallBox isOwner={isOwner}>{boxContent}</SmallBox> 
      )}
      <InvoicesModal
        isOpen={isInvoicesModalOpen}
        onClose={() => setIsInvoicesModalOpen(false)}
        orders={orders}
        matterName={name}
      />
    </>
  );
};

export default InvoicesBox;
