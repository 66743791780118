import React from 'react';

import BasicAside from 'styleguide/components/BasicAside';
import BasicList from 'styleguide/components/BasicList';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicDescription from 'styleguide/components/BasicDescription';
import styled from 'styled-components';

const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;
const AsideInstruct = styled(BasicAside.Wrapper)`
  padding: 10px;
`;
const PreviouslySelectedAside = () => (
  <BasicAside.Wrapper>
    <AsideHead>
      <BasicAside.Heading>Organizational Documents</BasicAside.Heading>
    </AsideHead>
    <AsideInstruct>
      <BasicAside.Content>
        <BasicList.Wrapper>
          <BasicList.Item
            hasSpacing
            tip={
              <>
                <BasicHeading tag="h5" size="xs">
                  Tip:
                </BasicHeading>

                <BasicDescription>
                  If you have a list of names, you can copy and paste the whole
                  list into a cell
                </BasicDescription>
              </>
            }
          >
            <BasicHeading tag="h5" size="md">
              Click or add all names that require org docs
            </BasicHeading>
          </BasicList.Item>
        </BasicList.Wrapper>
      </BasicAside.Content>
    </AsideInstruct>
  </BasicAside.Wrapper>
);

export default PreviouslySelectedAside;
