import React, { FC } from 'react';

import { getParamFromUrl } from 'helpers/helpers';

import Verification from './verification/Verification';
import ResetPassword from './resetPassword/ResetPassword';
import MagicLink from './magicLink/MagicLink';

const MODES = {
  verifyEmail: 'verifyEmail',
  resetPassword: 'resetPassword',
  magicLink: 'signIn'
};
const UserManagement: FC = () => {
  const mode = getParamFromUrl('mode');
  const oobCode = getParamFromUrl('oobCode') || '';
  const continueUrl = getParamFromUrl('continueUrl') || '/';
  const sharedCode = getParamFromUrl('sharingCode') || '/';
  const email = (getParamFromUrl('email') || '').replace(/ /g, "+")

  switch (mode) {
    case MODES.verifyEmail:
      return <Verification oobCode={oobCode} continueUrl={continueUrl} />;
    case MODES.resetPassword:
      return <ResetPassword oobCode={oobCode} />;
    case MODES.magicLink:
      return <MagicLink continueUrl={continueUrl} sharedCode={sharedCode} inviteeEmail={email} />;
    default:
      return null;
  }
};

export default UserManagement;
