import styled from 'styled-components';
import StyleguideTabs from 'styleguide/components/Tabs';
import StyleguideHorizontalLine from 'styleguide/components/HorizontalLine';
import StyleguideSelect from 'styleguide/components/Select';
import StyleguideCheckbox from 'styleguide/components/Checkbox';
import FormLabel from 'styleguide/components/FormLabel';
import Button from 'styleguide/components/Button';
import BasicText from 'styleguide/components/BasicText';
import Snackbar from 'styleguide/components/Snackbar';
import Loader from 'styleguide/components/Loader';
import TextInput from 'styleguide/components/TextInput';
import { ReactComponent as Info } from 'components/icons/IconInfoOutlined.svg';

export const Wrapper = styled.div`
  width: 600px;
  max-width: 100vw;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
`;

export const Tabs = styled(StyleguideTabs)`
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;


export const ContentWrapper = styled.div`
  padding: 32px;
  height: 430px;
  overflow: auto;
`;
export const ContentWrapperShareModal = styled.div`
  padding: 24px;
  height: 410px;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;
export const AccessRow = styled.div`
  display: flex;
  align-items: center;
 @media (max-width:768px){
   flex-direction: column;
   align-items: start;
 }
`;

export const InviteRow = styled(Row)`
  align-items: flex-start;
`;

export const GrayText = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray400};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
`;

export const InfoIcon = styled(Info)`
  cursor: pointer;

  path {
    transition: fill 100ms ease-in;
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }

  :hover path {
    fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const HorizontalLine = styled(StyleguideHorizontalLine)`
  background: ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const Label = styled(FormLabel)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  margin: 3px 0px 3px 10px;
`;

export const SuggestionText = styled.h4`
  display: inline-block;
  margin-right: 16px;
  margin-top: 8px;
  line-height: 1.3;
  font-family: 'Gilroy';
  font-weight: 500;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;


export const FieldDescription = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;

export const Select = styled(StyleguideSelect)`
  width: 150px;
  flex-shrink: 0;

  .react-basic-select {
    &__control {
      margin: 0;
    }

    &__menu {
      width: 450px;
      right: 0;
    }
  }
  .react-basic-select__menu-list {
    max-height: 290px
  }
  @media(max-width:400px){
    .react-basic-select__menu{
      right: -75px!important;
    }
  }
  @media(max-width:600px){
    .react-basic-select__menu{
      width: 350px;
      right: -60px;
    }
  }
`;

export const RoleSelectLabel = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-weight: 300;
`;

export const RoleSelectedLabel = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
`;

export const RoleSelectDescription = styled(RoleSelectLabel)`
  margin: 4px 0 0;
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  white-space: normal;
`;

export const SuggestionsWrapper = styled.div`
  max-height: 128px;
  overflow: auto;
`;
export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const UserRow = styled.div<{ isDeleteOpen?: boolean }>`
  display: grid;
  grid-template-columns: 32px 6fr 3fr 1fr;
  grid-gap: 8px;
  align-items: center;
  margin: 10px 0;

  ${({ isDeleteOpen }) =>
    isDeleteOpen &&
    `
    margin-bottom: 16px;
  `}
`;

export const UserRowButton = styled(Button)`
  margin: 0;
  padding: 16px;

  svg path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }
`;

export const UserRowDetailsColumn = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserRowName = styled(BasicText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const UserRowDescription = styled(BasicText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;

export const UserRowRoleText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray400};
  text-transform: capitalize;
  white-space: nowrap;
`;

export const Link = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const DeleteSnackbar = styled(Snackbar)`
  margin: 0 -24px;
  display: flex;
 
 
  .snackbar-content {
    font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
  }
  @media(max-width:768px){
    .snackbar-content{
    flex-direction: column;
    }
  }
`;

export const DeleteRowButton = styled(Button)`
  margin: 0;
  align-self: flex-start;
`;

export const DeleteRowButtonPrimary = styled(DeleteRowButton)`
  background: ${({ theme }) => theme.colorsNewDesign.error};

  &:hover {
    background: ${({ theme }) => theme.colorsNewDesign.error};
  }
`;

export const DeleteRowLoader = styled(Loader)`
  margin: 0 10px;
  align-self: center;
`;

export const TextArea = styled(TextInput)`
  background: #f9f9f9;
`;

export const Checkbox = styled(StyleguideCheckbox)`
  display: flex;
`;

export const TextDiv = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;