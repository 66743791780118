import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import BasicText from 'styleguide/components/BasicText';

interface SidebarLinkProps {
  icon: ReactNode;
  active: boolean;
  onClick?: () => void;
}

const IconWrapper = styled.div`
  margin-right: 16px;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  ${IconWrapper} {
    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }

  &:hover {
    color: rgba(255, 255, 255, 0.7);

    ${IconWrapper} {
      path {
        fill: rgba(255, 255, 255, 0.7);
      }
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: white;

      ${IconWrapper} {
        path {
          fill: white;
        }
      }
    `}
`;

const SidebarLink: FC<SidebarLinkProps> = ({
  icon,
  active,
  children,
  ...props
}) => (
  <Wrapper active={active} {...props}>
    <IconWrapper>{icon}</IconWrapper>
    <BasicText size="regular">{children}</BasicText>
  </Wrapper>
);

export default SidebarLink;
