import React, { FC, Dispatch } from 'react';

import { InvitedUser, Owner, CoworkerInfo } from 'api/matter';
import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import { ReactComponent as ShareIcon } from 'components/icons/IconShare.svg';

import Invite from './Invite';
import MagicLink from './MagicLink';
import ManageAccess from './ManageAccess';
import { Wrapper, Tabs } from './styled';

export const tabs = [
  { id: 'invite', label: 'Invite', badge: 0 },
  { id: 'share-a-link', label: 'Share a link', badge: 0 },
  { id: 'manage-access', label: 'Manage access', badge: 0 }
];

interface ShareModalProps {
  name: string;
  activeTab: string;
  setActiveTab: Dispatch<string>;
  onClose: () => void;
  fetchCoworkersEmail: () => Promise<void>;
  coworkersEmail: CoworkerInfo[];
  inviteToMatter: (
    email: string,
    role: string,
    doNotify: boolean,
    message?: string
  ) => Promise<void>;
  invites: InvitedUser[];
  updateInvite: (roleId: string, role: string) => Promise<void>;
  deleteInvite: (roleId: string) => Promise<void>;
  owner: Owner;
  openPermissions: () => void;
  sendMagicLink: (email: string) => Promise<void>;
  transferMatter: (email: string) => Promise<void>;
  isOwner: boolean;
}

const ShareModal: FC<ShareModalProps> = ({
  name,
  activeTab,
  onClose,
  fetchCoworkersEmail,
  coworkersEmail,
  inviteToMatter,
  setActiveTab,
  invites,
  updateInvite,
  deleteInvite,
  owner,
  openPermissions,
  sendMagicLink,
  transferMatter,
  isOwner
}) => (
  <BasicModal
    title={
      <>
        <ShareIcon />
        <Spacing direction="left" value={16} />
        <BasicHeading tag="h3" size="xl">
          {name}
        </BasicHeading>
      </>
    }
    isOpen={Boolean(activeTab)}
    onClose={onClose}
  >
    <Wrapper>
      <Tabs tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
      {activeTab === 'invite' && (
        <Invite
          fetchCoworkersEmail={fetchCoworkersEmail}
          coworkersEmail={coworkersEmail}
          inviteToMatter={inviteToMatter}
          openPermissions={openPermissions}
          onClose={onClose}
          invites={invites}
          owner={owner}
        />
      )}
      {activeTab === 'share-a-link' && (
        <MagicLink
          fetchCoworkersEmail={fetchCoworkersEmail}
          coworkersEmail={coworkersEmail}
          openPermissions={openPermissions}
          sendMagicLink={sendMagicLink}
          onClose={onClose}
          invites={invites}
        />
      )}
      {activeTab === 'manage-access' && (
        <ManageAccess
          invites={invites}
          updateInvite={updateInvite}
          deleteInvite={deleteInvite}
          owner={owner}
          openPermissions={openPermissions}
          transferMatter={transferMatter}
          isOwner={isOwner}
        />
      )}
    </Wrapper>
  </BasicModal>
);

export default ShareModal;
