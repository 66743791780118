import React from 'react';
import styled from 'styled-components';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import Badge from 'styleguide/components/Badge';
import iconFlag from 'components/icons/iconTagFlag.svg';
import iconCheck from 'components/icons/iconTagCheck.svg';
import iconBook from 'components/icons/iconTagBook.svg';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as ChevronDown } from 'components/icons/IconChevronDown.svg';

import { useMatterState } from '../../state/matter';
import { useDeviceState } from 'state/deviceState';

const HitsBadge = styled(Badge)``;
// min-width: 105px;
const StatusBadge = styled(Badge)``;
// min-width: 122px;

const GroupHeaderTr = styled.tr`
  border-radius: 5px;
`;

const GroupHeader = styled.td`

  border-bottom: 10px solid ${({ theme }) => theme.colorsNewDesign.backgroundGray};
  display: table-cell;
  cursor: pointer;
  position: relative;
  z-index: 2;
  background: #EFF3F6;
  min-height: 56px;

  & > div {
    background: #fff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #DBD7D7;
    padding: 19px 30px 19px 22px;
    height: 58px;
    @media (max-width: 768px) {
      padding: 12px 0px 12px 10px;
    }
  }

  &.expanded {
    position: -webkit-sticky;
    position: sticky;
    top: 122px;
    z-index: 1;
    border-bottom: 1px solid black;
    & > div {
      margin-bottom: 0px;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colorsNewDesign.grayBlue100};
  }

  @media (max-device-width: 900px) and (orientation: portrait) {
    padding: 0px 0px 0px 0px;
    &.expanded {
      position: -webkit-sticky;
      position: sticky;
      top: 55px;
      z-index: 1;
      border-bottom: 1px solid black;
    }
  }
  @media only screen and (max-device-width: 900px) and (orientation: landscape) {
    &.expanded {
      top: 55px;
    }
  }
`;

const GroupHeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const GroupRow = (props: any) => {
  // material-table's MTableGroupRow is not typed, hence any
  const isSearches = props.groupData.data.some(
    (rowData: any) => rowData.product.family === 'Searches'
  );
  const table = isSearches ? 'searches' : 'documents';
  const [deviceState] = useDeviceState();

  const [
    {
      view: {
        [table]: { orderLineItems }
      }
    }
  ] = useMatterState();

  const isExpanded = props.groupData.isExpanded;

  const hasHit = props.groupData.data.filter(
    (rowData: any) => rowData.status === 'Hit'
  ).length;

  const isInProgress = orderLineItems.some(
    (rowData: any) =>
      rowData.diligenceName === props.groupData.value &&
      rowData.status === 'In Progress'
  );

  return (
    <>
      <GroupHeaderTr
        className="groupHeaderRow"
        onClick={(event: any) => {
          props.onGroupExpandChanged(props.path);
        }}
      >
        <GroupHeader
          colSpan={props.columns.length - 1}
          className={isExpanded ? 'expanded' : ''}
        > <div>
          <GroupHeaderContent>
            <div style={{ width: '16px' }}>
              {isExpanded ? <ChevronDown /> : <ChevronRight />}
            </div>
            <Spacing
              direction="right"
              value={deviceState.isMobile ? '0px' : '20px'}
            />

            <BasicHeading
              tag="div"
              size={deviceState.isMobileOrTablet ? 'xs' : 'md'}
              style={{ textTransform: 'uppercase' }}
            >
              {props.groupData.value}
            </BasicHeading>
            {isSearches ? (
              <>
                <Spacing
                  direction="left"
                  value={deviceState.isMobile ? 'auto' : 'auto'}
                />
                {hasHit ? (
                  <>
                    <HitsBadge
                      color="red"
                      icon={
                        deviceState.isMobile ? (
                          ''
                        ) : (
                          <img src={iconFlag} alt="" />
                        )
                      }
                    >
                      {`${hasHit} ${hasHit === 1 ? 'hit' : 'hits'}`}
                    </HitsBadge>
                    <Spacing
                      direction="right"
                      value={deviceState.isMobile ? '0px' : '30px'}
                    />
                  </>
                ) : null}
                {isInProgress ? (
                  <StatusBadge
                    color="grey"
                    icon={<img src={iconBook} alt="" />}
                  >
                    {deviceState.isMobile ? '' : 'In progress'}
                  </StatusBadge>
                ) : (
                  <StatusBadge
                    color="green"
                    icon={
                      deviceState.isMobile ? '' : <img src={iconCheck} alt="" />
                    }
                  >
                    Complete
                  </StatusBadge>
                )}
              </>
            ) : null}
          </GroupHeaderContent>
          </div>
        </GroupHeader>
      </GroupHeaderTr>
      {isExpanded && (
        <>
          {/* <tr className="MuiTableRow-root MuiTableRow-head MuiTableHead-root groupContentRow">
            {props.columns.slice(1).map((column: any) => (
              <th className="MuiTableCell-root MuiTableCell-head MTableHeader-header-6">
                {column.title}
              </th>
            ))}
          </tr> */}
          {props.groupData.data.map((rowData: any, index: number) => (
            <props.components.Row
              actions={props.actions}
              key={index}
              columns={props.columns.slice(1)}
              components={props.components}
              data={rowData}
              detailPanel={props.detailPanel}
              getFieldValue={props.getFieldValue}
              icons={props.icons}
              path={[...props.path, index]}
              onRowSelected={props.onRowSelected}
              onRowClick={props.onRowClick}
              onToggleDetailPanel={props.onToggleDetailPanel}
              options={props.options}
              isTreeData={props.isTreeData}
              onTreeExpandChanged={props.onTreeExpandChanged}
              onEditingCanceled={props.onEditingCanceled}
              onEditingApproved={props.onEditingApproved}
              hasAnyEditingRow={props.hasAnyEditingRow}
              cellEditable={props.cellEditable}
              onCellEditStarted={props.onCellEditStarted}
              onCellEditFinished={props.onCellEditFinished}
              className="groupContentRow"
            />
          ))}
        </>
      )}
    </>
  );
};

export default GroupRow;
