import React from 'react';
import BasicModal from 'styleguide/components/BasicModal';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import FadedText from 'styleguide/components/FadedText';
import Center from 'styleguide/components/Center';
import Spacing from 'styleguide/components/Spacing';
import { useOrderFormState } from '../state/state';

const DeleteModal = () => {
  const [{ deleteModal }, { setDeleteModal, deleteStep }] = useOrderFormState();
  const step = deleteModal;

  return (
    <BasicModal isOpen={!!deleteModal} onClose={() => setDeleteModal(null)} hasCloseButton={false}>
      <div style={{ width: '400px', padding: '40px 20px' }}>
        <Center>
          <BasicHeading tag="div" size="xl">
            Delete all {step === "ofac" ? "OFAC" : step === 'documents' ? 'organizational documents': step}{step === 'ofac' ? ' searches':null}?
          </BasicHeading>
          <FadedText>This will remove all {step === "ofac" ? "OFAC" : step === 'documents' ? 'organizational documents': step}{step === 'ofac' ? ' searches':null} from your order</FadedText>
          <Spacing direction="top" value={20}>
            <div style={{ display: 'flex' }}>
              <Button
                isCentered
                type="button"
                onClick={() => {
                  setDeleteModal(null);
                }}
              >
                Cancel
              </Button>
              <Button
                isCentered
                danger
                type="button"
                onClick={() => {
                  deleteStep(deleteModal);
                }}
              >
                Delete
              </Button>
            </div>
          </Spacing>
        </Center>
      </div>
    </BasicModal>
  );
};

export default DeleteModal;
