import api from 'api/apiInstance';

import { confirmApi } from './confirm';
import { verifyApi } from './verify';

interface ResetPasswordPostParams {
  email: string;
}

export const resetPasswordApi = (path: string) => ({
  path,
  POST: (config: ResetPasswordPostParams) => api.post(path, config),
  confirm: () => confirmApi(`${path}/confirm`),
  verify: () => verifyApi(`${path}/verify`)
});
