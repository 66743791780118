import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useMediaQuery } from '@material-ui/core';
import { InvitedUser, CoworkerInfo, Owner } from 'api/matter';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import Button from 'styleguide/components/Button';
import TagsInput from 'styleguide/components/TagsInput';
import Loader from 'styleguide/components/Loader';
import Snackbar from 'styleguide/components/Snackbar';
import { EMAIL_VALIDATION } from 'helpers/validation';
import { isMagicLink } from 'helpers/helpers';

// import RoleSelect, { accessOptions } from './RoleSelect';
import RoleDropdown, { accessOptions } from './RoleDropdown';
import {
  Row,
  GrayText,
  InfoIcon,
  Footer,
  Label,
  SuggestionText,
  EmailWrapper,
  FieldDescription,
  InviteRow,
  TextArea,
  Checkbox,
  ContentWrapperShareModal
} from './styled';

interface InviteProps {
  fetchCoworkersEmail: () => Promise<void>;
  coworkersEmail: CoworkerInfo[];
  owner: Owner;
  inviteToMatter: (
    email: string,
    role: string,
    doNotify: boolean,
    message?: string
  ) => Promise<void>;
  openPermissions: () => void;
  onClose: () => void;
  invites: InvitedUser[];
}

const Invite: FC<InviteProps> = ({
  coworkersEmail,
  fetchCoworkersEmail,
  inviteToMatter,
  openPermissions,
  onClose,
  invites,
  owner
}) => {
  // console.log("owner",owner)
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isError, setIsError] = useState(false);
  const [ownerError, setOwnerError] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailInputValue, setEmailInputValue] = useState('');
  const [roleValue, setRoleValue] = useState(accessOptions[0]);
  const [notifyPeople, setNotifyPeople] = useState(true);
  const [messageValue, setMessageValue] = useState('');
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleSubmit = useCallback(() => {
    setIsLoading(true);

    const resetValues = () => {
      setIsLoading(false);
      setEmails([]);
      setEmailInputValue('');
      setRoleValue(accessOptions[0]);
      setMessageValue('');
    };

    if (emails.includes(owner?.email) === false) {
      Promise.all(
        emails.map(email =>
          inviteToMatter(
            email.trim(),
            roleValue.value,
            notifyPeople,
            messageValue
          )
        )
      )
        .then(() => {
          setIsSubmitted(true);
          resetValues();
        })
        .catch(() => {
          setIsSubmitted(false);
          resetValues();
        });
    } else if (emails.length === 1 && emails.includes(owner?.email) === true) {
      setOwnerError(true);
      setIsLoading(false);
    } else {
      Promise.all(
        emails
          .filter(el => el !== owner?.email)
          .map(email =>
            inviteToMatter(
              email.trim(),
              roleValue.value,
              notifyPeople,
              messageValue
            )
          )
      )
        .then(() => {
          setIsSubmitted(true);
          resetValues();
        })
        .catch(() => {
          setIsSubmitted(false);
          resetValues();
        });
    }
  }, [emails, inviteToMatter, owner, roleValue, messageValue, notifyPeople]);

  useEffect(() => {
    if (isEmpty(coworkersEmail)) {
      fetchCoworkersEmail();
    }
  }, []); //eslint-disable-line

  const filteredCoworkersEmail = useMemo(
    () =>
      uniq(
        coworkersEmail
          .filter(
            info =>
              !emails.includes(info.email) &&
              !invites
                .map(invite =>
                  isMagicLink(invite.contact)
                    ? invite.shareContact?.firstName
                    : invite.contact
                )
                .includes(
                  isMagicLink(info.email) ? info.shareEmail : info.email
                )
          )
          .map(info => (isMagicLink(info.email) ? info.shareEmail : info.email))
          .filter(email => !emails.includes(email))
      ),
    [coworkersEmail, emails, invites]
  );

  const emailsToShow = useMemo(
    () =>
      isShowMore ? filteredCoworkersEmail : filteredCoworkersEmail.slice(0, 4),
    [isShowMore, filteredCoworkersEmail]
  );

  const handleTagsChange = useCallback(
    (emails: string[]) => {
      if (emails.every(email => EMAIL_VALIDATION.test(email))) {
        setEmails(uniq(emails));
        setEmailInputValue('');
      } else {
        setIsError(true);
      }
    },
    [setEmails, setEmailInputValue, setIsError]
  );

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isError) {
        setIsError(false);
      }
      if (ownerError) {
        setOwnerError(false);
      }
      if (isSubmitted) {
        setIsSubmitted(false);
      }
      if (
        event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Tab' ||
        event.key === ','
      ) {
        event.preventDefault();
        handleTagsChange([...emails, emailInputValue]);
      }
    },
    [
      handleTagsChange,
      emailInputValue,
      emails,
      isError,
      ownerError,
      setIsError,
      isSubmitted,
      setIsSubmitted
    ]
  );

  const handleSuggestionTextClick = useCallback((email: string) => {
    setIsError(false);
    setEmails(prev => [...prev, email]);
  }, []);

  const handleInputBlur = useCallback(() => {
    if (isError) {
      setIsError(false);
    }
    if (emailInputValue) {
      if (EMAIL_VALIDATION.test(emailInputValue)) {
        setEmails(uniq([...emails, emailInputValue]));
      }
      setEmailInputValue('');
    }
  }, [setEmails, emails, emailInputValue, isError, setIsError]);

  const handleShowMoreClick = useCallback(() => {
    setIsShowMore(true);
  }, [setIsShowMore]);

  return (
    <>
      <ContentWrapperShareModal>
        <Row>
          <BasicHeading tag="h4" size="xl">
            Invite people to view this matter
          </BasicHeading>
          <Tooltip place="left" id="share-invite-info">
            Click to learn more about roles and permissions
          </Tooltip>
          {!isMobile && (
            <>
              <Spacing direction="left" value="auto" />
              <div>
                <GrayText>(Log in required)</GrayText>
              </div>
              <Spacing direction="left" value={18} />
              <InfoIcon
                onClick={openPermissions}
                data-tip
                data-for="share-invite-info"
              />
            </>
          )}
        </Row>
        {isMobile && (
          <Row>
            <div>
              <GrayText>(Log in required)</GrayText>
            </div>
            <Spacing direction="left" value={18} />
            <InfoIcon
              onClick={openPermissions}
              data-tip
              data-for="share-invite-info"
            />
          </Row>
        )}
        <Spacing direction="top" value={16} />
        <InviteRow>
          <TagsInput
            value={emails}
            onChange={handleTagsChange}
            inputProps={{
              placeholder: 'Enter email address(es)',
              autoFocus: true,
              onKeyDown: handleInputKeyDown,
              onBlur: handleInputBlur
            }}
            inputValue={emailInputValue.toLowerCase()}
            onChangeInput={setEmailInputValue}
            addOnPaste
            pasteSplit={(data): string[] =>
              data.split(/,|\s/g).map(s => s.trim())
            }
          />
          <Spacing direction="left" value={8} />
          {/* <RoleSelect
            value={roleValue}
            onChange={(val: any) => setRoleValue(val)}
          /> */}
          <RoleDropdown
            value={roleValue.value}
            onChange={(e: any) => setRoleValue(accessOptions.find((option) => option.value === e.target.value))}
          />
        </InviteRow>
        {!isError && emailInputValue && (
          <>
            <Spacing direction="top" value={8} />
            <FieldDescription size="xs">
              Type Enter, space or comma to add multiple emails
            </FieldDescription>
          </>
        )}
        {!isEmpty(emailsToShow) && (
          <>
            <Spacing direction="top" value={16} />
            <Label htmlFor="email">Add</Label>
            <EmailWrapper>
              {emailsToShow.map(email => (
                <GrayText
                  style={{ margin: '3px 0px 3px 10px', cursor: 'pointer' }}
                  key={email}
                  onClick={() => handleSuggestionTextClick(email)}
                >
                  {email}
                </GrayText>
              ))}
              {!isShowMore &&
                emailsToShow.length &&
                filteredCoworkersEmail.length > 4 && (
                  <SuggestionText
                    onClick={handleShowMoreClick}
                    style={{ margin: '5px 0px 4px 10px' }}
                  >
                    More...
                  </SuggestionText>
                )}
            </EmailWrapper>
            {/* </SuggestionsWrapper> */}
          </>
        )}
        <Spacing direction="top" value={16} />
        <TextArea
          id="email"
          as="textarea"
          hasNoMargin
          placeholder="Add a message (Optional)"
          rows={3}
          value={messageValue}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>): void =>
            setMessageValue(e.target.value)
          }
        />
        <Spacing direction="top" value={16} />
        <Row>
          <Checkbox
            checked={notifyPeople}
            onChange={() => setNotifyPeople(!notifyPeople)}
          />
          <Spacing direction="left" value={8} />
          Notify people by email
        </Row>
       
      </ContentWrapperShareModal>
      <Footer>
          {isSubmitted && (
            <>
              <Snackbar type="success" width="100%">
                Matter shared
              </Snackbar>
              <Spacing direction="right" value={8} />
            </>
          )}
          {isError && (
            <>
              <Snackbar type="error" width="100%">
                Please enter a valid email address
              </Snackbar>
              <Spacing direction="right" value={8} />
            </>
          )}
          {ownerError && (
            <>
              <Snackbar type="error" width="100%">
                You can't invite yourself.
              </Snackbar>
              <Spacing direction="right" value={8} />
            </>
          )}

          <Button resetOrder medium onClick={onClose}>
            Cancel
          </Button>
          <Spacing direction="left" value={8} />
          <Button
            resetOrder
            medium
            primary
            onClick={handleSubmit}
            disabled={
              isLoading || (emails.length === 0 && emailInputValue === '')
            }
          >
            {(isLoading && (
              <Loader marginLess size={2} background="#dddce7" />
            )) ||
              'Share'}
          </Button>
        </Footer>
    </>
  );
};

export default Invite;
