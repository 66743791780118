import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import BasicHeading from 'styleguide/components/BasicHeading';
import BaseLink from 'components/shared/baseLink/BaseLink';
import { ReactComponent as LogoColor } from 'components/icons/LogoColor.svg';
import { ReactComponent as LogoIcon } from 'components/icons/LogoSimple.svg';
import Button from 'styleguide/components/Button';

export const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 595px;
  
`;

export const LogoSimple = styled.div`
  cursor: pointer;
  margin-right: 140px;
  margin-bottom: -10px;
`;


export const Title = styled(BasicHeading)`
  color: white;
`;

export const Subtitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizesNewDesign.l};
  color: white;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const HelpText = styled.div`
  margin-top: auto;
  color: white;
  opacity: 0.4;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
`;

export const HelpTextLink = styled(BaseLink)`
  color: inherit;

  &:hover {
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
`;

export const RightColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  margin: 64px 0 40px 0;
`;

export const Logo = styled(LogoColor)`
  width: 133px;
`;

export const AppBarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content:center;

`;

export const Header = styled.div`
  display: flex;
  margin: auto;

    img {
      width: 133px;
    }
 

  
`;

export const HeaderLink = styled(Link)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  margin-left: auto;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;

export const HeaderIcon = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  margin-left: auto;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  white-space: nowrap;
  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;

export const ImageLogo = styled.div`
margin: auto;
flex: 1 1 100%;
${'' /* text-align: center; */}
  img {
      width: 133px;
    }
`;

export const ContentContainer = styled.div`
  padding: 15px 24px;
`;

export const LogoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoCross = styled(LogoIcon)`
  cursor: pointer;
`;

export const FiltersButton = styled(Button)`
  padding: 12px 16px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  svg {
    margin-right: 8px;

    ${({ active, theme }) =>
      !active &&
      css`
        path {
          fill: ${theme.colorsNewDesign.gray300};
        }
      `}
  }
`;



