import {
  FC,
  useEffect,
} from 'react';
import { auth } from 'firebaseConfig';
import { LOGIN_URL } from 'helpers/routes';

const Logout: FC = () => {

  useEffect(
    () => {
      auth.signOut().then(() => {
        window.location.href = LOGIN_URL;
      });
    },
    [] // eslint-disable-line
  );
  return null;
};

export default Logout;
