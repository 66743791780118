import React, { FC, useState, useEffect } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import Snackbar from 'styleguide/components/Snackbar';
import { ReactComponent as DeleteIcon } from 'components/icons/IconDelete2.svg';

import {
  DeleteModalContent,
  DeleteModalFooter,
  DeleteButton,
  GrayText,
  RoundIcon,
  DeleteModalConfirmationWrapper
} from './styled';
import { DeleteEstimateModalData } from './';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  deleteEstimateData: DeleteEstimateModalData;
  onDelete: (id: string, matterId: string) => void;
}

enum ModalSteps {
  CONFIRMATION,
  SUCCESS
}

const DeleteModal: FC<DeleteModalProps> = ({
  isOpen,
  onClose,
  deleteEstimateData,
  onDelete
}) => {
  const [modalStep, setModalStep] = useState(ModalSteps.CONFIRMATION);

  useEffect(() => {
    if (isOpen) {
      setModalStep(ModalSteps.CONFIRMATION);
    }
  }, [isOpen]);

  if (modalStep === ModalSteps.SUCCESS) {
    return (
      <BasicModal isOpen={isOpen} onClose={onClose} title=" ">
        <DeleteModalConfirmationWrapper>
          <Spacing direction="top" value={32} />
          <Snackbar type="success">Estimate deleted</Snackbar>
          <Spacing direction="top" value={93} />
        </DeleteModalConfirmationWrapper>
      </BasicModal>
    );
  }

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <BasicHeading tag="h2" size="xl">
          Delete estimate
        </BasicHeading>
      }
    >
      <DeleteModalContent>
        <Spacing direction="top" value={33} />
        <RoundIcon>
          <DeleteIcon />
        </RoundIcon>
        <Spacing direction="top" value={32} />
        <BasicHeading tag="h3" size="xl">
          Are you sure you want to delete the estimate for:
          <br />
          {deleteEstimateData?.name}
        </BasicHeading>
        <Spacing direction="top" value={20} />
        <GrayText size="regular">
          This will permanently delete the estimate and the
          <br />
          draft order associated with it.
        </GrayText>
        <Spacing direction="top" value={49} />
      </DeleteModalContent>
      <DeleteModalFooter>
        <Button medium onClick={() => onClose()}>
          Cancel
        </Button>
        <DeleteButton
          medium
          primary
          onClick={() => {
            onDelete(deleteEstimateData?.id, deleteEstimateData?.matterId);
            setModalStep(ModalSteps.SUCCESS);
          }}
        >
          Yes, delete
        </DeleteButton>
      </DeleteModalFooter>
    </BasicModal>
  );
};

export default DeleteModal;
