import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
  KeyboardEvent,
  useMemo
} from 'react';
import { useHistory } from 'react-router-dom';
import head from 'lodash/head';
import defaultTo from 'lodash/defaultTo';

import { InvitedUser, Owner } from 'api/matter';
import Spacing from 'styleguide/components/Spacing';
import NavButton from 'styleguide/components/NavButton';
import { ReactComponent as Download } from 'components/icons/IconDownload.svg';
import { ReactComponent as Document } from 'components/icons/IconDocument.svg';
import { ReactComponent as Share } from 'components/icons/IconShare.svg';
import { getOverviewUrl } from '../../constants';
import { theme } from 'theme/theme';
import { UserCircle } from '../common/styled';
import { isMagicLink } from 'helpers/helpers';
import { useDeviceState } from 'state/deviceState';

import {
  Wrapper,
  ButtonsWrapper,
  Title,
  TitleInput,
  Edit,
  InvitesWrapper
} from './styled';

interface NavProps {
  id: string;
  name: string;
  role: string;
  onNameChange: (name: string) => Promise<void>;
  onDownloadClick: () => void;
  onShareClick: () => void;
  onInviteCirclesClick: () => void;
  invites: InvitedUser[];
  owner: Owner;
  onGetFilesClick: () => void;
}

const Nav: FC<NavProps> = ({
  id,
  name,
  role,
  onNameChange,
  onDownloadClick,
  onShareClick,
  onInviteCirclesClick,
  invites,
  owner,
  onGetFilesClick
}) => {
  const history = useHistory();
  const [deviceState] = useDeviceState();

  const [isEditMode, setIsEditMode] = useState(false);
  const [nameValue, setNameValue] = useState(name);

  const circlesColors = useMemo(
    () => Object.values(theme.userCircleColors),
    []
  );

  const handleSave = useCallback(() => {
    onNameChange(nameValue);
    setIsEditMode(false);
  }, [onNameChange, nameValue]);

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setNameValue(e.target.value);
    },
    []
  );

  const handleNameKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      if (e.key === 'Enter') {
        handleSave();
      }
    },
    [handleSave]
  );

  const handleTitleClick = useCallback(() => history.push(getOverviewUrl(id)), [
    id,
    history
  ]);

  useEffect(() => setNameValue(name), [name]);

  return (
    <Wrapper>
      <Spacing direction="left" value={24} />
      {name && (
        <>
          {isEditMode && (
            <TitleInput
              onBlur={handleSave}
              value={nameValue}
              onChange={handleNameChange}
              onKeyUp={handleNameKeyUp}
              autoFocus
            />
          )}
          {!isEditMode && (
            <Title tag={deviceState.isMobile ? "div" : "h2"} size={deviceState.isMobile ? "sm" : "xl"}>
              <span onClick={handleTitleClick}>{name}</span>
              {role === 'owner' && <Edit onClick={() => setIsEditMode(true)} />}
            </Title>
          )}
        </>
      )}
      <ButtonsWrapper>
        <InvitesWrapper onClick={onInviteCirclesClick}>
          {invites.filter(invitee => !isMagicLink(invitee.contact)).length > 2 && (
            <UserCircle background={theme.colorsNewDesign.gray500}>
              +{invites.filter(invitee => !isMagicLink(invitee.contact)).length - 2}
            </UserCircle>
          )}

          {invites
            .slice(0, 2)
            .reverse()
            .map((user, index) => {
              const email = defaultTo(
                isMagicLink(user.contact)
                  ? user.shareContact?.firstName
                  : user.contact,
                ''
              );
              return (
                <UserCircle key={email} background={circlesColors[index + 1]}>
                  {email.replace(/\W/g, '').slice(0, 2)}
                </UserCircle>
              );
            })}
          <UserCircle background={circlesColors[0]}>
            {head(owner.firstName)}
            {head(owner.lastName)}
          </UserCircle>
        </InvitesWrapper>
        <Spacing direction="left" value={24} />
        {role === 'owner' && (
          <NavButton onClick={onShareClick}>
            <Share />
            <span>Share</span>
          </NavButton>
        )}
        <NavButton onClick={onGetFilesClick}>
          <Document />
          <span>Get Report</span>
        </NavButton>
        <NavButton onClick={onDownloadClick}>
          <Download />
          <span>Get Files</span>
        </NavButton>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default Nav;
