import React, { useEffect, useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MaterialTable from 'styleguide/components/MaterialTable';
import { theme } from 'theme/theme';
import SearchInput from 'styleguide/components/SearchInput';
import { useMatterState } from './state/matter';
import {
  TableAccessory,
  FiltersButton,
  ExpandButton,
  Loader,
  CountBadge
} from './components/common/styled';
import GroupRow from './components/HitDetailsGroupRow';
import Tooltip from 'styleguide/components/Tooltip';
import { ReactComponent as IconTooltip } from 'components/icons/IconTooltip.svg';
import { ReactComponent as IconExpand } from 'components/icons/IconExpand.svg';
import { ReactComponent as MoreIcon } from 'components/icons/more.svg';
// import {useMediaQuery} from "@material-ui/core";
import Spacing from 'styleguide/components/Spacing';
import { shortDateFormat } from 'helpers/formatters';
import { getFilePreviewUrl } from './constants';
// import InlineFilters from 'styleguide/components/InlineFilters';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';

import Filters from './components/HitDetailsFilters';
import { optionsToLookup } from './utils';
import SearchesNav from './components/SearchesNav';
// import PriorityForm from './components/PriorityForm';
import ReviewSelectForm from './components/ReviewSelectForm';
import NavButton from 'styleguide/components/NavButton';
import { ReactComponent as DownloadNew } from 'components/icons/DownloadNew.svg';
import { ReactComponent as MailNew } from 'components/icons/MailNew.svg';
import { ReactComponent as DocumentNew } from 'components/icons/DocumentNew.svg';
import { ReactComponent as Notes } from 'components/icons/Notes.svg';
import DownloadFileLink from './components/DownloadFileLink';
import HitDetailsDetailPanel from './components/HitDetailsDetailPanel';
import ColumnAttachment from './components/ColumnAttachment';
import DealbinderMessage from './components/DealbinderMessage';
import { useDeviceState } from 'state/deviceState';
import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { AuthContext } from 'components/app/Auth';
import sortBy from 'lodash/sortBy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    moreButton: {
      width: '30px',
      minWidth: '0px!important'
    },
    menu: {
      zIndex: 99
    }
  })
);

const StyledMenu = withStyles({
  list: {
    padding: 0
  },
  paper: {
    border: '1px solid #dedede',
    borderRadius: 0
  }
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    padding: '12px',
    fontSize: '13px',
    width: '108px',
    minHeight: 'auto',
    lineHeight: 1.2,
    '&:focus': {
      backgroundColor: '#B2D4FF',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const HitDetailsWrapper = styled.div`
  display: flex;
  position: sticky;
`;
const HitDetailsStatus = styled.div`
  line-height: 2.4;
`;
const FadedText = styled.span`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;
const HitDetailsContainer = styled.div`
  & td.MuiTableCell-root {
    box-shadow: none !important;
  }
`;

const SearchTypeLink = styled(Link)`
  font-weight: bold;
  color: #0f1027;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    color: #0f1027;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1px;
`;

const StyledNavButton = styled(NavButton)`
  background-color: transparent;
  color: #0a2aab;
  padding: 12px;
  /* 
  & svg {
    width: 32px;
  } */
  & svg path {
    fill: #212fcc;
  }
  &:hover {
    color: #a9adbb;
    svg path {
      fill: #a9adbb;
    }
  }
`;

const CStyledNavButton = styled(StyledNavButton)`
  position: relative;
`;

const IconTooltipStyled = styled(IconTooltip)`
  width: 10px;
  height: 10px;
`;

const RedDot = styled.div`
  height: 11px;
  width: 11px;
  border-radius: 11px;
  background-color: #fa0000;
  border: 1px solid #fff;
  position: absolute;
  right: 13px;
  top: 13px;
`;

const DocumentLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  & span {
    background: #fff;
    padding: 7px 10px;
    border-radius: 5px;
    border: 1px solid #212fcc;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
`;

const HitDetails = () => {
  const [deviceState] = useDeviceState();
  const currentUser = useContext(AuthContext);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const classes = useStyles();
  // const isMobile = useMediaQuery('max-width(768px)');
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const openAndCloseEmail = (val: any) => {
    openEmailModal(val);
    handleMobileMenuClose();
  };

  const open = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [
    {
      id: matterId,
      isLoading,
      view: { hitDetails },
      tables: {
        hitDetails: {
          filters: { priority, ...filters },
          search,
          expanded
        }
      },
      hits,
      tableFilterSidebar,
      invites
    },
    {
      setSearch,
      toggleExpanded,
      // setFilter,
      // clearFilter,
      fetchHitData,
      openEmailModal,
      toggleTableFilterSidebar
    }
  ] = useMatterState();

  useEffect(() => {
    if (!hits && isLoading === false) {
      fetchHitData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const hasPriorities = useMemo(
    () =>
      (hits || []).some(
        oli => oli.priorities && oli.priorities[0]?.priorityStatus
      ),
    [hits]
  );

  const countBadge = React.useMemo(() => Object.keys(filters).length, [
    filters
  ]);

  const hitsView = useMemo(
    () => {
      const hitsFiltered = (hits || []).filter(
        oli =>
          !!oli.product &&
          (!priority || !hasPriorities || oli.priorities[0]?.priorityStatus)
      );
      return sortBy(hitsFiltered, 'jurisdiction.name');
    },
    [hits, priority, hasPriorities]
  );
  const currentUserEmail = currentUser && currentUser.email;
  const isLimitedAccess =
    invites.filter(invite => invite.contact === currentUserEmail)[0]?.role ===
    'limitedViewer';

  if (!hits) {
    return <Loader marginLess background={theme.colorsNewDesign.lightBlue} />;
  }

  const column = [
    {
      title: 'Name',
      field: 'diligenceName',
      defaultGroupOrder: 0,
      customSort: () => 0,
      lookup: optionsToLookup(hitDetails.filterOptions.name)
    },
    ...(deviceState.isMobile
      ? [
          {
            title: 'Jurisdiction',
            field: 'jurisdiction.name',
            lookup: optionsToLookup(hitDetails.filterOptions.jurisdiction),
            cellStyle: {
              minWidth: '150px',
              maxWidth: '250px',
              paddingTop: '0px',
              paddingBottom: '0px'
            },
            render: (rowData: any) => (
              <>
                <ColumnAttachment left="-46px">
                  <HitDetailsStatus>
                    <DealbinderMessage
                      id={rowData.id}
                      message={rowData.dealbinderMessage}
                    />
                  </HitDetailsStatus>
                </ColumnAttachment>

                <SearchTypeLink
                  to={getFilePreviewUrl(matterId, 'hit-details', rowData.id)}
                >
                  {rowData.product?.mobileName}
                </SearchTypeLink>
                <p style={{ fontSize: '10px' }}>
                  <FadedText>
                    {rowData.source1 && (
                      <Spacing
                        direction="right"
                        value="5px"
                        style={{ display: 'inline-block' }}
                      >
                        <IconTooltipStyled
                          data-tip
                          data-for={`jurisdiction.${rowData.id}`}
                        />

                        <Tooltip id={`jurisdiction.${rowData.id}`}>
                          <p>
                            {rowData.source1}{' '}
                            {rowData.source1Date &&
                              '(through ' +
                                shortDateFormat(rowData.source1Date) +
                                ')'}
                          </p>
                          {rowData.source2 && (
                            <p>
                              {rowData.source2}{' '}
                              {rowData.source2Date &&
                                '(through ' +
                                  shortDateFormat(rowData.source2Date) +
                                  ')'}
                            </p>
                          )}
                          {rowData.yearsBack && (
                            <p>Years Searched Back: {rowData.yearsBack}</p>
                          )}
                        </Tooltip>
                      </Spacing>
                    )}
                    {rowData.product?.name === 'UCC - State'
                      ? rowData.jurisdiction?.name.split(',')[0]
                      : deviceState.isMobileOrTablet
                      ? rowData.jurisdiction?.jurisdictionShort
                      : rowData.jurisdiction?.name}
                  </FadedText>
                </p>
              </>
            )
          }
        ]
      : []),

    {
      title: 'Search Type',
      field: 'product.name',
      lookup: optionsToLookup(hitDetails.filterOptions.product),
      cellStyle: deviceState.isMobile
        ? { paddingTop: '0px', paddingBottom: '0px' }
        : {
            paddingTop: '11px',
            paddingBottom: '11px',
            maxWidth: '160px'
          },
      render: (rowData: any) =>
        deviceState.isMobile ? (
          <></>
        ) : (
          <>
            {/* <PriorityForm orderLineItem={rowData} /> */}
            <ColumnAttachment left="-46px">
              <DealbinderMessage
                id={rowData.id}
                message={rowData.dealbinderMessage}
              />
            </ColumnAttachment>
            <SearchTypeLink
              to={getFilePreviewUrl(matterId, 'hit-details', rowData.id)}
            >
              {rowData.product?.name}
            </SearchTypeLink>
          </>
        )
    },
    ...(deviceState.isMobile
      ? []
      : [
          {
            title: 'Jurisdiction',
            field: 'jurisdiction.name',
            lookup: optionsToLookup(hitDetails.filterOptions.jurisdiction),
            cellStyle: {
              width: '250px',
              paddingTop: '0px',
              paddingBottom: '0px'
            },
            render: (rowData: any) => (
              <FadedText>
                {rowData.source1 && (
                  <Spacing
                    direction="right"
                    value="10px"
                    style={{ display: 'inline-block', verticalAlign: 'bottom' }}
                  >
                    <IconTooltip
                      data-tip
                      data-for={`jurisdiction.${rowData.id}`}
                    />

                    <Tooltip id={`jurisdiction.${rowData.id}`}>
                      <p>
                        {rowData.source1}{' '}
                        {rowData.source1Date &&
                          '(through ' +
                            shortDateFormat(rowData.source1Date) +
                            ')'}
                      </p>
                      {rowData.source2 && (
                        <p>
                          {rowData.source2}{' '}
                          {rowData.source2Date &&
                            '(through ' +
                              shortDateFormat(rowData.source2Date) +
                              ')'}
                        </p>
                      )}
                      {rowData.yearsBack && (
                        <p>Years Searched Back: {rowData.yearsBack}</p>
                      )}
                    </Tooltip>
                  </Spacing>
                )}
                {rowData.product?.name === 'UCC - State'
                  ? rowData.jurisdiction?.jurisdictionShort.split(',')[0]
                  : rowData.jurisdiction?.jurisdictionShort}
              </FadedText>
            )
          }
        ]),
    {
      title: 'reviewStatus',
      field: 'reviewStatus',
      cellStyle: { padding: 0 },
      lookup: optionsToLookup(hitDetails.filterOptions.reviewStatus),
      render: () => '',
      customFilterAndSearch: (term: any, rowData: any) => {
        if (rowData.reviewHistory.length) {
          const reviewHistory = rowData.reviewHistory.sort(
            (el1: any, el2: any) => {
              if (el1.createdAt < el2.createdAt) {
                return 1;
              }
              if (el1.createdAt > el2.createdAt) {
                return -1;
              }
              return 0;
            }
          );
          return term.includes(reviewHistory[0].reviewStatus);
        }
        return false;
      }
    },
    {
      title: 'Actions',
      field: 'actions',
      cellStyle: { paddingTop: '0px', paddingBottom: '0px', maxWidth: '400px' },
      render: ({ id, reviewHistory, file, notes }: any) => (
        <ActionsWrapper>
          {file && (
            <>
              {deviceState.isDesktop ? (
                <>
                  <DocumentLink
                    to={{
                      pathname: getFilePreviewUrl(matterId, 'hit-details', id),
                      state: { fromButton: 'file' }
                    }}
                  >
                    <StyledNavButton>
                      <span>
                        <DocumentNew style={{ width: 32, height: 32 }} /> View
                        File
                      </span>
                    </StyledNavButton>
                  </DocumentLink>
                  <Tooltip id={`actions.1.${id}`}>View file</Tooltip>
                </>
              ) : (
                <DocumentLink
                  to={getFilePreviewUrl(matterId, 'hit-details', id)}
                >
                  <StyledNavButton>
                    <DocumentNew style={{ width: 32, height: 32 }} />
                  </StyledNavButton>
                </DocumentLink>
              )}
              <>
                {deviceState.isDesktop && (
                  <>
                    <DownloadFileLink orderLineItemId={id}>
                      <StyledNavButton
                        data-tip
                        data-for={`actions.2.${id}`}
                        data-delay-show={500}
                      >
                        <DownloadNew style={{ width: 32, height: 32 }} />
                      </StyledNavButton>
                    </DownloadFileLink>
                    <Tooltip id={`actions.2.${id}`}>Download file</Tooltip>
                    <StyledNavButton
                      onClick={() => openEmailModal(id)}
                      data-tip
                      data-for={`actions.3.${id}`}
                    >
                      <MailNew style={{ width: 32, height: 32 }} />
                    </StyledNavButton>
                    <Tooltip id={`actions.3.${id}`}>Email file</Tooltip>
                  </>
                )}
                {!isLimitedAccess && (
                  <>
                    <Link
                      to={{
                        pathname: getFilePreviewUrl(
                          matterId,
                          'hit-details',
                          id
                        ),
                        state: { fromButton: 'notes' }
                      }}
                    >
                      <CStyledNavButton data-tip data-for={`actions.4.${id}`}>
                        <Notes style={{ width: 32, height: 32 }} />
                        {notes?.length ? <RedDot /> : null}
                      </CStyledNavButton>
                    </Link>
                    <Tooltip id={`actions.4.${id}`}>Notes</Tooltip>
                  </>
                )}
              </>
              <ReviewSelectForm id={id} reviewHistory={reviewHistory} />

              {deviceState.isMobileOrTablet && (
                <>
                  {' '}
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    className={classes.moreButton}
                  >
                    <StyledNavButton>
                      <MoreIcon />
                    </StyledNavButton>
                  </Button>
                  <StyledMenu
                    id="simple-menu"
                    anchorEl={mobileMoreAnchorEl}
                    open={open}
                    onClose={handleMobileMenuClose}
                    className={classes.menu}
                    keepMounted
                  >
                    <StyledMenuItem>
                      <DownloadFileLink orderLineItemId={id}>
                        Get File
                      </DownloadFileLink>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={() => openAndCloseEmail(id)}>
                      Email
                    </StyledMenuItem>
                  </StyledMenu>{' '}
                </>
              )}
            </>
          )}
        </ActionsWrapper>
      )
    }
  ];

  return (
    <HitDetailsContainer>
      <TableAccessory>
        <div
          style={{ alignItems: 'center', padding: 0 }}
          className="col-sm-2 col-6"
        >
          <SearchesNav />
        </div>
        <div style={{ height: '40px', padding: 0 }} className="col-sm-4 col-6">
          {deviceState.isMobileOrTablet ? (
            ''
          ) : (
            <>
              <ExpandButton
                onClick={() => toggleExpanded('hitDetails')}
                // data-tip
                // data-for="expandrows"
                data-place="bottom"
                resetOrder
                active={expanded}
              >
                <IconExpand /> {expanded ? 'Collapse' : 'Expand'} rows
              </ExpandButton>
              {/* <Tooltip id="expandrows">
                {expanded ? 'Collapse rows' : 'Expand rows'}
              </Tooltip> */}
              <FiltersButton
                active={Boolean(Object.keys(filters).length)}
                onClick={() => toggleTableFilterSidebar('hitDetails')}
                resetOrder
              >
                <FiltersIcon />
                Filters
                {countBadge ? <CountBadge>{countBadge}</CountBadge> : null}
              </FiltersButton>
            </>
          )}
          <SearchInput
            style={{ width: 200 }}
            type="text"
            value={search}
            onChange={evt => setSearch('hitDetails', evt.target.value)}
            placeholder="Search"
          />
        </div>
      </TableAccessory>
      <MaterialTable
        filters={filters}
        search={search}
        hover
        stickyRows
        expanded={expanded}
        materialTableProps={{
          components: {
            GroupRow
          },
          columns: column,
          data: hitsView,
          options: {
            paging: false,
            grouping: true,
            header: false,
            filtering: true,
            rowStyle: {
              backgroundColor: '#ededed',
              height: 65,
              marginBottom: 5
            }
            // editCellStyle:{boxShadow: 'none!important'}
          },
          style: { backgroundColor: 'transparent', border: 'none' },
          detailPanel: (props: any) => (
            <HitDetailsWrapper>
              <HitDetailsDetailPanel {...props} />
            </HitDetailsWrapper>
          )
        }}
      />
      <Filters
        isOpen={tableFilterSidebar.hitDetails}
        onClose={() => toggleTableFilterSidebar('hitDetails')}
      />
    </HitDetailsContainer>
  );
};

export default HitDetails;
