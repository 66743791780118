import React, { FC } from 'react';
import ReactSelect, {
  Props,
  OptionProps,
  components,
  MultiValueProps
} from 'react-select';

import Checkbox from 'styleguide/components/Checkbox';
import styled from 'styled-components';

export type OptionType = { label: string; value: string };

const StyledCheckbox = styled(Checkbox)`
  display: inline-flex;
  margin-right: 4px;
`;

const StyledBasicSelect = styled(ReactSelect)`
  .react-basic-select__control {
    display: flex;
    width: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.regular};
    margin: 15px 0;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
    line-height: 1.2;
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};

 

    &--is-focused,
    &:hover {
      box-shadow: none;
      border: 1px solid ${({ theme }) => theme.colorsNewDesign.primary};
    }
  }

  .react-basic-select__placeholder {
    color: ${({ theme }) => theme.colorsNewDesign.gray400};
  }

  .react-basic-select__single-value {
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    font-weight: 500;
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  }


  .react-basic-select__value-container {
    padding: 12px 16px 12px 16px;

    &--is-multi {
      max-height: 84px;
      overflow: auto;
    }
  }

  .react-basic-select__menu {
    margin-top: 0;
    border-radius: 0;
    @media (max-width:768px){
      width: 550px;
      margin-left:-140px;

    }

  }

  .react-basic-select__menu-list {
    padding: 0;

    &--is-multi {
      .react-basic-select__option {
        &--is-selected {
          color: ${({ theme }) => theme.colorsNewDesign.darkest};
        }
      }
    }
  }

  .react-basic-select__option {
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
    line-height: 1.2;



    &--is-selected {
      background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
      color: ${({ theme }) => theme.colorsNewDesign.gray400};
    }

    &--is-focused {
      background-color: ${({ theme }) => theme.colorsNewDesign.selectHover};
      color: inherit;
    }
  }

  .react-basic-select__indicator-separator {
    display: none;
  }


  .react-basic-select__indicator {
    color: ${({ theme }) => theme.colorsNewDesign.darkest};
  }

  .react-basic-select__input {
    font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  .react-basic-select__multi-value {
    color: ${({ theme }) => theme.colorsNewDesign.primary};
    background: ${({ theme }) => theme.colorsNewDesign.grayBlue100};
    border-radius: 999px;

    &__label {
      font-size: ${({ theme }) => theme.fontSizesNewDesign.xxs};
      color: ${({ theme }) => theme.colorsNewDesign.primary};
      padding: 4px 5px 4px 12px;
      line-height: 1.5;
    }

    &__remove {
      border-radius: 999px;
      margin-right: 8px;

      &:hover {
        color: ${({ theme }) => theme.colorsNewDesign.error};
        background-color: inherit;
      }
    }

  }


`;

const MultiselectOption: FC<OptionProps<OptionType>> = props => (
  <components.Option {...props}>
    <StyledCheckbox checked={props.isSelected} />
    {props.children}
  </components.Option>
);

const MultiValue: FC<MultiValueProps<OptionType>> = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const allOption: OptionType = {
  label: 'Select all',
  value: '*'
};

const Select: FC<Props<OptionType>> = ({ components = {}, ...props }) => {
  // select all from https://github.com/JedWatson/react-select/issues/3543#issuecomment-565733597
  const options = props.isMulti ? [allOption, ...props.options] : props.options;
  const onChange = props.isMulti
    ? (selected: any, event: any) => {
        if (selected !== null && selected.length > 0) {
          if (selected[selected.length - 1].value === allOption.value) {
            return props.onChange(
              [allOption, ...(props.options as OptionType[])],
              event
            );
          }
          let result = [];
          if (selected.length === props.options.length) {
            if (selected.includes(allOption)) {
              result = selected.filter(
                (option: OptionType) => option.value !== allOption.value
              );
            } else if (event.action === 'select-option') {
              result = [allOption, ...props.options];
            }
            return props.onChange(result, event);
          }
        }

        return props.onChange(selected, event);
      }
    : props.onChange;

  return (
    <StyledBasicSelect
      components={
        props.isMulti
          ? { ...components, Option: MultiselectOption, MultiValue }
          : components
      }
      classNamePrefix="react-basic-select"
      {...props}
      options={options}
      onChange={onChange}
    />
  );
};

export default Select;
