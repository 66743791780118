import React, { FC } from 'react';
import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';

import { Wrapper, SuggestionText } from './styled';
import Spacing from 'styleguide/components/Spacing';
import Button from 'styleguide/components/Button';

interface ShareSuggestionPopupProps {
  onShare: () => void;
  onClose: () => void;
  isOpen: boolean;
}

const ShareSuggestionPopup: FC<ShareSuggestionPopupProps> = ({
  onShare,
  isOpen,
  onClose
}) => {
  const handleShare = () => {
    onClose();
    onShare();
  };

  return (
    <BasicModal isOpen={isOpen} onClose={onClose}>
      <Wrapper>
        <BasicHeading tag="h3" size="xl">
        Share with your team or client?
        </BasicHeading>
        <Spacing direction="top" value={25} />
        <SuggestionText>
          You can share this matter so that others can see updates to your order
          as they come in.
        </SuggestionText>
        <Spacing direction="top" value={20} />

        <div className="d-flex justify-content-center">
          <Button primary medium onClick={handleShare}>
            Share now
          </Button>
        </div>
        <Spacing direction="top" value={20} />
      
      </Wrapper>
    </BasicModal>
  );
};

export default ShareSuggestionPopup;
