import styled from 'styled-components';

import StyleguidePasswordInput from 'styleguide/components/PasswordInput';
import BasicText from 'styleguide/components/BasicText';

export const Wrapper = styled.div`
  width: 320px;
`;

export const PasswordInput = styled(StyleguidePasswordInput)`
  margin: 8px 0;
`;

export const LoaderWrapper = styled.div`
  text-align: center;
  margin-left: 10px;
`;

export const ResetSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HelpText = styled(BasicText)`
  width: 310px;
  text-align: center;
  line-height: 1.5;
`;
