import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ThemeType } from 'theme/theme';

interface BasicHeadingProps {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' ;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

interface StyledHeadingProps {
  size: keyof ThemeType['fontSizesNewDesign'];
  isCentered?: boolean;
}

const StyledHeading = styled.h1<StyledHeadingProps>`
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme, size }) => theme.fontSizesNewDesign[size]};
  color: ${({ theme }) => theme.colorsNewDesign.darkest};

  ${({ isCentered }) =>
    isCentered &&
    css`
      text-align: center;
    `};
`;

const BasicHeading: FC<BasicHeadingProps & StyledHeadingProps> = ({
  children,
  size = 'regular',
  tag,
  isCentered = false,
  className,
  onClick,
  style
}) => (
  <StyledHeading
    as={tag}
    size={size}
    isCentered={isCentered}
    className={className}
    onClick={onClick}
    style={style}
  >
    {children}
  </StyledHeading>
);

export default BasicHeading;
