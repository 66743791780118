import styled from 'styled-components';

const StyledDescription = styled.p`
  line-height: 1.5;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

export default StyledDescription;
