import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import uniq from 'lodash/uniq';
import { useProductState, getSearches } from '../../state/productsState';
import { useJurisdictionState } from '../../state/jurisdictionsState';
import { SearchesFormValues } from '../../searches';
import { UCC_STATE_PRODUCT_ID, WASHINGTON_DC_PRODUCT_ID } from '../../consts';

import {
  mapSelection
  //  reduceSelection
} from '../../utils';
import _ from 'lodash';

// Returns a function which will return list of products which can
// be applied for given jurisdiciton id
export function useValidSearchesProductsForJurisdiction() {
  const [{ states }] = useJurisdictionState();
  const [{ products }] = useProductState();
  const searchesProducts = getSearches(products);
  const stateIds = states.map(s => s.id);

  const getValidProducts = useCallback(
    (jurisdictionId: string) => {
      if (!jurisdictionId) return searchesProducts;
      const isDC = jurisdictionId === WASHINGTON_DC_PRODUCT_ID;
      const isState = stateIds.includes(jurisdictionId);

      if (isDC) {
        return searchesProducts.filter(p => p.id !== UCC_STATE_PRODUCT_ID);
      } else if (isState) {
        return searchesProducts.filter(p => p.id === UCC_STATE_PRODUCT_ID);
      }
      return searchesProducts;
    },
    [stateIds, searchesProducts]
  );

  return getValidProducts;
}

// Updates selected products in column for given jurisdiction to be valid
// used on jurisdiction change
export function useNormalizeProductsForJurisdiction(col: number) {
  const {
    values: { selection },
    setFieldValue
  } = useFormikContext<SearchesFormValues>();
  const getValidProducts = useValidSearchesProductsForJurisdiction();

  return (jurisdictionId: string) => {
    if (!selection || !selection[col]) return;
    const validProductIds = getValidProducts(jurisdictionId).map(p => p.id);
    setFieldValue(
      `selection.${col}`,
      selection[col].map(row =>
        row && row.length >= 0
          ? row.filter(id => validProductIds.includes(id))
          : row
      )
    );
  };
}

// Returns products avaliable for given cell
export function useSearchesProductsForColumn(col: number) {
  const {
    values: { jurisdictions }
  } = useFormikContext<SearchesFormValues>();
  const getValidProducts = useValidSearchesProductsForJurisdiction();
  return useMemo(() => getValidProducts(jurisdictions[col]?.value), [
    col,
    getValidProducts,
    jurisdictions
  ]);
}

// Returns array of products for each table column
export function useSearchesProductIdsForColumns() {
  const {
    values: { jurisdictions }
  } = useFormikContext<SearchesFormValues>();
  const getValidProducts = useValidSearchesProductsForJurisdiction();
  return useMemo(
    () =>
      jurisdictions && jurisdictions[0] !== null
        ? jurisdictions
            .filter(jurisdiction => jurisdiction !== null)
            .map(({ value }) => getValidProducts(value).map(p => p.id))
        : [],
    [getValidProducts, jurisdictions]
  );
}

// Handles bulk select for each column
export function useAllProductSelect() {
  const [{ products: allProducts }] = useProductState();
  const products = useMemo(() => getSearches(allProducts), [allProducts]);
  const validProductIdsForColumn = useSearchesProductIdsForColumns();

  const {
    values: { selection },
    setFieldValue
  } = useFormikContext<SearchesFormValues>();

  const disabled = useMemo(() => {
    let canSelect = uniq([].concat(...validProductIdsForColumn));
    const disabledList = products
      .map(p => p.id)
      .filter(id => !canSelect.includes(id));

    if (disabledList.length) {
      return [...disabledList, 'selectAll'];
    } else {
      return disabledList;
    }
  }, [validProductIdsForColumn, products]);

  // const selected = useMemo(
  //   () =>
  //     products
  //       .map(p => p.id)
  //       .filter(productId => {
  //         const isProductSelected = reduceSelection(
  //           (row: string[], colIndex: number) =>
  //             !validProductIdsForColumn[colIndex].includes(productId) ||
  //             row.includes(productId)
  //         )(selection);
  //         return isProductSelected;
  //       }),
  //   [selection, validProductIdsForColumn, products]
  // );

  // const selected: any = useMemo(() =>
  //   products.map(p => p.id)
  //     .filter(id =>
  //       selection.reduce((res: boolean, selectIds: any) =>
  //         selectIds.reduce((res: boolean, ids: any) =>
  //           ids && ids.length && ids.includes(id) && res, true), true))
  //   , [selection, products]);
  const flattened: string[] = _.uniq(_.flattenDeep(selection));
  const selected: any = useMemo(
    () =>
      products.map(p => p.id).filter((id: string) => flattened.includes(id)),
    [products, flattened]
  );

  const select = (productId: string) => {
    setFieldValue(
      'selection',
      mapSelection((row: string[], colIndex: number) =>
        !row.includes(productId) &&
        validProductIdsForColumn[colIndex].includes(productId)
          ? [...row, productId]
          : row
      )(selection)
    );
  };

  const deselect = (productId: string) => {
    setFieldValue(
      'selection',
      mapSelection((row: string[]) => row.filter(p => p !== productId))(
        selection
      )
    );
  };

  const allSelected = useMemo(() => selected.length === products.length, [
    selected,
    products
  ]);

  const selectAll = () => {
    setFieldValue(
      'selection',
      mapSelection((row: string[], colIndex: number) => [
        ...validProductIdsForColumn[colIndex]
      ])(selection)
    );
  };

  const deselectAll = () => {
    setFieldValue('selection', mapSelection(() => [])(selection));
  };

  return {
    disabled,
    products,
    selected,
    select,
    deselect,
    allSelected,
    selectAll,
    deselectAll
  };
}

// Handles bulk select for one column
export function useAllColumnProductSelect(colIndex: number) {
  const products = useSearchesProductsForColumn(colIndex);
  const {
    values: { selection },
    setFieldValue
  } = useFormikContext<SearchesFormValues>();

  const selected = useMemo(
    () =>
      products
        .map(p => p.id)
        .filter(productId => {
          if (!selection[colIndex]) return false;
          const isProductSelected = selection[colIndex]
            .map(row =>
              row && row.length >= 0 ? row.includes(productId) : true
            )
            .reduce((acc, val) => acc && val === true, true);
          return isProductSelected;
        }),
    [colIndex, products, selection]
  );

  const setValue = (map: (row: string[]) => string[]) => {
    if (selection[colIndex])
      setFieldValue(
        `selection.${colIndex}`,
        selection[colIndex].map(row =>
          row && row.length >= 0 ? map(row) : row
        )
      );
  };

  const select = (productId: string) =>
    setValue(row => (!row.includes(productId) ? [...row, productId] : row));

  const deselect = (productId: string) =>
    setValue(row => row.filter(p => p !== productId));

  const allSelected = useMemo(() => selected.length === products.length, [
    selected,
    products
  ]);

  const selectAll = () => setValue(row => products.map(p => p.id));

  const deselectAll = () => setValue(row => []);
  return {
    products,
    selected,
    select,
    deselect,
    allSelected,
    selectAll,
    deselectAll
  };
}
