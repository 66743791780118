import React, { FC, useState, useMemo, useEffect, Suspense, lazy } from 'react';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import size from 'lodash/size';
import { compareAsc } from 'date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import { HitDetails } from 'api/matter';
import MobileTab from 'styleguide/components/Tabs/MobileTab';
import SidebarDetailsGroup from '../SidebarDetailsGroup';
import SidebarNotes from './SidebarNotes';
import {
  PDFWrapperForMobile,
  SidebarWrapperMobile,
  BlankContainer,
  SidebarHeaderMobile,
  StickyContainerSidebar,
  SidebarContent,
  PDFMobileLoader
} from '../styled';
import _, { sortBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useMatterState } from '../../../state/matter';

const Viewer = lazy(() => import('./Viewer'));
const Fallback = (): any => null;

interface SidebarProps {
  id: string;
  productFamily: string;
  productName: string;
  hitDetails?: HitDetails[];
  role: string;
  fileUrl: string;
}

const Sidebar: FC<SidebarProps> = ({
  id,
  productFamily,
  productName,
  hitDetails = [],
  fileUrl,
  role
}) => {
  const [{ hits }] = useMatterState();
  const hit = useMemo(() => hits ? hits.find(h => h.id === id) : null, [id, hits]);

  const showDetailsTab = productFamily === 'Searches' && size(hitDetails) > 0;
  const showNotesTab = role !== 'limitedViewer';
  const history = useHistory();
  const tabs = useMemo(
    () =>
      [
        { id: 'documents', label: 'Document', badge: 0 },
        showDetailsTab && { id: 'details', label: 'Details', badge: 0 },
        showNotesTab && { id: 'notes', label: 'Notes', badge: hit ? hit.notes.length : 0 }
      ].filter(Boolean),
    [showDetailsTab, showNotesTab, hit]
  );

  const [activeTab, setActiveTab] = useState(tabs[0]?.id);
  const routeState:any = history.location.state;

  useEffect(() => {
    if (routeState) {
      if (routeState.fromButton === "notes" && showNotesTab) {
        setActiveTab('notes');
      }  
    }
  }, [showDetailsTab, routeState]); // eslint-disable-line

  const groupedHitDetails = useMemo(
    () =>
      mapValues(groupBy(hitDetails, 'group'), group =>
        group.sort((a, b) =>
          compareAsc(new Date(a.fileDate), new Date(b.fileDate))
        )
      ),
    [hitDetails]
  );

  if (tabs.length === 0) {
    return null;
  }

  return (
    <SidebarWrapperMobile>
      <StickyContainerSidebar>
        <SidebarHeaderMobile>
          <MobileTab tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
        </SidebarHeaderMobile>
      </StickyContainerSidebar>

      <BlankContainer />

      {activeTab === 'documents' && (
        <PDFWrapperForMobile>
          {fileUrl ? (
            <Suspense fallback={<Fallback />}>
              <Viewer fileUrl={fileUrl} />
            </Suspense>
          ) : <PDFMobileLoader><CircularProgress /></PDFMobileLoader>}
        </PDFWrapperForMobile>
      )}
      {activeTab === 'details' && (
        <SidebarContent>
          {sortBy(_.values(groupedHitDetails),'0.group').map(hitDetails => (
            <SidebarDetailsGroup
              productName={productName}
              hitDetails={hitDetails}
            />
          ))}
        </SidebarContent>
      )}
      {activeTab === 'notes' && <SidebarNotes id={id} />}
    </SidebarWrapperMobile>
  );
};

export default Sidebar;
