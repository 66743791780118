import React, { FC } from 'react';
import CellProductForm from '../TableForm/CellProductForm';
import SearchesCellProductValue from './SearchesCellProductValue';
import { useSearchesProductsForColumn } from './useProducts';

interface SearchesCellProductFormProps {
  row: number;
  col: number;
}

const SearchesCellProductForm: FC<SearchesCellProductFormProps> = props => {
  const products = useSearchesProductsForColumn(props.col);
  return (
    <CellProductForm
      {...props}
      products={products}
      valueComponent={SearchesCellProductValue}
      productFamily="Searches"
    />
  );
};

export default SearchesCellProductForm;
