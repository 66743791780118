import React, { FC } from 'react';
import head from 'lodash/head';
import defaultTo from 'lodash/defaultTo';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import BasicText from 'styleguide/components/BasicText';
import { ReactComponent as ShareIcon } from 'components/icons/IconShare.svg';
import UserAccessRow from '../ShareModal/UserAccessRow';
import { InvitedUser, Owner } from 'api/matter';
import { isMagicLink } from 'helpers/helpers';

import { Wrapper, BlueBox, HorizontalLine, Link } from './styled';

interface ViewAccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  invites: InvitedUser[];
  owner: Owner;
  openPermissions: () => void;
}

const ViewAccessModal: FC<ViewAccessModalProps> = ({
  isOpen,
  onClose,
  name,
  invites,
  owner,
  openPermissions
}) => (
  <BasicModal
    title={
      <>
        <ShareIcon />
        <Spacing direction="left" value={16} />
        <BasicHeading tag="h3" size="xl">
          {name}
        </BasicHeading>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
  >
    <BlueBox>
      <BasicText size="regular">
        Only the Owner can manage access to this matter
      </BasicText>
    </BlueBox>
    <Wrapper>
      <BasicText size="regular">
        The people below can view this matter with the following roles
      </BasicText>
      <Spacing direction="top" value={24} />
      <HorizontalLine width="100%" />
      <UserAccessRow
        name={`${owner.firstName} ${owner.lastName}`}
        initials={`${head(owner.firstName)}${head(owner.lastName)}`}
        description={owner.email}
        role="owner"
        index={0}
        isEditable={false}
        isMagicLink={false}
      />
      {invites.map((invite, index) => {
        const email = defaultTo(
          isMagicLink(invite.contact)
            ? invite.shareContact?.firstName
            : invite.contact,
          ''
        );
        return (
          <UserAccessRow
            key={invite.contact}
            name={email}
            initials={email.replace(/\W/g, '').slice(0, 2)}
            role={invite.role}
            index={index + 1}
            isEditable={false}
            isMagicLink={isMagicLink(invite.contact)}
          />
        );
      })}
      <Spacing direction="top" value={24} />
      <Link onClick={openPermissions} size="regular">
        Learn more about roles and permissions
      </Link>
    </Wrapper>
  </BasicModal>
);

export default ViewAccessModal;
