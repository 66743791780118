import React from 'react';

import BasicAside from 'styleguide/components/BasicAside';
import BasicOrderedList from 'styleguide/components/BasicOrderedList';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicDescription from 'styleguide/components/BasicDescription';
import Tooltip from 'styleguide/components/Tooltip';
import { ReactComponent as IconTooltip } from 'components/icons/IconTooltip.svg';
import styled from 'styled-components';
import Bg from 'components/icons/Group.png';
const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  h5,
  h3 {
    color: #fff;
  }
`;
const AsideInstruct = styled(BasicAside.Wrapper)`
  padding: 10px;
  background: url(${Bg});
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% 135%;
`;

const CreateGridAside = () => (
  <>
    <AsideHead>
      <BasicAside.SubHeading>SEARCHES: STEP 1 OF 2</BasicAside.SubHeading>
      <BasicAside.Heading>
        Create your <br /> Searches grid
      </BasicAside.Heading>
    </AsideHead>
    <AsideInstruct>
      <BasicAside.Content>
        <BasicOrderedList.Wrapper>
          <BasicOrderedList.Item
            description="Add all of the names you want to search."
            hasSpacing
            tip={
              <>
                <BasicHeading tag="h5" size="xs" style={{ color: '#84848D' }}>
                  Tip:
                </BasicHeading>

                <BasicDescription style={{ color: '#84848D' }}>
                  You can copy and paste a list of names.
                </BasicDescription>
              </>
            }
          >
            <BasicHeading tag="h5" size="md">
              Add names
            </BasicHeading>
          </BasicOrderedList.Item>
          <BasicOrderedList.Item
            description="Type or select the places to search."
            hasSpacing
            tip={
              <>
                <BasicDescription>
                  Specify a state{' '}
                  <strong>
                    <u>and county</u>
                  </strong>{' '}
                  (i.e., Delaware, Kent County) unless you are ONLY ordering
                  state-level UCC searches. &nbsp;&nbsp;
                  <IconTooltip
                    data-tip
                    data-for="createGridAsideJurisdictionsTooltip"
                    style={{ width: '15px' }}
                  />
                  <Tooltip
                    id="createGridAsideJurisdictionsTooltip"
                    place="right"
                  >
                    Why is a county required?
                    <br />
                    Many state and federal searches require county information.
                    <br />
                    For example, federal tax liens are generally filed and
                    searched at the county level. <br />
                    We would also use the county you provide
                    <br />
                    to determine which U.S. District to search for federal
                    litigation and bankruptcies.
                  </Tooltip>
                </BasicDescription>
              </>
            }
          >
            <BasicHeading tag="h5" size="md">
              Add jurisdictions
            </BasicHeading>
          </BasicOrderedList.Item>
          <BasicOrderedList.Item
            description="Click cells or drag across cells to indicate where each name should be searched."
            hasSpacing
          >
            <BasicHeading tag="h5" size="md">
              Match names to jurisdictions
            </BasicHeading>
          </BasicOrderedList.Item>
        </BasicOrderedList.Wrapper>
      </BasicAside.Content>
    </AsideInstruct>
  </>
);

export default CreateGridAside;
