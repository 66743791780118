import React, { FC } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import BasicText from 'styleguide/components/BasicText';
import Button from 'styleguide/components/Button';
import Spacing from 'styleguide/components/Spacing';
import { REGISTRATION_URL, LOGIN_URL } from 'helpers/routes';

import {
  RegistrationModalWrapper,
  RegistrationModalButtonsRow
} from './styled';
import BasicHeading from 'styleguide/components/BasicHeading';

interface RegistrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  logOut: (redirectTo?: string) => void;
}

const RegistrationModal: FC<RegistrationModalProps> = ({
  isOpen,
  onClose,
  logOut
}) => {
  const redirectToLogin = () => {
    logOut(LOGIN_URL);
  };

  const redirectToRegistration = () => {
    logOut(REGISTRATION_URL);
  };

  return (
    <BasicModal isOpen={isOpen} onClose={onClose} 
    title={
      <BasicHeading tag="h3" size="xl">
        Have an account?
      </BasicHeading>
    }>
      <RegistrationModalWrapper>
        <Spacing direction="top" value={24} />
        <BasicText size="regular">
          For full access to DealBinder, sign in or register.
          <br />
          Registration is free.
        </BasicText>
        <Spacing direction="top" value={28} />
        <RegistrationModalButtonsRow>
          <Button medium resetOrder onClick={redirectToLogin}>
            Sign In
          </Button>
          <Spacing direction="left" value={8} />
          <Button medium resetOrder primary onClick={redirectToRegistration}>
            Sign Up
          </Button>
        </RegistrationModalButtonsRow>
        <Spacing direction="top" value={24} />
      </RegistrationModalWrapper>
    </BasicModal>
  );
};

export default RegistrationModal;
