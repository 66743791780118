import { url } from 'api';

import { termsOfServiceApi } from './termsOfService';
import { termsOfUseApi } from './termsOfUse';
import { privacyPolicyApi } from './privacyPolicy';

const path = `${url}/legal-pages`;

export const legalPagesApi = () => ({
  path,
  termsOfService: () => termsOfServiceApi(`${path}/terms-of-service`),
  termsOfUse: () => termsOfUseApi(`${path}/terms-of-use`),
  privacyPolicy: () => privacyPolicyApi(`${path}/privacy-policy`)
});
