export default {
  title: `Smarter, faster search reports and organizational documents`,
  itemOne: `100% free to use`,
  itemFour: `Only pay for what you order`,
  itemTwo: `Instant order estimates`,
  itemThree: `Real time reporting, less stress`,
  step: `Step {currentStep} of 2`,
  titleDescription: `sign in to your account`,
  firstName: 'First name',
  firstNamePlaceholder: 'Enter first name',
  lastName: 'Last name',
  lastNamePlaceholder: 'Enter last name',
  email: `Email address`,
  emailPlaceholder: `Enter your email`,
  password: `Password`,
  passwordPlaceholder: `Enter password`,
  allMostFinished: `Almost finished!`,
  firmName: `Company name`,
  firmNamePlaceholder: `Enter company name`,
  firmAddress: `Address`,
  firmAddressPlaceholder: `Enter company Address`,
  city: `City`,
  cityPlaceholder: `Enter city`,
  state: `State`,
  statePlaceholder: `Select`,
  postalCode: `ZIP Code`,
  postalCodePlaceholder: `Enter ZIP Code`,
  phone: `Work Phone`,
  phonePlaceholder: `Enter work phone`,
  terms: `By signing up, I agree to the {privacyPolicy}, {termsOfService} and {termsOfUse}.`,
  termsOfService: `Terms of Service`,
  termsOfUse: `Terms of Use`,
  privacyPolicy: `Privacy Policy`,
  signInText: 'Already have an account?'
};
