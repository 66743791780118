import styled from 'styled-components';

import BaseLink from 'components/shared/baseLink/BaseLink';
import StyleguideTextInput from 'styleguide/components/TextInput';
import BasicText from 'styleguide/components/BasicText';

export const StyledBaseLink = styled(BaseLink)`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  width: 100%;
  text-align: center;
  display: block;
  font-size: 15px;
`;

export const TextInput = styled(StyleguideTextInput)`
  margin: 0 0 8px 0;
`;

export const HelpText = styled(BasicText)`
  width: 320px;
  text-align: center;
  line-height: 1.5;
`;

export const LoaderWrapper = styled.div`
  text-align: center;
  margin-left: 10px;
`;
