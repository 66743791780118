import registration from './registration';
import links from './links';
import shared from './shared';
import errors from './errors';
import verify from './verify';
import checkYourEmail from './checkYourEmail';
import login from './login';
import forgotPassword from './forgotPassword';
import resetPassword from './resetPassword';
import formStepsNav from './formStepsNav';
import notFound from './notFound';
import reviewStepsList from './reviewStepsList';
import newOrder from './newOrder';


export default {
  registration,
  links,
  shared,
  errors,
  verify,
  checkYourEmail,
  login,
  forgotPassword,
  resetPassword,
  formStepsNav,
  notFound,
  reviewStepsList,
  newOrder
};
