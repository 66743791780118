import React from 'react';
import { useHistory } from 'react-router-dom';
import { useOrderFormState } from './state/state';
import LoadingLayout from 'layout/loadingLayout/LoadingLayout';
import { orderToFormData } from './estimation';
import { fetchAdminOrderData } from 'api/order/admin';

const AdminOrder = (props: any) => {
  const history = useHistory();
  const [, { loadState }] = useOrderFormState();
  const contactId = props.match.params.contactid;
  const matterId = props.match.params.matterid;
  const orderId = props.match.params.orderid;

  React.useEffect(() => {
    if (contactId) {
      if (matterId && orderId) {
        fetchAdminOrderData(orderId).then(order => {
          if (order.stage === 'estimate') {
            const data = orderToFormData(order);
            loadState({
              ...data,
              matterId,
              orderId,
              shouldDeleteEstimate: true,
              contactId,
              adminDiscount: order.adminDiscount,
              adminDiscountMessage: order.adminDiscountMessage,
            });
            history.push('/order/review');
          } else {
            history.push('/');
          }
        });
      } else {
        loadState({ contactId });
        history.push('/order');
      }
    }
  }, []) // eslint-disable-line

  return <LoadingLayout />;
};

export default AdminOrder;
