import React, { FC } from 'react';
import {
  FiltersButton,
  CountBadge
} from '../../../pages/matter/components/common/styled';
import { useMatterState } from '../../../pages/matter/state/matter';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';




const OfacButton: FC = () => {

    const [
        {

          tables: {
            ofac: { filters }
          }
          
        },
        { toggleTableFilterSidebar }
      ] = useMatterState();
      const countBadge = React.useMemo(() => Object.keys(filters).length, [filters]);
   return(
       <>
         <FiltersButton
            active={Boolean(Object.keys(filters).length)}
            onClick={() => toggleTableFilterSidebar('ofac')}
            resetOrder
          >
            <FiltersIcon />
            { countBadge ? <CountBadge>{ countBadge }</CountBadge> : null }
          </FiltersButton>

    </>
);
}

export default OfacButton;
