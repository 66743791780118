import React from 'react';
import styled from 'styled-components';

import BasicAside from 'styleguide/components/BasicAside';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicDescription from 'styleguide/components/BasicDescription';


const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;


const CreateGridAside = () => (
  <BasicAside.Wrapper>
     <AsideHead>
        {/* <BasicAside.SubHeading>SEARCHES: STEP 1 OF 2</BasicAside.SubHeading> */}
        <BasicAside.Heading>
        Review your choices
        </BasicAside.Heading>
      </AsideHead>
    <BasicAside.Content>
      <BasicHeading className='pl-2' tag="h5"  size="xs">
        Tip:
      </BasicHeading>

      <BasicDescription className='pl-2'>
        Hover over a cell to edit, add or delete a selection
      </BasicDescription>
    </BasicAside.Content>
  </BasicAside.Wrapper>
);

export default CreateGridAside;
