import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { CONTACT_MAIL } from 'helpers/routes';

import Spacing from 'styleguide/components/Spacing';
import {
  LeftColumnContent,
  Title,
  DescriptionItem,
  CheckIcon,
  DescriptionText,
  HelpText,
  HelpTextLink
} from './styled';

const RegistrationLeftColumn: FC = () => (
  <LeftColumnContent>
    <Title tag="h1" size="xxxxl">
      <FormattedMessage id="registration.title" />
    </Title>
    <Spacing direction="top" value={50} />
    <DescriptionItem>
      <CheckIcon />
      <Spacing direction="right" value={16} />
      <DescriptionText size="l">
        <FormattedMessage id="registration.itemOne" />
      </DescriptionText>
    </DescriptionItem>
    <Spacing direction="top" value={25} />
    <DescriptionItem>
      <CheckIcon />
      <Spacing direction="right" value={16} />
      <DescriptionText size="l">
        <FormattedMessage id="registration.itemFour" />
      </DescriptionText>
    </DescriptionItem>
    <Spacing direction="top" value={25} />
    <DescriptionItem>
      <CheckIcon />
      <Spacing direction="right" value={16} />
      <DescriptionText size="l">
        <FormattedMessage id="registration.itemTwo" />
      </DescriptionText>
    </DescriptionItem>
    <Spacing direction="top" value={25} />
    <DescriptionItem>
      <CheckIcon />
      <Spacing direction="right" value={16} />
      <DescriptionText size="l">
        <FormattedMessage id="registration.itemThree" />
      </DescriptionText>
    </DescriptionItem>
    <HelpText>
      <FormattedMessage id="login.help" />{' '}
      <HelpTextLink href={CONTACT_MAIL}>
        <FormattedMessage id="links.contactUs" />
      </HelpTextLink>
    </HelpText>
  </LeftColumnContent>
);

export default RegistrationLeftColumn;
