import React, { FC, useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import {
  DASHBOARD_URL,
  MATTERS_URL,
  ESTIMATES_URL,
  SETTINGS_URL,
  getMatterUrl
} from 'helpers/routes';
import Sidebar from './components/Sidebar';

import Home from './components/Home';
import MyEstimates from './components/MyEstimates';
import MyMatters from './components/MyMatters';
import Settings from './components/Settings';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { Wrapper, ContentWrapper } from './styled';
import Matter from 'pages/matter';
import { useDeviceState } from 'state/deviceState';
import PersistentDrawerLeft from 'layout/authenticationLayout/component/PersistentDrawerLeft';

const Dashboard: FC = () => {
  const scrollingContainerRef = useRef(null);
  const { pathname } = useLocation();
  const [state] = useDeviceState();

  useEffect(() => {
    if (scrollingContainerRef?.current) {
      scrollingContainerRef.current.scrollTop = 0;
    }
  }, [pathname,scrollingContainerRef]);

  return (
    <>
   { state.isMobileOrTablet && <PersistentDrawerLeft sidebar={<Sidebar />}/>}
      <Wrapper>
        {state.isDesktop && <Sidebar />}
        <ContentWrapper ref={scrollingContainerRef}>
          <Switch>
            <Route path={getMatterUrl(':id')} component={Matter} />
            <Route path={MATTERS_URL} component={MyMatters} />
            <Route path={ESTIMATES_URL} component={MyEstimates} />
            <Route path={SETTINGS_URL} component={Settings} />
            <Route path={DASHBOARD_URL} component={Home} />
          </Switch>
          <ScrollToTop scrollingContainerRef={scrollingContainerRef} />
          <Footer />
        </ContentWrapper>
      </Wrapper>
      {/* </PersistentDrawerLeft> */}
      </>
      
  );
};

export default Dashboard;
