import React from 'react';
import { Link } from 'react-router-dom';
import MaterialTable from 'styleguide/components/MaterialTable';
import SearchInput from 'styleguide/components/SearchInput';
import Tag from 'styleguide/components/Tag';
import { useMatterState } from './state/matter';
import styled from 'styled-components';
import {
  TableAccessory,
  FiltersButton,
  ExpandButton,
  CountBadge
} from './components/common/styled';
import GroupRow from './components/GroupRow';
import Tooltip from 'styleguide/components/Tooltip';
import { ReactComponent as IconTooltip } from 'components/icons/IconTooltip.svg';
import { ReactComponent as IconExpand } from 'components/icons/IconExpand.svg';
import { shortDateFormat, etaDateFormat } from 'helpers/formatters';
import { getFilePreviewUrl } from './constants';
import InlineFilters from 'styleguide/components/InlineFilters';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
import Filters from './components/SearchesFilters';
import { optionsToLookup } from './utils';
import TableFooter from './components/TableFooter';
import SearchesNav from './components/SearchesNav';
import DealbinderMessage from './components/DealbinderMessage';
import ColumnAttachment from './components/ColumnAttachment';
import { useDeviceState } from 'state/deviceState';
import { theme } from 'theme/theme';
import _ from 'lodash';
import Loader from 'styleguide/components/Loader';

const SearchesStatus = styled.div`
  position: relative;
  left: 3px;
`;

const Searches = () => {
  const [deviceState] = useDeviceState();

  const [
    {
      id: matterId,
      isToggleLoading,
      view: { searches },
      tables: {
        searches: { filters, search, expanded }
      },
      tableFilterSidebar,
    },

    { setSearch, toggleExpanded, setFilter, clearFilter, toggleTableFilterSidebar }
  ] = useMatterState();

  const statusFilters = React.useMemo(() => {
    const statuses = searches.orderLineItems.map(oli => oli.status);
    return [
      { id: '', label: 'All' },
      { id: 'In Progress', label: 'In Progress' },
      { id: 'Hit', label: 'Hits' },
      { id: 'Clear', label: 'Clear' }
    ].filter(el => el.id === '' || statuses.includes(el.id));
  }, [searches.orderLineItems]);

  const countBadge = React.useMemo(() => Object.keys(filters).length, [filters]);
  React.useEffect(()=> {
    const row = _.uniq(searches.orderLineItems.map(row=> row.diligenceName));
    if(row?.length === 1){
      toggleExpanded('searches')
    }
  },[searches.orderLineItems,toggleExpanded])

  return (
    <>
      <TableAccessory>
        <div style={{ alignItems: 'center', padding: 0 }} className="col-sm-2 col-6">
          <SearchesNav />
        </div>
        {deviceState.isMobileOrTablet ? "" :
          <div className="col-sm">
            <InlineFilters
              tabs={statusFilters}
              activeTab={filters.status?.length === 1 ? filters.status[0] : ''}
              onChange={(id: string) => {
                clearFilter('searches');
                if (id !== '') {
                  setFilter('searches', 'status', [id]);
                }
              }}
            /></div>
        }

        <div style={{ height: '40px', padding: 0 }} className="col-sm-4 col-6 ">
          {deviceState.isMobileOrTablet ? "" : <> <ExpandButton
            onClick={() => toggleExpanded('searches')}
            // data-tip
            // data-for="expandrows"
            data-place="bottom"
            resetOrder
            active={expanded}
          >
            {isToggleLoading ? <Loader size={1.5} background="#f1f4f8" /> : <IconExpand />}
            {expanded ? 'Collapse' : 'Expand'} rows
          </ExpandButton>
            {/* <Tooltip id="expandrows">
              {expanded ? 'Collapse rows' : 'Expand rows'}
            </Tooltip> */}
            <FiltersButton
              active={Boolean(Object.keys(filters).length)}
              onClick={() => toggleTableFilterSidebar('searches')}
              resetOrder
            >
              <FiltersIcon />
              Filters
              {countBadge ? <CountBadge>{countBadge}</CountBadge> : null}
            </FiltersButton></>}
          <SearchInput
            style={{ width: 200, fontSize: theme.fontSizes.md }}
            type="text"
            value={search}
            onChange={evt => setSearch('searches', evt.target.value)}
            placeholder="Search"
          />
        </div>
      </TableAccessory>
      <MaterialTable
        filters={filters}
        search={search}
        hover
        stickyRows
        expanded={expanded}
        materialTableProps={{
          components: {
            GroupRow,
            Pagination: TableFooter
          },
          columns: [
            {
              title: 'Name',
              field: 'diligenceName',
              defaultGroupOrder: 0,
              customSort: () => 0,
              lookup: optionsToLookup(searches.filterOptions.name)
            },
            {
              title: 'Status',
              field: 'status',
              lookup: optionsToLookup(searches.filterOptions.status),
              render: ({
                status,
                id,
                estimatedTurnaround,
                dealbinderMessage
              }: any) => (
                <>
                  <ColumnAttachment left="-46px">
                    <SearchesStatus>
                      <DealbinderMessage id={id} message={dealbinderMessage} clickable />
                    </SearchesStatus>

                  </ColumnAttachment>
                  {status === 'In Progress' ? (
                    <>
                      <Tag
                        color="orange"
                        cursorPointer={true}
                        data-tip data-event={deviceState.isMobile ? 'click focus' : ''}
                        data-for={`status.${id}`}
                      >
                        {deviceState.isMobile ? "In Prog" : status}
                      </Tag>
                      {estimatedTurnaround ? (
                        <Tooltip id={`status.${id}`} isCapture={deviceState.isMobile}>
                          ETA: {etaDateFormat(estimatedTurnaround)}
                        </Tooltip>
                      ) : null}
                    </>
                  ) : (
                    <Tag color={status === 'Clear' ? 'cyan' : 'red'} isFilled>
                      {status}
                    </Tag>
                  )}
                </>
              )
            },
            {
              title: 'Search Type',
              field: 'product.name',
              lookup: optionsToLookup(searches.filterOptions.product),
              render: (rowData: any) => (
                <>
                  {deviceState.isMobileOrTablet ? rowData.product.mobileName : rowData.product.name}
                </>
              )
            },
            {
              title: 'Jurisdiction',
              field: 'jurisdiction.name',
              lookup: optionsToLookup(searches.filterOptions.jurisdiction),
              render: (rowData: any) => {
                return (
                  <>
                    {rowData.status !== 'In Progress' && rowData.source1 && (
                      <ColumnAttachment left="-31px">
                        {deviceState.isMobile ? <IconTooltip
                          data-tip
                          data-event='click focus'
                          data-for={`jurisdiction.${rowData.id}`}
                          style={deviceState.isMobile ? { width: '14px', height: '14px' } : { width: '14px', height: '14px' }}
                        /> :
                          <IconTooltip
                            data-tip
                            data-for={`jurisdiction.${rowData.id}`}
                            style={deviceState.isMobile ? { width: '14px', height: '14px' } : { width: '14px', height: '14px' }}
                          />
                        }

                        <Tooltip
                          id={`jurisdiction.${rowData.id}`} isCapture={deviceState.isMobile}
                        >
                          <p>
                            {rowData.source1} {rowData.source1Date && "(through " + shortDateFormat(rowData.source1Date) + ")"}
                          </p>
                          {rowData.source2 && (
                            <p>
                              {rowData.source2} {rowData.source2Date && "(through " + shortDateFormat(rowData.source2Date) + ")"}
                            </p>
                          )}
                          {rowData.yearsBack && (
                            <p>Years Searched Back: {rowData.yearsBack}</p>
                          )}
                        </Tooltip>
                      </ColumnAttachment>
                    )}
                    {
                      rowData.product.name === "UCC - State" ?
                        rowData.jurisdiction.state
                        : deviceState.isMobileOrTablet ? rowData.jurisdiction?.jurisdictionShort : rowData.jurisdiction?.name
                    }
                  </>
                )
              }
            },
            {
              title: 'reviewStatus',
              field: 'reviewStatus',
              cellStyle: { padding: 0, },
              lookup: optionsToLookup(searches.filterOptions.reviewStatus),
              render: () => '',
              customFilterAndSearch: (term: any, rowData: any) => {
                if (rowData.reviewHistory.length) {
                  const reviewHistory = _.sortBy(rowData.reviewHistory) 
                  return term.includes(reviewHistory[0].reviewStatus);
                }
                if (!rowData.reviewHistory.length && term[0] === 'Not reviewed yet') {
                  return true;
                }
                return false;
              }
            },
            {
              title: 'File',
              field: 'file',
              lookup: { true: 'true' },
              render: ({ id, file, status }: any) =>
                status === 'Hit' && file ? (
                  <>
                    <Link to={{ pathname: getFilePreviewUrl(matterId, 'searches', id), state: { fromButton: 'file' } }} >
                      {deviceState.isMobile ? "View" : "View file"}
                    </Link>
                  </>
                ) : (
                  '-'
                )
            }
          ],
          data: _.sortBy(searches.orderLineItems, "jurisdiction.name"),
          options: {
            paging: true,
            grouping: true,
            header: false,
            filtering: true,
            pageSize: 9999,
            debounceInterval: 1,
            editCellStyle: { background: '#EFF3F6!important', },
          },
          style: { border: 'none' },
        }}
      />

      <Filters
        isOpen={tableFilterSidebar.searches}
        onClose={() => toggleTableFilterSidebar('searches')}
      />
    </>
  );
};

export default Searches;
