import { createStore, createHook, StoreActionApi } from 'react-sweet-state';
import { uniqBy } from 'lodash';

import { fetchOrders, deleteMatter, Order } from 'api/orders';
import { OptionType } from 'styleguide/components/Select';

type Actions = typeof actions;
type StoreApi = StoreActionApi<State>;
export type Tables = 'myMatters';


export interface Filters {
  matterNames?: string[];
  orderStatuses?: string[];
  dates?: string[];
  invoiceStatuses?: string[];
}

interface State {
  isLoading: boolean;
  totalCount: number;
  orders: Order[];
  modalShow: boolean;
  currentPage: number;
  matterFilterOptions: OptionType[];
  dateFilterOptions: OptionType[];
  filters?: Filters;
  tableFilterSidebar?: {
    myMatters: boolean,
  }
}

const initialState: State = {
  isLoading: false,
  totalCount: null,
  orders: [],
  modalShow: false,
  currentPage: 1,
  matterFilterOptions: [],
  dateFilterOptions: [],
  filters: {},
  tableFilterSidebar: {
    myMatters: false,
  }
};

const formatDateForDateFilterOption = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

const fetchOrdersAction = async ({
  setState,
  getState
}: StoreApi): Promise<void> => {
  setState({ ...getState(), isLoading: true });

  const { currentPage: page, filters } = getState();
  const { orders, totalCount } = await fetchOrders({ page, ...filters });

  setState({
    ...getState(),
    totalCount,
    orders,
    modalShow:false,
    isLoading: false
  });
};
const setDeleteModal = async ({
  setState,
  getState
}: StoreApi): Promise<void> => {
  setState({ ...getState(), modalShow: !getState().modalShow });
};
const actions = {
  fetchData: () => async ({ dispatch }: StoreApi): Promise<void> => {
    dispatch(fetchOrdersAction);
  },
  triggerDeleteModal : () => async ({ dispatch }: StoreApi) => {
    dispatch(setDeleteModal);
  },
  changePage: (page: number) => async ({
    getState,
    setState,
    dispatch
  }: StoreApi): Promise<void> => {
    setState({ ...getState(), currentPage: page });

    dispatch(fetchOrdersAction);
  },
  deleteMatter: (id: string) => async ({ dispatch }: StoreApi) => {
    await deleteMatter(id);
    dispatch(fetchOrdersAction);
  },
  fetchMatterFilterOptions: () => async ({ getState, setState }: StoreApi) => {
    const { orders } = await fetchOrders({ pageSize: 999 });
    const matterFilterOptions = uniqBy(
      orders.map(({ matterName }) => ({
        value: matterName,
        label: matterName
      })),
      'label'
    );

    const dateFilterOptions = uniqBy(
      orders.map(({ orderDate }) => {
        const dateObject = new Date(orderDate);
        const date = dateObject.toLocaleDateString([], {
          dateStyle: 'short'
        } as any);
        return {
          value: formatDateForDateFilterOption(dateObject),
          label: date
        };
      }),
      'label'
    );

    setState({
      ...getState(),
      matterFilterOptions,
      dateFilterOptions
    });
  },
  updateFilters: (filters: Filters) => ({
    getState,
    setState,
    dispatch
  }: StoreApi) => {
    setState({ ...getState(), filters, currentPage: 1 });
    dispatch(fetchOrdersAction);
  },
  resetFilters: () => ({ getState, setState }: StoreApi) => {
    setState({ ...getState(), filters: {}, currentPage: 1 });
  },
  toggleTableFilterSidebar: (table: Tables) => ({ getState, setState }: StoreApi) => {
    let { tableFilterSidebar } = getState();

    setState({
      tableFilterSidebar: {
        ...tableFilterSidebar,
        [table]: !tableFilterSidebar[table]
      }
    })
  }
};

const Store = createStore<State, Actions>({
  initialState,
  actions,
  name: 'dashboardMyMatters'
});

export const useMyMattersState = createHook(Store);
