import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Steps from 'styleguide/components/Steps';
import FullLayout from 'styleguide/components/FullLayout';
import ExitButton from 'styleguide/components/ExitButton';
import ExitModal from './ExitModal';
import { STEP_PATHS, STEP_NAMES } from '../consts';
import { OrderFormStateSubscriber } from '../state/state';

const lastValidStep = (steps: STEP_NAMES[], validSteps: STEP_NAMES[]) => {
  const isStepNotValid = (step: STEP_NAMES) => !validSteps.includes(step);
  return (
    steps.findIndex((step, index, arr) => isStepNotValid(arr[index + 1])) || 0
  );
};

const isStepEnabled = (
  step: STEP_NAMES,
  steps: STEP_NAMES[],
  validSteps: STEP_NAMES[]
) =>
  steps.indexOf(step) <= lastValidStep(steps, validSteps) + 1;

const Navigation: FC = () => (
  <OrderFormStateSubscriber>
    {({ currentStep, steps, validSteps }) => (
      <FullLayout.Header>
        <FullLayout.Left />
        <FullLayout.Center>
          <Steps.List>
            {steps
              .filter(step => step !== STEP_NAMES.Start)
              .map(step => (
                <Steps.Item
                  isDone={validSteps.includes(step)}
                  isActive={currentStep === step}
                  key={step}
                >
                  {isStepEnabled(step, steps, validSteps) ? (
                    <Link to={STEP_PATHS[step]}>
                      <FormattedMessage id={`formStepsNav.${step}`} />
                    </Link>
                  ) : (
                    <FormattedMessage id={`formStepsNav.${step}`} />
                  )}
                </Steps.Item>
              ))}
          </Steps.List>
        </FullLayout.Center>
        <FullLayout.Right>
          <ExitModal
            trigger={open => <ExitButton type="button" onClick={open} />}
          />
        </FullLayout.Right>
      </FullLayout.Header>
    )}
  </OrderFormStateSubscriber>
);

export default Navigation;
