import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from 'styleguide/components/Button';
import BasicRadioList from 'styleguide/components/BasicRadioList';
import TextInput from 'styleguide/components/TextInput';
import Error from 'styleguide/components/Error';
import BasicBox, { BasicBoxFooter } from 'styleguide/components/BasicBox';
import Select, { OptionType } from 'styleguide/components/Select';
import { ValueType } from 'react-select';
import { useOrderFormState } from '../../state/state';
import Spacing from 'styleguide/components/Spacing';
import Loader from 'styleguide/components/Loader';
import Checkbox from 'styleguide/components/Checkbox';

import {
  ContactData,
  fetchContactData,
  fetchContactMatters
} from 'api/order/admin';

export const ReviewSchema = Yup.object().shape({
  matterId: Yup.object()
    .when('existing', {
      is: true,
      then: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required()
        })
        .required()
    })
    .nullable(),
  matterName: Yup.string().when('existing', {
    is: false,
    then: Yup.string()
      .min(3, 'Matter name should be at least 3 characters long')
      .required('Please specify matter name')
  })
});

export interface AdminReviewFormValues {
  stage?: string;
  existing: boolean;
  matterId?: OptionType;
  matterName?: string;
  costThreshold?: number;
  specialInstructions?: string;
  doNotify: boolean;
  adminDiscount?: number;
  adminDiscountMessage?: string;
}

const FormLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
`;
const DiscountWrapper = styled.span`
  display: inline-flex;
  position: relative;
  width: 100%;
`;
const RemoveDiscount = styled.span`
  color: red;
  position: absolute;
  right: 7px;
  top: 27px;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
const DiscountNotice = styled.div`
  color: ${({ theme }) => theme.userCircleColors.green};
  text-align: end;
  top: -12px;
  position: relative;
  padding: 5px;
`;

const ApplyButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

const ReviewForm = ({ setDiscount, discount }: any) => {
  const [
    {
      documents,
      searches,
      matterId,
      start,
      contactId,
      adminDiscount,
      adminDiscountMessage
    },
    { submitStep, submitAdmin }
  ] = useOrderFormState();

  const [contact, setContact] = React.useState<ContactData>(null);
  const [matters, setMatters] = React.useState([]);
  const [discountAmount, setDiscountAmount] = React.useState(null);
  const orderType = start?.orderType || 'order';
  React.useEffect(() => {
    if (document.readyState === 'complete') {
      if (discount) {
        document
          .getElementById('adminDiscount')
          .setAttribute('disabled', 'true');
      } else {
        document.getElementById('adminDiscount').removeAttribute('disabled');
      }
    }
  }, [discount]);
  React.useEffect(() => {
    //1. fetch contact
    fetchContactData(contactId).then(setContact);
    //2. fetch contact matters
    fetchContactMatters(contactId).then(data =>
      setMatters(
        data[0].map(({ id, name }: any) => ({ value: id, label: name }))
      )
    );
  }, []); // eslint-disable-line
  const handleDiscountApply = (setField: Function) => {
    setField('adminDiscount', Number(discountAmount));
    setDiscount(discountAmount);
  };
  const handleRemoveDiscount = (setField: Function) => {
    setField('adminDiscount', null);
    setDiscount(null);
  };
  return (
    <Formik<AdminReviewFormValues>
      initialValues={{
        existing: !!matterId,
        costThreshold: 0,
        matterName: '',
        matterId: matterId ? { label: ' ', value: matterId } : null,
        doNotify: true,
        adminDiscount,
        adminDiscountMessage
      }}
      onSubmit={({
        stage,
        existing,
        matterId,
        matterName,
        costThreshold,
        specialInstructions,
        doNotify,
        adminDiscount,
        adminDiscountMessage
      }) => {
        submitStep({
          stage,
          existing,
          matterId,
          matterName,
          costThreshold,
          specialInstructions
        });
        submitAdmin({
          doNotify,
          adminDiscount,
          adminDiscountMessage,
          email: contact.email
        });
      }}
      validationSchema={ReviewSchema}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form onKeyDown={onKeyDown}>
          <BasicBox
            title={`Create order on behalf of ${
              contact ? `${contact?.firstName} ${contact?.lastName}` : ''
            }`}
            headerType="success"
          >
            <BasicRadioList.Wrapper isRow>
              <BasicRadioList.Item
                name="existing"
                value="false"
                checked={values.existing === false}
                onChange={() => setFieldValue('existing', false)}
              >
                <FormLabel>Create new matter</FormLabel>
              </BasicRadioList.Item>

              <BasicRadioList.Item
                name="existing"
                value="true"
                checked={values.existing === true}
                onChange={() => setFieldValue('existing', true)}
              >
                <FormLabel>Add to existing matter</FormLabel>
              </BasicRadioList.Item>
            </BasicRadioList.Wrapper>
            {values.existing ? (
              <>
                <Field name="matterId">
                  {({ field: { value }, form: { setFieldValue } }: any) => (
                    <Select
                      options={matters}
                      placeholder="Select matter"
                      value={matters.find(m => m.value === value?.value)}
                      onChange={(val: ValueType<OptionType>) =>
                        setFieldValue('matterId', val)
                      }
                    />
                  )}
                </Field>
                {touched.matterId && errors.matterId && (
                  <Error>Please select matter</Error>
                )}
              </>
            ) : (
              <Field
                name="matterName"
                render={({ field }: any) => (
                  <>
                    <TextInput
                      {...field}
                      type="text"
                      placeholder="Enter matter name"
                    />
                    {touched.matterName && errors.matterName && (
                      <Error>{errors.matterName}</Error>
                    )}
                  </>
                )}
              />
            )}
            {(documents || searches) && (
              <Spacing direction="bottom" value="20px">
                <Spacing direction="bottom" value="10px">
                  <FormLabel>
                    Notify me (if possible) if copy costs will exceed
                  </FormLabel>
                </Spacing>
                <BasicRadioList.Wrapper>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="50"
                    checked={values.costThreshold === 50}
                    onChange={() => setFieldValue('costThreshold', 50)}
                  >
                    <FormLabel>$50</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="100"
                    checked={values.costThreshold === 100}
                    onChange={() => setFieldValue('costThreshold', 100)}
                  >
                    <FormLabel>$100</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="250"
                    checked={values.costThreshold === 250}
                    onChange={() => setFieldValue('costThreshold', 250)}
                  >
                    <FormLabel>$250</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="0"
                    checked={values.costThreshold === 0}
                    onChange={() => setFieldValue('costThreshold', 0)}
                  >
                    <FormLabel>Any amount is approved</FormLabel>
                  </BasicRadioList.Item>
                </BasicRadioList.Wrapper>
              </Spacing>
            )}
            <div>
              <FormLabel>
                Any special instruction or questions? We'll respond ASAP
              </FormLabel>
            </div>
            <Field
              name="specialInstructions"
              render={({ field }: any) => <TextInput {...field} type="text" />}
            />
            <h4>Admin opts</h4>
            <Field
              name="doNotify"
              render={({ field }: any) => (
                <Checkbox
                  checked={field.value}
                  onChange={() => setFieldValue(field.name, !field.value)}
                />
              )}
            />
            Should trigger confirmation email to user and Admin
            <div>
              <FormLabel>Discount amount</FormLabel>
            </div>
            <Field
              name="adminDiscount"
              render={({ field }: any) => (
                <DiscountWrapper>
                  <TextInput
                    value={discountAmount}
                    {...field}
                    type="number"
                    id="adminDiscount"
                    onChange={(e: any) => {
                      setFieldValue('adminDiscount', e.target.value);
                      setDiscountAmount(e.target.value);
                    }}
                  />
                  {discount ? (
                    <RemoveDiscount
                      onClick={() => handleRemoveDiscount(setFieldValue)}
                    >
                      Remove
                    </RemoveDiscount>
                  ) : null}
                </DiscountWrapper>
              )}
            />
            {discount ? <DiscountNotice>Applied!</DiscountNotice> : null}
            <ApplyButton>
              <Button
                type="button"
                primary
                onClick={() => handleDiscountApply(setFieldValue)}
              >
                Apply
              </Button>
            </ApplyButton>
            <FormLabel>Discount message</FormLabel>
            <Field
              name="adminDiscountMessage"
              render={({ field }: any) => <TextInput {...field} type="text" />}
            />
            <BasicBoxFooter>
              {isSubmitting && (
                <Spacing direction="top" value={20}>
                  <Loader size={3}>Loading...</Loader>
                </Spacing>
              )}
              <Button
                type="submit"
                onClick={() =>
                  setFieldValue(
                    'stage',
                    orderType === 'order' ? 'estimate' : 'order'
                  )
                }
                disabled={isSubmitting}
              >
                {orderType === 'order' ? 'Save estimate' : 'Place order'}
              </Button>
              <Button
                type="submit"
                primary
                onClick={() => setFieldValue('stage', orderType)}
                disabled={isSubmitting}
              >
                {orderType === 'order' ? 'Place order' : 'Save estimate'}
              </Button>
            </BasicBoxFooter>
          </BasicBox>
        </Form>
      )}
    </Formik>
  );
};

export default ReviewForm;
