import React, { FC, useContext, useMemo } from 'react';
import { Formik, Form } from 'formik';
import Sidesheet from 'styleguide/components/Sidesheet';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import { OptionType } from 'styleguide/components/Select';
import Button from 'styleguide/components/Button';
import Spacing from 'styleguide/components/Spacing';
import { useMatterState } from '../../state/matter';
import FiltersSelect from 'pages/dashboard/components/MyMatters/FiltersSelect';

import {
  ModalContentWrapper,
  ModalContent,
  ModalFooter
} from 'pages/dashboard/components/MyMatters/styled';
import { AuthContext } from 'components/app/Auth';

interface FiltersProps {
  isOpen: boolean;
  onClose: () => void;
}

const documentsFilters = (filterOptions: any) =>
  Object.keys(filterOptions).reduce(
    (acc: any, key: string) => {
      acc[key] = filterOptions[key].map((el: any) => ({
        value: el,
        label: el
      }));
      return acc;
    },
    {}
  );

interface FiltersFormValue {
  status: OptionType[];
  name: OptionType[];
  product: OptionType[];
  jurisdiction: OptionType[];
  reviewStatus: OptionType[];
}

const filterKeys = {
  status: 'status',
  name: 'diligenceName',
  product: 'product.name',
  jurisdiction: 'jurisdiction.name',
  reviewStatus: 'reviewStatus'
};

const Filters: FC<FiltersProps> = ({ isOpen, onClose }) => {
  const [
    {
      view: { documents },
      tables: {
        documents: {
          filters
        }
      },
      invites,
      data,
    },
    { setFilter, clearFilter }
  ] = useMatterState();

  const currentUser = useContext(AuthContext);
  const currentUserEmail = currentUser && currentUser.email;
  const LoggedUserAccess = invites.filter((invite) => invite.contact === currentUserEmail);
  const hasAccess = LoggedUserAccess.map((currentUser)=> currentUser.role).includes("reviewer");
  const isOwner = data.owner.email === currentUserEmail;
  const options = useMemo(() => documentsFilters(documents.filterOptions), [
    documents.filterOptions
  ]);
  const initialValues = useMemo(
    () => ({
      status: options.status.filter((el: any) =>
        filters['status']?.includes(el.value)
      ),
      name: options.name.filter((el: any) =>
        filters['diligenceName']?.includes(el.value)
      ),
      product: options.product.filter((el: any) =>
        filters['product.name']?.includes(el.value)
      ),
      jurisdiction: options.jurisdiction.filter((el: any) =>
        filters['jurisdiction.name']?.includes(el.value)
      ),
      reviewStatus: options.reviewStatus.filter((el: any) =>
        filters['reviewStatus']?.includes(el.value)
      )
    }),
    [filters, options]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Sidesheet
      title={
        <BasicHeading tag="h2" size="xxl">
          Filters
        </BasicHeading>
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik<FiltersFormValue>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={values => {
          clearFilter('documents');
          Object.keys(values).forEach(key => {
            const value = values[key as keyof FiltersFormValue];
            if (value && value.length) {
              setFilter(
                'documents',
                filterKeys[key as keyof typeof filterKeys],
                value.map(option => option.value)
              );
            }
          });

          onClose();
        }}
      >
        {() => (
          <Form>
            <ModalContentWrapper>
              <ModalContent>
                <BasicText size="sm">Fulfillment Status</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="status"
                  placeholder="Select status"
                  options={options.status}
                  isLoading={false}
                />
                {(hasAccess || isOwner) && (<>
                  <Spacing direction="top" value={16} />
                  <BasicText size="sm">Review Status</BasicText>
                  <Spacing direction="top" value={8} />
                  <FiltersSelect
                    name="reviewStatus"
                    placeholder="Select review status"
                    options={options.reviewStatus}
                    isLoading={false}
                  />
                </>
                )}
                <Spacing direction="top" value={16} />
                <BasicText size="sm">Name</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="name"
                  placeholder="Select name"
                  options={options.name}
                  isLoading={false}
                />
                <Spacing direction="top" value={16} />
                <BasicText size="sm">Document type</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="product"
                  placeholder="Select type"
                  options={options.product}
                  isLoading={false}
                />
                <Spacing direction="top" value={16} />
                <BasicText size="sm">Jurisdiction</BasicText>
                <Spacing direction="top" value={8} />
                <FiltersSelect
                  name="jurisdiction"
                  placeholder="Select jurisdiction"
                  options={options.jurisdiction}
                  isLoading={false}
                />
              </ModalContent>
              <ModalFooter>
                <Button
                  medium
                  onClick={() => {
                    clearFilter('documents');
                  }}
                  type="button"
                >
                  Clear filters
                </Button>
                <Button medium primary type="submit">
                  Apply
                </Button>
              </ModalFooter>
            </ModalContentWrapper>
          </Form>
        )}
      </Formik>
    </Sidesheet>
  );
};

export default Filters;
