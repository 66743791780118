import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Button from 'styleguide/components/Button';
import BasicRadioList from 'styleguide/components/BasicRadioList';
import TextInput from 'styleguide/components/TextInput';
import Error from 'styleguide/components/Error';
import BasicBox, { BasicBoxFooter } from 'styleguide/components/BasicBox';
import MatterSelect from '../MatterSelect';
import { OptionType } from 'styleguide/components/Select';
import { useOrderFormState } from '../../state/state';
import Spacing from 'styleguide/components/Spacing';
import Loader from 'styleguide/components/Loader';

export const ReviewSchema = Yup.object().shape({
  matterId: Yup.object()
    .when('existing', {
      is: true,
      then: Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required()
        })
        .required()
    })
    .nullable(),
  matterName: Yup.string().when('existing', {
    is: false,
    then: Yup.string()
      .min(3, 'Matter name should be at least 3 characters long')
      .required('Please specify matter name')
  })
});

export interface ReviewFormValues {
  stage?: string;
  existing: boolean;
  matterId?: OptionType;
  matterName?: string;
  costThreshold?: number;
  specialInstructions?: string;
}

const FormLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
`;

const onKeyDown = (keyEvent: any) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};

const ReviewForm = ({ setDiscount, discount }: any) => {
  const [
    { documents, searches, matterId, start, order },
    { submitStep, submit }
  ] = useOrderFormState();

  const orderType = start?.orderType || 'order';
  React.useEffect(() => {
    if (order?.adminDiscount) {
      setDiscount(Number(order.adminDiscount))
    }
  }, [setDiscount, order])
  return (
    <Formik<ReviewFormValues>
      initialValues={{
        existing: !!matterId,
        costThreshold: (order && ![null, undefined].includes(order.charterCopyCostThreshold)) ? order.charterCopyCostThreshold : 9999,
        matterName: '',
        matterId: matterId ? { label: ' ', value: matterId } : null,
        specialInstructions: (order && order.specialInstructions) ? order.specialInstructions : ''
      }}
      onSubmit={values => {
        submitStep(values);
        submit({
          adminDiscount: discount,
          adminDiscountMessage: ""
        });
      }}
      validationSchema={ReviewSchema}
    >
      {({ values, errors, touched, setFieldValue, isSubmitting }) => (
        <Form onKeyDown={onKeyDown}>
          <BasicBox title="Ready to proceed?" headerType="success">
            <BasicRadioList.Wrapper isRow>
              <BasicRadioList.Item
                name="existing"
                value="false"
                checked={values.existing === false}
                onChange={() => setFieldValue('existing', false)}
              >
                <FormLabel>Create new matter</FormLabel>
              </BasicRadioList.Item>

              <BasicRadioList.Item
                name="existing"
                value="true"
                checked={values.existing === true}
                onChange={() => setFieldValue('existing', true)}
              >
                <FormLabel>Add to existing matter</FormLabel>
              </BasicRadioList.Item>
            </BasicRadioList.Wrapper>
            {values.existing ? (
              <>
                <MatterSelect name="matterId" />
                {touched.matterId && errors.matterId && (
                  <Error>Please select matter</Error>
                )}
              </>
            ) : (
              <Field
                name="matterName"
                render={({ field }: any) => (
                  <>
                    <TextInput
                      {...field}
                      type="text"
                      placeholder="Enter matter name"
                    />
                    {touched.matterName && errors.matterName && (
                      <Error>{errors.matterName}</Error>
                    )}
                  </>
                )}
              />
            )}

            {(documents || searches) && (
              <Spacing direction="bottom" value="20px">
                <Spacing direction="bottom" value="10px">
                  <FormLabel>
                    Notify me (if possible) if copy costs will exceed
                  </FormLabel>
                </Spacing>
                <BasicRadioList.Wrapper>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="0"
                    checked={values.costThreshold === 0}
                    onChange={() => setFieldValue('costThreshold', 0)}
                  >
                    <FormLabel>Do not pull any copies</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="50"
                    checked={values.costThreshold === 50}
                    onChange={() => setFieldValue('costThreshold', 50)}
                  >
                    <FormLabel>$50</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="100"
                    checked={values.costThreshold === 100}
                    onChange={() => setFieldValue('costThreshold', 100)}
                  >
                    <FormLabel>$100</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="250"
                    checked={values.costThreshold === 250}
                    onChange={() => setFieldValue('costThreshold', 250)}
                  >
                    <FormLabel>$250</FormLabel>
                  </BasicRadioList.Item>
                  <BasicRadioList.Item
                    name="costThreshold"
                    value="9999"
                    checked={values.costThreshold === 9999}
                    onChange={() => setFieldValue('costThreshold', 9999)}
                  >
                    <FormLabel>Any amount is approved</FormLabel>
                  </BasicRadioList.Item>
                </BasicRadioList.Wrapper>
              </Spacing>
            )}
            <div>
              <FormLabel>
                Any special instruction or questions? We'll respond ASAP
              </FormLabel>
            </div>
            <Field
              name="specialInstructions"
              render={({ field }: any) => <TextInput {...field} type="text" />}
            />
            <BasicBoxFooter>
              {isSubmitting && (
                <Spacing direction="top" value={20}>
                  <Loader size={3}>Loading...</Loader>
                </Spacing>
              )}
              <Button
                type="submit"
                onClick={() =>
                  setFieldValue(
                    'stage',
                    orderType === 'order' ? 'estimate' : 'order'
                  )
                }
                disabled={isSubmitting}
              >
                {orderType === 'order' ? 'Save estimate' : 'Place order'}
              </Button>
              <Button
                type="submit"
                primary
                onClick={() => setFieldValue('stage', orderType)}
                disabled={isSubmitting}
              >
                {orderType === 'order' ? 'Place order' : 'Save estimate'}
              </Button>
            </BasicBoxFooter>
          </BasicBox>
        </Form>
      )}
    </Formik>
  );
};

export default ReviewForm;
