import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';

type Color = 'red' | 'grey' | 'green';

interface BadgeProps {
  color: Color;
  icon?: React.ReactNode;
}

const Wrapper = styled.div<BadgeProps>`
  display: inline-flex;
  padding: 4px 12px;
  align-items: center;
`;

const Text = styled(BasicSubHeading)<BadgeProps>`
  ${({ theme, color }) => css`
    color: ${theme.badgeColors.text[color]};
  `}
`;

const IconWrapper = styled.span<BadgeProps>`
  width: 24px;
  height: 24px;
  ${({ theme, color }) => css`
    background: ${theme.badgeColors.background[color]};
  `}
  display: inline-flex;
  margin-right: 8px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  & > * {
    margin-left: 1px;
  }
`;

const Badge: FC<BadgeProps> = ({ icon, color, children, ...props }) => (
  <Wrapper
    color={color}
    {...props}
  >
    {icon && <IconWrapper color={color}>{icon}</IconWrapper>}
    <Text color={color} size="sm">
      {children}
    </Text>
  </Wrapper>
);

export default Badge;
