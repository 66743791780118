import styled from 'styled-components';

interface FormLabelProps {
  htmlFor?: string;
  as?: string;
}

const StyledLabel = styled.span<FormLabelProps>`
  line-height: 1.3;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  cursor: pointer;
`;

export default StyledLabel;
