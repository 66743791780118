import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { MatterData, OrderLineItem } from 'api/matter';
import _, { orderBy } from 'lodash';

export interface MatterView {
  searches: {
    orderLineItems: OrderLineItem[];
    filterOptions: {
      status: string[];
      name: string[];
      product: string[];
      jurisdiction: string[];
      reviewStatus: string[];
    };
  };
  hitDetails: {
    orderLineItems: OrderLineItem[];
    filterOptions: {
      name: string[];
      product: string[];
      jurisdiction: string[];
      reviewStatus: string[];
    };
  };
  ofac: {
    ofacSourceDetail: string;
    orderLineItems: OrderLineItem[];
    filterOptions: {
      status: string[];
      name: string[];
      reviewStatus: string[];
    };
  };
  documents: {
    orderLineItems: OrderLineItem[];
    filterOptions: {
      status: string[];
      name: string[];
      product: string[];
      jurisdiction: string[];
      reviewStatus: string[];
    };
  };
}

const getFilterOptions = (obj: any, column: string): string[] =>
  uniq(
    obj.map((el: any) => get(el, column)).filter((d: any) => d !== undefined)
  );

const getReviewFilterOptions = (obj: any, column: string): string[] =>
  uniq(
    obj.map((el: any) =>
      get(el, column) === undefined ? 'Not reviewed yet' : get(el, column)
    )
  );

const nameComparator = (str1: string, str2: string) => {
  if (str1 < str2) {
    return -1;
  }
  if (str1 > str2) {
    return 1;
  }
  return 0;
};

const orderLineItemsComparator = (el1: OrderLineItem, el2: OrderLineItem) =>
  el1.status.length - el2.status.length ||
  nameComparator(el1.jurisdiction.name, el2.jurisdiction.name) ||
  el1.product.sortOrder - el2.product.sortOrder;

export const matterView = (matterData: MatterData): MatterView => {
  const allOrderLineItems = flatten(
    matterData.orders.map(o => o.orderLineItems)
  ).map(oli => ({
    ...oli,
    hitDetails: []
  }));

  const searchesOrderLineItems = allOrderLineItems
    .filter(oli => oli.product.family === 'Searches')
    .sort(orderLineItemsComparator);

  const hitDetailsOrderLineItems = searchesOrderLineItems.filter(
    oli => oli.status === 'Hit'
  );

  const ofacOrderLineItems = allOrderLineItems
    .filter(oli => oli.product.family === 'OFAC')
    .map(oli => ({ ...oli, review: '' }))
    .sort(orderLineItemsComparator);

  const documentsOrderLineItems = allOrderLineItems
    .filter(oli => oli.product.family === 'Organizational Documents')
    .sort(orderLineItemsComparator)
    .map(data => ({
      ...data,
      reviewHistory: orderBy(data.reviewHistory, ['createdAt'], ['desc'])
    }));
  return {
    searches: {
      // orderLineItems: _.sortBy(searchesOrderLineItems, ['diligenceName']),
      orderLineItems: searchesOrderLineItems,
      filterOptions: {
        status: getFilterOptions(searchesOrderLineItems, 'status'),
        name: getFilterOptions(searchesOrderLineItems, 'diligenceName'),
        product: getFilterOptions(searchesOrderLineItems, 'product.name'),
        jurisdiction: getFilterOptions(
          searchesOrderLineItems,
          'jurisdiction.name'
        ),
        reviewStatus: getReviewFilterOptions(
          searchesOrderLineItems,
          'reviewHistory.0.reviewStatus'
        )
      }
    },
    hitDetails: {
      orderLineItems: hitDetailsOrderLineItems,
      filterOptions: {
        name: getFilterOptions(hitDetailsOrderLineItems, 'diligenceName'),
        product: getFilterOptions(hitDetailsOrderLineItems, 'product.name'),
        jurisdiction: getFilterOptions(
          hitDetailsOrderLineItems,
          'jurisdiction.name'
        ),
        reviewStatus: getReviewFilterOptions(
          hitDetailsOrderLineItems,
          'reviewHistory.0.reviewStatus'
        )
      }
    },
    ofac: {
      ofacSourceDetail: matterData.ofacSourceDetail,
      // orderLineItems: _.sortBy(ofacOrderLineItems, ['diligenceName']),
      orderLineItems: ofacOrderLineItems,
      filterOptions: {
        status: getFilterOptions(ofacOrderLineItems, 'status'),
        name: getFilterOptions(ofacOrderLineItems, 'diligenceName'),
        reviewStatus: getReviewFilterOptions(
          ofacOrderLineItems,
          'reviewHistory.0.reviewStatus'
        )
      }
    },
    documents: {
      // orderLineItems: _.sortBy(documentsOrderLineItems, ['diligenceName']),
      orderLineItems: documentsOrderLineItems,
      filterOptions: {
        status: getFilterOptions(documentsOrderLineItems, 'status'),
        name: getFilterOptions(documentsOrderLineItems, 'diligenceName'),
        product: getFilterOptions(documentsOrderLineItems, 'product.name'),
        jurisdiction: getFilterOptions(
          documentsOrderLineItems,
          'jurisdiction.name'
        ),
        reviewStatus: getReviewFilterOptions(
          documentsOrderLineItems,
          'reviewHistory.0.reviewStatus'
        )
      }
    }
  };
};
