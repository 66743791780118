import React, { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconDelete } from 'components/icons/IconDelete.svg';
import { ReactComponent as IconEdit } from 'components/icons/IconEdit.svg';
import Button from '../Button';

export interface InlineEditProps {
  onEdit?: () => void;
  onDelete?: () => void;
  dataTip?: string;
}

const ButtonsWrapper = styled.div`
  display: flex;
`;

const InlineEditButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
  background: ${({ theme }) => theme.colorsNewDesign.lightest};
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.boxShadow.light};
  border-radius: 4px;
  margin-right: 4px;

  svg path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray500};
  }
`;

export const InlineEditWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InlineEdit: FC<InlineEditProps> = ({ onEdit, onDelete,dataTip }) => (
  <ButtonsWrapper>
    {onEdit ? (
      <InlineEditButton data-tip={dataTip} type="button" onClick={onEdit}>
        <IconEdit />
      </InlineEditButton>
    ) : null}

    {onDelete ? (
      <InlineEditButton type="button" onClick={onDelete}>
        <IconDelete />
      </InlineEditButton>
    ) : null}
  </ButtonsWrapper>
);

export default InlineEdit;
