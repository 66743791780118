import React, { FC, lazy, Suspense, ReactNode, MouseEvent } from 'react';
import { MaterialTableProps } from 'material-table';

const Table = lazy(() => import('./Table'));

export interface TableProps {
  materialTableProps: MaterialTableProps<any>;
  paginationItemLabel?: string;
  totalCount?: number;
  currentPage?: number;
  onChangePage?: (
    event: MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  filters?: object;
  style?: object;
  footer?: ReactNode;
  search?: string;
  hover?: boolean;
  stickyRows?: boolean;
  expanded?: boolean;
  paginationPosition?: "bottom" | "top" | "both";
  pageSize?: number;
}

const EmptyFallback = (): null => null;

const MaterialTable: FC<TableProps> = props => (
  <Suspense fallback={<EmptyFallback />}>
    <Table {...props} />
  </Suspense>
);

export { default as cellStyles } from './cellStyles';

export default MaterialTable;
