import React, { FC } from 'react';
import styled from 'styled-components';

import InlineEdit, { InlineEditProps } from '../InlineEdit';

interface CellFormProps extends InlineEditProps {
  isEnabled?: boolean;
}

const CellFormAccessory = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
`;

const CellFormWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 35px;

  ${CellFormAccessory} {
    display: none;
  }

  &:hover {
    ${CellFormAccessory} {
      display: flex;
    }
  }
`;

const CellForm: FC<CellFormProps> = ({
  isEnabled = true,
  onEdit,
  onDelete,
  children
}) => (
  <CellFormWrapper>
    {children}
    {isEnabled && (
      <CellFormAccessory>
        <InlineEdit onEdit={onEdit} onDelete={onDelete} />
      </CellFormAccessory>
    )}
  </CellFormWrapper>
);

export default CellForm;
