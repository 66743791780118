import styled from 'styled-components';
import BasicText from 'styleguide/components/BasicText';

export const Wrapper = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};

  @media (max-width:768px){
    width: 100%;
    height:100%;
  }
`;

export const ContentWrapper = styled.div`
  padding: 32px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

export const FieldDescription = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;