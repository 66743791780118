import styled from 'styled-components';

const RoundIcon = styled.div`
  background: ${({ theme }) => theme.colorsNewDesign.primary};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default RoundIcon;
