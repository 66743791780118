import React, { FC } from 'react';
import uniq from 'lodash/uniq';
import NamesForm from '../TableForm/NamesForm';
import { useOrderFormState } from '../../state/state';

const PreviouslySelectedStep: FC = () => {
  const [{ ofac, searches, documents }] = useOrderFormState();

  const allNames = uniq([
    ...(ofac?.names || []),
    ...(searches?.names || []),
  ]).filter(name => !documents || !documents.names.includes(name));

  return (
    <NamesForm
      previouslySelected={allNames}
    />
  );
};

export default PreviouslySelectedStep;
