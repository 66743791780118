import React, { FC, useMemo, useCallback } from 'react';

import { FileData } from 'api/matter';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';

import {
  TableWrapper,
  SimpleHeaderRow,
  HeaderCellContent,
  CellContent,
  CheckboxCellContent,
  HeaderCheckboxCellContent,
  SimpleRow,
  Checkbox
} from './styled';
import { useDeviceState } from 'state/deviceState';

interface SimpleTableProps {
  list: FileData[];
  selectFiles: (ids: string[]) => void;
  unselectFiles: (ids: string[]) => void;
  selectedFiles: string[];
  header: string;
}

const SimpleTable: FC<SimpleTableProps> = ({
  list,
  selectedFiles,
  selectFiles,
  unselectFiles,
  header
}) => {
  const [state] = useDeviceState();

  const isAllChecked = useMemo(
    () => list.every(row => selectedFiles.includes(row.id)),
    [list, selectedFiles]
  );

  const handleAllCheckboxClick = useCallback(() => {
    const allIds = list.map(row => row.id);
    if (isAllChecked) {
      unselectFiles(allIds);
    } else {
      selectFiles(allIds);
    }
  }, [list, selectFiles, unselectFiles, isAllChecked]);

  const handleCheckboxClick = useCallback(
    (id: string, isChecked: boolean) => {
      if (isChecked) {
        unselectFiles([id]);
      } else {
        selectFiles([id]);
      }
    },
    [selectFiles, unselectFiles]
  );

  return (
    <TableWrapper>
      <SimpleHeaderRow onClick={handleAllCheckboxClick}>
        <HeaderCheckboxCellContent>
          <Checkbox showLabel={false} checked={isAllChecked} />
        </HeaderCheckboxCellContent>
        <HeaderCellContent>
          <BasicHeading tag="h4" size="md">
            {header}
          </BasicHeading>
        </HeaderCellContent>
        {
          state.isDesktop && <HeaderCellContent />
        }
      </SimpleHeaderRow>
      {list.map(row => {
        const isChecked = selectedFiles.includes(row.id);
        return (
          <SimpleRow
            onClick={() => handleCheckboxClick(row.id, isChecked)}
            isSelected={isChecked}
            key={row.id}
          >
            <CheckboxCellContent>
              <Checkbox showLabel={false} checked={isChecked} />
            </CheckboxCellContent>
            <CellContent>
              <BasicText size="sm">{row.name}</BasicText>
            </CellContent>
            {
              state.isDesktop && (
                <CellContent>
                  <BasicText size="sm">{row.reviewStatus}</BasicText>
                </CellContent>
              )
            }
          </SimpleRow>
        );
      })}
    </TableWrapper>
  );
};

export default SimpleTable;
