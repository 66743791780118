import React, { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
import BasicText from 'styleguide/components/BasicText';

const Wrapper = styled.div`
  margin-top: auto;
`;

const Content = styled(BasicText)`
  padding: 12px 50px;
  position: relative;
  top: 100%;
  display: flex;
  justify-content: flex-end;
  color: #3c3b3b;
  line-height: 1.3;
  font-weight: 100;

  span {
    font-size: inherit;
    color: inherit;
    margin-left: 15px;

    &:hover {
      color: inherit;
    }
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: inherit;
    }
  }

  @media(max-width:768px){
    padding-top: 50px;
    white-space: nowrap;
    justify-content: center;


  }
`;

const CopyrightText = styled.div`
  margin-left: 30px;
  @media(max-width:768px){
    margin-left: 10px;


  }
`;

const Footer: FC = () => (
  <Wrapper>
    <Content size="xs">
      <LegalPagesModal
        title={<FormattedMessage id="links.termsOfUse" />}
        type="termsOfUse"
      />
      <LegalPagesModal
        title={<FormattedMessage id="links.termsOfService" />}
        type="termsOfService"
      />
      <LegalPagesModal title="Privacy" type="privacyPolicy" />
      <CopyrightText>
        &copy; {new Date().getFullYear()} DealBinder
      </CopyrightText>
    </Content>
  </Wrapper>
);

export default Footer;
