import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';

import { theme } from 'theme/theme';
import Error from 'styleguide/components/Error';
import Spacing from 'styleguide/components/Spacing';
import Button from 'styleguide/components/Button';
import Loader from 'styleguide/components/Loader';
import PasswordStrength from 'styleguide/components/PasswordStrength';

import { PasswordInput, LoaderWrapper } from './styled';
import { ResetPasswordFirstStepProps } from './ResetPassword';

const ResetPasswordFirstStep: FC<ResetPasswordFirstStepProps> = ({
  onSubmit,
  validationSchema,
  initialValues,
  isLoading,
  passwordPlaceholder,
  confirmPasswordPlaceholder
}) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <PasswordInput
          onChange={handleChange}
          onBlur={handleBlur}
          name="password"
          value={values.password}
          placeholder={passwordPlaceholder}
          invalid={Boolean(touched.password && errors.password)}
          autoFocus
        />
        {touched.password && errors.password && (
          <Error>{errors.password}</Error>
        )}
        <Spacing direction="top" value={16} />
        <PasswordInput
          onChange={handleChange}
          onBlur={handleBlur}
          name="confirmPassword"
          value={values.confirmPassword}
          placeholder={confirmPasswordPlaceholder}
          invalid={Boolean(touched.confirmPassword && errors.confirmPassword)}
        />
        {touched.confirmPassword && errors.confirmPassword && (
          <Error>{errors.confirmPassword}</Error>
        )}
        <Spacing direction="top" value={26} />
        <PasswordStrength password={values.password} />
        <Spacing direction="top" value={20} />

        <Button primary disabled={isLoading} width="100%">
          <FormattedMessage id="shared.save" />
        </Button>

        {isLoading && (
          <LoaderWrapper>
            <Spacing direction="top" value={16} />
            <Loader background={theme.colorsNewDesign.lightBlue} />
          </LoaderWrapper>
        )}
      </Form>
    )}
  </Formik>
);

export default ResetPasswordFirstStep;
