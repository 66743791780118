import React, { FC } from 'react';
import StatusBox, {
  MultiStatusBoxWrapper
} from 'styleguide/components/StatusBox';
import { useProductState, getCharterDocs, getGoodStanding } from '../../state/productsState';

interface CellProductValueProps {
  value?: string[];
}

const CellForm: FC<CellProductValueProps> = ({ value }) => {
  const [state] = useProductState();
  const charterDocs = getCharterDocs(state);
  const goodStanding = getGoodStanding(state);
  const isCharterSelected = value?.includes(charterDocs.id);
  const isGoodStandingSelected = value?.includes(goodStanding.id);
  return (
    <MultiStatusBoxWrapper>
      {isCharterSelected && (
        <StatusBox hasIcon={false} color="green" hasBackgroundColor isCondensed>
          Charter
        </StatusBox>
      )}
      {isGoodStandingSelected && (
        <StatusBox
          color="purple"
          hasBackgroundColor
          hasIcon={false}
          isCondensed
        >
          Good Standing
        </StatusBox>
      )}
    </MultiStatusBoxWrapper>
  );
};

export default CellForm;
