import React, { FC } from 'react';
import get from 'lodash/get';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSection from 'styleguide/components/BasicSection';

import {
  EstimateData,
  Pricing,
  StateLevelEstimate
} from '../../utils/formData';
import { useOrderFormState } from '../../state/state';

interface TurnaroundDetailsProps {
  estimate?: EstimateData;
}

const pricingToString = (pricing: Pricing) => {
  const from =
    pricing.turnaroundMin > 0 ? pricing.turnaroundMin : 'Same business day';
  const to =
    pricing.turnaroundMax === 1
      ? `1 business day`
      : `${pricing.turnaroundMax} business days`;
  const expediteFee = pricing.expediteFee
    ? `(+$${pricing.expediteFee} per name)`
    : '';

  return `${from} - ${to} ${expediteFee}`;
};

const chartersEstimateTitle = (estimate: StateLevelEstimate) => 
  get(estimate, `${Object.keys(estimate)[0]}.pricings[0].charterFormat`, '');

const TurnaroundForm: FC<{ pricing: Pricing; type: string; state: string; }> = ({ pricing, type, state }) => {
  const [{ turnarounds }, { setTurnaround }] = useOrderFormState();
  const checked = get(turnarounds, `${type}.${state}`, 'Standard') === pricing.turnaround;
  return (
    <div>
      <label>
        <input
          type="radio"
          value={pricing.turnaround}
          checked={checked}
          onChange={() => setTurnaround(type as any, state, pricing.turnaround)}
        />
        {pricingToString(pricing)}
      </label>
    </div>
  );
};

const PricingDetails: FC<{
  pricings: Pricing[];
  type: string;
  state: string;
}> = ({ pricings, type, state }) => {
  return (
    <>
      {pricings.map(pricing => (
        <TurnaroundForm key={pricing.id} pricing={pricing} type={type} state={state} />
      ))}
    </>
  );
};

const TurnaroundRow: FC<{
  estimate?: StateLevelEstimate;
  title: string;
  type: string;
}> = ({ estimate, title, type }) => {
  if (!estimate || !Object.keys(estimate).length) return null;
  return (
    <>
      <BasicHeading tag="div" size="md">
        {title}
      </BasicHeading>
      {Object.keys(estimate).map(state => (
        <BasicSection title={state} condensed>
          {estimate[state].pricings.length === 1 ?
            pricingToString(estimate[state].pricings[0])
          : (
            <PricingDetails pricings={estimate[state].pricings} type={type} state={state}/>
          )}
        </BasicSection>
      ))}
    </>
  );
};

const SimpleTurnaroundRow: FC<{
  estimate?: StateLevelEstimate;
  title: string;
}> = ({ estimate, title }) => {
  if (!estimate || !Object.keys(estimate).length) return null;
  return (
    <>
      <BasicHeading tag="div" size="md">
        {title}
      </BasicHeading>
      {Object.keys(estimate).map(state => (
        <BasicSection title={state} condensed>
          {estimate[state].turnaround}
        </BasicSection>
      ))}
    </>
  );
};


const TurnaroundDetails: FC<TurnaroundDetailsProps> = ({ estimate }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  if (!estimate.detailedEstimate) return null;
  const {
    searchesEstimate,
    ofacsEstimate,
    chartersEstimate,
    goodStandingsEstimate
  } = estimate.estimate.stateLevelEstimate;
  return (
    <>
      <Button variant="inline" onClick={() => setIsOpen(!isOpen)}>
        Details & Expedite Options
      </Button>

      {isOpen && (
        <>
          <SimpleTurnaroundRow title="Searches" estimate={searchesEstimate} />
          <TurnaroundRow
            title="Good Standing Certificates"
            estimate={goodStandingsEstimate}
            type="goodStanding"
          />
          <TurnaroundRow
            title={`Charter Documents (${chartersEstimateTitle(
              chartersEstimate
            )})`}
            estimate={chartersEstimate}
            type="charterDocs"
          />
          <SimpleTurnaroundRow title="OFAC" estimate={ofacsEstimate} />
        </>
      )}
    </>
  );
};

export default TurnaroundDetails;
