import React, { FC } from 'react';

import { ReactComponent as Logo } from 'components/icons/LogoColor.svg';

import { Wrapper } from './styled';

const SimpleLayout: FC = ({ children, ...props }) => (
  <Wrapper {...props}>
    <div>
      <Logo />
    </div>
    {children}
  </Wrapper>
);

export default SimpleLayout;
