import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';
import { Note } from '../matter';

const getNotesUrl = (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): string =>
  `${url}/matters/${matterId}/orders/${orderId}/order-line-items/${orderLineItemId}/notes`;
const getNoteUrl = (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  id: string
): string =>
  `${url}/matters/${matterId}/orders/${orderId}/order-line-items/${orderLineItemId}/notes/${id}`;

interface NoteData {
  note_id: string;
  note_text: string;
  note_tags: string[];
  note_createdAt: Date;
  note_updatedAt: Date;
  contact_firstName: string;
  contact_lastName: string;
  contact_email: string;
}

export const fetchNotes = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): Promise<Note[]> => {
  const client = await getAuthorizedApi();
  try {
    const response = await client.get<NoteData[]>(
      getNotesUrl(matterId, orderId, orderLineItemId)
    );
    return response.data.map(note => ({
      id: note.note_id,
      text: note.note_text,
      tags: note.note_tags,
      createdAt: note.note_createdAt,
      updatedAt: note.note_updatedAt,
      author: {
        firstName: note.contact_firstName,
        lastName: note.contact_lastName,
        email: note.contact_email
      }
    }));
  } catch (err) {
    return [];
  }
};

export const createNote = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  text: string,
  tags: string[] = []
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getNotesUrl(matterId, orderId, orderLineItemId), {
    text,
    tags
  });
};

export const deleteNote = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  id: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.delete(getNoteUrl(matterId, orderId, orderLineItemId, id));
};

export const updateNote = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  id: string,
  text: string,
  tags: string[] = []
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.patch(getNoteUrl(matterId, orderId, orderLineItemId, id), {
    text,
    tags
  });
};
