import React, { FC } from 'react';
import { isEmpty, some } from 'lodash';

import {
    FiltersButton,
  } from '../../../pages/matter/components/common/styled';
  import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
  import { useMyMattersState } from '../../../pages/dashboard/state/myMatters';





const MatterButton: FC = () => {
  const [state, actions] = useMyMattersState();
  const areFiltersApplied = some(state.filters, filter => !isEmpty(filter));


   return(
    <FiltersButton
              active={areFiltersApplied}
              onClick={() => actions.toggleTableFilterSidebar('myMatters')}
            >
    <FiltersIcon />
  </FiltersButton>
);
}

export default MatterButton;
