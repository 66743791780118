import api from 'api/apiInstance';

interface resendPostParams {
  email: string;
}

export const resendApi = (path: string) => ({
  path,
  POST: (config: resendPostParams) => api.post(path, config)
});
