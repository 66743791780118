import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  height:100vh;

  @media (max-width:768px){
    height: calc(100vh - 59px);
  }
`;

export const ContentWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background};
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
  min-height: 100vh;

  @media (max-width:768px){
    min-height: calc(100vh - 59px);
  }

`;

