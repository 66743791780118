import React, { FC, InputHTMLAttributes, useState, useCallback } from 'react';
import styled from 'styled-components';
import { ReactComponent as IconEye } from 'components/icons/IconEye.svg';

import TextInput, { TextInputProps } from '../TextInput';

const Wrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(TextInput)`
  padding-right: 48px;
`;

const Icon = styled(IconEye)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const PasswordInput: FC<TextInputProps &
  InputHTMLAttributes<HTMLInputElement>> = props => {
  const [showText, setShowText] = useState(false);
  const handleIconClick = useCallback((): void => {
    setShowText(!showText);
  }, [showText]);
  return (
    <Wrapper>
      <StyledInput {...props} type={showText ? 'text' : 'password'} />
      <Icon onClick={handleIconClick} />
    </Wrapper>
  );
};

export default PasswordInput;
