import React, { FC, useMemo } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import Tag from 'styleguide/components/Tag';
import Spacing from 'styleguide/components/Spacing';

import { getSearchesUrl, getOfacUrl, getDocumentsUrl } from '../../constants';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import {
  Box,
  BoxTitleRow,
  BoxSummaryRow,
  ETAText,
  ProgressBar
} from './styled';
import styled from 'styled-components';

type Type = 'searches' | 'ofac' | 'org-docs';

export interface SummaryBoxHitsData {
  label?: string;
  count?: number;
}

interface SummaryBoxProps {
  id: string;
  type: Type;
  progressValue: number;
  eta: Date;
  hitsData: SummaryBoxHitsData;
  role: string;
}

const names = {
  searches: 'Searches',
  ofac: 'OFAC',
  'org-docs': 'Org Docs'
};

const StyledTag = styled(Tag)`
  cursor: pointer;
`;

const getHitLabel = (type: Type, hitsCount: number, role: string) => {
  const suffix = role === 'owner' || role === 'reviewer' ? ' to review' : '';
  if (type === 'org-docs') {
    return `${hitsCount > 1 ? 'documents' : 'document'}${suffix}`;
  }
  return `${hitsCount > 1 ? 'hits' : 'hit'}${suffix}`;
};

const SummaryBox: FC<SummaryBoxProps> = ({
  id,
  type,
  hitsData,
  progressValue,
  eta,
  role
}) => {
  const history = useHistory();

  const tabUrls: { [key: string]: string } = useMemo(
    () => ({
      searches: getSearchesUrl(id),
      ofac: getOfacUrl(id),
      'org-docs': getDocumentsUrl(id)
    }),
    [id]
  );

  const handleRedirect = () => {
    const url = tabUrls[type];
    history.push(url);
  };

  const name = names[type];

  const hitLabel = useMemo(() => getHitLabel(type, hitsData.count, role), [
    type,
    hitsData,
    role
  ]);
  return (
    <Box isClickable onClick={handleRedirect}>
      <BoxTitleRow>
        <BasicHeading tag="h3" size="xxl">
          {name}
        </BasicHeading>
        <ChevronRight />
      </BoxTitleRow>
      <BasicSubHeading size="xxs" isUppercase>
        {!hitsData.count ? (
          hitsData.label
        ) : (
          <StyledTag color="red">
            {hitsData.count} {hitLabel}
          </StyledTag>
        )}
      </BasicSubHeading>
      <BoxSummaryRow>
        {progressValue !== 100 && (
          <>
            <StyledTag color="grey">ETA</StyledTag>
            <Spacing direction="left" value={8} />
            <ETAText size="sm">{eta && format(eta, 'iiii, M/dd')}</ETAText>
            <Spacing direction="left" value="auto" />
          </>
        )}
        <BasicHeading tag="h4" size="l">
          {progressValue}%
        </BasicHeading>
      </BoxSummaryRow>
      <ProgressBar value={progressValue} />
    </Box>
  );
};

export default SummaryBox;
