import React, { useContext, useMemo, useRef, useCallback } from 'react';
import uniq from 'lodash/uniq';
import styled from 'styled-components';
import { useMatterState, selectors } from '../../state/matter';
import Note from 'styleguide/components/Note';
import { formatDistance } from 'date-fns';
import { AuthContext } from 'components/app/Auth';
import { HitOrderLineItem } from 'api/matter';
import Add from './Add';
import { deleteNote, updateNote } from 'api/notes';
import { GrayText } from '../ShareModal/styled';
import { useDeviceState } from 'state/deviceState';


const Wrapper = styled.div`
  position: absolute;
  right: 0;
  max-width: 100%;
  width: 434px;
  border-left: 1px solid #ebecef;
  flex-shrink: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width:768px)
  {
    width: 100%;
  }
`;

const NotesWrapper = styled.div`
  padding: 10px 26px 50px 26px;
  border-bottom: 1px solid #ebecef;
  overflow-y: auto;
  flex-grow: 2;
  margin: 0 -24px;
  &.notes-wrapper-empty {
    padding: 0;
  }
`;

interface NotesAdditionalProps {
    canAdd: boolean;
}

const Notes = ({canAdd, ...props }: HitOrderLineItem & NotesAdditionalProps) => {
  const wrapper = useRef();
  const { email } = useContext(AuthContext);
  const [state, { fetchNotes }] = useMatterState();
  const { id: matterId, invites } = state;
  const order = selectors.findOrderForLineItem(state)(props.id);
  const [deviceState] = useDeviceState();

  const authors = useMemo(() => {
    const emails = invites.length ?  invites.map(i => i.contact) : uniq(props.notes.map(n => n.author.email));
    const owner = state.data?.owner?.email || '';
    return [owner, ...emails];
  }, [props.notes, invites]); // eslint-disable-line

  const scrollTop = useCallback(() => {
    if (wrapper.current) {
      const wrapperDiv = wrapper.current as any;
      wrapperDiv.scrollTop = 0;
    }
  }, [wrapper]);

  return (
    <div style={deviceState.isMobileOrTablet? { minHeight: props.notes.length ? '600px' : '600px'}:{ minHeight: props.notes.length ?'300px' : '200px'}}>
    <Wrapper>
      <NotesWrapper ref={wrapper} className={props.notes.length ? '' : 'notes-wrapper-empty'}>
        {
          props.notes.length ? (
            props.notes.map(note => (
              <Note
                key={note.id}
                id={note.id}
                name={`${note.author.firstName} ${note.author.lastName}`}
                created={`${formatDistance(
                  new Date(note.createdAt),
                  new Date()
                )} ago`}
                isUpdated={note.createdAt !== note.updatedAt}
                onEdit={note.author.email === email ? async (text: string) => {
                    await updateNote(matterId, order.id, props.id, note.id, text);
                    await fetchNotes(order.id, props.id);
                } : null}
                onDelete={note.author.email === email ? async () => {
                    await deleteNote(matterId, order.id, props.id, note.id);
                    await fetchNotes(order.id, props.id);
                } : null}
                color={authors.indexOf(note.author.email)}
                text={note.text}
              />
            ))
          ) : (<GrayText className="py-5 text-center">No notes yet</GrayText>)
        }
      </NotesWrapper>
      {canAdd && <Add matterId={matterId} orderId={order.id} id={props.id} addCallback={scrollTop} />}
    </Wrapper>
    </div>
  );
};

const allowedRoles = ['viewer', 'collaborator', 'reviewer', 'owner'];

const NotesGuard = (props: HitOrderLineItem) => {
  const [
    {
      data: { role }
    }
  ] = useMatterState();

  if (!allowedRoles.includes(role)) return null;

  return <Notes {...props} canAdd={role !== 'viewer'} />;
};

export default NotesGuard;
