import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface BasicBoxProps {
  title: string | ReactNode;
  className?: string;
}

interface StyledHeaderProps {
  headerType?: 'default' | 'success';
  isHeaderCentered?: boolean;
}

const BasicBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  box-shadow: ${({ theme }) => theme.boxShadow.strong};
  border-radius: ${({ theme }) => theme.borderRadius.strong};
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray50};
`;

const StyledHeader = styled.header<StyledHeaderProps>`
  padding: 24px 32px 23px;
  line-height: 1.3;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xl};
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};

  ${({ headerType, theme }) => {
    switch (headerType) {
      case 'success':
        return css`
          background-color: #3DBC70;
          color: ${theme.colorsNewDesign.lightest};
        `;
      default:
        return css`
          background-color: ${theme.colorsNewDesign.lightest};
          color: ${theme.colorsNewDesign.darkest};
          border-bottom: 1px solid ${theme.colorsNewDesign.gray100};
        `;
    }
  }};

  ${({ isHeaderCentered }) =>
    isHeaderCentered &&
    css`
      text-align: center;
    `};
`;

const Content = styled.div`
  padding: 24px 24px 28px 24px;

  & > section:first-child {
    padding-top: 0;
  }
`;

export const BasicBoxFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * {
    margin-left: 16px;
  }
`;

const BasicBox: FC<BasicBoxProps & StyledHeaderProps> = ({
  children,
  title,
  headerType = 'default',
  isHeaderCentered = false,
  className
}) => (
  <BasicBoxWrapper className={className}>
    <StyledHeader headerType={headerType} isHeaderCentered={isHeaderCentered}>
      {title}
    </StyledHeader>
    <Content>{children}</Content>
  </BasicBoxWrapper>
);

export default BasicBox;
