import React, { FC, useMemo } from 'react';
import { HitDetails } from 'api/matter';
import get from 'lodash/get';
import { format } from 'date-fns';
import { currencyFormat } from 'helpers/formatters';
import Spacing from 'styleguide/components/Spacing';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import BasicText from 'styleguide/components/BasicText';
import { ReactComponent as DocumentIcon } from 'components/icons/IconDocument.svg';

import {
  DetailsGroupWrapper,
  HitDetailsWrapper,
  HitDetailsRow,
  ViewLink
} from './styled';
import Button from 'styleguide/components/Button';
import { useHistory } from 'react-router-dom';
import { useDeviceState } from 'state/deviceState';

interface SidebarDetailsGroup {
  productName: string;
  hitDetails: HitDetails[];
}

const getFieldsToShow = (productName: string): string[] =>
  [
    'fileDate',
    'fileNumber',
    (productName === 'UCC - State' || productName === 'UCC - County') && 'type',
    (productName === 'UCC - State' || productName === 'UCC - County') &&
      'securedParty',
    (productName === 'Suits & Judgments - Federal' ||
      productName === 'Suits & Judgments - State') &&
      'plaintiff',
    (productName === 'Tax Lien - State' ||
      productName === 'Tax Lien - Federal') &&
      'amount'
  ].filter(Boolean);

const getFieldName = (productName: string, field: string): string => {
  if (
    (productName === 'Suits & Judgments - Federal' ||
      productName === 'Suits & Judgments - State') &&
    field === 'fileNumber'
  ) {
    return 'Index Number';
  }

  const fieldsMapping: { [key: string]: string } = {
    fileDate: 'Filed',
    fileNumber: 'Document #',
    type: 'Document Type',
    securedParty: 'Secured Party',
    plaintiff: 'Plaintiff',
    amount: 'Amount'
  };

  return fieldsMapping[field] || field;
};

const formatValue = (key: string, value: string) => {
  if (key === 'fileDate') return format(new Date(value), 'M/d/yy');
  if (key === 'amount') {
    const formatted = currencyFormat(Number(value));
    return formatted ? `$${formatted}` : '';
  }
  return value;
};

const SidebarDetailsGroup: FC<SidebarDetailsGroup> = ({
  productName,
  hitDetails
}) => {
  const fieldsToShow = useMemo(() => getFieldsToShow(productName), [
    productName
  ]);
  const history = useHistory();
  const [deviceState] = useDeviceState();


  return (
    <DetailsGroupWrapper>
      {hitDetails.map(hitDetail => (
        <HitDetailsWrapper>
          <DocumentIcon />
          <Spacing direction="left" value={12} />
          <div>
            {fieldsToShow.map(field => (
              <HitDetailsRow>
                <BasicSubHeading isUppercase size="sm">
                  {getFieldName(productName, field)}
                </BasicSubHeading>
                <BasicText size="sm">
                  {formatValue(field, get(hitDetail, field))}
                </BasicText>
              </HitDetailsRow>
            ))}
          </div>
          {
            get(hitDetail, 'page') && !deviceState.isMobile? <ViewLink><Button onClick={() => {
              history.push(`${window.location.pathname}?page=${get(hitDetail, 'page')}`)
            }}>View</Button></ViewLink> : null
          }
        </HitDetailsWrapper>
      ))}
    </DetailsGroupWrapper>
  );
};

export default SidebarDetailsGroup;
