import React from 'react';
import {
    ExpandButton,
    FiltersButton,
    CountBadge
  } from '../../../pages/matter/components/common/styled';
  import { ReactComponent as IconExpand } from 'components/icons/IconExpand.svg';
  import { useMatterState } from '../../../pages/matter/state/matter';
  // import SearchInput from 'styleguide/components/SearchInput';
  import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';
  import Loader from 'styleguide/components/Loader';


const SearchesButton = () => {

  const [
    {
      isToggleLoading,
      tables: {
        searches: {expanded,filters }
      }
    },
    {toggleExpanded,toggleTableFilterSidebar }
  ] = useMatterState();  
  const countBadge = React.useMemo(() => Object.keys(filters).length, [filters]);
  return(
    <>
    <ExpandButton
     onClick={() => toggleExpanded('searches')}
     data-tip
     data-for="expandrows"
     data-place="bottom"
     resetOrder
     active={expanded}
    >
    {isToggleLoading ? <Loader size={1.5} background="#f1f4f8" /> : <IconExpand />} 
    
   </ExpandButton>
   <FiltersButton
            active={Boolean(Object.keys(filters).length)}
            onClick={() => toggleTableFilterSidebar('searches')}
            resetOrder
          >
            <FiltersIcon />
            { countBadge ? <CountBadge>{ countBadge }</CountBadge> : null }
          </FiltersButton>
         
   </>
);
}

export default SearchesButton;
