import React, { FC } from 'react';
import { Field } from 'formik';
import { ValueType } from 'react-select';
import { OptionType } from 'styleguide/components/Select';

import { Select } from './styled';

interface FiltersSelectProps {
  name: string;
  placeholder: string;
  options: OptionType[];
  isLoading: boolean;
}

const FiltersSelect: FC<FiltersSelectProps> = ({
  name,
  placeholder,
  options,
  isLoading
}) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue } }: any) => (
      <Select
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        allowSelectAll
        options={options}
        placeholder={isLoading ? 'Loading...' : placeholder}
        value={value}
        onChange={(val: ValueType<OptionType>) => setFieldValue(name, val)}
      />
    )}
  </Field>
);

export default FiltersSelect;
