import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOrderFormState } from './state';

import { STEP_NAMES, STEP_PATHS } from '../consts';

function useOrderNavigationHandler() {
  const [state, actions] = useOrderFormState();

  const history = useHistory();
  // update url on currentStep change
  useEffect(() => {
    if (history.location.pathname !== STEP_PATHS[state.currentStep])
      history.push(STEP_PATHS[state.currentStep]);
  }, [state.currentStep, history]);

  // update state on url change
  useEffect(() => {
    let currentUrl = history.location.pathname.replace(/\/order[/]*/, '');
    currentUrl = currentUrl.length ? currentUrl : STEP_NAMES.Start;
    actions.urlChanged(currentUrl);
  }, [history.location.pathname, actions]);
}

export default useOrderNavigationHandler;
