import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ThemeType } from './theme';

const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
    ${reset}
    
    @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-Light.ttf');
        font-weight: 100;
    }
    
    @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-Regular.ttf');
        font-weight: 300;
    }
    
    @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-SemiBold.ttf');
        font-weight: 500;
    }
    
    @font-face {
        font-family: 'Open Sans';
        src: url('/fonts/OpenSans-Bold.ttf');
        font-weight: 700;
    }

    @font-face {
        font-family: 'Gilroy';
        src: url('/fonts/Gilroy-Light.otf');
        font-weight: 100;
    }
    
    @font-face {
        font-family: 'Gilroy';
        src: url('/fonts/Gilroy-Medium.woff');
        font-weight: 300;
    }
    
    @font-face {
        font-family: 'Gilroy';
        src: url('/fonts/Gilroy-SemiBold.woff');
        font-weight: 500;
    }


    html body {
        font-family: ${({ theme }) => theme.fontFamilies.openSans};
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes.md};
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
        font-smooth: auto;
        overflow:hidden;
        overflow-y:auto;
        width: 100vw;
        height: 100vh;

    }
    
    b {
       font-weight: 500;
    }
    
    strong {
        font-weight: 700;
    }

    button, input, * {
        &:focus {
            outline: none;
        }
    }

    a {
        color: ${({ theme }) => theme.colorsNewDesign.primary};

        &:hover {
            color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
        }
    }

    .grecaptcha-badge {
        width: 70px !important;
        overflow: hidden !important;
        transition: all 0.3s ease !important;
        left: 4px !important;
        bottom: 80px !important;
    }

    .grecaptcha-badge:hover {
        width: 256px !important;
    }

    body div#hubspot-messages-iframe-container {
        bottom: -14px!important;
    }

    .slick-dots li.slick-active button:before {
        color: ${({ theme }) => theme.colorsNewDesign.primary};
    }

    .slick-dots li button:before {
        color: ${({ theme }) => theme.colorsNewDesign.gray200};
        opacity: 1;
    }

    .rpv-core-inner-page {
        background: #323232 !important;
    }
     
    .review-select {
        width: 221px;
        height: 40px;
        margin-right: 14px;
    }

    .modal-header {
        align-items: center;
    }
    
    @media (max-width: 500px) {
        .review-select {
          width: 0px;
          height: 0px;
          margin-right: 0px;
        }
    }
    @media screen and (max-device-width: 768px) and (orientation: portrait)  {  div#hubspot-messages-iframe-container { visibility: hidden; }}
    @media screen and (max-device-width: 1068px) and (orientation: landscape) {  div#hubspot-messages-iframe-container { visibility: hidden; }}
`;

export default GlobalStyles;
