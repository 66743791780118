import React, { FC, useContext, ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import { LOGIN_URL, CHECK_YOUR_EMAIL_URL } from 'helpers/routes';
import { APPROVED } from 'helpers/constants';

import { AuthContext } from './Auth';

export interface PrivateRouteProps extends RouteProps {
  component?: FC;
  render?: () => ReactNode;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const currentUser = useContext(AuthContext);

  const getRedirectPath = () => {
    if (currentUser && !currentUser.emailVerified) {
      return CHECK_YOUR_EMAIL_URL;
    }

    return LOGIN_URL;
  };

  const onRender = (props: any) => {
    if (
      currentUser?.emailVerified &&
      (currentUser?.claims?.status === APPROVED || currentUser?.claims?.limited)
    ) {
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: getRedirectPath(),
          search: `?continueUrl=${props.location.pathname}`,
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route {...rest} render={onRender} />;
};

export default PrivateRoute;
