import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Modal, { Props, Styles } from 'react-modal';

import ExitButton from 'styleguide/components/ExitButton';

interface BasicModalAdditionalProps {
  className?: string;
  onClose: () => void;
  title?: ReactNode | string;
  hasCloseButton?: boolean;
  customStyles?: Styles;
  deviceState?:any
}

export type BasicModalProps = BasicModalAdditionalProps & Props;

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  position: relative;
  max-height: 80vh;
  overflow-y: auto;

  @media (max-width: 500px) {
    max-height: 93vh;
  }
`;

const StyledExitButton = styled(ExitButton)`
  background-color: ${({ theme }) => theme.colorsNewDesign.lightest};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;

  @media (max-width: 768px) {
    top: 0;
    right: 0;
  } 
`;

const StyledHeading = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};

  @media (max-width: 500px) {
    padding-left: 76px;
  } 
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(7, 13, 38, 0.6)',
    zIndex: 999,
  }
};

const BasicModal: FC<BasicModalProps> = ({
  children,
  className,
  deviceState,
  onClose,
  title,
  hasCloseButton,
  customStyles: customStylesProps = { content: {}, overlay: {} },
  ...restProps
}) => 
  (
  <Modal
    {...restProps}
    onRequestClose={onClose}
    ariaHideApp={false}
    style={
      {
      content: {...customStyles.content, ...customStylesProps.content },
      overlay: { ...customStyles.overlay, ...customStylesProps.overlay }
    }}
  >
    {title && <StyledHeading>{title}</StyledHeading>}
    {hasCloseButton && <StyledExitButton type="button" onClick={onClose} />}
    <Container className={className}>{children}</Container>
  </Modal>
);

BasicModal.defaultProps = {
  hasCloseButton: false
};

export default BasicModal;
