import React, { FC } from 'react';
import { useField } from 'formik';
import { Cell } from 'styleguide/components/Table';
import { useDraggableCell } from '../TableForm/utils';

interface CellFormProps {
  row: number;
  col: number;
}

const CellForm: FC<CellFormProps> = ({ row, col }) => {
  const [, { value }, { setValue }] = useField(`selection.${col}.${row}`);
  const setValueCb = React.useCallback(
    (selected: boolean) => setValue(selected ? [] : null),
    [setValue]
  );
  const eventHandlers = useDraggableCell(row, col, !!value, setValueCb);

  return (
    <Cell
      {...eventHandlers}
      hasHoverState={!eventHandlers.isSelected}
      data-hover="Select"
    />
  );
};

export default CellForm;
