import React from 'react';
import styled from 'styled-components';

import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import Badge from 'styleguide/components/Badge';
import iconFlag from 'components/icons/iconTagFlag.svg';
import { ReactComponent as ChevronRight } from 'components/icons/IconChevronRight.svg';
import { ReactComponent as ChevronDown } from 'components/icons/IconChevronDown.svg';
import { useDeviceState } from 'state/deviceState';


const GroupHeaderTr = styled.tr`
  border-radius: 5px;
`;

const HitsBadge = styled(Badge)`
  min-width: 105px;
`;

const GroupHeader = styled.td`
  display: table-cell;
  cursor: pointer;
  position: relative;

  z-index: 2;
  min-height: 56px;

  & > div {
    background: #fff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #DBD7D7;
    padding: 12px 47px 12px 22px;
    margin-bottom: 10px;
    @media (max-width: 768px) {
      padding: 12px 0px 12px 10px;
    }
  }

  &.expanded {
    position: sticky;
    top: 122px;
    z-index: 100;

    & > div {
      margin-bottom: 0px;
      border-bottom: 0;
      border-radius: 5px 5px 0 0;
    }
  }

  @media (max-width: 768px) {
    padding: 0px 0px 0px 0px;

    &.expanded {
      position: sticky;
      top: 55px;
      z-index: 1;
    }
   }  
`;

const ExpandContent = styled.div`
  padding: 0px 13px 13px 13px;
  border-radius: 0 0 5px 5px;
  border: 1px solid #DBD7D7;
  border-top: 0;
  margin-bottom: 10px;
  background-color: #fff;
`;

const GroupHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const GroupRow = (props: any) => {
  // material-table's MTableGroupRow is not typed, hence any
  const isExpanded = props.groupData.isExpanded;
  const [deviceState] = useDeviceState();

  const hasHit = props.groupData.data.filter(
    (rowData: any) => rowData.status === 'Hit'
  ).length;

  return (
    <>
      <GroupHeaderTr
        className="groupHeaderRow"
        onClick={(event: any) => {
          props.onGroupExpandChanged(props.path);
        }}
      >
        <GroupHeader colSpan={props.columns.length} className={isExpanded ? 'expanded' : ''}>
          <div>
            <GroupHeaderContent>
              <div style={{ width: '16px' }}>
                {isExpanded ? <ChevronDown /> : <ChevronRight />}
              </div>
              <Spacing direction="right" value={deviceState.isMobile ? "0px" : "20px"} />

              <BasicHeading
                tag="div"
                size={deviceState.isMobileOrTablet ? "xs" : "md"}
                style={{ textTransform: 'uppercase' }}
              >
                {props.groupData.value}
              </BasicHeading>
              <Spacing direction="left" value="auto" />
              <HitsBadge color="red" icon={<img src={iconFlag} alt="" />}>
                {`${hasHit} ${hasHit === 1 ? 'hit' : 'hits'}`}
              </HitsBadge>
            </GroupHeaderContent>
          </div>
        </GroupHeader>
      </GroupHeaderTr>
      {isExpanded && (
        <tr>
          <td>
            <ExpandContent>
              <table className="MuiTable-root">
                <tbody className="MuiTableBody-root">
                  {props.groupData.data.map((rowData: any, index: number) => (
                    <props.components.Row
                      actions={props.actions}
                      key={index}
                      columns={props.columns.slice(1)}
                      components={props.components}
                      data={{ ...rowData, tableData: { ...rowData.tableData, showDetailPanel: props.detailPanel } }}
                      detailPanel={props.detailPanel}
                      getFieldValue={props.getFieldValue}
                      icons={props.icons}
                      path={[...props.path, index]}
                      onRowSelected={props.onRowSelected}
                      onRowClick={props.onRowClick}
                      onToggleDetailPanel={props.onToggleDetailPanel}
                      options={props.options}
                      isTreeData={props.isTreeData}
                      onTreeExpandChanged={props.onTreeExpandChanged}
                      onEditingCanceled={props.onEditingCanceled}
                      onEditingApproved={props.onEditingApproved}
                      hasAnyEditingRow={props.hasAnyEditingRow}
                      cellEditable={props.cellEditable}
                      onCellEditStarted={props.onCellEditStarted}
                      onCellEditFinished={props.onCellEditFinished}
                      className="groupContentRow groupContentRow--hitDetails"
                    />
                  ))}
                </tbody>
              </table>
            </ExpandContent>
          </td>
        </tr>
      )}
    </>
  );
};

export default GroupRow;
