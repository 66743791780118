import React, { FC, useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { ReCaptcha } from 'react-recaptcha-v3';
// import { Field } from 'formik';
import { Rifm } from 'rifm';

import { getFormatter } from 'helpers/formatters';
import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
// import Checkbox from 'styleguide/components/Checkbox';
import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
import Error from 'styleguide/components/Error';
import Loader from 'styleguide/components/Loader';
import Snackbar from 'styleguide/components/Snackbar';

import { RegistrationSecondStepRenderProps } from './RegistrationSecondStep';
import { getRecaptchaKey } from '../contants';
// import { FormLabel, TextInput, Button, TermsLabel, Select } from './styled';
import { FormLabel, TextInput, Button, TermsLabel } from './styled';
import { useDeviceState } from 'state/deviceState';
import CompanySelectField from 'pages/order/components/CompanySelectField';

const RegistrationFirstStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledColumn = styled.div`
  padding-left: 5px;
  padding-right: 5px;
`;

const RegistrationSecondStepPresentational: FC<RegistrationSecondStepRenderProps> = ({
  initialValues,
  onSubmit,
  validationSchema,
  states,
  verifyCallback,
  isLoading,
  isError
}) => {
  const [maskedValue, setMaskedValue] = useState('');
  const { formatMessage } = useIntl();
  // const options = states.map(state => ({ label: state, value: state }));
  const [state] = useDeviceState();

  return (
    <RegistrationFirstStepWrapper>
      <div className="clearfix">
        <div className="float-left">
          {state.isDesktop ? (
            <BasicHeading tag="h2" size="xxxl">
              <FormattedMessage id="registration.allMostFinished" />
            </BasicHeading>
          ) : (
            <BasicHeading tag="h1" size="xl">
              <FormattedMessage id="registration.allMostFinished" />
            </BasicHeading>
          )}
        </div>
        <div className="float-right">
          <BasicSubHeading size="xxs" className="mt-2" isUppercase>
            <FormattedMessage
              id="registration.step"
              values={{ currentStep: 2 }}
            />
          </BasicSubHeading>
        </div>
      </div>

      <Spacing direction="top" value={state.isDesktop ? 10 : 16} />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <StyledRow>
              <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.firmName" />
                  {/* <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="firmName"
                    name="firmName"
                    value={values.firmName}
                    placeholder={formatMessage({
                      id: 'registration.firmNamePlaceholder'
                    })}
                    invalid={Boolean(touched.firmName && errors.firmName)}
                    autoFocus
                  /> */}
                  <CompanySelectField name="firmName" />
                </FormLabel>
                {touched.firmName && errors.firmName && (
                  <Error>{errors.firmName}</Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>

            {/* <StyledRow>
              <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.firmAddress" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="firmAddress"
                    name="firmAddress"
                    value={values.firmAddress}
                    placeholder={formatMessage({
                      id: 'registration.firmAddressPlaceholder'
                    })}
                    invalid={Boolean(touched.firmAddress && errors.firmAddress)}
                  />
                </FormLabel>
                {touched.firmAddress && errors.firmAddress && (
                  <Error>{errors.firmAddress}</Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>
            <StyledRow className="row">
              <StyledColumn className="col-xl">
                <FormLabel as="label">
                  <FormattedMessage id="registration.city" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="city"
                    name="city"
                    value={values.city}
                    placeholder={formatMessage({
                      id: 'registration.cityPlaceholder'
                    })}
                    invalid={Boolean(touched.city && errors.city)}
                  />
                </FormLabel>
                {touched.city && errors.city && <Error>{errors.city}</Error>}
                <Spacing direction="top" value={16} />
              </StyledColumn>
              <StyledColumn className="col-sm">
                <FormLabel as="label">
                  <FormattedMessage id="registration.state" />
                  <Field name="state">
                    {({ field: { value }, form: { setFieldValue } }: any) => (
                      <Select
                        options={options}
                        placeholder={formatMessage({
                          id: 'registration.statePlaceholder'
                        })}
                        value={value ? { value: value, label: value } : null}
                        onChange={(val: any) => {
                          setFieldValue('state', val.value);
                        }}
                        invalid={Boolean(touched.state && errors.state)}
                      />
                    )}
                  </Field>
                </FormLabel>
                {touched.state && errors.state && <Error>{errors.state}</Error>}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow> */}

            <StyledRow>
              {/* <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.postalCode" />
                  <TextInput
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="postalCode"
                    name="postalCode"
                    value={values.postalCode}
                    placeholder={formatMessage({
                      id: 'registration.postalCodePlaceholder'
                    })}
                    invalid={Boolean(touched.postalCode && errors.postalCode)}
                  />
                </FormLabel>
                {touched.postalCode && errors.postalCode && (
                  <Error>{errors.postalCode}</Error>
                )}
                <Spacing direction="top" value={16} />
              </StyledColumn> */}
              <StyledColumn>
                <FormLabel as="label">
                  <FormattedMessage id="registration.phone" />
                  <Rifm
                    value={maskedValue}
                    onChange={setMaskedValue}
                    format={getFormatter('phoneNumber')}
                  >
                    {({ value: valueFromRifm, onChange: onChangeFromRifm }) => (
                      <TextInput
                        style={{ width: "50%" }}
                        id="phone"
                        name="phone"
                        value={valueFromRifm}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          handleChange(e);
                          onChangeFromRifm(e);
                        }}
                        onBlur={handleBlur}
                        placeholder={formatMessage({
                          id: 'registration.phonePlaceholder'
                        })}
                        invalid={Boolean(touched.phone && errors.phone)}
                      />
                    )}
                  </Rifm>
                </FormLabel>
                {touched.phone && errors.phone && <Error>{errors.phone}</Error>}
                <Spacing direction="top" value={16} />
              </StyledColumn>
            </StyledRow>

            <StyledRow>
              <StyledColumn>
                <TermsLabel as="label">
                  {/* <Checkbox
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="hasAcceptedTerms"
                    checked={values.hasAcceptedTerms}
                  /> */}
                  <Spacing direction="right" value={7} />
                  <div>
                    <FormattedMessage
                      id="registration.terms"
                      values={{
                        termsOfService: (
                          <LegalPagesModal
                            title={
                              <FormattedMessage id="links.termsOfService" />
                            }
                            type="termsOfService"
                          />
                        ),
                        privacyPolicy: (
                          <LegalPagesModal
                            title={
                              <FormattedMessage id="links.privacyPolicy" />
                            }
                            type="privacyPolicy"
                          />
                        ),
                        termsOfUse: (
                          <LegalPagesModal
                            title={<FormattedMessage id="links.termsOfUse" />}
                            type="termsOfUse"
                          />
                        )
                      }}
                    />
                  </div>
                </TermsLabel>
                {touched.hasAcceptedTerms && errors.hasAcceptedTerms && (
                  <>
                    <Spacing direction="top" value={8} />
                    <Error>{errors.hasAcceptedTerms}</Error>
                  </>
                )}
              </StyledColumn>
            </StyledRow>

            <ReCaptcha
              sitekey={getRecaptchaKey()}
              verifyCallback={verifyCallback}
              action=""
            />

            {isError && (
              <>
                <Spacing direction="top" value={16} />
                <Snackbar type="error">
                  Oops! There was an error. Please try again and if it doesn't
                  help contact us:{' '}
                  <a href="mailto:info@dealbinder.com">info@dealbinder.com</a>
                </Snackbar>
              </>
            )}

            <Spacing direction="top" value={24} />

            <Button width="100%" primary disabled={isLoading}>
              {(isLoading && (
                <>
                  <Loader marginLess size={2} background="#dddce7" />
                  <Spacing direction="right" value={16} />
                </>
              )) || <FormattedMessage id="shared.signUp" />}
            </Button>

            {/* {isLoading && (
              <>
                <Spacing direction="top" value={16} />
                <Loader marginLess centered />
                <Spacing direction="top" value={16} />
              </>
            )} */}
          </Form>
        )}
      </Formik>
    </RegistrationFirstStepWrapper>
  );
};

export default RegistrationSecondStepPresentational;
