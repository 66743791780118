import React, { FC } from 'react';
import styled from 'styled-components';

import IconDone from 'components/icons/IconDone.svg';

const StyledCheckbox = styled.span`
  display: inline-block;
  height: 16px;
  width: 16px;
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray200};
  border-radius: ${({ theme }) => theme.borderRadius.regular};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;

  &:checked {
    & ~ ${StyledCheckbox} {
      border-color: ${({ theme }) => theme.colorsNewDesign.primary};
      background-color: ${({ theme }) => theme.colorsNewDesign.primary};

      &:before {
        background: url(${IconDone}) center no-repeat;
      }
    }
  }
`;

const StyledLabel = styled.label`
  vertical-align: middle;
`;

const Checkbox: FC<any> = ({ showLabel = true, className, ...props }) => {
  const content = (
    <>
      <StyledInput type="checkbox" {...props} />
      <StyledCheckbox />
    </>
  );

  if (showLabel) {
    return (
      <StyledLabel className={className} htmlFor={props.id}>
        {content}
      </StyledLabel>
    );
  }

  return <div className={className}>{content}</div>;
};

export default Checkbox;
