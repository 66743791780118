import React from 'react';
import styled from 'styled-components';
import BasicAside from 'styleguide/components/BasicAside';

const AsideHead = styled.div`
  padding: 15px;
  color: #fff;
  background: #3dbc70;
  * {
    color: #fff;
  }
`;

const ConfigStepAside = () => (
  <AsideHead>
    <BasicAside.Heading>
      Which Organizational Documents do you need?
    </BasicAside.Heading>
  </AsideHead>
);

export default ConfigStepAside;
