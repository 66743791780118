import React, { FC } from 'react';
import styled from 'styled-components';

import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';

const Wrapper = styled.div``;

const StyledBasicSubHeading = styled(BasicSubHeading)`
  margin-bottom: 3px;
`;

const StyledContent = styled.div`
  margin-top: 20px;
`;

const StyledHeading = styled(BasicHeading)`
  color: ${({ theme }) => theme.userCircleColors.green};
`

const Heading: FC = ({ children }) => (
  <StyledHeading tag="h3" size="xxl">
    {children}
  </StyledHeading>
);

const SubHeading: FC = ({ children }) => (
  <StyledBasicSubHeading size="xxs" isUppercase>
    {children}
  </StyledBasicSubHeading>
);

const BasicAside = {
  Wrapper,
  Heading,
  SubHeading,
  Content: StyledContent
};

export default BasicAside;
