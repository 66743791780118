import React, { FC } from 'react';

import BasicModal from 'styleguide/components/BasicModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import MaterialTable from 'styleguide/components/MaterialTable';

import { permissions } from './constants';
import { DescriptionWrapper, Check, Close, TableWrapper } from './styled';

interface PermissionsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PermissionsModal: FC<PermissionsModalProps> = ({ isOpen, onClose }) => (
  <BasicModal
    title={
      <>
        <BasicHeading tag="h3" size="xl">
          About Permissions
        </BasicHeading>
      </>
    }
    isOpen={isOpen}
    onClose={onClose}
    customStyles={{
      overlay: {
        zIndex: 10000
      }
    }}
  >
    <DescriptionWrapper>
      <BasicText size="regular">
        People invited to a matter have different role-based permissions.
      </BasicText>
    </DescriptionWrapper>
    <TableWrapper>
      <MaterialTable
        materialTableProps={{
          columns: [
            {
              title: 'Permission',
              field: 'name',
              cellStyle: {
                position: 'sticky',
                left: 0,
                whiteSpace: 'pre',
                paddingLeft: '16px',
                backgroundColor: '#fff',
              },
              headerStyle: {
                position: 'sticky',
                left: 0,
                zIndex: 11,
                fontSize: '11px',
                paddingLeft: '16px',
                backgroundColor: '#F7F8FC',
                boxShadow: 'rgb(226 230 240) 0px 1px 0px'
              }        
            },
            {
              title: 'Limited Viewer',
              field: 'limitedViewer',
              render: ({ limitedViewer }) =>
                limitedViewer ? <Check /> : <Close />,
              headerStyle: {
                fontSize: '11px',
              }
            },
            {
              title: 'Viewer',
              field: 'viewer',
              render: ({ viewer }) => (viewer ? <Check /> : <Close />),
              headerStyle: {
                fontSize: '11px',
              }
            },
            {
              title: 'Collaborator',
              field: 'collaborator',
              render: ({ collaborator }) =>
                collaborator ? <Check /> : <Close />,
              headerStyle: {
                fontSize: '11px',
              }
            },
            {
              title: 'Reviewer',
              field: 'reviewer',
              render: ({ reviewer }) => (reviewer ? <Check /> : <Close />),
              headerStyle: {
                fontSize: '11px',
              }
            },
            {
              title: 'Owner',
              field: 'owner',
              render: ({ owner }) => (owner ? <Check /> : <Close />),
              headerStyle: {
                fontSize: '11px',
              }
            }
          ],
          data: permissions,
          options: { paging: false }
        }}
      />
    </TableWrapper>
  </BasicModal>
);

export default PermissionsModal;
