import React, { FC } from 'react';
import { useField } from 'formik';
import { Cell } from 'styleguide/components/Table';
import StatusBox from 'styleguide/components/StatusBox';
import { useProductState, getCharterDocs } from '../../state/productsState';
import { useDraggableCell } from '../TableForm/utils';
interface CellFormProps {
  row: number;
  col: number;
}

const CellForm: FC<CellFormProps> = ({ row, col }) => {
  const [state] = useProductState();
  const charterDoc = getCharterDocs(state);
  const [, { value }, { setValue }] = useField(`selection.${col}.${row}`);
  const isCharterSelected = value?.includes(charterDoc.id);

  const setValueCb = React.useCallback(
    (selected: boolean) => {
      if (!selected) {
        setValue(null);
      } else {
        setValue([charterDoc.id]);
      }
    },
    [setValue, charterDoc]
  );
  const { isSelected, ...eventHandlers } = useDraggableCell(
    row,
    col,
    Boolean(isCharterSelected),
    setValueCb
  );

  return (
    <Cell
      isFullSize
      {...eventHandlers}
      hasHoverState={!isSelected}
      data-hover="Select"
    >
      <StatusBox color="green" hasBackgroundColor={isSelected} hasIcon={false}>
        {isSelected ? 'Charter' : null}
      </StatusBox>
    </Cell>
  );
};

export default CellForm;
