import React, { FC, ReactNode, useContext } from 'react';
import {
  Route as RouterRoute,
  RouteProps as RouterRouteProps,
  Redirect
} from 'react-router';

import { getParamFromUrl } from 'helpers/helpers';
import { DASHBOARD_URL, CHECK_YOUR_EMAIL_URL } from 'helpers/routes';
import { APPROVED } from 'helpers/constants';

import { AuthContext } from './Auth';

export interface PublicRouteProps extends RouterRouteProps {
  component?: FC;
  render?: () => ReactNode;
}

const PublicRoute: FC<PublicRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const currentUser = useContext(AuthContext);

  const onRender = (props: any) => {
    if (currentUser && !currentUser.emailVerified) {
      return (
        <Redirect
          to={{
            pathname: CHECK_YOUR_EMAIL_URL,
            state: { from: props.location }
          }}
        />
      );
    }

    if (
      currentUser?.emailVerified &&
      (currentUser?.claims?.status === APPROVED || currentUser?.claims?.limited)
    ) {
      const continueUrl = getParamFromUrl('continueUrl') || DASHBOARD_URL;
      return (
        <Redirect
          to={{
            pathname: continueUrl,
            state: { from: props.location }
          }}
        />
      );
    }

    return <Component {...props} />;
  };

  return <RouterRoute {...rest} render={onRender} />;
};

export default PublicRoute;
