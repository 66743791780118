import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const userDetailsUrl = `${url}/contacts`;

export interface UserDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  firmName?: string;
  phone?: string;
  firmAddress?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  isReceivingDailyUpdates?: boolean;
}

export const fetchUserDetails = async (): Promise<UserDetails> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(userDetailsUrl);

  return data;
};

export const updateUserDetails = async (user: UserDetails): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.patch(userDetailsUrl, user);
};

export const deleteAccount = async (): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.delete(userDetailsUrl);
};
