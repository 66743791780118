import React, { FC } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import uniq from 'lodash/uniq';

import FullLayout from 'styleguide/components/FullLayout';
import Button, { RightButtonIcon } from 'styleguide/components/Button';
import { ChevronRight, ChevronLeft } from 'styleguide/components/Icon';
import Error from 'styleguide/components/Error';
import TableForm from './components/TableForm';
import JurisdictionStateSelectField from './components/JurisdictionStateSelectField';
import Tooltip from 'styleguide/components/Tooltip';
import { OrderFormStateSubscriber } from './state/state';
import Navigation from './components/Navigation';

import ConfigStepAside from './components/documents/ConfigStepAside';
import PreviouslySelectedAside from './components/documents/PreviouslySelectedAside';
import CharterDocumentsAside from './components/documents/CharterDocumentsAside';
import GoodStandingAside from './components/documents/GoodStandingAside';
import ReviewAside from './components/documents/ReviewAside';

import DocumentsConfigStep from './components/documents/configStep';
import PreviouslySelectedStep from './components/documents/PreviouslySelectedStep';
import CharterDocumentsFormCell from './components/documents/CharterDocumentsFormCell';
import GoodStandingFormCell from './components/documents/GoodStandingFormCell';
import DocumentsReviewCellProductForm from './components/documents/DocumentsReviewCellProductForm';
import HeaderSelect from './components/documents/HeaderSelect';
import DeleteModal from './components/DeleteModal';
import OrderFormObserver from './components/OrderFormObserver';
import { useScrollTop } from 'helpers/hooks';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Bg from 'components/icons/Group.png';

const LayoutAside = styled(FullLayout.Aside)`
  padding: 0px;
  background:#fff url(${Bg});
  height: 100%;
  background-repeat: no-repeat;
  background-position: 100% 120%;
`;

export enum DocumentsFormStates {
  Config,
  PreviouslySelected,
  CharterDocuments,
  GoodStandingCertificates,
  Review
}

const formStates = [
  DocumentsFormStates.Config,
  DocumentsFormStates.PreviouslySelected,
  DocumentsFormStates.CharterDocuments,
  DocumentsFormStates.GoodStandingCertificates,
  DocumentsFormStates.Review
];

export const DocumentsSchema = Yup.object().shape({
  charterDocuments: Yup.bool(),
  goodStanding: Yup.bool().when('charterDocuments', {
    is: false,
    then: Yup.bool()
      .required()
      .oneOf([true], 'Please select at least one type')
  }),
  jurisdictions: Yup.array().when('state', {
    is: (value) => [DocumentsFormStates.Review, DocumentsFormStates.CharterDocuments,  DocumentsFormStates.GoodStandingCertificates].includes(value),
    then: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.string().required(),
            label: Yup.string().required()
          })
          .required()
      )
      .required('Please add a jurisdiction')
  }),
  names: Yup.array().when('state', {
    is: (value) => [DocumentsFormStates.Review, DocumentsFormStates.CharterDocuments,  DocumentsFormStates.GoodStandingCertificates].includes(value),
    then: Yup.array()
      .of(
        Yup.string()
          .min(3, 'Names must be 3 or more characters')
          .required('Please add a name')
          .test({
            name: 'unique',
            message: 'Remove duplicate name',
            test: function(val: any) {
              if (val) {
                const valueIndex = this.parent.findIndex(
                  (name: string) =>
                    name && name.toLowerCase() === val.toLowerCase()
                );
                return `names[${valueIndex}]` === this.path;
              }
              return true;
            }
          })
      )
      .required('Please add a name')
      .min(1, 'Please add a name')
  }),
  selection: Yup.array()
    .when('state', {
      is: (value) => [DocumentsFormStates.Review, DocumentsFormStates.CharterDocuments,  DocumentsFormStates.GoodStandingCertificates].includes(value),
      then: Yup.array()
        .test({
          name: 'error',
          message: 'Please select at least one cell',
          test: function(val: any) {
            if (!val) return false;
            
            let check = false;

            val.forEach((j: any) => {
              j.forEach((k: any) => {
                check = check || !isEmpty(k);
              });
            });
            
            return check;
          }
        })
    })
});

export interface DocumentsFormValues {
  charterDocuments?: boolean;
  goodStanding?: boolean;

  withAmendments?: boolean;
  certifiedCopy?: boolean;

  names?: string[];
  previousNames?: string[];
  jurisdictions?: string[];
  selection?: string[][][];
  state: DocumentsFormStates;
}

const DocumentsError = ({
  message,
  submitCount
}: {
  message: string;
  submitCount: number;
}) =>
  message &&
  message.length &&
  (message !== 'Please add a name' || submitCount > 0) ? (
    <Error>{message}</Error>
  ) : null;

const Documents: FC = () => {
  const { ref, scrollTop } = useScrollTop();
  return (
    <OrderFormStateSubscriber>
      {(state, actions) => (
        <Formik<DocumentsFormValues>
          initialValues={
            {
              names: [''],
              previousNames: [],
              jurisdictions: [''],
              charterDocuments: false,
              goodStanding: false,
              withAmendments: true,
              certifiedCopy: true,
              state: DocumentsFormStates.Config,
              ...(state.documents || {})
            } as DocumentsFormValues
          }
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(values, helpers) => {
            const st = formStates
              .filter(
                step =>
                  state.searches ||
                  state.ofac ||
                  step !== DocumentsFormStates.PreviouslySelected
              )
              .filter(
                step =>
                  values.charterDocuments ||
                  step !== DocumentsFormStates.CharterDocuments
              )
              .filter(
                step =>
                  values.goodStanding ||
                  step !== DocumentsFormStates.GoodStandingCertificates
              ).filter(step => {
                if (step === DocumentsFormStates.Review && [values.goodStanding, values.charterDocuments].includes(false)) {
                  return false;
                }
                return true;
              });

            if (values.state === DocumentsFormStates.PreviouslySelected) {
              const names = uniq([...values.names, ...values.previousNames]);
              helpers.setFieldValue(
                'names',
                names.length > 1 ? names.filter(Boolean) : names
              );
              helpers.setFieldValue('previousNames', []);
            }

            if (([DocumentsFormStates.CharterDocuments, DocumentsFormStates.GoodStandingCertificates].includes(values.state)) && !st.includes(DocumentsFormStates.Review)) {
              actions.submitStep(values);
            }
            else if (values.state === DocumentsFormStates.Review) {
              actions.submitStep(values);
            } else {
              helpers.setFieldValue('state', st[st.indexOf(values.state) + 1]);
            }
            scrollTop();
          }}
          validationSchema={DocumentsSchema}
        >
          {({ values, errors, touched, submitCount, setFieldValue }) => (
            <Form>
              <OrderFormObserver />
              <FullLayout.Wrapper>
                <Navigation />
                <FullLayout.Container>
                  <LayoutAside>
                    {values.state === DocumentsFormStates.Config && (
                      <ConfigStepAside />
                    )}
                    {values.state ===
                      DocumentsFormStates.PreviouslySelected && (
                      <PreviouslySelectedAside />
                    )}
                    {values.state === DocumentsFormStates.CharterDocuments && (
                      <CharterDocumentsAside />
                    )}
                    {values.state ===
                      DocumentsFormStates.GoodStandingCertificates && (
                      <GoodStandingAside />
                    )}
                    {values.state === DocumentsFormStates.Review && (
                      <ReviewAside />
                    )}
                  </LayoutAside>
                  <FullLayout.Content
                    autoScroll={values.state === DocumentsFormStates.Config}
                    ref={ref}
                  >
                    {values.state === DocumentsFormStates.Config && (
                      <DocumentsConfigStep />
                    )}
                    {values.state ===
                      DocumentsFormStates.PreviouslySelected && (
                      <PreviouslySelectedStep />
                    )}
                    {values.state === DocumentsFormStates.CharterDocuments && (
                      <TableForm
                        selectComponent={JurisdictionStateSelectField}
                        component={CharterDocumentsFormCell}
                        headerSelectComponent={HeaderSelect}
                      />
                    )}
                    {values.state ===
                      DocumentsFormStates.GoodStandingCertificates && (
                      <TableForm
                        selectComponent={JurisdictionStateSelectField}
                        component={GoodStandingFormCell}
                        headerSelectComponent={HeaderSelect}
                      />
                    )}
                    {values.state === DocumentsFormStates.Review && (
                      <TableForm
                        selectComponent={JurisdictionStateSelectField}
                        component={DocumentsReviewCellProductForm}
                      />
                    )}
                    <DeleteModal />
                  </FullLayout.Content>
                </FullLayout.Container>
                <FullLayout.Footer>
                  <FullLayout.Left>
                    <Tooltip/>
                    <Button
                      data-tip="Delete everything in this section"
                      small
                      type="button"
                      onClick={actions.showDeleteModal}
                    >
                      Delete
                    </Button>
                  </FullLayout.Left>
                  <FullLayout.Center>
                    {errors.goodStanding && (
                      <Error>{errors.goodStanding}</Error>
                    )}
                    {touched.names && errors.names && errors.names.length && (
                    <DocumentsError
                      submitCount={submitCount}
                      message={
                        typeof errors.names === 'string'
                          ? errors.names
                          : errors.names.find(
                              (el, i) =>
                                Boolean(el) &&
                                ((touched.names as unknown) as boolean[])[i]
                            )
                      }
                    />
                  )}
                    {touched.jurisdictions &&
                      errors.jurisdictions &&
                      errors.jurisdictions.length && (
                        <Error>Please add a jurisdiction</Error>
                      )}
                    {Boolean(
                      !errors.jurisdictions &&
                        !errors.names &&
                        submitCount &&
                        errors.selection
                    ) && <Error>{errors.selection}</Error>}
                  </FullLayout.Center>
                  <FullLayout.Right>
                    <Button type="button" onClick={actions.previousStep}>
                      <ChevronLeft />
                    </Button>
                    <Button type="submit" primary className="mr-5">
                      Next
                      <RightButtonIcon>
                        <ChevronRight />
                      </RightButtonIcon>
                    </Button>
                  </FullLayout.Right>
                </FullLayout.Footer>
              </FullLayout.Wrapper>
            </Form>
          )}
        </Formik>
      )}
    </OrderFormStateSubscriber>
  );
};

export default Documents;
