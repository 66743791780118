import styled, { css, keyframes } from 'styled-components';
import { theme } from 'theme/theme';
import { Link as RouterLink } from 'react-router-dom';
import ReactCalendar from 'react-calendar';

import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import BasicText from 'styleguide/components/BasicText';
import StyleguideHorizontalLine from 'styleguide/components/HorizontalLine';
import FormLabel from 'styleguide/components/FormLabel';
import { ReactComponent as IconClose } from 'components/icons/IconExit.svg';


export const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
`;
// grid-template-columns: repeat(3, 1fr);


export const SmallBox = styled.div<{ isClickable?: boolean,isOwner?:boolean }>`
  background: white;
  box-shadow: 0 1px 1px rgba(19, 31, 21, 0.04);
  border-radius: 4px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  transition: box-shadow 100ms ease-in;
  position: relative;

  @media(max-width:768px){
    flex-direction:column;
    height: 100px;
    text-align:center;
    padding: 10px 10px;

  }

  ${({ isClickable,isOwner }) =>
    isClickable &&
    css`
      cursor: pointer;
      h5.invoiceText {
        color: ${({ theme }) => isOwner ? theme.colorsNewDesign.primaryHover : 'inherit'};
      };
      & svg.invoiceText {
        path {
          fill :${({ theme }) =>isOwner ? theme.colorsNewDesign.primaryHover: ''};
        };
      };
      &:hover {
        box-shadow: 0 10px 20px rgba(121, 122, 149, 0.25),
          0 2px 2px rgba(121, 122, 149, 0.3);
        h5.invoiceText {
          color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
        };
        & svg.invoiceText {
          path {
            fill :${({ theme }) => theme.colorsNewDesign.primaryHover};
          };
        }
      }
    `}
`;

export const SmallBoxTitle = styled.div`
  display: flex;
  align-items: center;
 
  @media(max-width:768px){
    flex-direction:column;

  }
`;

const getInvoiceTextColor = (type: string): string => {
  switch (type) {
    case 'Paid': {
      return theme.colorsNewDesign.cyan110;
    }
    case 'Outstanding': {
      return theme.colorsNewDesign.error;
    }
    default: {
      return theme.colorsNewDesign.gray600;
    }
  }
};

export const InvoiceStatus = styled(BasicSubHeading) <{ type: string }>`
  color: ${({ type }) => getInvoiceTextColor(type)};
  font-size: 12px;
  @media(max-width:768px){
    font-size: 9px;

  }
`;

export const InvoicesModalContent = styled.div`
  width: 526px;
  max-width: 100vw;
`;

export const InvoicesBoxLink = styled(RouterLink)`
  &:hover {
    text-decoration: none;
  }
`;

export const Clear = styled(IconClose)`
  margin-right: 8px;
  opacity: 0;

  path {
    fill: ${({ theme }) => theme.colorsNewDesign.gray300};
  }

  :hover {
    path {
      fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
  }
`;

export const ClosingDateSmallBox = styled(SmallBox) <{ isOwner: boolean }>`
${({ isOwner }) =>
    isOwner &&
    css`
    & > div:nth-child(-n + 2),h5 {
      color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
    & svg {
      path {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
    }
    `}
  &:hover {
    
    ${Clear} {
      opacity: 1;
    }
    ${({ isOwner }) =>
    isOwner &&
    css`
    & > div:nth-child(-n + 2),h5 {
      color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
    & svg {
      path {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
    }
    `}
  }
  @media(max-width:768px){
    flex-direction:column;
    height: 100px;
    text-align:center;
    min-width:110px;
    padding: 10px 10px;

  }

`;


export const SmallBoxAssign = styled(SmallBox) <{ isOwner: boolean }>`
${({ isOwner }) =>
    isOwner &&
    css`
    & > div:nth-child(-n + 2),h5 {
      color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
    & svg {
      path {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
      circle {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
    }
    `}
  &:hover {
    ${Clear} {
      opacity: 1;
    }
    ${({ isOwner }) =>
    isOwner &&
    css`
    & > div:nth-child(-n + 2),h5 {
      color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
    }
    & svg {
      path {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
      circle {fill: ${({ theme }) => theme.colorsNewDesign.primaryHover};};
    }
    `}
  }
  @media(max-width:768px){
    flex-direction:column;
    height: 100px;
    text-align:center;
    min-width:110px;
    padding: 10px 10px;

  }
  `;

export const ClosingDateText = styled(BasicText)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;
export const CalenderWrapper = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: #fff;
  z-index: 100;
  @media (max-width:768px){
    min-width:250px
  }
`;

export const Calendar = styled(ReactCalendar)`
  width: 100%;
`;
export const CalenderButton = styled.div`
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  border: 1px solid #a0a096;
  border-top: none;
  & button {
    border: 1px solid #F3F3F5;
    padding:  10px 15px;
    position: relative;
    background: #fff;
    border-radius: 5px;
    :hover {
      background: #0A2AAB;
      color: #fff;
    }
  }
  
`;

export const Box = styled(SmallBox)`
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 32px;
  box-shadow: 0 2px 2px rgba(121, 122, 149, 0.3);
  border-radius: 8px;
  min-height: 266px;
`;

export const BoxTitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  width: 100%;
`;

export const BoxSummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100%;
  margin-bottom: 24px;
`;

export const ETAText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
`;

const progressBarAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const ProgressBar = styled.div<{ value: number }>`
  background: ${({ theme }) => theme.colorsNewDesign.gray200};
  height: 15px;
  width: 100%;
  border-radius: 999px;
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    display: block;
    height: 15px;
    border-radius: 999px;
    background: ${({ theme }) => theme.colorsNewDesign.scooter};
    width: ${({ value }) => `${value}%`};
    animation: ${progressBarAnimation} 0.3s ease-in;
  }
`;

export const AddReviewerText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
`;

export const ReviewerText = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
`;

export const Link = styled(BasicText)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const AssignToContentWrapper = styled.div`
  padding: 16px 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const HorizontalLine = styled(StyleguideHorizontalLine)`
  background: ${({ theme }) => theme.colorsNewDesign.gray100};
`;

export const Label = styled(FormLabel)`
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
  border-top: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
`;
