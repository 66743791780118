import React, { FC, useState, useEffect } from 'react';
import { isEmpty, some } from 'lodash';
import { Helmet } from 'react-helmet';

import Spacing from 'styleguide/components/Spacing';
import BasicHeading from 'styleguide/components/BasicHeading';
import { ReactComponent as FiltersIcon } from 'components/icons/IconFilters.svg';

import { useMyMattersState } from '../../state/myMatters';
import { Wrapper, Header, FiltersButton } from './styled';
import Table from './Table';
import EmptyState from './EmptyState';
import DeleteModal from './DeleteModal';
import Filters from './Filters';

import { Loader } from 'pages/matter/components/common/styled';
import { theme } from 'theme/theme';
import { useDeviceState } from 'state/deviceState';

export interface DeleteMatterModalData {
  matterId: string;
  matterName: string;
}

const MyMatters: FC = () => {
  const [state, actions] = useMyMattersState();
  const [deviceState] = useDeviceState();

  // const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [deleteMatterData, setDeleteMatterData] = useState<
    DeleteMatterModalData
  >(null);
  useEffect(() => {
    actions.resetFilters();
    actions.fetchData();
  }, [actions]);

  const areFiltersApplied = some(state.filters, filter => !isEmpty(filter));

  const shouldDisplayEmptyState =
    !state.isLoading && state.totalCount === 0 && !areFiltersApplied;

  if (state.isLoading) return <Loader marginLess background={theme.colorsNewDesign.lightBlue} />;
  return (
    <>
      <Helmet>
        <title>My Matters - DealBinder</title>
      </Helmet>
      <Wrapper>
       { deviceState.isMobileOrTablet?"":<><Header>
        <Spacing direction="top" value={deviceState.isMobileOrTablet?0:0} />

          <BasicHeading tag="h1" size={deviceState.isMobile? "regular":"xxl"}>
            My Matters and Invoices
          </BasicHeading>
          <Spacing direction="left" value="auto" />
          {!shouldDisplayEmptyState && (
            <FiltersButton
              active={areFiltersApplied}
              onClick={() => actions.toggleTableFilterSidebar('myMatters')}
            >
              <FiltersIcon />
              Filters
            </FiltersButton>
          )}
        </Header>
        <Spacing direction="top" value={20} /></>}
        {shouldDisplayEmptyState ? (
          <EmptyState />
        ) : (
          <Table
            totalCount={state.totalCount}
            isLoading={state.isLoading}
            orders={state.orders}
            onChangePage={actions.changePage}
            currentPage={state.currentPage}
            setDeleteMatterData={setDeleteMatterData}
            onDelete={actions.triggerDeleteModal}
          />
        )}
        <Filters
          isOpen={state.tableFilterSidebar.myMatters}
          onClose={() => actions.toggleTableFilterSidebar('myMatters')}
          onSubmit={actions.updateFilters}
          fetchMatterFilterOptions={actions.fetchMatterFilterOptions}
          matterFilterOptions={state.matterFilterOptions}
          dateFilterOptions={state.dateFilterOptions}
          filters={state.filters}
        />
        <DeleteModal
          isOpen={state.modalShow}
          onClose={actions.triggerDeleteModal}
          deleteMatterData={deleteMatterData}
          onDelete={actions.deleteMatter}
        />
      </Wrapper>
    </>
  );
};

export default MyMatters;
