import React, { FC, useCallback, useEffect, useState } from 'react';
import { uniq, isEmpty } from 'lodash';

import Tooltip from 'styleguide/components/Tooltip';
import BaseModal from 'styleguide/components/BasicModal/BootstarpModal';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Loader from 'styleguide/components/Loader';
import Spacing from 'styleguide/components/Spacing';
import Button from 'styleguide/components/Button';
import { FilesList, DownloadedFileData } from 'api/matter';
import { makeStyles } from '@material-ui/core';
import FullTable from './FullTable';
import SimpleTable from './SimpleTable';
import { Wrapper, ModalFooter, Snackbar, SnackbarLink } from './styled';
import { useDeviceState } from 'state/deviceState';
import { useMatterState } from 'pages/matter/state/matter';

const useStyles = makeStyles({
  BackDropCss: {
    zIndex: 9999
  },
  modalBodyCss: {
    overflow:'hidden !important',
  }
})

interface DownloadFilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  filesList?: FilesList;
  downloadFiles: (filesIds: string[]) => Promise<DownloadedFileData>;
}

const DownloadFilesModal: FC<DownloadFilesModalProps> = ({
  isOpen,
  onClose,
  filesList,
  downloadFiles
}) => {
  const classes = useStyles();
  const link = React.useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [deviceState] = useDeviceState();
  const [state] = useMatterState();

  useEffect(() => {
    if (isOpen) {
      setHasDownloaded(false);
      setSelectedFiles([]);
    }
  }, [isOpen]);

  const selectFiles = useCallback(
    (ids: string[]) => {
      setSelectedFiles(uniq([...selectedFiles, ...ids]));
    },
    [selectedFiles]
  );

  const unselectFiles = useCallback(
    (ids: string[]) => {
      setSelectedFiles(selectedFiles.filter(id => !ids.includes(id)));
    },
    [selectedFiles]
  );

  const handleDownloadClick = useCallback(() => {
    setIsLoadingDownload(true);
    downloadFiles(selectedFiles).then(fileData => {
      const href = window.URL.createObjectURL(
        new Blob([fileData.data], { type: fileData.type })
      );
      const a = link.current;
      a.download = fileData.filename;
      a.href = href;
      a.click();
      a.href = '';
      setIsLoadingDownload(false);
      setHasDownloaded(true);
    });
  }, [selectedFiles, downloadFiles]);

  const areSearchesFilesEmpty = isEmpty(filesList?.searchesFiles);
  const areOfacFilesEmpty = isEmpty(filesList?.ofacFiles);
  const areOrgDocsFilesEmpty = isEmpty(filesList?.orgDocsFiles);
  const areFilesEmpty =
    areSearchesFilesEmpty && areOfacFilesEmpty && areOrgDocsFilesEmpty;
 
  return (
    <BaseModal
      className={classes.BackDropCss}
      isOpen={isOpen}
      deviceState={deviceState}
      onClose={onClose}
      title={
        <BasicHeading tag="h2" size="xl">
          Download Files
        </BasicHeading>
      }
      modalBodyClass={classes.modalBodyCss}
    >
      {/* eslint-disable-next-line */}
      <a ref={link} />
      <>
        <Wrapper>
          {areFilesEmpty && (
            <BasicText size="regular">
              There are currently no files to download
            </BasicText>
          )}
          {!areSearchesFilesEmpty && (
            <FullTable
              list={filesList.searchesFiles}
              selectFiles={selectFiles}
              unselectFiles={unselectFiles}
              selectedFiles={selectedFiles}
              header="Searches"
              role={state.data?.role}
            />
          )}
          {!areSearchesFilesEmpty && !areOfacFilesEmpty && (
            <Spacing direction="top" value={16} />
          )}
          {!areOfacFilesEmpty && (
            <SimpleTable
              list={filesList.ofacFiles}
              selectFiles={selectFiles}
              unselectFiles={unselectFiles}
              selectedFiles={selectedFiles}
              header="OFAC Searches"
            />
          )}
          {!areOfacFilesEmpty && !areOrgDocsFilesEmpty && (
            <Spacing direction="top" value={16} />
          )}
          {!areOrgDocsFilesEmpty && (
            <FullTable
              list={filesList.orgDocsFiles}
              selectFiles={selectFiles}
              unselectFiles={unselectFiles}
              selectedFiles={selectedFiles}
              header="Org Docs"
              role={state.data?.role}
            />
          )}
        </Wrapper>
        {!areFilesEmpty && (
          <ModalFooter>
            {selectedFiles.length === 0 && (
              <Tooltip id="disabled-download-files-button">
                Please select at least one file
              </Tooltip>
            )}
            {hasDownloaded && (
              <>
                <Snackbar type="success">
                  Your files have been downloaded.{' '}
                  <SnackbarLink onClick={onClose}>
                    Return to matter
                  </SnackbarLink>
                </Snackbar>
                <Spacing direction="left" value={8} />
              </>
            )}
            <div className="row align-items-center">
             
              <div
                data-tip
                data-for="disabled-download-files-button"
                className="col"
              >
                <Button
                  onClick={handleDownloadClick}
                  primary
                  medium
                  disabled={isLoadingDownload || selectedFiles.length === 0}
                >
                 {(isLoadingDownload && (
                    <>
                     <Spacing direction="left" value={24} />
                      <Loader marginLess size={2} background="#dddce7" />
                     <Spacing direction="right" value={24} />

                    </>
                  )) ||
                    'Download'}
                </Button>
              </div>
            </div>
          </ModalFooter>
        )}
      </>
    </BaseModal>
  );
};

export default DownloadFilesModal;
