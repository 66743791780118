import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import SimpleLayout from 'layout/simpleLayout';
import Spacing from 'styleguide/components/Spacing';
import RoundIcon from 'styleguide/components/RoundIcon';
import Button from 'styleguide/components/Button';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicSubHeading from 'styleguide/components/BasicSubHeading';
import { ReactComponent as IconInfo } from 'components/icons/IconInfo.svg';

import { Wrapper } from './styled';

const NotFoundOrder: FC = () => (
  <>
    <Helmet>
      <title>Page not found</title>
    </Helmet>
    <SimpleLayout>
      <Wrapper>
        <Spacing direction="top" value={80} />
        <RoundIcon>
          <IconInfo />
        </RoundIcon>
        <Spacing direction="top" value={40} />
        <BasicSubHeading isUppercase isSlim size="xxs">
          <FormattedMessage id="notFound.helper" />
        </BasicSubHeading>
        <Spacing direction="top" value={18} />
        <BasicHeading tag="h1" size="xxxxl" isCentered>
          <FormattedMessage id="notFound.title" />
        </BasicHeading>
        <Spacing direction="top" value={34} />
        <a href="https://www.dealbinder.com">
          <Button primary>
            <FormattedMessage id="notFound.button" />
          </Button>
        </a>
      </Wrapper>
    </SimpleLayout>
  </>
);

export default NotFoundOrder;
