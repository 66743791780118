import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const getMatterUrl = (id: string): string => `${url}/matters/${id}`;
const getHitOrderLineItemsUrl = (id: string): string =>
  `${url}/matters/${id}/order-line-items`;
const getFilesUrl = (id: string): string => `${getMatterUrl(id)}/files`;
const getReportUrl = (id: string): string => `${getFilesUrl(id)}/full-report`;
const getFilesListUrl = (id: string): string =>
  `${getMatterUrl(id)}/files/list`;
const getOrderLineItemFileUrl = (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): string =>
  `${getMatterUrl(
    matterId
  )}/orders/${orderId}/order-line-items/${orderLineItemId}/file`;
const getCoworkersUrl = (matterId: string): string =>
  `${getMatterUrl(matterId)}/sharing/invites/coworkers`;
const getInvitesUrl = (matterId: string): string =>
  `${getMatterUrl(matterId)}/sharing/invites`;
const getInviteUrl = (matterId: string, roleId: string): string =>
  `${getInvitesUrl(matterId)}/${roleId}`;
const getShareLinkUrl = (matterId: string): string =>
  `${getMatterUrl(matterId)}/sharing/links`;
const getAssignUrl = (matterId: string): string =>
  `${getMatterUrl(matterId)}/assign`;
const getTransferMatterUrl = (matterId: string): string =>
  `${url}/admin/matters/${matterId}/transfer`;
const getEmailFileUrl = (matterId: string, orderId: string, orderLineItemId: string) =>
  `${getOrderLineItemFileUrl(matterId, orderId, orderLineItemId)}/email`;

export interface MatterFetchResponse {
  type: 'error' | 'data';
  payload: MatterFetchError | MatterData;
}

export interface MatterFetchError {
  status: number;
}

// TODO: fill in interfaces:
export interface HitDetails {
  amount?: string;
  dealbinderMessage?: string;
  fileDate?: string;
  fileNumber?: string;
  group?: string;
  id: string;
  name?: string;
  plaintiff?: string;
  securedParty?: string;
  type?: string;
}
export interface Priority {
  id: string;
  priorityStatus: boolean;
  createdAt: Date;
}
export interface Note {
  id: string;
  text: string;
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
  author: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export interface ReviewHistoryContact {
  email: string;
  firstName: string;
  lastName: string;
}

export interface ReviewHistory {
  id: string;
  reviewStatus: string;
  createdAt: string;
  reviewer: ReviewHistoryContact;
}

export interface OrderLineItem {
  id: string;
  status: string;
  diligenceName: string;
  file?: boolean;
  source1?: string;
  source1Date?: string;
  source2?: string;
  source2Date?: string;
  yearsBack?: string;
  dealbinderMessage?: string;
  estimatedTurnaround?: string;
  jurisdiction: {
    jurisdictionShort:string
    name: string;
  };
  product: {
    name: string;
    mobileName: string;
    family: string;
    sortOrder: number;
  };
  reviewHistory?: ReviewHistory[];
}

export interface HitOrderLineItem extends OrderLineItem {
  hitDetails: HitDetails[];
  priorities: Priority[];
  notes: Note[];
}

export interface Order {
  id: string;
  invoiceStatus: string;
  invoiceLink: string;
  orderLineItems: OrderLineItem[];
}

export interface Owner {
  email: string;
  firstName: string;
  lastName: string;
}

export interface MatterData {
  name: string;
  orders: Order[];
  ofacSourceDetail: string;
  dealbinderMessage: string;
  searchNotes: string;
  orgNotes: string;
  ofacNotes: string;
  role: string;
  closingDate: string;
  owner: Owner;
  // TODO: extend interface
}

export const fetchMatterData = async (
  id: string
): Promise<MatterFetchResponse> => {
  const client = await getAuthorizedApi();
  try {
    const response = await client.get<MatterData>(getMatterUrl(id));
    return { type: 'data', payload: response.data };
  } catch (err:any) {
    return { type: 'error', payload: { status: err.response.status } };
  }
};

export interface FileData {
  id: string;
  name: string;
  productName: string;
  jurisdictionShort: string;
  reviewStatus: string;
  productNameMobile: string;
}

export interface FilesList {
  searchesFiles: FileData[];
  ofacFiles: FileData[];
  orgDocsFiles: FileData[];
}

const createFileData = (responseFile: any): FileData => ({
  id: responseFile.oli_id,
  name: responseFile.oli_diligenceName,
  productName: responseFile.product_name,
  productNameMobile: responseFile.product_mobileName,
  jurisdictionShort: responseFile.jurisdiction_jurisdictionShort,
  reviewStatus: responseFile.review_reviewStatus
});

export const fetchFilesData = async (id: string): Promise<FilesList> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(getFilesListUrl(id));

  const hits = data;

  const isOrgDoc = (name: string): boolean =>
    name === 'Charter Documents' || name === 'Good Standing';

  const searchesFiles = hits
    .filter(
      (item: any) =>
        item.product_name !== 'OFAC' &&
        !isOrgDoc(item.product_name) &&
        item.oli_status === 'Hit'
    )
    .map(createFileData);
  const ofacFiles = hits
    .filter(
      (item: any) => item.product_name === 'OFAC' && item.oli_status === 'Hit'
    )
    .map(createFileData);
  const orgDocsFiles = hits
    .filter(
      (item: any) =>
        isOrgDoc(item.product_name) && item.oli_status === 'Received'
    )
    .map(createFileData);

  return {
    searchesFiles,
    ofacFiles,
    orgDocsFiles
  };
};

export interface DownloadedFileData {
  data: BlobPart;
  filename: string;
  type: string;
}

export const downloadFiles = async (
  id: string,
  filesIds: string[]
): Promise<DownloadedFileData> => {
  const client = await getAuthorizedApi();
  const { data, headers } = await client.get(getFilesUrl(id), {
    params: { files: filesIds },
    responseType: 'blob'
  });
  const filename = headers['content-disposition']
    .split(';')[1]
    .trim()
    .split('=')[1]
    .replace(/"/g, '');

  return { data, filename, type: headers['content-type'] };
};

export const downloadReport = async (
  id: string,
  docType:string,
  includedFiles?: string[],
): Promise<DownloadedFileData> => {
  const client = await getAuthorizedApi();
  const { data, headers } = await client.get(getReportUrl(id), {
    params: { includedFiles,docType },
    responseType: 'blob'
  });
  const filename = headers['content-disposition']
    .split(';')[1]
    .trim()
    .split('=')[1]
    .replace(/"/g, '');

  return { data, filename, type: headers['content-type'] };
};

export interface EditMatterParams {
  name?: string;
  closingDate?: string;
}

export const editMatter = async (
  id: string,
  params: EditMatterParams
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.patch(getMatterUrl(id), params);
};

export const downloadOrderLineItemFile = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string
): Promise<BlobPart> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(
    getOrderLineItemFileUrl(matterId, orderId, orderLineItemId),
    {
      responseType: 'blob'
    }
  );

  return data;
};

export const fetchHitData = async (
  matterId: string
): Promise<HitOrderLineItem[]> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get<HitOrderLineItem[]>(
    getHitOrderLineItemsUrl(matterId)
  );

  return data;
};

export interface CoworkerInfo {
  email: string;
  shareEmail?: string;
}

export const fetchCoworkersEmail = async (
  matterId: string
): Promise<CoworkerInfo[]> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(getCoworkersUrl(matterId));

  return data.map((coworker: any) => ({
    email: coworker.contact,
    shareEmail: coworker.shareContact?.firstName
  }));
};

export const inviteToMatter = async (
  matterId: string,
  email: string,
  role: string,
  doNotify: boolean,
  message?: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getInvitesUrl(matterId), {
    invitee: email,
    role,
    doNotify,
    message
  });
};

export interface InvitedUser {
  id: string;
  contact: string;
  role: string;
  createdAt: string;
  shareContact?: {
    firstName: string;
  };
}

export const fetchInvitesList = async (
  matterId: string
): Promise<InvitedUser[]> => {
  const client = await getAuthorizedApi();
  const { data } = await client(getInvitesUrl(matterId));
  return data;
};

export const updateInvite = async (
  matterId: string,
  roleId: string,
  role: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.patch(getInviteUrl(matterId, roleId), { role });
};

export const transferMatter = async (
  matterId: string,
  email: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getTransferMatterUrl(matterId), { email });
};

export const deleteInvite = async (
  matterId: string,
  roleId: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.delete(getInviteUrl(matterId, roleId));
};

export const sendMagicLink = async (
  matterId: string,
  email: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getShareLinkUrl(matterId), { invitee: email });
};

export const getAssign = async (matterId: string): Promise<string> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(getAssignUrl(matterId));
  return data?.assignedTo;
};

export const assign = async (
  matterId: string,
  assignedTo: string,
  email: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getAssignUrl(matterId), { assignedTo, email });
};

export const unassign = async (matterId: string): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.delete(getAssignUrl(matterId));
};

export const emailFile = async (
  matterId: string,
  orderId: string,
  orderLineItemId: string,
  emails: string[],
  subject: string,
  message: string
): Promise<void> => {
  const client = await getAuthorizedApi();
  await client.post(getEmailFileUrl(matterId, orderId, orderLineItemId), { to: emails, subject, message });
};
