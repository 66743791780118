export default {
  titleOne: `Smarter, faster search reports`,
  title: 'Smarter, faster search reports and organizational documents',
  subtitle: 'Get an instant estimate and start ordering today.',
  help: 'Need help?',
  resetPassword: `reset it`,
  forgotPassword: `Forgot password?`,
  emailFieldPlaceholder: 'Enter your email',
  passwordFieldPlaceholder: 'Enter password',
  signUpText: 'Don’t have an account?'
};
