import React, { FC, FocusEvent, ChangeEvent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import Error from 'styleguide/components/Error';

import { FormLabel, TextInput } from './styled';

interface TextFieldProps {
  handleChange: (e: ChangeEvent) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  touched: boolean;
  error: string;
  showLabel?: boolean;
}

const TextField: FC<TextFieldProps> = ({
  handleChange,
  handleBlur,
  name,
  value,
  touched,
  error,
  showLabel
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormLabel as="label" color="gray600">
      { showLabel && <FormattedMessage id={`registration.${name}`} /> }
      <TextInput
        onChange={handleChange}
        onBlur={handleBlur}
        id={name}
        name={name}
        value={value}
        placeholder={formatMessage({
          id: `registration.${name}Placeholder`
        })}
        invalid={Boolean(touched && error)}
      />
      {touched && error && <Error>{formatMessage({ id: error })}</Error>}
    </FormLabel>
  );
};

export default TextField;
