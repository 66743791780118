import React, { FC } from 'react';

import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { REGISTRATION_URL } from 'helpers/routes';
import Spacing from 'styleguide/components/Spacing';

import LegalPagesModal from 'components/shared/legalPagesModal/LegalPagesModal';
import LoginForm from './LoginForm';
import {
  RightColumnContent,
  Header,
  HeaderLink,
  FooterText
} from './styled';
import logo from 'components/icons/dealbinder.png';
import { useDeviceState } from 'state/deviceState';


const LoginRightColumn: FC = () => {
  const location = useLocation();
  const [state] = useDeviceState();

  return (
    <>
      <RightColumnContent>
        {state.isDesktop && <Header>
          <a href="https://www.dealbinder.com" >
            <img src={logo} alt="Dealbinder logo" />
          </a>

          <HeaderLink to={`${REGISTRATION_URL}${location.search}`} >
            <FormattedMessage id="shared.signUp" />
          </HeaderLink>
        </Header>}
        { state.isDesktop ? <Spacing direction="top" value={80} /> : <Spacing direction="top" value={0} /> }

        <LoginForm />

        <FooterText className="text-lg-left text-center">
          <LegalPagesModal
            title={<FormattedMessage id="links.privacyPolicy" />}
            type="privacyPolicy"
          />{' '}
        and{' '}
          <LegalPagesModal
            title={<FormattedMessage id="links.termsOfService" />}
            type="termsOfService"
          />
        </FooterText>
      </RightColumnContent>

    </>
  );

};

export default LoginRightColumn;
