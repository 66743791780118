import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_URL } from 'helpers/routes';
import Snackbar from 'styleguide/components/Snackbar';
import Spacing from 'styleguide/components/Spacing';
import HorizontalLine from 'styleguide/components/HorizontalLine';
import { ResetSuccessWrapper, HelpText } from './styled';
import { ResetPasswordSecondStepProps } from './ResetPassword';
import { auth } from 'firebaseConfig';

export interface LoginFormValues {
  email: string;
  password: string;
}
const ResetPasswordSecondStep: FC<ResetPasswordSecondStepProps> = ({ currentEmail, currentPassword }) => {
  const history = useHistory();
  const handleRedirect = useCallback(async () => {
    try{
       await auth.signInWithEmailAndPassword(currentEmail, currentPassword);
       history.push({
        pathname: DASHBOARD_URL
      })
    }catch(error){
      console.log(error);
    }
    
  }, [currentEmail,history, currentPassword])
  useEffect(() => {
    
    setTimeout(() => {
      handleRedirect();
    }, 5000);
  }, [handleRedirect]);


  return (
    <ResetSuccessWrapper>
      <Snackbar type="success" width="272px">
        <FormattedMessage id="resetPassword.confirmation" />
      </Snackbar>
      <Spacing direction="top" value={36} />
      <HorizontalLine width="320px" />
      <Spacing direction="top" value={24} />
      <HelpText size="regular">
        <FormattedMessage id="resetPassword.successHelpText" />{' '}
        <Link to={{ pathname: DASHBOARD_URL}}>
          <FormattedMessage id="resetPassword.successHelpTextLink" />
        </Link>
      </HelpText>
    </ResetSuccessWrapper>
  );
};

export default ResetPasswordSecondStep;
