import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import ButtonGroup from 'styleguide/components/ButtonGroup';
import Badge from 'styleguide/components/Badge';
import iconFlag from 'components/icons/iconTagFlag.svg';
import { getSearchesUrl, getHitDetailsUrl } from '../../constants';
import { useMatterState } from '../../state/matter';

const HitsBadge = styled(Badge)`
padding-right:0
`;

const SearchesNav = () => {
  const history = useHistory();
  const location = useLocation();
  const [
    {
      id,
      view: { searches }
    }
  ] = useMatterState();

  const tabUrls: { [key: string]: string } = useMemo(
    () => ({
      searches: getSearchesUrl(id),
      hitDetails: getHitDetailsUrl(id)
    }),
    [id]
  );

  const activeTab = useMemo(
    () => Object.keys(tabUrls).find(tab => location.pathname === tabUrls[tab]),
    [tabUrls, location.pathname]
  );

  const handleTabChange = useCallback(
    (id: string) => {
      history.push(tabUrls[id]);
    },
    [history, tabUrls]
  );

  return (
    <>
      {searches.orderLineItems.some(oli => oli.status === 'Hit') ? (
        <ButtonGroup.Wrapper>
          <ButtonGroup.Button
            onClick={() => handleTabChange('searches')}
            active={activeTab === 'searches'}
            style={{maxHeight:"42px"}}
          >
            All Results
          </ButtonGroup.Button>
          <ButtonGroup.Button
            onClick={() => handleTabChange('hitDetails')}
            active={activeTab === 'hitDetails'}
            style={{paddingTop:"5px",paddingBottom:"5px",paddingLeft:"0",maxHeight:"42px"}}
          >
            <HitsBadge color="red" icon={<img src={iconFlag} alt="" />} />
            Hit Details
          </ButtonGroup.Button>
        </ButtonGroup.Wrapper>
      ) : null}
    </>
  );
};

export default SearchesNav;
