import React, { FC } from 'react';
import { Field } from 'formik';
import { ValueType } from 'react-select';
import Select, { OptionType } from 'styleguide/components/Select';

interface SupportModalMatterSelectProps {
  name: string;
  matters: OptionType[];
  isLoading: boolean;
}

const SupportModalMatterSelect: FC<SupportModalMatterSelectProps> = ({
  name,
  matters,
  isLoading
}) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue } }: any) => (
      <Select
        options={matters}
        placeholder={isLoading ? 'Loading...' : 'Select matter'}
        value={value}
        onChange={(val: ValueType<OptionType>) => setFieldValue(name, val)}
      />
    )}
  </Field>
);

export default SupportModalMatterSelect;
