import { auth } from 'firebaseConfig';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import api from '../apiInstance';
import { url } from '../url';

interface URLParams {
  [key: string]: any;
}

const authorizedGetRequest = async (
  endpoint: string,
  config: AxiosRequestConfig = {}
) => {
  const token = await auth.currentUser.getIdToken();
  return api.get(`${url}${endpoint}`, {
    ...config,
    headers: { ...(config.headers || {}), Authorization: `Bearer ${token}` }
  });
};

export const fetchJurisdictions = (params: URLParams = {}) =>
  authorizedGetRequest('/jurisdictions', { params });

export const fetchProducts = (params: URLParams = {}) =>
  authorizedGetRequest('/products', { params });

type OrderResponse = {
  createdAt: string;
};

type MattersResponse = {
  matter: {
    id: string;
    name: string;
    createdAt: string;
    orders: OrderResponse[];
  };
  role?: string,
  contact?: string
}[];

export const fetchMatters = (
  params: URLParams = {}
): Promise<AxiosResponse<MattersResponse>> =>
  authorizedGetRequest('/matters', { params });
