import React, { FC, useCallback, useMemo, useState } from 'react';
import { useMediaQuery } from "@material-ui/core";
import Spacing from 'styleguide/components/Spacing';
import Tooltip from 'styleguide/components/Tooltip';
import BasicText from 'styleguide/components/BasicText';
import { UserCircle } from '../common/styled';
import { theme } from 'theme/theme';
import { ReactComponent as DeleteIcon } from 'components/icons/IconDelete2.svg';
import { ReactComponent as AttachIcon } from 'components/icons/IconAttach.svg';

// import RoleSelect, { accessOptions } from './RoleSelect';
import RoleDropdown, { accessOptions } from './RoleDropdown';
import {
  UserRow,
  UserRowButton,
  UserRowName,
  UserRowDescription,
  Row,
  UserRowRoleText,
  HorizontalLine,
  UserRowDetailsColumn,
  DeleteSnackbar,
  DeleteRowButton,
  DeleteRowButtonPrimary,
  DeleteRowLoader,
  TextDiv
} from './styled';

interface UserAccessRowProps {
  name: string;
  initials: string;
  description?: string;
  role: string;
  index: number;
  onRoleChange?: (role: string) => void;
  onRoleDelete?: () => Promise<void>;
  isEditable: boolean;
  isMagicLink: boolean;
  isAdmin?: boolean;
}

const UserAccessRow: FC<UserAccessRowProps> = ({
  name,
  initials,
  description,
  role,
  index,
  onRoleChange,
  onRoleDelete,
  isEditable,
  isMagicLink,
  isAdmin
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const color = useMemo(
    () =>
      Object.values(theme.userCircleColors)[index >= 10 ? index % 10 : index],
    [index]
  );
  const roleValue = useMemo(
    () => accessOptions.find(option => option.value === role),
    [role]
  );
 
  const handleDelete = useCallback(() => {
    setIsDeleteLoading(true);
    onRoleDelete().then(() => setIsDeleteLoading(false));
  }, [onRoleDelete]);

  return (
    <>
      {isDeleteOpen && (
        <>
          <DeleteSnackbar type="info">
            <div>
              Are you sure you want to delete this {isMagicLink ? 'link' : 'invite'}?
              <br />
              {!isMagicLink ?
                'This person will lose access to this matter.' :
                'This person will no longer be able to access this matter through this link.'
              }
            </div>
            <Spacing direction="top" value={5} />
            <Row>
              <Spacing direction="right" value="auto" />
              {isDeleteLoading && <DeleteRowLoader background="#fcf2e8" />}
              <DeleteRowButton
                resetOrder
                medium
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </DeleteRowButton>
              <Spacing direction="left" value={8} />
              <DeleteRowButtonPrimary
                primary
                resetOrder
                medium
                onClick={handleDelete}
                disabled={isDeleteLoading}
              >
                Delete
              </DeleteRowButtonPrimary>
            </Row>

          </DeleteSnackbar>
          <Spacing direction="top" value={14} />
        </>
      )}
      <UserRow isDeleteOpen={isDeleteOpen}>
        {!isMagicLink ? <UserCircle background={color}>{initials}</UserCircle> :
          <Spacing direction="left" value={8} />
        }
        
        <TextDiv>
          <Row>
            <Spacing direction="left" value={8} />
            <UserRowDetailsColumn>
              <UserRowName size="sm">{isMobile ? name.slice(0, 12).concat('...') : name}</UserRowName>
              {description && (
                <UserRowDescription className='text-lowercase' size="xs">{description}</UserRowDescription>
              )}
            </UserRowDetailsColumn>
          </Row>
        </TextDiv>
        {roleValue?.description && (
          <Tooltip id={`role-tooltip-${name}`}>
            {roleValue?.description} 
          </Tooltip>
        )}
        {isMagicLink && (
          <Row data-tip data-for={`role-tooltip-${name}`}>
            <Spacing direction="left" value={19} />
            <div>
              <BasicText size="sm">{roleValue?.label}</BasicText>
              <Spacing direction="top" value={5} />
              <Row>
                <AttachIcon />
                <Spacing direction="left" value={8} />
                <UserRowRoleText size="sm">Shared link</UserRowRoleText>
              </Row>
            </div>
          </Row>
        )}
        {!isEditable && !isMagicLink && (
          <>
            <Row data-tip data-for={`role-tooltip-${name}`}>
              <Spacing direction="left" value={19} />
              <UserRowRoleText size="sm">
                {role === 'owner' ? role : roleValue?.label}
              </UserRowRoleText>
            </Row>
          </>
        )}
        {isEditable && !isMagicLink && (
          // <RoleSelect
          //   value={roleValue}
          //   onChange={(val: any) => onRoleChange(val.value)}
          //   isAdmin={isAdmin}
          // />
          <RoleDropdown
            value={roleValue.value}
            onChange={(e: any) => onRoleChange(e.target.value)}
            isAdmin={isAdmin}
          />

        )}
        {isEditable && (
          <div>
            <UserRowButton onClick={() => setIsDeleteOpen(true)}>
              <DeleteIcon />
            </UserRowButton>
          </div>
        )}
      </UserRow>
      <HorizontalLine width="100%" />
    </>
  );
};

export default UserAccessRow;
