import styled from 'styled-components';

const TextTable = styled.div`
    table {
        width: 100%;
    }

    td, th {
        border-bottom: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
        padding: 16px 5px;
    }

    th {
        font-weight: bold;
    }

    tfoot td {
        text-align: right;
        border-bottom: none;
        font-weight: bold;
    }
`;

export default TextTable;