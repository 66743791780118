import {
  createStore,
  createSubscriber,
  createHook,
  StoreActionApi
} from 'react-sweet-state';

import { AxiosResponse } from 'axios';
import { fetchJurisdictions } from 'api/data';

export type Jurisdiction = {
  id: string;
  county: string;
  countyShort: string;
  district: string;
  name: string;
  state: string;
  stateShort: string;
  stateId: string;
  sortOrder: number;
  type: string;
};

export type JurisdictionState = {
  popular: Jurisdiction[];
  states: Jurisdiction[];
  all: Jurisdiction[];
  page?: number;
  count?: number;
  total?: number;
  pageCount?: number;
  isLoading: boolean;
  isLoaded: boolean;
};

type StoreApi = StoreActionApi<JurisdictionState>;
type Actions = typeof actions;

const initialState: JurisdictionState = {
  popular: [],
  states: [],
  all: [],
  page: 0,
  pageCount: 0,
  isLoading: false,
  isLoaded: false
};

export interface JurisdictionFetchResponse {
  data: Jurisdiction[];
  page?: number;
  count?: number;
  total?: number;
  pageCount?: number;
}

export const actions = {
  loadInitial: () => async ({ getState, setState }: StoreApi) => {
    const { isLoading, isLoaded } = getState();
    if (isLoading || isLoaded) return;

    setState({ ...getState(), isLoading: true });

    const popularResponse = await fetchJurisdictions({
      filter: 'type||$eq||Popular'
    });
    const statesResponse = await fetchJurisdictions({
      filter: 'county||$isnull'
    });
    const allResponse = await fetchJurisdictions({
      page: 1,
      limit: 50
    });

    setState({
      ...getState(),
      popular: popularResponse.data,
      states: statesResponse.data,
      all: allResponse.data.data,
      page: allResponse.data.page,
      pageCount: allResponse.data.pageCount,
      isLoading: false,
      isLoaded: true
    });
  },
  loadNextPage: () => async ({ getState, setState }: StoreApi) => {
    const state = getState();

    if (state.page + 1 >= state.pageCount || state.isLoading) return;

    setState({ ...state, isLoading: true });
    const response: AxiosResponse<JurisdictionFetchResponse> = await fetchJurisdictions(
      {
        page: state.page + 1,
        limit: 50
      }
    );
    setState({
      ...state,
      all: [...state.all, ...response.data.data],
      page: response.data.page,
      pageCount: response.data.pageCount,
      isLoading: false
    });
  }
};

const Store = createStore<JurisdictionState, Actions>({
  initialState,
  actions,
  name: 'jurisdiction'
});

export const useJurisdictionState = createHook(Store);
export const JurisdictionStateSubscriber = createSubscriber(Store);
