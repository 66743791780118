import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Tab = styled.div<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  line-height: 1.3;
  padding: 3px 15px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primary};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
    background: #EEF1FF;
    border: 1px solid #D2D8F1;
    border-radius: 4px;
    color: ${({ theme }) => theme.colorsNewDesign.primary};
    `}
`;

interface Tab {
  id: string;
  label: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onChange: (id: string) => void;
}

const Tabs: FC<TabsProps> = ({ tabs, activeTab, onChange }) => (
  <Wrapper>
    {tabs.map(tab => (
      <Tab
        key={tab.id}
        isActive={activeTab === tab.id}
        onClick={() => onChange(tab.id)}
      >
        {tab.label}
      </Tab>
    ))}
  </Wrapper>
);

export default Tabs;
