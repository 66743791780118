import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import StyleguideFormLabel from 'styleguide/components/FormLabel';
import StyleguideTextInput from 'styleguide/components/TextInput';
import StyleguidePasswordInput from 'styleguide/components/PasswordInput';
import StyleguideButton from 'styleguide/components/Button';
import StyleguideSelect from 'styleguide/components/Select';
import BaseLink from 'components/shared/baseLink/BaseLink';
import { ReactComponent as Check } from 'components/icons/IconCheckCircle.svg';
import { ReactComponent as LogoColor } from 'components/icons/LogoColor.svg';

export const LeftColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 165px 0 40px 0;
  width: 595px;
`;

export const Title = styled(BasicHeading)`
  color: white;
`;

export const DescriptionItem = styled.div`
  display: flex;
  margin-left: 24px;
`;

export const CheckIcon = styled(Check)`
  opacity: 0.4;

  path {
    fill: white;
  }
`;

export const DescriptionText = styled(BasicText)`
  color: white;
`;

export const HelpText = styled.div`
  margin-top: auto;
  color: white;
  opacity: 0.4;
  font-size: ${({ theme }) => theme.fontSizesNewDesign.xs};
`;

export const HelpTextLink = styled(BaseLink)`
  color: inherit;

  &:hover {
    color: inherit;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
`;

// width: 327px;
export const RightColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  margin: 48px 0 40px 0;
`;

export const Logo = styled(LogoColor)`
  width: 133px;
`;

export const Button = styled(StyleguideButton)`
  padding: 15px 16px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 64px;
`;

export const HeaderButton = styled(Button)`
  order: 0;
`;

export const HeaderLink = styled(Link)`
  color: ${({ theme }) => theme.colorsNewDesign.primary};
  margin-left: auto;
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md2};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  }
`;

export const FooterText = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  margin-top: auto;

  a {
    color: ${({ theme }) => theme.colorsNewDesign.gray600};
    text-decoration: underline;

    &:hover {
      color: ${({ theme }) => theme.colorsNewDesign.gray700};
    }
  }
`;

export const FormLabel = styled(StyleguideFormLabel)`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const TermsLabel = styled(StyleguideFormLabel)`
  display: flex;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.sm};
  align-items: center;

  label {
    display: flex;
  }

  span {
    font-size: inherit;
  }
`;

export const TextInput = styled(StyleguideTextInput)`
  margin: 8px 0;
`;

export const PasswordInput = styled(StyleguidePasswordInput)`
  margin: 8px 0;
`;

export const SignUpText = styled.div`
  color: ${({ theme }) => theme.colorsNewDesign.gray600};
  font-size: ${({ theme }) => theme.fontSizesNewDesign.regular};
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
`;

export const Select = styled(StyleguideSelect)`
  && .react-basic-select__control {
    margin: 8px 0;

    ${({ theme, invalid }) =>
      invalid &&
      `
      border: 1px solid ${theme.colorsNewDesign.error};    
    `}
  }

  && .react-basic-select__value-container {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;
