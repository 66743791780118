import * as yup from 'yup';

import {
  ONLY_LETTERS_VALIDATION,
  POSTAL_CODE_VALIDATION,
  PHONE_NUMBER_VALIDATION
} from 'helpers/validation';
import { UserDetails } from 'api/settings';
import { STATES } from 'pages/registration/contants';

export const validationSchema = yup
  .object()
  .shape<
    Pick<
      UserDetails,
      | 'firstName'
      | 'lastName'
      | 'firmAddress'
      | 'city'
      | 'state'
      | 'postalCode'
      | 'phone'
    >
  >({
    firstName: yup
      .string()
      .required('errors.firstName')
      .min(1, 'errors.firstName')
      .matches(ONLY_LETTERS_VALIDATION, {
        message: 'errors.firstName',
        excludeEmptyString: true
      }),
    lastName: yup
      .string()
      .required('errors.firstName')
      .min(2, 'errors.firstName')
      .matches(ONLY_LETTERS_VALIDATION, {
        message: 'errors.firstName',
        excludeEmptyString: true
      }),
    firmAddress: yup
      .string()
      .required('errors.firmAddress')
      .min(5, 'errors.firmAddress'),
    city: yup
      .string()
      .required('errors.city')
      .min(5, 'errors.city'),
    state: yup
      .string()
      .required('errors.state')
      .oneOf(STATES, 'errors.state'),
    postalCode: yup
      .string()
      .required('errors.postalCode')
      .matches(POSTAL_CODE_VALIDATION, 'errors.postalCode'),
    phone: yup
      .string()
      .required('errors.phone')
      .matches(PHONE_NUMBER_VALIDATION, 'errors.phone')
  });
