import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  @media (max-width: 768px) {
    min-height: calc(100vh - 56px);
  } 

`;

// max-width: 824px;
export const LeftColumn = styled.div`
  background: ${({ theme }) => theme.colorsNewDesign.primaryHover};
  display: flex;
  justify-content: center;
  flex-grow: 1;

    
`;

export const RightColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`;
