import { getMatterUrl } from 'helpers/routes';

export const getMatterTabUrl = (id: string, tab: string): string =>
  `${getMatterUrl(id)}/${tab}`;
export const getOverviewUrl = (id: string): string => getMatterUrl(id);
export const getSearchesUrl = (id: string): string =>
  getMatterTabUrl(id, 'searches');
  export const getHitDetailsUrl = (id: string): string =>
  getMatterTabUrl(id, 'hit-details');
export const getOfacUrl = (id: string): string => getMatterTabUrl(id, 'ofac');
export const getDocumentsUrl = (id: string): string =>
  getMatterTabUrl(id, 'org-docs');
export const getFilePreviewUrl = (
  matterId: string,
  matterTab: string,
  orderLineItemId: string
): string =>
  `${getMatterTabUrl(matterId, matterTab)}/filePreview/${orderLineItemId}`;
