import styled, { css } from 'styled-components';

interface ButtonProps {
  primary?: boolean;
  danger?: boolean;
  disabled?: boolean;
  small?: boolean;
  medium?: boolean;
  wide?: boolean;
  variant?: 'inline' | 'contained' | 'outlined';
  width?: string;
  isCentered?: boolean;
  resetOrder?: boolean;
  active?: boolean;
}

export const LeftButtonIcon = styled.span`
  & > * {
    display: block;
    margin-right: 13px;
  }
`;

export const RightButtonIcon = styled.span`
  & > * {
    display: block;
    margin-left: 13px;
  }
`;

const Button = styled.button<ButtonProps>`
  background: ${({ theme }) => theme.colorsNewDesign.lightest};
  border: 1px solid ${({ theme }) => theme.colorsNewDesign.gray100};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 19px 24px;
  display: flex;
  flex-direction: row;
  font-family: ${({ theme }) => theme.fontFamilies.gilroy};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  font-size: ${({ theme }) => theme.fontSizes.md2};
  line-height: 1.2;
  transition: background 100ms ease-in;
  text-align: center;
  color: ${({ theme }) => theme.colorsNewDesign.darkest};
  flex: none;
  order: ${({ resetOrder }) => (resetOrder ? 0 : 1)};
  align-self: center;
  margin: 8px 0;
  align-items: center;
  justify-content:center;
  min-width: ${({ width }) => width};

  &:hover {
    background: ${({ theme }) => theme.colorsNewDesign.lightBlue};
  }

  svg path {
    fill: ${({ theme }) => theme.colorsNewDesign.darkest}; 
}

${({ medium }) =>
  medium &&
  css`
    padding: 15px 24px;
  `}

${({ small, theme }) =>
  small &&
  css`
    padding: 6px 24px;
    font-size: ${theme.fontSizes.sm};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colorsNewDesign.gray200};

      &:hover {
        background: ${theme.colorsNewDesign.lightest};
      }
    `}

  ${({ primary, disabled, theme }) =>
    primary &&
    css`
      background: ${theme.colorsNewDesign.primary};

      box-shadow: ${theme.boxShadow.strong};
      border: none;
      color: ${theme.colorsNewDesign.lightest};

      &:hover {
        background: ${theme.colorsNewDesign.primaryHover};
      }

      svg path {
        fill: ${theme.colorsNewDesign.lightest};
      }

      ${disabled &&
        css`
          background: ${theme.colorsNewDesign.gray200};

          &:hover {
            background: ${theme.colorsNewDesign.gray200};
          }
        `}
    `}

  ${({ variant, theme }) =>
    variant === 'inline' &&
    css`
      color: ${theme.colorsNewDesign.primary};
      background: transparent;
      border: none;
      padding: 0;
      display: inline;

      &:hover {
        background: transparent;
      }
    `}
  
    ${({ isCentered }) =>
      isCentered &&
      css`
        margin-left: auto;
        margin-right: auto;
      `};

      ${({ danger, variant, theme }) =>
        danger &&
        css`
          background-color: ${variant === 'outlined'
            ? 'transparent'
            : theme.colorsNewDesign.error};
          color: ${variant === 'outlined'
            ? theme.colorsNewDesign.error
            : theme.colorsNewDesign.lightest};
          border-color: ${variant === 'outlined'
            ? theme.colorsNewDesign.error
            : 'transparent'};
          &:hover {
            background-color: ${theme.colorsNewDesign.error};
            color: ${theme.colorsNewDesign.lightest};
          }
        `}

        ${({ active, theme }) =>
          active &&
          css`
            background: ${theme.colorsNewDesign.grayBlue100};
            color: ${theme.colorsNewDesign.primary};
            border: 1px solid ${theme.colorsNewDesign.grayBlue200};

            svg path {
              fill: ${theme.colorsNewDesign.primary};
            }

            &:hover {
              background: ${theme.colorsNewDesign.grayBlue100};
            }
          `}
`;

Button.defaultProps = {
  variant: 'contained'
};

export default Button;
