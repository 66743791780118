import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useOrderFormState } from './state/state';
import CenteredLayout from 'styleguide/components/CenteredLayout';
import BasicBox from 'styleguide/components/BasicBox';
import Button from 'styleguide/components/Button';
import Center from 'styleguide/components/Center';
import BasicHeading from 'styleguide/components/BasicHeading';
import Spacing from 'styleguide/components/Spacing';
import FadedText from 'styleguide/components/FadedText';
import OrderConfirmIcon from 'components/icons/OrderConfirm.svg';
import { getMatterUrl } from 'helpers/routes';
import { useDashboardHomeState } from 'pages/dashboard/state/home';

const Confirm: FC = () => {
  const { push } = useHistory();
  const [state, { exit }] = useOrderFormState();
  const [, actions] = useDashboardHomeState();
  React.useEffect(
    () => () => {
      // resets form state after the user leaves last page
      exit();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  React.useEffect(() => {
    actions.fetchData();
  }, [actions]);
  const returnLink = React.useCallback(() => {
    if (state.matterId && state.review?.stage !== 'estimate') {
      push({
        pathname: getMatterUrl(state.matterId),
        search: "shareSuggestion"
      });
    } else {
      push({ pathname: '/dashboard' });
    }
  }, [state.matterId, state.review, push]);

  const headerText =
    state?.review?.stage === 'order' ? 'Order received!' : 'Estimate Saved!';
  return (
    <CenteredLayout>
      <Container>
        <Row className="justify-content-md-center">
          <Col md="5">
            <BasicBox title={headerText} headerType="success" isHeaderCentered>
              <Center>
                <Spacing direction="top" value={16}>
                  <img src={OrderConfirmIcon} alt="" />
                </Spacing>
                <Spacing direction="top" value={32}>
                  <BasicHeading tag="div" size="md">
                    We’ve emailed the details to you
                  </BasicHeading>
                </Spacing>
                <Spacing direction="top" value={32}>
                  <Center>
                    <Button primary onClick={returnLink}>
                      Go to dashboard
                    </Button>
                  </Center>
                </Spacing>
              </Center>
            </BasicBox>
            <Spacing direction="top" value={16}>
              <Center>
                <FadedText>
                  <strong>Need help?</strong> Chat with us or{' '}
                  <a href="mailto:info@dealbinder.com">email us</a>
                </FadedText>
              </Center>
            </Spacing>
          </Col>
        </Row>
      </Container>
    </CenteredLayout>
  );
};

export default Confirm;
