import React, { FC, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconDelete } from 'components/icons/IconDelete.svg';

interface DeleteButtonProps {
  className?: string;
}

const StyledDeleteButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsNewDesign.transparent};
  border: none;
  color: ${({ theme }) => theme.colorsNewDesign.gray200};

  &:hover {
    color: ${({ theme }) => theme.colorsNewDesign.error};
  }

  &:disabled {
    color: ${({ theme }) => theme.colorsNewDesign.gray50};
    cursor: not-allowed;

    &:hover {
      color: ${({ theme }) => theme.colorsNewDesign.gray50};
    }
  }
`;

const DeleteButton: FC<DeleteButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, ...restProps }) => (
  <StyledDeleteButton className={className} {...restProps}>
    <IconDelete />
  </StyledDeleteButton>
);

export default DeleteButton;
