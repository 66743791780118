import React, { FC } from 'react';
import BasicModal from 'styleguide/components/BasicModal';
import Button from 'styleguide/components/Button';
import TextTable from 'styleguide/components/TextTable';
import BasicHeading from 'styleguide/components/BasicHeading';
import BasicText from 'styleguide/components/BasicText';
import Center from 'styleguide/components/Center';
import Spacing from 'styleguide/components/Spacing';

import { EstimateData } from '../../utils/formData';
import { currencyFormat } from 'helpers/formatters';
import { useDeviceState } from 'state/deviceState';

interface PriceDetailsModalProps {
  estimate?: EstimateData;
  adminDiscount?: any;
}

const PriceDetailsModal: FC<PriceDetailsModalProps> = ({ estimate, adminDiscount }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deviceState] = useDeviceState()
  if (!estimate.detailedEstimate) return null;
  return (
    <>
      <Button variant="inline" onClick={() => setIsOpen(true)}>
        Show details
      </Button>

      <BasicModal
        isOpen={modalIsOpen}
        onClose={() => setIsOpen(false)}
        title={
          <BasicHeading tag="div" size="xxl">
            Estimate Details
          </BasicHeading>
        }
      >
        <TextTable style={deviceState.isMobileOrTablet ? { width: 'auto', padding: '16px' } : { width: '600px', padding: '16px' }}>
          <table style={deviceState.isMobileOrTablet ? { width: '100%', height: '65vh' } : { width: '100%' }}>
            <thead>
              <tr>
                <th>Service</th>
                <th>
                  <Center>Quantity</Center>
                </th>
                <th>
                  <Center>Rate</Center>
                </th>
                <th>
                  <Center>Amount</Center>
                </th>
              </tr>
            </thead>
            <tbody>
              {estimate.detailedEstimate?.map((estimateDetail, i) => (
                <>
                  <tr>
                    <td>
                      <div>
                        <strong>
                          {estimateDetail.hasSearches
                            ? estimateDetail.county ? estimateDetail.label
                              .split('<BR>')
                              .map(name => <div>{name}</div>)
                              :estimateDetail.label
                            : estimateDetail.products}
                        </strong>
                      </div>
                      <div>
                        {estimateDetail.hasSearches
                          ? estimateDetail.products
                          : null}
                      </div>
                      <div>
                        {estimateDetail.names.split('<BR>').map(name => (
                          <div>{name}</div>
                        ))}
                      </div>
                    </td>
                    <td>
                      <Center>{estimateDetail.quantity}</Center>
                    </td>
                    <td>
                      <Center>${estimateDetail.price}</Center>
                    </td>
                    <td>
                      <Center>${estimateDetail.totalPrice}</Center>
                    </td>
                  </tr>
                  {estimateDetail.hasExpediteFee && (
                    <tr>
                      <td>
                        <div>
                          <strong>
                            Expedite Fee - {estimateDetail.products}
                          </strong>
                        </div>
                        <div>{estimateDetail.turnaroundLanguage}</div>
                      </td>
                      <td>
                        <Center>{estimateDetail.quantity}</Center>
                      </td>
                      <td>
                        <Center>{estimateDetail.expediteFee}</Center>
                      </td>
                      <td>
                        <Center>${estimateDetail.expediteAmount}</Center>
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4}>
                  {estimate.estimate.hasDiscount || adminDiscount ? (
                    <div>
                      <div>
                        Original estimate: $
                        {currencyFormat(estimate.estimate.grossPrice)}
                      </div>
                      {adminDiscount ? <div>
                        Admin discount ({Math.round((adminDiscount * 100) / estimate.estimate.netPrice)}%): $
                        {currencyFormat(adminDiscount)}
                      </div>:null}
                      {estimate.estimate.discount100 && estimate.estimate.discountAmount ? <div>
                        Volume discount ({estimate.estimate.discount100}%): $
                        {currencyFormat(estimate.estimate.discountAmount)}
                      </div>:null}
                      <div>
                        Final estimate: $
                        {currencyFormat(!adminDiscount ? estimate.estimate.netPrice : (estimate.estimate.netPrice - adminDiscount))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      Total estimate: $
                      {currencyFormat(estimate.estimate.grossPrice)}
                    </div>
                  )}
                </td>
              </tr>
            </tfoot>
          </table>
          <Spacing direction="top" value={16}>
            <BasicText size="xs" faded>
              <div>
                * <strong>Includes</strong> typical state fees and correspondent fees.{' '}
              </div>
              Copy costs and taxes (if applicable) not included.
              <div>
                Please note that estimates are not guarantees, though we honor
                them to the best of our ability and treat every order with
                urgency. Price and turnaround time may be affected by variables
                unique to your order or beyond our control (e.g., copious
                results, interruptions in government data access, etc).
              </div>
            </BasicText>
          </Spacing>
        </TextTable>
      </BasicModal>
    </>
  );
};

export default PriceDetailsModal;
