import { auth } from 'firebaseConfig';
import { get } from 'lodash';

import { getAuthorizedApi } from '../apiInstance';
import { url } from '../url';

const fetchDashboardDataUrl = `${url}/dashboard/matters`;
const getFetchInvoicesInfoUrl = (matterId: string) =>
  `${url}/matters/${matterId}`;

export interface DashboardOrder {
  id: string;
  name: string;
  status: 'In progress' | 'Complete';
  isOwner: boolean;
}

export interface DashboardEstimate {
  id: string;
  name: string;
  price: number;
  matterId: string;
}

export interface DashboardOutstandingMatter {
  id: string;
  name: string;
  orderCount: number;
  firstInvoiceLink?: string;
}

interface DashboardOrdersData {
  count: number;
  list: DashboardOrder[];
}

interface DashboardEstimatesData {
  count: number;
  list: DashboardEstimate[];
}

interface DashboardOutstandingMattersData {
  count: number;
  list: DashboardOutstandingMatter[];
}

export interface DashboardData {
  orders: DashboardOrdersData;
  estimates: DashboardEstimatesData;
  outstandingMatters: DashboardOutstandingMattersData;
}

const createOrder = (responseOrder: any, currentUserEmail: any): DashboardOrder => {
  return {
    id: get(responseOrder, 'id'),
    name: get(responseOrder, 'name'),
    status: get(responseOrder, 'status'),
    isOwner: get(responseOrder, 'roles').find((role: any) => role.contact === currentUserEmail)?.role === 'owner'
  }
};

const createEstimate = (responseEstimate: any): DashboardEstimate => ({
  id: get(responseEstimate, 'id'),
  name: get(responseEstimate, 'matter.name'),
  price: get(responseEstimate, 'priceEstimate') || 0,
  matterId: get(responseEstimate, 'matter.id'),
});

const createOutstandingMatter = (
  responseMatter: any
): DashboardOutstandingMatter => ({
  id: responseMatter?.id,
  name: responseMatter?.name,
  orderCount: responseMatter?.orderCount,
  firstInvoiceLink: responseMatter?.orders[0]?.invoiceLink
});

export const fetchDashboardData = async (): Promise<DashboardData> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(fetchDashboardDataUrl);
  let currentUserEmail = await auth.currentUser.email;

  const orders = data.orders.matters.map((order: any) => createOrder(order, currentUserEmail));
  const estimates = data.estimates[0].map(createEstimate);
  const outstandingMatters = data.outstanding.matters.map(
    createOutstandingMatter
  );

  const ordersData: DashboardOrdersData = {
    count: data.orders.inProgressCount,
    list: orders
  };
  const estimatesData: DashboardEstimatesData = {
    count: data.estimates[1] || 0,
    list: estimates
  };
  const outstandingMattersData: DashboardOutstandingMattersData = {
    count: data.outstanding.count,
    list: outstandingMatters
  };

  return {
    orders: ordersData,
    estimates: estimatesData,
    outstandingMatters: outstandingMattersData
  };
};

export interface InvoiceInfo {
  id: string;
  orderDate: string;
  invoiceStatus: string;
  invoiceLink: string;
}

const createInvoiceInfo = (order: any): InvoiceInfo => ({
  id: order.id,
  orderDate: order.orderDate || order.createdAt,
  invoiceStatus: order.invoiceStatus,
  invoiceLink: order.invoiceLink
});

export const fetchInvoicesInfo = async (
  matterId: string
): Promise<InvoiceInfo[]> => {
  const client = await getAuthorizedApi();
  const { data } = await client.get(getFetchInvoicesInfoUrl(matterId));

  const invoices: InvoiceInfo[] = data.orders.map(createInvoiceInfo);

  return invoices;
};
